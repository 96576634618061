import React, { useEffect, useState } from 'react';
import { Grid } from 'amn-ui-core';
import { ControlledTypeAhead } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UnitCollapsible from '@AMIEWEB/Unit/Common/UnitCollapsible';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { TravelExperienceSections } from 'app/enums/Common';
import { CustomTooltip } from '@AMIEWEB/Common';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import { IGroupedTypeAheadOption } from '@AMIEWEB/NewOrder/types';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectTravelExperiences } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { getPositiveIntegerValue } from '@AMIEWEB/NewOrder/CreateOrder/helper';
import { isWholeNumber } from '@AMIEWEB/Order/OrderCreationForm/helper';
import { setIsRequiredValue } from './TravelHelper';
import { useTravelTileStyles } from './Experience.styles';

const MIN_YEARS_EXPERIENCE = 0;

enum MonthsExperience {
  Min = 0,
  Max = 12,
}

enum AssignmentYears {
  Min = 0,
  Max = 11,
}

export const TravelTile = ({
  skillSetIndex,
  groupIndex,
  skillSetField,
}: {
  skillSetIndex: number;
  groupIndex: number;
  skillSetField: any;
}) => {
  const { control, register, setValue, getValues } = useFormContext();
  const { classes } = useTravelTileStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataList = useSelector(selectTravelExperiences);
  const [recordAdded, setRecordAdded] = useState<boolean>(skillSetField.travelExperience?.experienceType !== null);
  const [experienceTypeValue, setExperienceType] = useState(skillSetField.travelExperience?.experienceType?.groupBy);
  const [showNoOfAssignment, setShowNoOfAssignment] = useState<boolean>(
    skillSetField.travelExperience?.experienceType?.groupBy === TravelExperienceSections.NoOfAssignments,
  );
  const [showYearOfExperiences, setShowYearOfExperiences] = useState<boolean>(
    skillSetField.travelExperience?.experienceType?.groupBy === TravelExperienceSections.YearsOfExperience,
  );
  const [yearsOfExperienceFocused, setYearsOfExperienceFocused] = useState(false);
  const [monthsOfExperienceFocused, setMonthsOfExperienceFocused] = useState(false);
  const yearsOfExperience = useWatch({
    control,
    name: `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.yearsOfExperience`,
  });
  const monthsOfExperience = useWatch({
    control,
    name: `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.monthsOfExperience`,
  });

  useEffect(() => {
    dispatch(orderCreationActions.getTravelExperienceOptions());
  }, []);

  const experienceTypeSelectedOptionChanged = (option: IGroupedTypeAheadOption) => {
    if (option.groupBy === TravelExperienceSections.YearsOfExperience) {
      setShowYearOfExperiences(true);
      setShowNoOfAssignment(false);
    } else {
      setShowNoOfAssignment(true);
      setShowYearOfExperiences(false);
    }
  };

  const addTravelExperience = () => {
    if (!recordAdded) {
      setRecordAdded(true);
    }
  };

  const remove = () => {
    skillSetField.travelExperience = null;
    setRecordAdded(false);
    setShowNoOfAssignment(false);
    setShowYearOfExperiences(false);
  };

  const setIsRequired = (experienceTypeChanged = false) => {
    setIsRequiredValue(
      experienceTypeChanged,
      getValues,
      setValue,
      groupIndex,
      skillSetIndex,
      skillSetField?.travelExperience,
    );
  };
  return (
    <Grid item xs={12}>
      <UnitCollapsible
        title={t('facility.units.travelExp.sectionTitle', {
          travelExperienceCount: recordAdded ? 1 : 0,
        })}
        iconToolTipContent={t('facility.units.travelExp.addTravelExperience')}
        onClickHandler={addTravelExperience}
        fieldCount={recordAdded ? 1 : 0}
        isDisabled={recordAdded}
        isCollapsible={false}
        showIcon={false}
        enableSummaryBackground
        children={
          <Grid container>
            <Grid item xs={12} className={classes.travelExperienceWrapper}>
              {recordAdded && (
                <Grid>
                  <Grid container xs={12}>
                    <Grid container gap={2} xs={11.63} mt={1}>
                      <Grid
                        item
                        xs={2.18}
                        marginRight={Boolean(skillSetField.travelExperience?.experienceType?.groupBy) ? 0 : '11px'}
                      >
                        <ControlledTypeAhead
                          register={register}
                          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.experienceType`}
                          label={t('facility.units.travelExp.title')}
                          control={control}
                          options={dataList || []}
                          customScroll={true}
                          onChange={value => {
                            setIsRequired(experienceTypeValue !== value.groupBy);
                            experienceTypeSelectedOptionChanged(value);
                            setExperienceType(prev => value.groupBy);
                          }}
                          defaultValue={skillSetField.travelExperience?.experienceType}
                          disabled={false}
                          id={'travelExperienceType'}
                          showDropdownIcon={true}
                          groupBy={'groupBy'}
                        />
                      </Grid>
                      {showNoOfAssignment && (
                        <Grid item xs={1.88} marginRight={'11px'}>
                          <Controller
                            control={control}
                            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.numberOfAssignments`}
                            defaultValue={skillSetField.travelExperience?.numberOfAssignments}
                            render={({ onChange, ...rest }) => (
                              <SingleLineInput
                                id={'numberOfAssignments'}
                                inputDefaultValue={null}
                                inputLabel={t('facility.units.travelExp.noOfAssignments')}
                                inputName={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.numberOfAssignments`}
                                inputValue={rest.value}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const parsedValue = parseInt(event.target.value);
                                  if (
                                    event.target.value === '' ||
                                    (AssignmentYears.Min <= parsedValue && parsedValue < AssignmentYears.Max)
                                  ) {
                                    onChange(
                                      event.target.value === '' ? null : getPositiveIntegerValue(event.target.value),
                                    );
                                    setIsRequired();
                                  }
                                }}
                                inputType={'number'}
                                fieldStyle={classes.fieldStyle}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {showYearOfExperiences && (
                        <Grid item xs={1.15}>
                          <Controller
                            control={control}
                            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.yearsOfExperience`}
                            defaultValue={skillSetField.travelExperience?.yearsOfExperience ?? null}
                            render={({ onChange, ...rest }) => (
                              <SingleLineInput
                                id={'unitExperienceInYears'}
                                inputDefaultValue={skillSetField.travelExperience?.yearsOfExperience ?? null}
                                inputLabel={t('facility.units.unitDetails.unitRequirements.experienceIn')}
                                inputName={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.yearsOfExperience`}
                                inputValue={rest.value}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  if (
                                    event.target.value === '' ||
                                    parseInt(event.target.value) >= MIN_YEARS_EXPERIENCE
                                  ) {
                                    onChange(
                                      event.target.value === '' ? null : getPositiveIntegerValue(event.target.value),
                                    );
                                    setIsRequired();
                                  }
                                }}
                                showEndAdornment={yearsOfExperienceFocused || isWholeNumber(yearsOfExperience)}
                                endAdornmentValue={t('facility.units.unitDetails.unitRequirements.years')}
                                inputType={'number'}
                                fieldStyle={classes.fieldStyle}
                                shrinkLabel={yearsOfExperienceFocused || isWholeNumber(yearsOfExperience)}
                                handleWarning={() => setYearsOfExperienceFocused(true)}
                                handleBlur={() => {
                                  setYearsOfExperienceFocused(false);
                                  rest.onBlur();
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {showYearOfExperiences && (
                        <Grid item xs={1.15} marginRight={'11px'}>
                          <Controller
                            control={control}
                            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.monthsOfExperience`}
                            defaultValue={skillSetField.travelExperience?.monthsOfExperience ?? null}
                            render={({ onChange, ...rest }) => (
                              <SingleLineInput
                                id={'unitExperienceInMonths'}
                                inputDefaultValue={null}
                                inputLabel={t('facility.units.unitDetails.unitRequirements.experienceIn')}
                                inputName={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.monthsOfExperience`}
                                inputValue={rest.value}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const parsedValue = parseInt(event.target.value);
                                  if (
                                    event.target.value === '' ||
                                    (MonthsExperience.Min <= parsedValue && parsedValue < MonthsExperience.Max)
                                  ) {
                                    onChange(
                                      event.target.value === '' ? null : getPositiveIntegerValue(event.target.value),
                                    );
                                    setIsRequired();
                                  }
                                }}
                                showEndAdornment={monthsOfExperienceFocused || isWholeNumber(monthsOfExperience)}
                                endAdornmentValue={t('facility.units.unitDetails.unitRequirements.months')}
                                inputType={'number'}
                                fieldStyle={classes.fieldStyle}
                                shrinkLabel={monthsOfExperienceFocused || isWholeNumber(monthsOfExperience)}
                                handleWarning={() => setMonthsOfExperienceFocused(true)}
                                handleBlur={() => {
                                  setMonthsOfExperienceFocused(false);
                                  rest.onBlur();
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item sx={{ alignSelf: 'center' }} className={classes.disabled}>
                        <Controller
                          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.isRequired`}
                          control={control}
                          defaultValue={skillSetField.travelExperience?.isRequired ?? false}
                          render={({ onChange, ...rest }) => (
                            <SimpleCheckbox
                              isChecked={rest.value || false}
                              handleChecked={event => {
                                onChange(event.target.checked);
                              }}
                              checkboxLabel={t('facility.units.unitDetails.unitRequirements.required')}
                              checkboxName={'experienceTravelExperiencesRequired'}
                              labelStyle={classes.labelMarginRight}
                              id={'experienceTravelExperiencesRequired'}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sx={{ alignSelf: 'center' }} className={`${classes.checkbox}`}>
                        <Controller
                          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.withinTwoYears`}
                          defaultValue={skillSetField.travelExperience?.withinTwoYears ?? false}
                          control={control}
                          render={({ onChange, ...rest }) => (
                            <SimpleCheckbox
                              isChecked={rest.value || false}
                              handleChecked={event => {
                                onChange(event.target.checked);
                              }}
                              checkboxLabel={t('facility.units.travelExp.withinTwoYears')}
                              checkboxName={'experienceTravelExperiencesWithin2Years'}
                              labelStyle={classes.labelMarginRight}
                              id={'experienceTravelExperiencesWithin2Years'}
                              isdisabled={!(showNoOfAssignment || showYearOfExperiences)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sx={{ alignSelf: 'center' }} className={`${classes.checkbox}`}>
                        <Controller
                          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.isAMNTravel`}
                          defaultValue={skillSetField.travelExperience?.isAMNTravel ?? false}
                          control={control}
                          render={({ onChange, ...rest }) => (
                            <SimpleCheckbox
                              isChecked={rest.value || false}
                              handleChecked={event => {
                                onChange(event.target.checked);
                              }}
                              checkboxLabel={t('facility.units.travelExp.aMNTravel')}
                              checkboxName={'experienceTravelExperiencesAMNTravel'}
                              labelStyle={classes.labelMarginRight}
                              id={'experienceTravelExperiencesAMNTravel'}
                              isdisabled={!(showNoOfAssignment || showYearOfExperiences)}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item gap={2} sx={{ alignSelf: 'center', paddingTop: 0.5 }} xs={0.37}>
                      <CustomTooltip
                        standardMargin
                        tooltipContent={t('facility.units.travelExp.deleteTravelExperience')}
                      >
                        <DeleteOutlinedIcon className={classes.actionButtons} onClick={() => remove()} />
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};
