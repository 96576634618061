import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import React, { useContext } from 'react';
import { Backdrop, Box, Checkbox, FormControl, FormControlLabel, Grid } from 'amn-ui-core';
import UserDeviceSelection from './UserDeviceSelection';
import { CloseConfirmation } from '@AMIEWEB/Notification/Common/CloseDialog';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import { CustomBackdrop } from '@AMIEWEB/Common/Backdrop/CustomBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSelection, selectVoiceLoader } from 'store/redux-store/notification/notification.selector';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { NotificationContext } from '@AMIEWEB/Notification/NotificationWrapper';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { UserDeviceProps } from 'app/models/Notification/Notification';
import { voiceCloseModalData } from './Helper';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const useStyles = makeStyles()(theme => ({
  modalContent: {
    margin: '2% 5%',
    color: theme.palette.framework.system.charcoal,
  },
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '33vw',
      overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
    overflow: 'hidden',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.framework.system.white,
    boxSizing: 'border-box',
  },
  snackBar: {
    position: 'absolute',
    zIndex: 2,
    bottom: 20,
  },
}));

interface IDeviceSelection {
  userDeviceLine: UserDeviceProps;
  setUserDeviceLine: React.Dispatch<React.SetStateAction<UserDeviceProps>>;
  HandleInitiateCall: () => void;
}

const DeviceSelection: React.FC<IDeviceSelection> = (props: IDeviceSelection) => {
  const { userDeviceLine, setUserDeviceLine, HandleInitiateCall } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const globalData = useSelector(notificationSelection);
  const userDevicePreference = useSelector(selectUserPreference);
  const { onClose } = useContext(NotificationContext);
  const loader = useSelector(selectVoiceLoader);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...voiceCloseModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
  };

  return (
    <React.Fragment>
      <GenericDialog
        open
        draggable
        disablePortal
        disableEscapeKeyDown
        dialogTitleProps={{
          text: t('notification.voice.log.deviceSelection'),
          expandable: false,
          closeButton: true,
          minimize: false,
        }}
        dialogContentProps={{
          classes: { root: classes.modalContent },
        }}
        dialogActions={[
          {
            text: t('notification.voice.log.ok'),
            variant: 'contained',
            color: 'primary',
            onClick: HandleInitiateCall,
            disabled: !userDevicePreference.current?.line || !userDevicePreference.current?.device,
          },
        ]}
        fullWidth={true}
        className={classes.modalContainer}
        variant={'blue'}
        onClose={() => onClose(ChannelType.voice)}
      >
        {globalData?.snackbarData?.showCloseAction && globalData?.snackbarData?.showResponse && (
          <Backdrop className={classes.backdrop} open />
        )}
        {loader && <CustomBackdrop open />}
        <Box className={classes.modalContent}>
          <Grid>
            <UserDeviceSelection />
          </Grid>
          <FormControl>
            <FormControlLabel
              disabled={isNullOrUndefined(userDevicePreference.current?.line || userDevicePreference.current?.device)}
              control={
                <Checkbox
                  checked={userDeviceLine.isDefaultDeviceAndLine}
                  onChange={event => {
                    setUserDeviceLine(prevState => ({ ...prevState, isDefaultDeviceAndLine: event.target.checked }));
                  }}
                />
              }
              label={t('notification.voice.log.setDefault')}
            />
          </FormControl>
        </Box>
        <Box className={classes.snackBar}>
          {globalData?.snackbarData?.channel === ChannelType.voice && !globalData?.snackbarData?.manual && (
            <CloseConfirmation
              yesAction={handleModalClose}
              noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
            />
          )}
        </Box>
      </GenericDialog>
    </React.Fragment>
  );
};

export default DeviceSelection;
