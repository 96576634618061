import { put, call, select, getContext, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  trackPromise,
  manuallyIncrementPromiseCounter,
  manuallyResetPromiseCounter,
  manuallyDecrementPromiseCounter,
} from 'react-promise-tracker';
import { getPlacementCandidatePlacement } from 'app/services/PlacementServices/PlacementServices';
import { placementDetailsAction } from './slice';
import { ModalBindData } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Helpers/CandidatePlacementHelper';
import {
  IPlacementLink,
  IPlacementStatusSource,
  IPlacementStatusSuccessType,
  IPlacementTagsUpdate,
  ITimestampRequest,
} from 'app/models/Placement/PlacementDetails';
import { httpSuccess } from 'app/services/serviceHelpers';
import { placementStatusAction } from '../placement-status/slice';
import { missingField } from 'app/constants';
import {
  selectPlacementDetails,
  selectPlacementHeaderData,
  selectPlacementOrderType,
  selectedComplianceStatusNoteList,
} from './selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { placementStatusOptions } from '../../../app/components/Placement/NextPlacementStatus/StatusDefaults';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { IPlacementTimestampResponse } from 'app/models/Placement';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import queryString from 'query-string';
import { submitPacketModalQueryString } from '../../../app/components/Placement/NextPlacementStatus/Packets/SubmitPacketDialog';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { CredentialingService } from 'app/services/CredentialingServices/credentialing-service';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { OrderService } from 'app/services/OrderServices/order-service';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';
import { saveSubmissionPacketsToCosmos } from './update.saga';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { placementContactsFlattened } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/utils';
import { getCertificates, getLicensure, searchNoteListByText } from './helper';
import { formatDate } from '@AMIEWEB/Order/OrderDetails/helper';
import { getDisciplineSpecialtyData } from 'app/services/SharedServices/SharedServices';
import { convertToFormat } from 'app/helpers/dateHelper';
import { PlacementDocumentFileNamePrefix } from '../placement-documents/types';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { requestArbitrationAgreementRequirements } from '../placement-summary/common-saga';
import {
  selectPlacementConsolidatedDetails,
  selectPlacementConsolidatedOrderType,
} from '../placement-summary/consolidatedStoreSelectors';
import { placementDocumentsActions } from '../placement-documents/slice';
import { requestPlacementDocuments } from '../placement-documents/saga';
import i18next from 'i18next';
import { IArbitrationAgreementCreationBody } from '../candidate-work-experience/types';
import { IBannerAction } from 'app/models/Global/Global';

const TrackPlacementProgressWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-progress-call');
const TrackHeaderWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-header-call');
const TrackQuickGlanceWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-quick-glance');
const TrackContactsWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-contacts');
const TrackCandidateRecordsRightWrapper = (fn, ...args) => trackPromise(fn(...args), 'candidate-records-right');
const TrackPlacementCandidatePlacementCountDetaisWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-candididate-placement-count-details');
const TrackPlacementSellingPointsDetailsWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-selling-points-details');
const TrackEducationWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-candidate-education-list');
const TrackPlacementStatusDetails = (fn, ...args) => trackPromise(fn(...args), 'get-placement-status-details');
const TrackChangeHistoryWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-change-history');
const TrackBlackstrawWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-blackstraw-calls');
const TrackPlacementOrderType = (fn, ...args) => trackPromise(fn(...args), 'placement-order-type');
export const TrackCandidateSSN = (fn, ...args) => trackPromise(fn(...args), 'candidate-ssn');
export const TrackCandidateDOB = (fn, ...args) => trackPromise(fn(...args), 'candidate-dob');
const TrackPlacementTimestamps = (fn, ...args) => trackPromise(fn(...args), 'validate-placement-timestamps');
const TrackPassportChecklistWrapper = (fn, ...args) => trackPromise(fn(...args), 'passport-checklist-items');
const TrackPlacementSupervisor = (fn, ...args) => trackPromise(fn(...args), 'placement-supervisor');
const TrackPlacementSupervisee = (fn, ...args) => trackPromise(fn(...args), 'placement-supervisee');
const TrackPlacementSupervisorDetals = (fn, ...args) => trackPromise(fn(...args), 'placement-supervisor-Details');
const TrackArbitrationAgreementCreation = (fn, ...args) => trackPromise(fn(...args), 'placement-arbitration-agreement');
const TrackPlacementIdLink = (fn, ...args) => trackPromise(fn(...args), 'placement-supervisor-link');
const TrackPlacementSupervisionDelete = (fn, ...args) => trackPromise(fn(...args), 'placement-Supervision-delete');
const TrackPlacementTags = (fn, ...args) => trackPromise(fn(...args), 'placement-tags');
const TrackPlacementTagsUpdate = (fn, ...args) => trackPromise(fn(...args), 'placement-Tags-Update');
export const TrackComplianceStatusNotes = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-compliance-status-notes');

/**
 * placement details get action
 * @param action
 */
export function* requestHeaderDetails(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    manuallyIncrementPromiseCounter('placement-header-call');
    const placementService: PlacementService = yield getContext('placementService');
    const orderService: OrderService = yield getContext('orderService');

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    let headerResponse;

    if (action.payload.isInitLoad && consolidatedDetails) {
      headerResponse = consolidatedDetails.header;
    }

    if (!headerResponse?.status || !headerResponse?.data) {
      headerResponse = yield call(placementService.getPlacementHeader, action.payload.placementId);
    }

    if (httpSuccess(headerResponse?.status)) {
      yield put(
        placementDetailsAction.setPlacementHeaderDetails({
          ...headerResponse.data,
          placementDate: {
            startDate: headerResponse.data.placementStartDate,
            endDate: headerResponse.data.placementEndDate,
          },
          placementStartDate: headerResponse.data.placementStartDate,
          placementEndDate: headerResponse.data.placementEndDate,
          vmsReqDetails: {
            vmsReqNo: headerResponse.data.vmsReqNo,
            lastUpdated: headerResponse.data.vmsReqNoLastUpdated,
            lastUpdatedBy: headerResponse.data.vmsReqNoLastUpdatedBy,
          },
        }),
      );
      const orderResponse = yield call(
        TrackHeaderWrapper,
        orderService.getOrderDetailsById,
        headerResponse?.data.orderId,
      );
      if (httpSuccess(orderResponse?.status)) {
        yield put(orderDataActions.SetOrderUnitDetails(orderResponse?.data?.unit?.unitId));
        yield put(placementSummaryActions.setOrderDivision(orderResponse.data?.division));
      }
      /** requestCandidateSSN */
      yield put(placementDetailsAction.getCandidateSSN({ candidateId: headerResponse.data?.candidate.id }));
      /** requestCandidateDOB */
      yield put(placementDetailsAction.getCandidateDOB({ candidateId: headerResponse.data?.candidate.id }));

      yield put(placementDetailsAction.resetForm());

      return { candidateId: headerResponse.data?.candidate.id, orderId: headerResponse.data?.orderId };
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestHeaderDetails',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  } finally {
    manuallyDecrementPromiseCounter('placement-header-call');
  }
}

export function* requestComplianceStatusProgressNotes(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const [responseStatusNote, responseOrderRequirement, responseDocDeadline, responseComments] = yield all([
      call(TrackComplianceStatusNotes, placementService.getComplianceStatusNotes, action.payload.placementId),
      call(TrackComplianceStatusNotes, placementService.getComplianceStateOrderRequirement, action.payload.placementId),
      call(TrackComplianceStatusNotes, placementService.getComplianceStateNoteDocdeadline, action.payload.placementId),
      call(TrackComplianceStatusNotes, placementService.getComplianceStateNoteComments, action.payload.placementId),
    ]);
    if (
      httpSuccess(responseStatusNote?.status) ||
      httpSuccess(responseOrderRequirement?.status) ||
      httpSuccess(responseDocDeadline?.status) ||
      httpSuccess(responseComments?.status)
    ) {
      yield put(
        placementDetailsAction.setComplianceStatusNotes({
          notes: responseStatusNote?.data.notes,
          notesList: responseStatusNote?.data.notesList,
        }),
      );
      yield put(
        placementDetailsAction.setComplianceStatusHeader({
          license: getLicensure(responseOrderRequirement.data.groups),
          certificates: getCertificates(responseOrderRequirement.data.groups),
          docDealine: responseDocDeadline.data,
          comments: responseComments.data,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestComplianceStatusProgressNotes',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* searchComplianceStatusNotes(action: PayloadAction<string>) {
  try {
    const originalNotesList = yield select(selectedComplianceStatusNoteList);
    const filteredNotes = searchNoteListByText(action.payload, originalNotesList);
    yield put(placementDetailsAction.setFilteredComplianceStatusNotes(filteredNotes));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'searchComplianceStatusNotes',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

/**
 * Checks if a placement has been modified by another user in order to prevent overriding those newer changes
 * @returns boolean
 */
export function* checkPlacementTimestamps(
  action: PayloadAction<
    { source: IPlacementStatusSource } & {
      placement?: ITimestampRequest;
      candidate?: ITimestampRequest;
      placementStrikeDetails?: any;
      fields?: string[];
      dirtyFields?: string[];
      updates?: any;
    }
  >,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    // Generate placementStrikDetails, if strike is being checked
    const response = yield call(TrackPlacementTimestamps, placementService.validatePlacementTimestamp, {
      placementId: action.payload?.placement?.id,
      placementTimestamp: action.payload?.placement?.timestamp,
      candidateTimestamp: action.payload?.candidate?.timestamp,
      placementStrikeDetails: action.payload.placementStrikeDetails,
      fields: action.payload.fields,
    });
    if (!httpSuccess(response?.status) || !response?.data) {
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          successType: IPlacementStatusSuccessType.placementConflict,
          source: action.payload.source,
        }),
      );
      return false;
    } else {
      return true;
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'checkPlacementTimestamps',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
    return null;
  }
}

export function* getAndSetPlacementTimestamp(action: PayloadAction<{ placementId: number }>) {
  try {
    const timestampResponse: IPlacementTimestampResponse | undefined = yield call(getPlacementTimestamp, {
      payload: { placementId: action.payload.placementId },
      type: placementDetailsAction.getPlacementTimestamp.type,
    });
    yield put(placementDetailsAction.setTimestamps(timestampResponse));
  } catch (e) {}
}

/**
 * Get the current timestamp for placement details
 */
export function* getPlacementTimestamp(
  action: PayloadAction<{ placementId }>,
): Generator<any, IPlacementTimestampResponse | undefined, any> {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(
      TrackPlacementTimestamps,
      placementService.getPlacementTimestamp,
      action.payload.placementId,
    );
    if (httpSuccess(response?.status) && response.data) {
      return {
        placementTimestamp: response.data.placementTimestamp,
        candidateTimestamp: response.data.candidateTimestamp,
        travelerOptionTimestamp: response.data.travelerOptionTimestamp,
      };
    }
    return undefined;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementTimestamp',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
    return undefined;
  }
}

/**
 * placement status details get action
 * @param action
 */
export function* requestPlacementStatusDetails(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementHeaderData = yield select(selectPlacementHeaderData);
    let statusResponse;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      statusResponse = {
        status: consolidatedDetails.common?.statusDetails?.status,
        data: consolidatedDetails.common?.statusDetails?.data,
      };
    }

    if (!statusResponse?.status || !statusResponse?.data) {
      statusResponse = yield call(
        TrackPlacementStatusDetails,
        placementService.getPlacementStatusDetails,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(statusResponse?.status)) {
      const { data } = statusResponse;
      yield put(
        placementStatusAction.setPlacementStatus({
          availablePlacementStatus: data?.availablePlacementStatus ?? [],
          currentPlacementStatus: {
            activityTypeId: data?.activityTypeId,
            activityStatusId: data?.activityStatusId,
            activityStatus: data?.activityStatus,
          },
          updatePlacementStatusResponse: null,
        }),
      );
      const { modal, klg: documentId, t: template, f: filename } = queryString.parse(window.location.search);
      const submitPacketItem = data?.availablePlacementStatus.find(
        item =>
          !item.disabled &&
          item.activityStatusId === placementStatusOptions.submitPacket.activityStatusId &&
          item.activityTypeId === placementStatusOptions.submitPacket.activityTypeId,
      );

      /** Code: To save packets to AMNOne cosmos container - OrderMgmt */
      if (!!documentId && !!template) {
        const { userInfo } = yield select(selectUser);
        const createdBy = Concatenate([userInfo.firstName, userInfo.lastName], ' ');
        const packetName =
          filename ??
          `${PlacementDocumentFileNamePrefix.submission}${placementHeaderData.candidate.firstName}_${placementHeaderData.candidate.lastName}_${action.payload.placementId}`;
        yield call(saveSubmissionPacketsToCosmos, {
          payload: {
            documentId,
            packetName,
            placementId: action.payload.placementId,
            template,
            createdByUserId: userInfo.employeeId,
            createdBy,
            /** To be mapped later for email specs */
            lastUpdatedByUserId: userInfo.employeeId,
            lastUpdatedBy: createdBy,
          },
          type: placementStatusAction.saveSubmissionPacketsToCosmos.type,
        });
      }

      /** Code: To open submit packet modal on recognizing via return url */
      if (modal === submitPacketModalQueryString && submitPacketItem) {
        yield put(
          placementStatusAction.setSelectedStatus({
            status: submitPacketItem,
            statusChangeSource: 'whatsNext',
          }),
        );
        window.history.pushState(null, '', window.location.pathname);
      }
      return true;
    }
    return false;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPlacementStatusDetails',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
    return false;
  }
}

export function* requestPlacementExtensionStatusDetails(
  action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false | null }>,
) {
  try {
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary.assignment?.details?.status,
        data: consolidatedDetails.summary.assignment?.details?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementStatusDetails,
        placementService.getExtensionStatusDetails,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const isPlacementExtendable = response?.data?.availableExtensionStatus?.length > 0 ? true : false;
      yield put(placementStatusAction.setExtensionDetails({ isPlacementExtendable, ...response.data }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementQuickGlance',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementQuickGlance(
  action: PayloadAction<{
    placementId: number;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    const quickGlanceResponse = yield call(
      TrackQuickGlanceWrapper,
      placementService.getPlacementQuickGlanceTiles,
      action.payload.placementId,
    );
    if (httpSuccess(quickGlanceResponse?.status))
      yield put(placementDetailsAction.setQuickGlanceDetails(quickGlanceResponse.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementQuickGlance',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementContacts(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let contactResponse;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      contactResponse = {
        status: consolidatedDetails.common?.contact?.status,
        data: consolidatedDetails.common?.contact?.data,
      };
    }

    if (!contactResponse?.status || !contactResponse?.data) {
      contactResponse = yield call(
        TrackContactsWrapper,
        placementService.getPlacementKeyContacts,
        action.payload.placementId,
      );
    }

    if (httpSuccess(contactResponse?.status)) {
      const flattenedContacts = placementContactsFlattened(contactResponse.data);
      yield put(placementDetailsAction.setPlacementContacts({ ...contactResponse.data, flattenedContacts }));

      const {
        keyInternalPartners: {
          recruiter,
          accountManager,
          regionalDirector,
          credentialingAnalyst,
          clientContractSpecialist,
          clinicalManager,
        },
      } = flattenedContacts;

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: 'recruiter',
          data: {
            value: recruiter.id ? { name: recruiter.title, value: recruiter.id, id: recruiter.id } : null,
            error: null,
            isDirty: false,
          },
        }),
      );

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: 'accountManager',
          data: {
            value: accountManager.id
              ? { name: accountManager.title, value: accountManager.id, id: accountManager.id }
              : null,
            error: null,
            isDirty: false,
          },
        }),
      );

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: 'regionalDirector',
          data: {
            value: regionalDirector.id
              ? { name: regionalDirector.title, value: regionalDirector.id, id: regionalDirector.id }
              : null,
            error: null,
            isDirty: false,
          },
        }),
      );

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: 'credentialingAnalyst',
          data: {
            value: credentialingAnalyst.id
              ? { name: credentialingAnalyst.title, value: credentialingAnalyst.id, id: credentialingAnalyst.id }
              : null,
            error: null,
            isDirty: false,
          },
        }),
      );

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: 'clientContractSpecialist',
          data: {
            value: clientContractSpecialist.id
              ? {
                  name: clientContractSpecialist.title,
                  value: clientContractSpecialist.id,
                  id: clientContractSpecialist.id,
                }
              : null,
            error: null,
            isDirty: false,
          },
        }),
      );

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: 'clinicalManager',
          data: {
            value: clinicalManager.id
              ? { name: clinicalManager.title, value: clinicalManager.id, id: clinicalManager.id }
              : null,
            error: null,
            isDirty: false,
          },
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementContacts',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementCandidatePlacementCounts(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.candidatePlacementCounts?.status,
        data: consolidatedDetails.common?.candidatePlacementCounts?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementCandidatePlacementCountDetaisWrapper,
        placementService.getCandidatePlacementCountByPid,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) yield put(placementDetailsAction.setCandidatePlacements(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementCandidatePlacementCounts',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementCandidateSellingPoints(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.candidateSellingPoints?.status,
        data: consolidatedDetails.common?.candidateSellingPoints?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementSellingPointsDetailsWrapper,
        placementService.getCandidateSellingPointsByPid,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementDetailsAction.setCanidateSellingPoints(response.data));
      yield put(placementDetailsAction.resetForm());
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementCandidateSellingPoints',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestStrikeStatus(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.strikeStatus?.status,
        data: consolidatedDetails.common?.strikeStatus?.data ?? {
          eventManagementStatusId: 0,
          eventManagementStatus: null,
          schedulingStatusId: 0,
          schedulingStatus: null,
        },
      };
    }

    if (
      !response?.status ||
      !response?.data?.eventManagementStatus ||
      !consolidatedDetails?.common?.strikeStatus?.status
    ) {
      response = yield call(
        TrackCandidateRecordsRightWrapper,
        placementService.getStrikeStatus,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementDetailsAction.setStrikeStatus(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementStrikeStatus',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestCandidateTimeOffRequestsData(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.timeOffRequestsDetails?.status,
        data: consolidatedDetails.common?.timeOffRequestsDetails?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackBlackstrawWrapper,
        placementService.getCandidateTimeOffRequestsByPid,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementDetailsAction.setCandidateTimeOffRequests(response.data ?? {}));
      yield put(placementDetailsAction.resetForm());
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCandidateTimeOffRequestsData',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestCandidateVerifiedSkillsetsData(
  action: PayloadAction<{
    placementId: number;
    isInitLoad?: boolean | false;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.candidateTravelerVerifiedSkillset?.status,
        data: consolidatedDetails.common?.candidateTravelerVerifiedSkillset?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackEducationWrapper,
        placementService.getCandidateVerifiedSkillsetsByPid,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) yield put(placementDetailsAction.setCandidateVerifiedSkillsets(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCandidateVerifiedSkillsetsData',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* getPlacementCandidatePlacementDetails(
  action: PayloadAction<{
    placementId: number;
    categoryId: number;
  }>,
) {
  try {
    const data = yield call(
      TrackEducationWrapper,
      getPlacementCandidatePlacement,
      action.payload.placementId,
      action.payload.categoryId,
    );
    if (data) {
      yield put(
        placementDetailsAction.setModalData({
          categoryId: action.payload.categoryId,
          data: data
            .map((info: any) => {
              return ModalBindData(info);
            })
            .sort((a, b) =>
              a.status.toLowerCase() > b.status.toLowerCase()
                ? 1
                : a.status.toLowerCase() === b.status.toLowerCase()
                ? 0
                : -1,
            ),
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementCandidatePlacementDetails',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementProgress(
  action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.progressBar?.status,
        data: consolidatedDetails.common?.progressBar?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementProgressWrapper,
        placementService.getPlacementProgressStatus,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementDetailsAction.setPlacementProgress(response.data));
      return true;
    } else return false;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestPlacementProgress',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestLandsGtgDetails(action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.landsDetails?.status,
        data: consolidatedDetails.common?.landsDetails?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementProgressWrapper,
        placementService.getLandGtgDetails,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) yield put(placementDetailsAction.setLandsGtgDetails(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestLandsGtgDetails',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementChangeHistory(
  action: PayloadAction<{ placementId: number; pageSize: number; pageNumber: number }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(TrackChangeHistoryWrapper, placementService.getPlacementChangeHistory, {
      entityId: `${action.payload.placementId}`,
      entity: 'placement',
      pageSize: action.payload.pageSize,
      pageNumber: action.payload.pageNumber,
      sortColumn: 'created',
      sortOrder: 'desc',
    });

    if (httpSuccess(response?.status)) {
      const historyRecords = (response.data || []).map((item, index) => ({
        ...item,
        id: `${item.entityId}-${index}`,
        created: convertToFormat(item.created, 'MM/DD/YYYY hh:mm:ss A', true),
        externalSystem: item.externalSystem || missingField,
        /** cleanup for requested time off */
        ...(item.fieldChanged === 'Requested Time Off'
          ? {
              oldValue: (item.oldValue as string)?.replace(/\n/g, '').split(','),
              newValue: (item.newValue as string)?.replace(/\n/g, '').split(','),
            }
          : {}),
      }));

      yield put(placementDetailsAction.setChangeHistory(historyRecords));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementChangeHistory',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

// Gets placement order type and timestamp
export function* requestRequiredPlacementData(
  action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false }>,
) {
  try {
    manuallyIncrementPromiseCounter('TrackPlacementRequiredData');
    yield put(placementDetailsAction.setRequiredDataLoadStatus(undefined));

    const placementService: PlacementService = yield getContext('placementService');
    let response, timestampResponse;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      const orderType = yield select(selectPlacementConsolidatedOrderType);
      response = {
        status: 200,
        data: orderType,
      };

      const timestampDetailsResponse = consolidatedDetails.common.timestampDetails;
      timestampResponse =
        httpSuccess(timestampDetailsResponse?.status) && timestampDetailsResponse.data
          ? {
              placementTimestamp: timestampDetailsResponse.data.placementTimestamp,
              candidateTimestamp: timestampDetailsResponse.data.candidateTimestamp,
              travelerOptionTimestamp: timestampDetailsResponse.data.travelerOptionTimestamp,
            }
          : undefined;
    }

    if (!response || !timestampResponse) {
      [response, timestampResponse] = yield all([
        call(TrackPlacementOrderType, placementService.getPlacementOrderType, action.payload.placementId),
        call(getPlacementTimestamp, {
          payload: { placementId: action.payload.placementId },
          type: placementDetailsAction.getPlacementTimestamp.type,
        }),
      ]);
    }

    if (httpSuccess(response?.status) && !isNullOrUndefined(timestampResponse)) {
      const { data } = response;
      yield put(placementDetailsAction.setPlacementOrderType(data));
      yield put(placementDetailsAction.setTimestamps(timestampResponse));
      yield put(placementDetailsAction.setRequiredDataLoadStatus(true));
      return data;
    } else {
      yield put(placementDetailsAction.setRequiredDataLoadStatus(false));
    }
    manuallyResetPromiseCounter('TrackPlacementRequiredData');
  } catch (error) {
    console.log('error', error);
    manuallyResetPromiseCounter('TrackPlacementRequiredData');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRequiredPlacementData',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestCandidateSSN(
  action: PayloadAction<{ candidateId: string | number; disableFormReset?: boolean }>,
) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackCandidateSSN, candidateService.getCandidateSsn, action.payload.candidateId);
    if (httpSuccess(response?.status)) {
      yield put(
        placementDetailsAction.setSSN({
          ssn: response.data?.ssn ?? null,
          timestamp: response.data?.timestamp ?? null,
        }),
      );
      return response;
    } else {
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'SSN failed to load.',
        }),
      );
      if (!action.payload.disableFormReset) {
        yield put(placementDetailsAction.resetForm());
      }
      throw new Error('error getting ssn');
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        severity: 'error',
        message: 'SSN failed to load.',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCandidateSSN',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
    return {
      status: 500,
      data: null,
    };
  }
}

export function* requestCandidateDOB(action: PayloadAction<{ candidateId; disableFormReset?: boolean }>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackCandidateDOB, candidateService.getCandidateDOB, action.payload.candidateId);
    if (httpSuccess(response?.status)) {
      yield put(
        placementDetailsAction.setDOB({
          dob: response.data.birthDate ?? null,
          timestamp: response.data.timestamp ?? null,
        }),
      );
      return response;
    } else {
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'DOB failed to load.',
        }),
      );
      if (!action.payload.disableFormReset) {
        yield put(placementDetailsAction.resetForm());
      }
      throw new Error('get dob failed');
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        severity: 'error',
        message: 'DOB failed to load.',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCandidateDOB',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
    return {
      status: 500,
      data: null,
    };
  }
}

export function* requestPassportChecklistItems(
  action: PayloadAction<{
    placementId: number;
  }>,
) {
  try {
    const credentialingService: CredentialingService = yield getContext('credentialingService');
    const response = yield call(
      TrackPassportChecklistWrapper,
      credentialingService.getPassportChecklistByPid,
      action.payload.placementId,
    );
    if (httpSuccess(response?.status)) {
      yield put(placementDetailsAction.setPassportChecklist(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPassportChecklist',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestExtTimeOffPayerOpts() {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(placementService.getExtTimeOffPayerOpts);
    if (httpSuccess(response?.status)) yield put(placementDetailsAction.setExtTimeOffPayerOpts(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestExtTimeOffPayerOpts',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestExtDirectHireOpts() {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(placementService.getExtDirectHireOpts);
    if (httpSuccess(response?.status)) yield put(placementDetailsAction.setExtDirectHireOpts(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestExtDirectHireOpts',
        area: 'src/app/components/Placement/PlacementDetails/store/PlacementDetails.saga.ts',
      },
    });
  }
}

export function* requestPlacementSupervisor(
  action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.supervisorDetails?.status,
        data: consolidatedDetails.common?.supervisorDetails?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementSupervisor,
        placementService.getPlacementSupervisor,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = response?.data || [];
      const mappedData = data.map(item => ({
        supervision: item.firstName + ' ' + item.lastName,
        placementId: item.placementId,
        startDate: formatDate(item.startDate),
        endDate: formatDate(item.endDate),
        placementSupervisingID: item.placementSupervisingID,
      }));
      yield put(placementDetailsAction.setPlacementSupervision(mappedData));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPlacementSupervisor',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementSupervisee(
  action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.superviseeDetails?.status,
        data: consolidatedDetails.common?.superviseeDetails?.data,
      };
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackPlacementSupervisee,
        placementService.getPlacementSupervisee,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status) && response?.data) {
      const data = response?.data || [];
      const mappedData = data?.map(item => ({
        supervision: item.firstName + ' ' + item.lastName,
        placementId: item.placementId,
        startDate: formatDate(item.startDate),
        endDate: formatDate(item.endDate),
        placementSupervisingID: item.placementSupervisingID,
      }));
      yield put(placementDetailsAction.setPlacementSupervisee(mappedData));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPlacementSupervisee',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementDetails(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    const response = yield call(
      TrackPlacementSupervisorDetals,
      placementService.getSupervision,
      action.payload.placementId,
    );

    if (httpSuccess(response?.status) && response?.data) {
      const data = response?.data;
      const mappedData: any[] = [
        {
          supervisor: `${data.candidate.firstName} ${data.candidate.lastName}`,
          placementId: data.placementId,
          startDate: formatDate(data.placementStartDate),
          endDate: formatDate(data.placementEndDate),
          supervisorRequired: data.supervisorRequired,
        },
      ];

      yield put(placementDetailsAction.setPlacementLinkDetails(mappedData));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementDetails',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* createExternalTaskForArbitrationAgrementDocument(action: PayloadAction<{ placementId: string }>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const reqBody: IArbitrationAgreementCreationBody = {
      placementId: parseInt(action.payload.placementId),
    };
    const response = yield call(
      TrackArbitrationAgreementCreation,
      candidateService.postExternalTaskforArbitrationAgreementDetails,
      reqBody,
    );

    if (httpSuccess(response?.status)) {
      yield call(requestPlacementDocuments, {
        type: placementDocumentsActions.getPlacementDocuments.type,
        payload: { placementId: reqBody.placementId },
      });
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.arbitrationAgreementBtn.snackbar.success'),
          severity: 'success',
        }),
      );
    } else {
      if (response?.data?.responseCode === -2) {
        yield put(
          globalActions.setBanner({
            message: response?.data?.message,
            severity: 'error',
            justify: 'flex-start',
            action: IBannerAction.refreshPage,
            justifyActionContent: 'flex-end',
          }),
        );
      }
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.arbitrationAgreementBtn.snackbar.failure'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementDetails',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* linkPlacementId(action: PayloadAction<IPlacementLink>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const payload = {
      supervisorPlacementId: action.payload.supervisorPlacementId,
      superviseePlacementId: action.payload.superviseePlacementId,
    };
    const {
      header: { placementId },
    } = yield select(selectPlacementDetails);

    const response = yield call(TrackPlacementIdLink, placementService.likSupervisionPID, payload);
    const data = response?.data;
    yield put(placementDetailsAction.setPlacementLinkId(data));
    if (data?.responseCode === 0) {
      yield put(placementDetailsAction.setPlacementLinkModalOpen(false));
      yield put(placementDetailsAction.getPlacementSupervisorAction({ placementId }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementLinks',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}
export function* deletesupervisor(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(
      TrackPlacementSupervisionDelete,
      placementService.deleteSupervision,
      action.payload.placementId,
    );
    if (response && httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: 'Placement has been unlinked successfully',
          severity: 'success',
        }),
      );
      const {
        header: { placementId },
      } = yield select(selectPlacementDetails);
      yield put(placementDetailsAction.getPlacementSupervisorAction({ placementId }));
      yield put(placementDetailsAction.getPlacementSuperviseeAction({ placementId }));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: could not unLink Placement',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'deleteSupervision',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestDisciplineLookup() {
  try {
    const result = yield call(getDisciplineSpecialtyData);
    if (result) yield put(placementDetailsAction.setDisciplineData(result.data.disciplines));
    yield put(placementDetailsAction.setSpecialitiesData(result.data.disciplineSpecialties));
    yield put(placementDetailsAction.setSubSpecialitiesData(result.data.subSpecialties));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestDisciplineLookup',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* requestPlacementTags(action: PayloadAction<{ placementId: number; isInitLoad?: boolean | false }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const { userInfo } = yield select(selectUser);
    const response = yield call(
      TrackPlacementTags,
      placementService.getPlacementTags,
      action.payload.placementId,
      userInfo?.employeeId,
    );

    if (httpSuccess(response?.status) && response?.data) {
      yield put(placementDetailsAction.setPlacementTags(response?.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPlacementTags',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}

export function* placementTagsUpdate(action: PayloadAction<IPlacementTagsUpdate>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const payload = {
      placementId: action.payload.placementId,
      updatedBy: action.payload.updatedBy,
      placementTags: action.payload.placementTags?.tags,
      placementflagTypeId: action.payload.placementTags?.placementFlagTypeId,
    };

    const response = yield call(TrackPlacementTagsUpdate, placementService.updatePlacementTags, payload);
    if (httpSuccess(response.status)) {
      yield put(placementDetailsAction.setPlacementTags(response?.data?.placementTags));
      yield put(
        globalActions.setSnackBar({
          message: response?.data?.message,
          severity: response?.data?.responseCode > 0 ? 'error' : 'success',
        }),
      );
      yield call(requestArbitrationAgreementRequirements, {
        payload: {
          placementId: action.payload.placementId,
          arbitrationAgreementFlagEnabled: action.payload?.arbitrationAgreementFlagEnabled,
        },
        type: placementSummaryActions.getArbitrationAgreementRequirements.type,
      });

      const timestampResponse: IPlacementTimestampResponse = yield call(getPlacementTimestamp, {
        payload: { placementId: action.payload.placementId },
        type: placementDetailsAction.getPlacementTimestamp.type,
      });
      if (timestampResponse) {
        yield put(
          placementDetailsAction.setTimestamps({
            candidateTimestamp: timestampResponse.candidateTimestamp,
            placementTimestamp: timestampResponse.placementTimestamp,
            travelerOptionTimestamp: timestampResponse.travelerOptionTimestamp,
          }),
        );
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updatePlacementTags',
        area: '/src/store/redux-store/placement-details/request.saga.ts',
      },
    });
  }
}
