import { ISmsResult } from 'app/models/Sms/ISmsResult';
import { CompareAttachmentsInInbox } from 'app/components/GlobalSearch/helper';
import { IChat } from 'app/models/Sms/IChat';
import _ from 'lodash';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { IEmployee, ISmsAssociatedRecord, ISmsPayload } from 'app/models/Notification/Notification';
import { ICoveredUserInboxes } from 'app/models/UserCoverage/UserCoverage';
import { IUser } from 'oidc/user.redux';
import { smsPayloadToFormData } from './Helper';

export const FILE_SIZE_EXCEEDED = 'File is larger than 614400 bytes';
export const INVALID_FILE_FORMAT = 'File type must be .png,.jpeg,.jpg';
export const MAX_FILE_SIZE = 600;
export const validFileFormats = /(\.jpeg|\.jpg|\.png)$/i;

export async function fetchLogByPageNumber(
  refresh = false,
  pageNumber = 1,
  globalData,
  candidateDetails,
  hasActiveInbox,
  coveredInbox,
  smsData,
  setLoader,
  handleSubmitResponse,
  dispatch,
  notificationDataActions,
  messageStack,
) {
  const payload = createPayload(
    refresh,
    pageNumber,
    globalData,
    candidateDetails,
    hasActiveInbox,
    coveredInbox,
    smsData,
  );

  try {
    const response = await GetUserLogs(payload);
    setLoader(false);
    const updatedChatHistory = handleResponse(response, refresh, globalData, messageStack);

    dispatch(notificationDataActions.updateChatHistory(updatedChatHistory));

    return updatedChatHistory;
  } catch (err) {
    handleError(setLoader, handleSubmitResponse);
  }
}

const createPayload = (refresh, pageNumber, globalData, candidateDetails, hasActiveInbox, coveredInbox, smsData) => {
  return {
    contactId: `${candidateDetails?.travelerId ?? ''}`,
    pageSize: globalData.sms.chatHistory!.chats!.pageSize,
    pageNumber: refresh ? 1 : pageNumber + 1,
    channel: ['SMS'],
    initiatedBy: hasActiveInbox ? coveredInbox?.activeInbox?.phoneNumber : smsData.sender.phoneNumber,
    contactPhone:
      globalData.sms.chatHistory!.chats!.results.find(Boolean)?.message?.tos?.find(Boolean)?.phoneNumber ||
      globalData.sms.data?.tos?.find(Boolean)?.phoneNumber,
  };
};

const handleResponse = (response, refresh, globalData, messageStack) => {
  const updatedResult = refresh
    ? response.results
    : [...globalData.sms.chatHistory!.chats!.results, ...response.results];

  response.results = returnDistinctResults(updatedResult);

  if (!refresh) {
    messageStack.Reset();
    messageStack.messageStack = CompareAttachmentsInInbox(response) as IChat;
  }
  
  return {
    ...globalData.sms.chatHistory,
    chats: {...response},
  };
};

const returnDistinctResults = updatedResult => {
  // In the case a user sends a new message or more and then scrolls backwards through
  // older messages we will end up fetching 1 or more SMS messages that were already fetched
  // This function ensures we do not display duplicate messages in those cases
  return _.uniqBy(updatedResult, 'id');
};

const handleError = (setLoader, handleSubmitResponse) => {
  setLoader(false);
  handleSubmitResponse(true, 'error', 'Failed to fetch older messages');
};

export function GetOldestChatId(chatResults: ISmsResult[]) {
  if (chatResults.length === 0) return null;

  const oldestRecord = chatResults.reduce((oldest, current) => {
    return new Date(current.publishedOn) < new Date(oldest.publishedOn) ? current : oldest;
  }, chatResults[0]);

  return oldestRecord.id;
}

export const GetActiveSender = (coveredInbox: ICoveredUserInboxes): IEmployee => {
  return {
    name: `${coveredInbox?.activeInbox?.firstName} ${coveredInbox?.activeInbox?.lastName}`,
    email: coveredInbox?.activeInbox?.coveredUserEmail,
    senderId: coveredInbox?.activeInbox?.coveredUserId,
    userId: coveredInbox?.activeInbox?.coveredUserId,
    NtUserName: `${coveredInbox?.activeInbox?.firstName}.${coveredInbox?.activeInbox?.lastName}`,
    deviceName: '',
    lineNumber: '',
    phoneNumber: coveredInbox?.activeInbox?.phoneNumber,
  };
};

export const GetActiveSharedDeskSender = (user: IUser, coveredInbox: ICoveredUserInboxes): IEmployee => {
  return {
    name: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
    email: coveredInbox?.activeInbox?.coveredUserEmail,
    senderId: `${user.userInfo.employeeId}`,
    userId: `${user.userInfo.employeeId}`,
    NtUserName: user.userInfo?.ntUserName,
    deviceName: '',
    lineNumber: '',
    phoneNumber: coveredInbox?.activeInbox?.phoneNumber,
  };
};

export const GetHandleSendAssociatedRecords = (
  hasActiveInbox: boolean,
  hasActiveSharedInbox: boolean,
  user: IUser,
  manualEntry: boolean,
  existingRecords: ISmsAssociatedRecord[],
) => {
  let associatedRecords;

  if (hasActiveInbox) {
    if (hasActiveSharedInbox) {
      associatedRecords = [
        ...existingRecords,
        {
          name: 'sharedUserId',
          value: `${user.userInfo?.sharedProfile?.employeeId}`,
        },
      ];
    } else {
      associatedRecords = [
        ...existingRecords,
        {
          name: 'coveringEmployeeId',
          value: `${user.userInfo?.employeeId}`,
        },
        {
          name: 'coveringEmployeeName',
          value: `${user.userInfo?.firstName} ${user.userInfo?.lastName}`,
        },
      ];
    }
  } else if (!manualEntry) {
    associatedRecords = existingRecords;
  } else {
    associatedRecords = [];
  }
  const processedRecords = ProcessAssociatedRecords(associatedRecords);
  return processedRecords;
};

export const ProcessAssociatedRecords = (records: ISmsAssociatedRecord[]) => {
  return records
    .filter(
      record =>
        record?.name !== undefined && record?.name !== '' && record?.value !== undefined && record?.value !== '',
    )
    .map(record => ({
      name: typeof record?.name === 'string' ? record?.name : String(record?.name),
      value: typeof record?.value === 'string' ? record?.value : String(record?.value),
    }));
};

export const PrepareSmsPayload = (
  publishSmsPayload: ISmsPayload,
  globalData: any,
  enableBrandSelection: boolean,
  hasActiveInbox: boolean,
  hasActiveSharedInbox: boolean,
  user: IUser,
  coveredInbox: ICoveredUserInboxes,
  manualEntry: boolean,
  attachments: File,
  tos: any[],
) => {
  publishSmsPayload.isBrandSelectionSkipped =
    globalData?.sms?.isBrandSelectionSkipped || enableBrandSelection ? true : false;
  publishSmsPayload.brand = !!globalData?.sms?.isBrandSelectionSkipped ? null : publishSmsPayload?.brand;

  publishSmsPayload.sender = hasActiveInbox
    ? hasActiveSharedInbox
      ? GetActiveSharedDeskSender(user, coveredInbox)
      : GetActiveSender(coveredInbox)
    : publishSmsPayload.sender;

  publishSmsPayload.associatedRecords = GetHandleSendAssociatedRecords(
    hasActiveInbox,
    hasActiveSharedInbox,
    user,
    manualEntry,
    publishSmsPayload.associatedRecords,
  );

  if (manualEntry) {
    publishSmsPayload.tos = tos;
    publishSmsPayload.manualEntry = manualEntry;
  }

  const isRecruitmentRole = publishSmsPayload?.userRole
    .toLowerCase()
    .split(/[,.]/)
    .some(role => role.trim() === 'recruitment');

  const hasContactId = globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
  const shouldSkipBrandSelection = globalData?.sms?.isBrandSelectionSkipped || enableBrandSelection;

  if (isRecruitmentRole && hasContactId && shouldSkipBrandSelection) {
    publishSmsPayload.associatedRecords.push({
      name: 'skipBrandSelection',
      value: 'true',
    });
  }

  if (!isRecruitmentRole || shouldSkipBrandSelection || !hasContactId) {
    publishSmsPayload.associatedRecords = publishSmsPayload.associatedRecords.filter(
      record => record.name !== 'brandId',
    );

    delete publishSmsPayload.brandId;
    delete publishSmsPayload.brand;

    publishSmsPayload.tos = publishSmsPayload.tos?.map(item => {
      const { brand, brandId, ...rest } = item;
      return rest;
    });
  }

  return smsPayloadToFormData(publishSmsPayload, attachments);
};

export const GetFileFromUrl = async (url, name, type) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: type,
  });
};
