import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { makeStyles } from 'tss-react/mui';
import { Alert, IconButton, Theme, Typography } from 'amn-ui-core';
import { FormInputDropdown } from '../PersonalInfoTab/Form/Controls/FormInputDropdown';
import { FormInputText } from '../PersonalInfoTab/Form/Controls/FormInputText';
import { CountryPhoneNumber } from '../PersonalInfoTab/Form/EmergencyContact/CountryPhoneNumber';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { DropDownItem } from '../../../../../models/DropDown';
import { useTranslation } from 'react-i18next';
import FormInputTextField from '../../WorkExperience/Edit/components/FormInputTextField';
import { contactPreferences } from '../WorkExperienceTab/components/viewComponents/AddEditReference/ReferenceUtils';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginLeft: '0px',
    padding: '20px 20px 0px 20px !important',
  },
  alingmentField: {
    padding: '0 0 12px 5px !important',
  },
  alignmentTextField: {
    padding: '0 16px 12px 5px !important',
  },
  workExperienceField: {
    padding: '5px 16px 12px 5px !important',
  },
  buttonAlignment: {
    alignSelf: 'center',
  },
  alingmentFieldHeader: {
    padding: '8px 0 0 5px !important',
  },
  alingmentFieldSubHeader: {
    paddingTop: '6px !important',
  },
  header: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500,
  },
  datePickerInput: {
    height: '55px',
  },
  referenceLabel: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingTop: '3px',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  addButtonRoot: {
    color: '#C4C6C9',
    background: 'none !important',
    padding: '0 !important',
    '&:hover': {
      color: '#2870B9',
    },
  },
  phonelabel: {
    '&.react-tel-input .special-label': {
      display: 'inline-flex',
      position: 'absolute',
      top: '5px',
      left: '70px',
      background: 'transparent',
      fontSize: '11px',
      color: theme.palette.system.darkGrey,
    },
    '&.react-tel-input .form-control': {
      paddingTop: theme.spacing(3),
    },
  },
  containerGrid: {
    padding: '8px 0px 0px 0px !important',
    margin: '0px',
  },
  comments: {
    '& .MuiInputBase-root': {
      paddingRight: '2px',
    },
  },
  disabledField: {
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.framework.system.backgroundLightGrey,
    },
  },
  disabledLabel: {
    color: theme.palette.framework.system.darkGray2,
  },
  normalLabel: {
    color: theme.palette.framework.system.tertiaryGrey,
  },
  checkboxLabelDisabled: {
    color: `${theme.palette.framework.system.darkGray2} !important`,
  },
  checkboxLabelEnabled: {
    color: `${theme.palette.framework.system.charcoal} !important`,
  },
  normalField: {
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.framework.system.platinum,
    },
  },
}));

interface ReferenceFormFieldsProps {
  workExperienceOptions: DropDownItem[];
  phoneTypeOptions: DropDownItem[];
  callPreferenceOptions: DropDownItem[];
  bestTimeToReachOptions: DropDownItem[];
  referenceTypeOptions: DropDownItem[];
  setSaveDisabled: (x: boolean) => void;
  displayAlert: boolean;
}

export const ReferenceFormFields = (props: ReferenceFormFieldsProps) => {
  const {
    workExperienceOptions,
    phoneTypeOptions,
    callPreferenceOptions,
    bestTimeToReachOptions,
    referenceTypeOptions,
    setSaveDisabled,
    displayAlert,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext();
  const [options, setOptions] = useState<DropDownItem[]>(workExperienceOptions);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [secondPhoneEnabled, setSecondPhoneEnabled] = useState<boolean>(false);
  const referenceTypeOptions2 = referenceTypeOptions?.filter(i => i.Description != null);
  const phoneTypeOptions2 = phoneTypeOptions?.filter(i => i.Description != null);
  const callPreferenceOptions2 = callPreferenceOptions?.filter(i => i.Description != null);
  const bestTimeToReachOptions2 = bestTimeToReachOptions?.filter(i => i.Description != null);
  const formValues = getValues();

  useEffect(() => {
    if (!getValues('pWorkExperience')) {
      const defaultWorkExperience = workExperienceOptions?.length ? workExperienceOptions[0].Description : '';
      defaultWorkExperience && setValue('pWorkExperience', defaultWorkExperience);
    }
  }, []);

  useEffect(() => {
    setOptions(workExperienceOptions);
  }, [workExperienceOptions]);

  useEffect(() => {
    if (formValues?.pSupervisorName && formValues?.pWorkExperience) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [formValues?.pSupervisorName, formValues?.pWorkExperience]);

  const handleWorkExperienceChange = event => {
    setValue('pWorkExperience', event.target.value, { shouldDirty: true });
    setDisabled(false);
  };

  const checkSaveDisabled = () => {
    if (Object.keys(errors).length > 0) {
      setSaveDisabled(true);
    } else if (formValues?.pSupervisorName && formValues?.pWorkExperience) {
      setSaveDisabled(false);
    }
  };

  const handleReferenceDateChange = event => {
    setValue('pReferenceDate', event);
    checkSaveDisabled();
  };

  const handleCountryPhone1Change = (value, data, event, formattedValue) => {
    setValue('pPhoneNumber1', formattedValue);
    checkSaveDisabled();
  };

  const handleCountryPhone2Change = (value, data, event, formattedValue) => {
    setValue('pPhoneNumber2', formattedValue);
    checkSaveDisabled();
  };

  const validateEmail = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
  };

  const handleOnEmailChange = value => {
    if (value && !validateEmail(value)) {
      setError('pEmail', {
        message: t('candidate.referenceGrid.invalidEmailErrorMessage'),
      });
    } else {
      clearErrors('pEmail');
    }
    checkSaveDisabled();
  };

  return (
    <>
      {displayAlert &&
        getValues('pEmail')?.length < 1 &&
        watch('pPhoneNumber1')?.length < 3 &&
        watch('pPhoneNumber2')?.length < 3 && (
          <Typography>
            <Alert severity="error">{t('candidate.referenceGrid.alertMessage')}</Alert>
          </Typography>
        )}
      <LayoutGrid direction="column" className={classes.root} spacing={4}>
        <LayoutGridItem item className={classes.containerGrid} width={'100%'}>
          <LayoutGrid
            container
            direction="row"
            data-testid="container"
            justifyContent="flex-start"
            spacing={4}
            className={classes.containerGrid}
            width={'100%'}
          >
            <LayoutGridItem item xs={12} className={classes.workExperienceField} width={'100%'} spacing={3}>
              <FormInputDropdown
                name={'pWorkExperience'}
                label={
                  getValues('pWorkExperience')
                    ? t('candidate.referenceGrid.workExperienceRequired')
                    : `${t('candidate.referenceGrid.workExperienceRequired')} *`
                }
                require={true}
                options={options}
                handleChange={handleWorkExperienceChange}
                inputValue={getValues('pWorkExperience')}
                maxWidthDropdown={'100px'}
                standard
                useMenuItem
              ></FormInputDropdown>
            </LayoutGridItem>
            <LayoutGridItem item xs={12} className={`${classes.alingmentField} ${classes.alingmentFieldSubHeader}`}>
              <LayoutGrid container xs={12} spacing={3}>
                <LayoutGridItem xs={5}>
                  <FormInputText
                    name="pSupervisorName"
                    label={t('candidate.referenceGrid.supervisorEvaluator')}
                    require={true}
                    readOnly={disabled}
                    customClass={disabled ? classes.disabledField : classes.normalField}
                    handleChange={event => {
                      setValue('pSupervisorName', event.target.value);
                      checkSaveDisabled();
                    }}
                    message={errors['pSupervisorName']?.message || ''}
                    maxLengthError={25}
                  />
                </LayoutGridItem>
                <LayoutGridItem xs={3.5}>
                  <FormInputText
                    name="pTitle"
                    label={t('candidate.referenceGrid.title')}
                    require={false}
                    readOnly={disabled}
                    handleChange={event => {
                      setValue('pTitle', event.target.value);
                      checkSaveDisabled();
                    }}
                    customClass={disabled ? classes.disabledField : classes.normalField}
                    message={errors['pTitle']?.message || ''}
                    maxLengthError={255}
                  />
                </LayoutGridItem>
                <LayoutGridItem xs={3.5}>
                  <FormInputDropdown
                    name="pcontactPreference"
                    label={t('candidate.referenceGrid.contactPreference')}
                    require={false}
                    disabled={disabled}
                    options={contactPreferences}
                    handleChange={event => {
                      setValue('pcontactPreference', event.target.value);
                      checkSaveDisabled();
                    }}
                    message={errors['pcontactPreference']?.message || ''}
                    standard
                    showErrorAttribute={true}
                    useMenuItem
                    customClass={disabled ? classes.disabledField : classes.normalField}
                    labelClass={disabled ? classes.disabledLabel : classes.normalLabel}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item xs={12} className={classes.alingmentField}>
              <LayoutGrid container xs={7} spacing={3}>
                <LayoutGridItem xs={5}>
                  <FormInputDropdown
                    name="pPhoneType1"
                    label={
                      watch('pPhoneNumber1')
                        ? `${t('candidate.referenceGrid.phoneType')} *`
                        : t('candidate.referenceGrid.phoneType')
                    }
                    require={watch('pPhoneNumber1') ? true : false}
                    disabled={disabled}
                    options={phoneTypeOptions2}
                    handleChange={event => {
                      if (event.target.value?.length) clearErrors('pPhoneType1');
                      setValue('pPhoneType1', event.target.value);
                      checkSaveDisabled();
                    }}
                    message={errors['pPhoneType1']?.message || ''}
                    standard
                    showErrorAttribute={true}
                    useMenuItem
                    customClass={disabled ? classes.disabledField : classes.normalField}
                    labelClass={disabled ? classes.disabledLabel : classes.normalLabel}
                  />
                </LayoutGridItem>
                <LayoutGridItem xs={6}>
                  <Controller
                    control={control}
                    name="pPhoneNumber1"
                    render={({ value }) => (
                      <CountryPhoneNumber
                        className={classes.phonelabel}
                        label={t('candidate.referenceGrid.phoneNumber')}
                        inlineStyle={{
                          width: '100%',
                          height: '54px',
                          backgroundColor: disabled
                            ? theme.palette.framework.system.backgroundLightGrey
                            : theme.palette.framework.system.platinum,
                        }}
                        onChange={handleCountryPhone1Change}
                        isDisabled={disabled}
                        value={value}
                      />
                    )}
                  />
                </LayoutGridItem>
                {!secondPhoneEnabled && (
                  <LayoutGridItem xs={0.5} className={classes.buttonAlignment}>
                    <IconButton
                      color="primary"
                      type="button"
                      classes={{ root: classes.addButtonRoot }}
                      disabled={disabled}
                      disableTouchRipple
                      disableFocusRipple
                      onClick={() => setSecondPhoneEnabled(true)}
                      aria-label="phone-add-button"
                      size="small"
                    >
                      <AddBoxIcon style={{ height: 40, width: 40 }} />
                    </IconButton>
                  </LayoutGridItem>
                )}
              </LayoutGrid>
            </LayoutGridItem>
            {secondPhoneEnabled && (
              <LayoutGridItem item xs={12} className={classes.alingmentField}>
                <LayoutGrid container xs={7} spacing={3}>
                  <LayoutGridItem xs={5}>
                    <FormInputDropdown
                      name="pPhoneType2"
                      label={
                        watch('pPhoneNumber2')
                          ? `${t('candidate.referenceGrid.phoneType')} *`
                          : t('candidate.referenceGrid.phoneType')
                      }
                      require={watch('pPhoneNumber2') ? true : false}
                      disabled={disabled}
                      options={phoneTypeOptions2}
                      handleChange={event => {
                        if (event.target.value?.length) clearErrors('pPhoneType2');
                        setValue('pPhoneType2', event.target.value);
                        checkSaveDisabled();
                      }}
                      message={errors['pPhoneType2']?.message || ''}
                      standard
                      showErrorAttribute={true}
                      useMenuItem
                      customClass={disabled ? classes.disabledField : classes.normalField}
                      labelClass={disabled ? classes.disabledLabel : classes.normalLabel}
                    />
                  </LayoutGridItem>
                  <LayoutGridItem xs={6}>
                    <Controller
                      control={control}
                      name="pPhoneNumber2"
                      render={({ value }) => (
                        <CountryPhoneNumber
                          className={classes.phonelabel}
                          label={t('candidate.referenceGrid.phoneNumber')}
                          inlineStyle={{
                            width: '100%',
                            height: '54px',
                            backgroundColor: disabled
                              ? theme.palette.framework.system.backgroundLightGrey
                              : theme.palette.framework.system.platinum,
                          }}
                          onChange={handleCountryPhone2Change}
                          isDisabled={disabled}
                          value={value}
                        />
                      )}
                    />
                  </LayoutGridItem>
                  <LayoutGridItem xs={1} className={classes.buttonAlignment}>
                    <IconButton
                      color="primary"
                      type="button"
                      classes={{ root: classes.addButtonRoot }}
                      disabled={disabled}
                      disableTouchRipple
                      disableFocusRipple
                      onClick={() => setSecondPhoneEnabled(false)}
                      aria-label="phone-add-button"
                      size="small"
                    >
                      <DeleteOutline style={{ height: 24, width: 24 }} />
                    </IconButton>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            )}
            <LayoutGridItem item xs={5} className={`${classes.alignmentTextField} ${classes.alingmentFieldSubHeader}`}>
              <FormInputTextField
                name="pEmail"
                label={t('candidate.referenceGrid.emailID')}
                required={false}
                disabled={disabled}
                onChange={event => {
                  handleOnEmailChange(event?.target?.value);
                  setValue('pEmail', event?.target?.value);
                }}
                onBlur={event => handleOnEmailChange(event?.target?.value)}
                maxLength={255}
                maxLengthErrorMessage={t('candidate.referenceGrid.TextFieldErrorMessage')}
                customClass={disabled ? classes.disabledField : classes.normalField}
              />
            </LayoutGridItem>
            <LayoutGridItem item xs={12} className={classes.alingmentField}>
              <LayoutGrid container xs={6} spacing={3}>
                <LayoutGridItem xs={4} sx={{ display: 'flex', alignItems: 'center', minHeight: '80px' }}>
                  <Controller
                    name={'okToContact'}
                    control={control}
                    render={({ onChange, ...rest }) => (
                      <SimpleCheckbox
                        isdisabled={disabled}
                        isChecked={rest.value || false}
                        handleChecked={event => {
                          onChange(event.target.checked);
                        }}
                        checkboxLabel={t('candidate.workExperience.addEditReference.referenceContacts.okToContact')}
                        checkboxName={'AddReferenceOkToContact'}
                        id={'AddReferenceOkToContact'}
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.framework.system.main,
                          },
                        }}
                        labelStyle={disabled ? classes.checkboxLabelDisabled : classes.checkboxLabelEnabled}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem xs={6}>
                  {watch('okToContact') && (
                    <FormInputDropdown
                      name="pBestTimeToReach"
                      label={t('candidate.referenceGrid.bestTimeToReach')}
                      require={false}
                      disabled={disabled}
                      options={bestTimeToReachOptions2}
                      handleChange={event => {
                        setValue('pBestTimeToReach', event.target.value);
                        checkSaveDisabled();
                      }}
                      message={errors['pBestTimeToReach']?.message || ''}
                      standard
                      useMenuItem
                      customClass={disabled ? classes.disabledField : classes.normalField}
                      labelClass={disabled ? classes.disabledLabel : classes.normalLabel}
                    />
                  )}
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
