import { totalCountNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';
import { dropDownFilterOptions, getFacilitySearchLookups } from '@AMIEWEB/GlobalSearch/helper';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { FilterType } from 'app/enums/Common';
import { getSortedData } from 'app/helpers/arrayHelpers';
import { ICascadeStateCityZipDDLOption, ICity, IStateCityZip, IZip } from 'app/models/Candidate/IStateCityZip';
import { getCitiesAndZipsByStates, getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { getAmnEndOfWeek, getAmnStartOfWeek } from 'utils/dates/dateExtraction';

export enum CandidateMatchFlterName {
  recruiter = 'recruiterIds',
  brand = 'brandIds',
  candidateStatus = 'candidateStatusIds',
  discipline = 'disciplineIds',
  specialty = 'specialtyIds',
  candidateSkillSet = 'skillsetFilter',
  candidateAgggreggate = 'candidateFilter',
  candidate = 'candidateIds',
  cid = 'cIds',
  availableStartDate = 'availableStartDate',
  lastApplicationDate = 'lastApplicationDateRange',
  lastPlacementEndDate = 'LastPlacementEndDateRange',
  nonContacted = 'nonContactedDate',
  callBackDate = 'callBackDateRange',
  state = 'states',
  city = 'city',
  zip = 'zipCode',
  radius = 'radius',
  virtualInterview = 'virtualInterview',
  covidVaxStatus = 'covidVaxStatus',
  licenseType = 'licenseType',
  licenseState = 'state',
  licenseCompactStatus = 'compactStatus',
  licenseExp = 'expirationDate',
  license = 'license',
  certification = 'certification',
  certificationType = 'certificationType',
  certificationIssuedBy = 'issuedBy',
  certificationExpDate = 'expirationDate',
  workHistory = 'workHistory',
  workYearOfExp = 'yearsOfExperience',
  workHistoryState = 'state',
  workHistoryFacility = 'facilityId',
  workHistoryDiscipline = 'disciplineId',
  workHistoryUnitSpecialty = 'unitSpecialtyId',
  workHistoryTravelExp = 'travelExperience',
  activePlacements = 'activePlacements',
}

export const getAllCitiesAndZips = (data: IStateCityZip[]) => {
  const zips: IZip[] = [];
  const cities: ICity[] = [];
  const citiesAdded = new Map<string, boolean>();
  data?.forEach(element => {
    zips.push(...element.zips);
    for (let i = 0; i < element.cities.length; i++) {
      const city = element.cities[i];
      if (citiesAdded.has(city.name)) continue;
      cities.push(city);
      citiesAdded.set(city.name, true);
    }
  });

  const city = getSortedData(cities || [], FilterType.string) as ICity[];

  const cityOpts = !city.length
    ? []
    : city.map(x => ({
        id: parseInt(x.id),
        name: x.name,
        value: x.name,
      }));

  const zipOpts = getSortedData(zips || [], FilterType.numeric).map(x => ({
    id: parseInt(x.id),
    name: x.name,
    value: x.name,
  }));
  return {
    cities: cityOpts,
    zips: zipOpts,
  };
};
export const getFilteredCities = (cities: ICascadeStateCityZipDDLOption[], key: string) => {
  const city = getSortedData(cities, FilterType.string) as ICascadeStateCityZipDDLOption[];
  const filteredCities: ICascadeStateCityZipDDLOption[] = [];
  for (let i = 0; i < 100 && i < city.length; i++) {
    filteredCities.push(city[i]);
  }
  const cityOpts = !city.length
    ? []
    : filteredCities.map(x => ({
        id: x.id,
        name: x.name,
        value: x.name,
      }));

  return cityOpts;
};
export const getFilteredZips = (zips: ICascadeStateCityZipDDLOption[], key: string) => {
  const zip = getSortedData(zips, FilterType.numeric) as ICascadeStateCityZipDDLOption[];
  const filteredZips: ICascadeStateCityZipDDLOption[] = [];
  for (let i = 0; i < 100 && i < zip.length; i++) {
    filteredZips.push(zip[i]);
  }
  const zipOpts = !zip.length
    ? []
    : filteredZips.map(x => ({
        id: x.id,
        name: x.name,
        value: x.name,
      }));
  return zipOpts;
};

export const upgradeBasedOnSearchOption = async (
  key: string,
  searchMode: string,
  gridStateFilters: any,
): Promise<ICascadeStateCityZipDDLOption[]> => {
  const selectedStates = (
    !(gridStateFilters?.filters?.states as any[])?.length
      ? []
      : gridStateFilters?.filters?.states?.filter(item => item?.isCompactOpt !== true)
  ) as any[];

  const selectedCities = (
    !(gridStateFilters?.filters?.city as any[])?.length ? [] : gridStateFilters?.filters?.city
  ) as any[];
  let filteredZips: IStateCityZip[];
  let filteredCities: IStateCityZip[];
  if (searchMode === 'z') {
    filteredZips = (await getCitiesAndZipsByStates({
      states: selectedStates.map(x => x.name),
      cities: selectedCities.map(x => x.name),
      searchMode: searchMode,
      searchValue: key,
    })) as IStateCityZip[];
    return getAllCitiesAndZips(filteredZips).zips;
  } else {
    filteredCities = (await getCitiesAndZipsByStates({
      states: selectedStates.map(x => x.name),
      cities: [],
      searchMode: searchMode,
      searchValue: key,
    })) as IStateCityZip[];
    return getAllCitiesAndZips(filteredCities).cities;
  }
};

export const vaccinationStatusOpts = [
  {
    id: 0,
    value: 'U',
    name: 'Unvaccinated',
    labelPrefix: 'Vaccination Status',
  },
  {
    id: 1,
    value: 'D',
    name: 'Declined Vaccination',
    labelPrefix: 'Vaccination Status',
  },
  {
    id: 2,
    value: 'V',
    name: 'Vaccinated',
    labelPrefix: 'Vaccination Status',
  },
];

export const preCandidateStatusOpts = [
  {
    id: 1,
    value: '2',
    name: 'Active',
  },
  {
    id: 2,
    value: '10',
    name: 'Active with Audit',
  },
  {
    id: 10,
    value: '1',
    name: 'Pending',
  },
  {
    id: 11,
    value: '11',
    name: 'Pending with Audit',
  },
];

export const virtualInterviewOpts = [
  { id: 0, value: true, name: 'Yes', labelPrefix: 'Virtual Interview' },
  { id: 1, value: false, name: 'No', labelPrefix: 'Virtual Interview' },
];

export const getStatesArray = arg => {
  const array: string[] = [];
  arg?.forEach(element => {
    array.push(element.name);
  });
  return array;
};
export async function getFilteredOptions(options, keyword) {
  let result = [];
  if (keyword) {
    const filteredData = options.filter(
      opt =>
        opt.value.toLowerCase() === keyword.toLowerCase() ||
        opt.name.toLowerCase().includes(keyword.toLowerCase()) ||
        opt.id === 0,
    );
    if (filteredData?.length >= 1) {
      result = filteredData;
    }
  } else {
    result = options;
  }
  return result?.map(opt => ({ ...opt }));
}

export class OrderCandidateMatchFilterSpecs {
  constructor(public filterSpecs: any, public t: any, public gridStateFilters: any) {}

  getOrderCandidateMatchFilters = () => {
    const date = new Date();
    return [
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.recruiter'),
        name: CandidateMatchFlterName.recruiter,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        applyOnClickAway: true,
        fetchOptions: async key => await getSearchLookups('recruiters', key),
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.candidate'),
        name: CandidateMatchFlterName.candidateAgggreggate,
        type: FilterCompTypes.CANDIDATESEARCH,
        applyOnClickAway: true,
        isMultiSelect: true,
        isSelectAll: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.brand'),
        name: CandidateMatchFlterName.brand,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.brandOpts, key),
        applyOnClickAway: true,
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.candidStatus'),
        name: CandidateMatchFlterName.candidateStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.candidateStatusOpts, key),
        applyOnClickAway: true,
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.skillSet'),
        name: CandidateMatchFlterName.candidateSkillSet,
        type: FilterCompTypes.CANDIDATESKILLSET,
        isMultiSelect: true,
        options: [],
        placeholder: 'Select',
        selectAllValue: 'All',
        version2: true,
        returnsObjectAsValue: true,
        size: 'large',
        customSelectedCount: value => totalCountNodes(value),
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.availableStartDate'),
        name: CandidateMatchFlterName.availableStartDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.availableStartDate'),
        useMaxWidth: true,
        single: true,
        maxWidth: 200,
        clickAway: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        disablePortal: true,
        definedRanges: [
          {
            label: '1 Day',
            startDate: addDays(date, -1),
            endDate: addDays(date, -1),
            useLabelAsValue: false,
          },
          {
            label: '2 Days',
            startDate: addDays(date, -2),
            endDate: addDays(date, -2),
            useLabelAsValue: false,
          },
          {
            label: '7 Days',
            startDate: addDays(date, -7),
            endDate: addDays(date, -7),
            useLabelAsValue: false,
          },
          {
            label: '20 Days',
            startDate: addDays(date, -20),
            endDate: addDays(date, -20),
            useLabelAsValue: false,
          },
          {
            label: '30 Days',
            startDate: addDays(date, -30),
            endDate: addDays(date, -30),
            useLabelAsValue: false,
          },
          {
            label: '60 Days',
            startDate: addDays(date, -60),
            endDate: addDays(date, -60),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.lastApplicationDate'),
        name: CandidateMatchFlterName.lastApplicationDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.lastApplicationDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        disablePortal: true,
        definedRanges: [
          {
            label: 'Today',
            startDate: date,
            endDate: date,
            useLabelAsValue: false,
          },
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'Last 30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'Last 60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.nonContacted'),
        name: CandidateMatchFlterName.nonContacted,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.nonContacted'),
        useMaxWidth: true,
        single: true,
        maxWidth: 140,
        clickAway: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        disablePortal: true,
        definedRanges: [
          {
            label: '1 Day',
            startDate: startOfDay(addDays(date, -1)),
            endDate: endOfDay(addDays(date, -1)),
            useLabelAsValue: false,
          },
          {
            label: '2 Days',
            startDate: startOfDay(addDays(date, -2)),
            endDate: endOfDay(addDays(date, -2)),
            useLabelAsValue: false,
          },
          {
            label: '7 Days',
            startDate: startOfDay(addDays(date, -7)),
            endDate: endOfDay(addDays(date, -7)),
            useLabelAsValue: false,
          },
          {
            label: '20 Days',
            startDate: startOfDay(addDays(date, -20)),
            endDate: endOfDay(addDays(date, -20)),
            useLabelAsValue: false,
          },
          {
            label: '30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(addDays(date, -30)),
            useLabelAsValue: false,
          },
          {
            label: '60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(addDays(date, -60)),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.callBackDate'),
        name: CandidateMatchFlterName.callBackDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.callBackDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        disablePortal: true,
        definedRanges: [
          {
            label: 'Today',
            startDate: date,
            endDate: date,
            useLabelAsValue: false,
          },
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'Last 30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'Last 60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.lastPlacementEndDate'),
        name: CandidateMatchFlterName.lastPlacementEndDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.lastPlacementEndDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        disablePortal: true,
        definedRanges: [
          {
            label: 'Today',
            startDate: date,
            endDate: date,
            useLabelAsValue: false,
          },
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'Last 30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'Last 60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.state'),
        name: CandidateMatchFlterName.state,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.statesOpts, key),
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.city'),
        name: CandidateMatchFlterName.city,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredCities(this.filterSpecs.cityOptions, key);
          const cityOptions = await upgradeBasedOnSearchOption(key, 'c', this.gridStateFilters);
          return cityOptions;
        },
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.zip'),
        name: CandidateMatchFlterName.zip,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredZips(this.filterSpecs.zipOptions, key);
          const zipOptions = await upgradeBasedOnSearchOption(key, 'z', this.gridStateFilters);
          return zipOptions;
        },
        isMultiSelect: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        hideClearAll: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.radius'),
        name: CandidateMatchFlterName.radius,
        type: FilterCompTypes.TEXTFIELDNUMBER,
        applyOnClickAway: true,
        placeholder: 'Radius',
        disabled: this.gridStateFilters?.filters?.zipCode ? false : true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.virtualInterview'),
        name: CandidateMatchFlterName.virtualInterview,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: virtualInterviewOpts,
        applyOnClickAway: true,
        applyOnEnter: true,
        hideClearAll: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.covidVaxStatus'),
        name: CandidateMatchFlterName.covidVaxStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getFilteredOptions(vaccinationStatusOpts, key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: 'Licenses',
        name: CandidateMatchFlterName.license,
        nestedlabelPrefix: 'Lic',
        type: FilterCompTypes.NESTEDFILTER,
        hasDefaultValue: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        manipulateDefaultValue: (currentFilterName, setValue, nestedFieldValue, selectedCheckBox = 'Or') => {
          const nestedFieldArray =
            nestedFieldValue?.value && nestedFieldValue?.value?.length > 0 ? [...nestedFieldValue.value] : [];
          const nestedFieldArrayLength = nestedFieldArray?.length ?? 0;
          nestedFieldArray[nestedFieldArrayLength] = {
            compactStatus: {
              id: 0,
              value: false,
              name: 'Single State',
              position: 3,
              useLabelName: false,
            },
          };
          const newNestedFieldValue = {
            ...nestedFieldValue,
            type: 'NestedFilter',
            nestedlabelPrefix: 'Lic',
            value: nestedFieldArray,
            operation: selectedCheckBox,
          };
          setValue(currentFilterName, newNestedFieldValue);
        },
        filters: [
          {
            filterName: 'License Type',
            name: CandidateMatchFlterName.licenseType,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.licensesOptions,
            isMultiSelect: false,
            position: 1,
            isRequired: true,
            filterOptions: (options, { inputValue }) => {
              return dropDownFilterOptions(options, inputValue);
            },
          },
          {
            filterName: 'State',
            name: CandidateMatchFlterName.licenseState,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.statesOpts
              ? this.filterSpecs?.statesOpts?.filter(x => x.value !== 'All') ?? []
              : [],
            isMultiSelect: false,
            position: 2,
            filterOptions: (options, { inputValue }) => {
              return dropDownFilterOptions(options, inputValue);
            },
          },
          {
            filterName: 'Compact Status',
            name: CandidateMatchFlterName.licenseCompactStatus,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: [
              { name: 'Single State', value: false },
              { name: 'Multi State', value: true },
            ].map((x, id) => ({ id: id, value: x.value, name: x.name })),
            isMultiSelect: false,
            position: 3,
          },
          {
            filterName: 'Expiration Date',
            name: CandidateMatchFlterName.licenseExp,
            type: FilterCompTypes.DATERANGEV2,
            labelPrefixV1: 'Ex',
            variant: 'outlined',
            secondaryLabel: 'Expiration Date',
            useMaxWidth: true,
            single: true,
            maxWidth: 140,
            clickAway: true,
            disablePortal: true,
            position: 4,
            definedRanges: [
              {
                label: '1 Day',
                startDate: startOfDay(addDays(date, -1)),
                endDate: endOfDay(addDays(date, -1)),
                useLabelAsValue: false,
              },
              {
                label: '2 Days',
                startDate: startOfDay(addDays(date, -2)),
                endDate: endOfDay(addDays(date, -2)),
                useLabelAsValue: false,
              },
              {
                label: '7 Days',
                startDate: startOfDay(addDays(date, -7)),
                endDate: endOfDay(addDays(date, -7)),
                useLabelAsValue: false,
              },
              {
                label: '20 Days',
                startDate: startOfDay(addDays(date, -20)),
                endDate: endOfDay(addDays(date, -20)),
                useLabelAsValue: false,
              },
              {
                label: '30 Days',
                startDate: startOfDay(addDays(date, -30)),
                endDate: endOfDay(addDays(date, -30)),
                useLabelAsValue: false,
              },
              {
                label: '60 Days',
                startDate: startOfDay(addDays(date, -60)),
                endDate: endOfDay(addDays(date, -60)),
                useLabelAsValue: false,
              },
            ],
          },
        ],
      },
      {
        filterName: 'Certifications',
        name: CandidateMatchFlterName.certification,
        nestedlabelPrefix: 'Cert',
        type: FilterCompTypes.NESTEDFILTER,
        applyOnClickAway: true,
        applyOnEnter: true,
        filters: [
          {
            filterName: 'Certification',
            name: CandidateMatchFlterName.certificationType,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.certificationOptions,
            isMultiSelect: false,
            position: 1,
            isRequired: true,
            filterOptions: (options, { inputValue }) => {
              return dropDownFilterOptions(options, inputValue);
            },
          },
          {
            filterName: 'Issued By',
            name: CandidateMatchFlterName.certificationIssuedBy,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.certificationIssuers ? this.filterSpecs.certificationIssuers : [],
            isMultiSelect: false,
            position: 2,
          },
          {
            filterName: 'Expiration Date',
            name: CandidateMatchFlterName.certificationExpDate,
            type: FilterCompTypes.DATERANGEV2,
            variant: 'outlined',
            secondaryLabel: 'Expiration Date',
            labelPrefixV1: 'Ex',
            useMaxWidth: true,
            single: true,
            maxWidth: 140,
            position: 3,
            clickAway: true,
            disablePortal: true,
            definedRanges: [
              {
                label: '1 Day',
                startDate: addDays(date, -1),
                endDate: addDays(date, -1),
                useLabelAsValue: false,
              },
              {
                label: '2 Days',
                startDate: addDays(date, -2),
                endDate: addDays(date, -2),
                useLabelAsValue: false,
              },
              {
                label: '7 Days',
                startDate: addDays(date, -7),
                endDate: addDays(date, -7),
                useLabelAsValue: false,
              },
              {
                label: '20 Days',
                startDate: addDays(date, -20),
                endDate: addDays(date, -20),
                useLabelAsValue: false,
              },
              {
                label: '30 Days',
                startDate: addDays(date, -30),
                endDate: addDays(date, -30),
                useLabelAsValue: false,
              },
              {
                label: '60 Days',
                startDate: addDays(date, -60),
                endDate: addDays(date, -60),
                useLabelAsValue: false,
              },
            ],
          },
        ],
      },
      {
        filterName: 'Work History',
        name: CandidateMatchFlterName.workHistory,
        nestedlabelPrefix: 'Exp',
        type: FilterCompTypes.NESTEDFILTER,
        applyOnClickAway: true,
        applyOnEnter: true,
        filters: [
          {
            filterName: 'Years of Experience',
            name: CandidateMatchFlterName.workYearOfExp,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: [
              { id: 0, name: '0 to 2', value: { min: 0, max: 2 } },
              { id: 1, name: '3 to 5', value: { min: 3, max: 5 } },
              { id: 2, name: '6 to 10', value: { min: 6, max: 10 } },
              { id: 3, name: '10 to 12', value: { min: 10, max: 12 } },
              { id: 4, name: '12+', value: { min: 12, max: null } },
            ],
            isMultiSelect: false,
            position: 1,
            //isRequired: true
          },
          {
            filterName: 'State',
            name: CandidateMatchFlterName.workHistoryState,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.statesOpts
              ? this.filterSpecs?.statesOpts?.filter(x => x.value !== 'All') ?? []
              : [],
            isMultiSelect: false,
            position: 2,
          },
          {
            filterName: 'Facility',
            name: CandidateMatchFlterName.workHistoryFacility,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            fetchOptions: getFacilitySearchLookups,
            isMultiSelect: false,
            position: 3,
            //isRequired: true
          },
          {
            filterName: 'Discipline',
            name: CandidateMatchFlterName.workHistoryDiscipline,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs?.disciplineOpts?.filter(x => x.value !== 'All') ?? [],
            isMultiSelect: false,
            position: 4,
          },
          {
            filterName: 'Unit Specialty',
            name: CandidateMatchFlterName.workHistoryUnitSpecialty,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs?.specialtyOpts?.filter(x => x.value !== 'All') ?? [],
            isMultiSelect: false,
            position: 5,
          },
          {
            filterName: 'Travel Experience',
            name: CandidateMatchFlterName.workHistoryTravelExp,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: ['AMN', 'Other', 'None'].map((x, id) => ({ id: id, value: x, name: x })),
            isMultiSelect: false,
            position: 6,
            //isRequired: true
          },
        ],
      },
      {
        filterName: 'Active Placements',
        name: CandidateMatchFlterName.activePlacements,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        hiddenInput: false,
        options: [
          {
            id: 0,
            name: 'Exclude',
            value: {
              include: false,
            },
          },
        ],
        forceCheckboxOptIcon: true,
        hideClearAll: true,
        searchBoxHiddenWithSingleOption: false,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
    ];
  };
}
/**
 * Get the state name from state Abbr list
 * @param filterStateDetails - Object containing all applied state in filter
 * @param stateAbbrList - list containing state Abbr after applying filter and chip slection
 * @returns list of final state names for filtering
 */
export  const getStateNames = (filterStateDetails, stateAbbrList = []) => {
  if (stateAbbrList.length !== 0) {
    const result = filterStateDetails.filter(state => stateAbbrList.includes(state.value)).map(state => state.name);
    return result;
  } else return stateAbbrList;
};