import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Box, Button, InputAdornment, TextField } from 'amn-ui-core';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT, ChannelType } from '../../Constants';
import { conversationNotes, notificationSelection } from 'store/redux-store/notification/notification.selector';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { theme } from 'styles/global-styles';
import { selectUser } from 'oidc/user.selectors';
import { SaveConversationNotes } from 'app/services/NotificationServices/NotificationService';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { loadGridUserLogs, loadUserLogs } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { NotificationContext } from '../../NotificationWrapper';
import { useMessagingStyles } from './Messaging.styles';
import { IEmployee } from 'app/models/Notification/Notification';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';
import { useSmsFeatureFlags } from './useSmsDropzone';
import { CloseConfirmation } from '@AMIEWEB/Notification/Common/CloseDialog';
import { GetActiveSender } from './Helpers/MessagingHelper';

interface ISmsHeaderNotes {
  initialNotes: string;
  setNotesOpen: (value: boolean) => void;
  scrollToBottom: () => void;
}

export const SmsHeaderNotes = ({ initialNotes, setNotesOpen, scrollToBottom }: ISmsHeaderNotes) => {
  const { t } = useTranslation();
  const globalData = useSelector(notificationSelection);
  const { onClose, enableBrandSelection } = useContext(NotificationContext);
  const unknownNum = !globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
  const warningMessage = globalData.snackbarData?.changeWarning;
  const conversationNotesData = useSelector(conversationNotes);

  const user = useSelector(selectUser);
  const SENDER: IEmployee = getDefaultSender(user);
  const dispatch = useDispatch();
  const coveredInbox = useSelector(selectCoveredInbox);
  const smsData = globalData?.sms?.data;
  const activeCoveredSender: IEmployee = GetActiveSender(coveredInbox);

  const [notes, setNotes] = useState(initialNotes);

  const { classes } = useMessagingStyles({ unknownNum, warningMessage: !!warningMessage, enableBrandSelection });
  const { hasActiveInbox } = useSmsFeatureFlags(coveredInbox, user);
  const updateNotes = () => {
    if (conversationNotesData?.length === 0 || conversationNotesData !== notes) {
      dispatch(notificationDataActions.setNotes(notes));
    } else {
      dispatch(notificationDataActions.setNotes(''));
    }
  };

  const saveNotes = async () => {
    let newTosData = _.cloneDeep(smsData?.tos[0]);
    newTosData = { ...newTosData, brandId: !!newTosData?.brandId ? `${newTosData?.brandId}` : null };
    const addNotesData = {
      brandId: !!newTosData?.brandId ? newTosData?.brandId?.toString() : null,
      sender: hasActiveInbox ? activeCoveredSender : SENDER,
      tos: newTosData,
      notes: notes,
      publishedOn: new Date(),
    };
    await SaveConversationNotes(addNotesData)
      .then(response => {
        dispatch(
          globalActions.setSnackBar({
            message: globalData?.sms?.notes
              ? t('notification.sms.notesUpdatedMessage')
              : t('notification.sms.notesAddedMessage'),
            severity: 'success',
          }),
        );
        onClose();
      })
      .catch(error => {
        dispatch(
          globalActions.setSnackBar({
            message: t('notification.sms.notesErrorMessage'),
            severity: 'error',
          }),
        );
      });
    dispatch(notificationDataActions.setNotes(''));
    setNotesOpen(false);
    const payload = {
      brand: smsData?.brand,
      brandId: smsData?.brandId?.toString(),
      contactId: globalData?.sms?.data?.tos?.find(Boolean)?.contactId,
      channel: ['Conversation', 'Email', 'Voice'],
    };
    scrollToBottom();
    setTimeout(() => {
      dispatch(loadUserLogs({ payload }));
      dispatch(loadGridUserLogs({ ...payload, pageSize: 50 }));
    }, 4000);
  };

  const handleNotesCancelOnclick = () => {
    if (conversationNotesData && conversationNotesData !== notes) {
      onClose(ChannelType.sms, false, t('notification.sms.notesCancelMessage'));
    } else if (!conversationNotesData && notes?.length > 0) {
      onClose(ChannelType.sms, false, t('notification.sms.notesCancelMessage'));
    } else {
      setNotesOpen(false);
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  };

  const handleYesAction = () => {
    setNotesOpen(false);
    setNotes(conversationNotesData || '');
    dispatch(notificationDataActions.setNotes(''));
    dispatch(notificationDataActions.setSnackBarData(null));
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  };

  return (
    <>
      <TextField
        label={t('notification.voice.enterNotes')}
        className={classes.notesModal}
        rows={globalData?.snackbarData?.channel === ChannelType.sms && globalData.snackbarData?.changeWarning ? 16 : 19}
        value={notes}
        onBlur={() => updateNotes()}
        multiline
        inputProps={{
          maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
        }}
        InputProps={{
          startAdornment:
            notes?.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
              <InputAdornment
                position="start"
                style={{ position: 'absolute', top: '0', right: '0', color: theme.palette.system.errorRed }}
              >
                <InfoOutlinedIcon className={classes.errorInfoIcon} />
              </InputAdornment>
            ) : (
              <></>
            ),
        }}
        variant="filled"
        error={notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
        onChange={e => setNotes(e.target.value)}
      />
      <LayoutGrid container justifyContent="space-between" style={{ width: '97%', marginLeft: '0px' }} xs={12}>
        <LayoutGridItem style={{ paddingTop: '3px', paddingLeft: '20px' }}>
          <label className={classes.errorText}>{`${
            notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? t('notification.voice.maxCharLimit') : ' '
          }`}</label>
        </LayoutGridItem>
        <LayoutGridItem style={{ paddingTop: '3px' }}>
          <label
            className={
              notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? classes.errorText : classes.characterCount
            }
          >{`${notes?.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
        </LayoutGridItem>
      </LayoutGrid>
      <Box className={classes.button}>
        <Button onClick={() => handleNotesCancelOnclick()} style={{ color: 'gray' }}>{`CANCEL`}</Button>
        <Button
          disabled={conversationNotesData === notes || notes?.length === 0}
          onClick={() => saveNotes()}
          color="primary"
        >{`SAVE`}</Button>
      </Box>
      {globalData?.snackbarData?.channel === ChannelType.sms && !globalData.snackbarData?.changeWarning && (
        <Box className={classes.confirmationMessage}>
          <CloseConfirmation
            yesAction={handleYesAction}
            noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
          />
        </Box>
      )}
    </>
  );
};
