import { instanceOfISelection, ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { ContactProps, IEmployee } from 'app/models/Notification/Notification';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { generateFieldChips } from '../AmWorkDesk/PlacementsDesk/filterHelper';
import { ChannelType, UseType, SendType } from '../Notification/Constants';
import { instanceOfTreeView } from '../Common/TreeView/CustomTreeView';
import { orderSearchFilterName } from './Order/SearchUtils';
import { candidateSearchFilterName } from './Candidate/SearchUtils';
import { facilitySearchFilterName } from './Facility/SearchUtils';
import { instanceOfDateRangePicker, instanceOfDateRangePickerSingle } from 'app/ComponentLibrary/DateRangeNew';
import { convertToFormat } from 'app/helpers/dateHelper';
import { PlacementSearchFilterName } from './Placement/SearchUtils';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { getSearchLookups, getDescriptionsByKeyword } from 'app/services/SharedServices/SharedServices';
import { IMessageAttachment, ISmsResult } from 'app/models/Sms/ISmsResult';
import _ from 'lodash';
import { pageNames } from 'app/constants/PageNames';
import { Concatenate } from 'utils/string/string';
import { getSortedData } from 'app/helpers/arrayHelpers';
import { FilterType } from 'app/enums/Common';
import i18next from 'i18next';
import { CandidatesFilter } from './Candidate/FilterUtils';
import moment from 'moment';
import { CandidateMatchFlterName } from '@AMIEWEB/Order/OrderDetails/OrderCandidateMatch/OrderCandidateMatchFilterUtils';
import { CoverageRequestsGridKeys } from '@AMIEWEB/AmWorkDesk/CoverageDesk/Tabs/Request/gridHelper';
import { UnitOrderFilterName } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/OrdersTab/filterUtils';
import { PlacementsFilter } from './Placement/FilterUtils';

/**
 * Below functionalties are limited to type ISelection
 * Need to be updated for dates and single selection
 */

const isAllValueSelected = opts => opts.some(opt => opt?.value === 'All');
const isChipSelectedInField = (selectedChips, field) => selectedChips && selectedChips[field];

export const getFilterRequest = (filter, selectedChips?) =>
  Object.fromEntries(
    Object.entries(filter).map(field => [
      field[0],
      Array.isArray(field[1])
        ? !isChipSelectedInField(selectedChips, field[0]) && (field[1].length === 0 || isAllValueSelected(field[1]))
          ? undefined
          : field[1].length > 0 && instanceOfTreeView(field[1][0])
            ? isChipSelectedInField(selectedChips, field[0])
              ? selectedChips[field[0]]
              : field[1]
            : containsNullValue(field[0])
              ? (isChipSelectedInField(selectedChips, field[0]) ? selectedChips[field[0]] : field[1]).reduce(
                (results, item) =>
                  !item.isCompactOpt ? [...results, item?.value === '0' ? null : item?.value] : results,
                [],
              )
              : (isChipSelectedInField(selectedChips, field[0]) ? selectedChips[field[0]] : field[1]).reduce(
                (results, item) => (!item.isCompactOpt ? [...results, item?.value] : results),
                [],
              )
        : field[1]
          ? instanceOfDateRangePicker(field[1])
            ? formatDateRangeFilter(field[1])
            : instanceOfDateRangePickerSingle(field[1])
              ? formatDateRangeSingleFilter(field[1])
              : instanceOfISelection(field[1])
                ? (field[1] as ISelection).value
                : (field[1] as any).type === 'NestedFilter'
                  ? (function () {
                    const values = field[1] as any;
                    if (!values.value || !values.value.length) return null;
                    let results = [];
                    if (field[0] === 'license') {
                      results = values.value.map(val => {
                        const res = {};
                        if (!!val?.licenseType && !!val?.licenseType?.value) {
                          res['licenseType'] = val?.licenseType?.value;
                        }
                        if (!!val?.compactStatus && val?.compactStatus?.value) {
                          res['compact'] = true;
                        }
                        if (!val?.compactStatus?.value && !!val?.state?.value) {
                          res['compact'] = false;
                          res['state'] = val?.state?.value;
                        }
                        if (!!val?.expirationDate && !!val?.expirationDate?.startDate) {
                          res['expirationDate'] = val?.expirationDate?.startDate;
                        }
                        return res;
                      });
                    }
                    if (field[0] === facilitySearchFilterName.facilityNested) {
                      results = values.value.map(val => {
                        const res = {};
                        if (
                          !!val?.[facilitySearchFilterName.facilityType] &&
                          !!val?.[facilitySearchFilterName.facilityType]?.value
                        ) {
                          res[facilitySearchFilterName.facilityType] = parseInt(
                            val?.[facilitySearchFilterName.facilityType]?.value,
                          );
                        }
                        if (
                          !!val?.[facilitySearchFilterName.description] &&
                          !!val?.[facilitySearchFilterName.description]?.value
                        ) {
                          res[facilitySearchFilterName.description] = parseInt(
                            val?.[facilitySearchFilterName.description]?.value,
                          );
                        }
                        return res;
                      });
                    } else {
                      results = values.value.map(val => {
                        const res = {};
                        Object.entries(val)?.forEach(nestedFields => {
                          const v = nestedFields[1] as any;
                          if (Object.hasOwn(v, 'value')) {
                            res[nestedFields[0]] = v.value ?? '';
                          } else if (Object.hasOwn(v, 'startDate') && !!v.startDate) {
                            res[nestedFields[0]] = v.startDate ?? null;
                          }
                        });
                        return res;
                      });
                    }
                    return {
                      operation: values?.operation ?? 'Or',
                      values: results,
                    };
                  })()
                  : field[1]
          : undefined,
    ]),
  );

const formatDateRangeFilter = range => {
  return {
    startDate: convertToFormat(range.startDate, 'yyyy-MM-DDTHH:mm:ss', false),
    endDate: convertToFormat(range.endDate, 'yyyy-MM-DDTHH:mm:ss', false),
  };
};

const formatDateRangeSingleFilter = range => {
  return convertToFormat(range.startDate, 'yyyy-MM-DDTHH:mm:ss', false);
};

const containsNullValue = fieldName => {
  switch (fieldName) {
    case PlacementSearchFilterName.arbitrationStatus:
      return true;
    default:
      return false;
  }
};

export const generateChips = (filter, t, category, disableDelete, selectedChips?) =>
  Object.fromEntries(
    Object.entries(filter).map(field => {
      return [
        field[0],
        generateFieldChips(
          field[1],
          selectedChips ? selectedChips[field[0]] : undefined,
          getPrefixLabel(t)(field[0], category),
          false,
          getPostfixLabel(t)(field[0], category),
          disableDelete,
        ),
      ];
    }),
  );

export const revalidateChipSelection = (values, selections) =>
  Object.fromEntries(
    Object.entries(selections).map(([key, value]) => {
      if (value?.[0]?.key === 'selections')
        return [key, values[key].selections.some(item => item.value === value[0].value.value) ? value : null];
      else if (Array.isArray(value)) {
        if (
          key === candidateSearchFilterName.candidateSkillSet ||
          key === candidateSearchFilterName.candidateAgggreggate
        ) {
          return [key, value];
        }
        return [
          key,
          Array.isArray(values[key]) && value.some(selected => values[key]?.some(item => item.value === selected.value))
            ? value
            : null,
        ];
      } else return [key, null];
    }),
  );

export const getPrefixLabel = t => (key: any, category: SearchType) => {
  switch (category) {
    case SearchType.order:
      return customSwitch({
        [orderSearchFilterName.guaranteedHours]: 'Guaranteed Hours',
        [orderSearchFilterName.expectedHours]: 'Expected hours',
        [orderSearchFilterName.orderBillRate]: 'Order Bill Rate ($)',
        [orderSearchFilterName.orderEndDateRange]: 'Order End Date Range',
        [orderSearchFilterName.orderAgeDateRange]: 'Order Age Date Range',
        [orderSearchFilterName.startDateRange]: 'Order Start Date Range',
        [orderSearchFilterName.weeklyGrossPay]: 'Gross Pay Range ($)',
        [orderSearchFilterName.orderIds]: 'OID',
        [orderSearchFilterName.region]: 'Region',
      })(null)(key);
    case SearchType.candidate:
      return customSwitch({
        [candidateSearchFilterName.availableToStart]: t('search.globalSearch.candidate.filterNames.availableToStart'),
        [candidateSearchFilterName.lastApplicationDate]: t(
          'search.globalSearch.candidate.filterNames.lastApplicationDate',
        ),
        [candidateSearchFilterName.engagementDate]: t('search.globalSearch.candidate.filterNames.engagementDate'),
        [candidateSearchFilterName.nonContacted]: t('search.globalSearch.candidate.filterNames.nonContacted'),
        [candidateSearchFilterName.callBackDate]: t('search.globalSearch.candidate.filterNames.callBackDate'),
        [candidateSearchFilterName.lastPlacementEndDate]: t(
          'search.globalSearch.candidate.filterNames.lastPlacementEndDate',
        ),
        [CandidateMatchFlterName.availableStartDate]: 'Available To Start Date',
        [candidateSearchFilterName.candidateAgggreggate]: 'Candidate',
        [candidateSearchFilterName.recruitmentLeaderIds]: t(
          'search.globalSearch.placement.filterNames.recruitmentLeader',
        ),
        [candidateSearchFilterName.region]: t('search.globalSearch.placement.filterNames.region'),
      })(null)(key);
    case SearchType.placement:
      return customSwitch({
        [PlacementSearchFilterName.credentialingAnalyst]: t(
          'search.globalSearch.placement.filterNames.CredentialingAnalyst',
        ),
        [PlacementSearchFilterName.placementClinicalContractSpecialist]: t(
          'search.globalSearch.placement.filterNames.ClientContractSpecialist',
        ),
        [PlacementSearchFilterName.accountManager]: t('search.globalSearch.placement.filterNames.AccountManager'),
        [PlacementSearchFilterName.recruiter]: t('search.globalSearch.placement.filterNames.Recruiter'),
        [PlacementSearchFilterName.placementCuedTo]: t('search.globalSearch.placement.filterNames.placementCuedTo'),
        [PlacementSearchFilterName.availableStartDate]: t(
          'search.globalSearch.placement.filterNames.availableStartDate',
        ),
        [PlacementSearchFilterName.startDate]: t('search.globalSearch.placement.filterNames.startDate'),
        [PlacementSearchFilterName.endDate]: t('search.globalSearch.placement.filterNames.endDate'),
        [PlacementSearchFilterName.extensionStatus]: t(
          'search.globalSearch.placement.filterNames.extensionStatusPrefix',
        ),
        [PlacementSearchFilterName.screen]: t('search.globalSearch.placement.filterNames.screenPrefix'),
        [PlacementSearchFilterName.oid]: t('search.globalSearch.placement.filterNames.oid'),
        [PlacementSearchFilterName.arbitrationStatus]: t(
          'search.globalSearch.placement.filterNames.arbitrationAgreement',
        ),
        [PlacementSearchFilterName.recruitmentLeaders]: t(
          'search.globalSearch.placement.filterNames.recruitmentLeader',
        ),
        [PlacementSearchFilterName.region]: t('search.globalSearch.placement.filterNames.region'),
        [PlacementSearchFilterName.nonContacted]: t('search.globalSearch.placement.filterNames.nonContacted'),
      })(null)(key);
    case SearchType.facility:
      return customSwitch({
        [facilitySearchFilterName.credentialingAnalyst]: t(
          'search.globalSearch.facility.filterNames.credentialingAnalyst',
        ),
        [facilitySearchFilterName.clinicalManager]: t('search.globalSearch.facility.filterNames.clinicalManager'),
        [facilitySearchFilterName.contractAffiliateName]: t(
          'search.globalSearch.facility.filterNames.contractAffiliateName',
        ),
        [facilitySearchFilterName.facilityAgggreggate]: t(
          'search.globalSearch.facility.filterNames.facilityFilter',
        ),
        [facilitySearchFilterName.clientContractSpecialist]: t(
          'search.globalSearch.facility.filterNames.clientContractSpecialist',
        ),
        [facilitySearchFilterName.accountManager]: t('search.globalSearch.facility.filterNames.accountManager'),
        [facilitySearchFilterName.region]: t('search.globalSearch.placement.filterNames.region'),
      })(null)(key);
    case SearchType.coverage:
      return customSwitch({
        [CoverageRequestsGridKeys.coverageFor]: t('search.globalSearch.coverage.coverageFor'),
        [CoverageRequestsGridKeys.coveredBy]: t('search.globalSearch.coverage.coveredBy'),
        [CoverageRequestsGridKeys.createdBy]: t('search.globalSearch.coverage.createdBy'),
        [CoverageRequestsGridKeys.dateRange]: t('search.globalSearch.coverage.dateRange'),
      })(null)(key);
    case SearchType.unit:
      return customSwitch({
        [UnitOrderFilterName.orderAgeDateRange]: t('Order Age Date Range'),
        [UnitOrderFilterName.mfs]: t('search.globalSearch.order.filterNames.mfs'),
      })(null)(key);
    case SearchType.orderPlacement:
      return customSwitch({
        [PlacementSearchFilterName.credentialingAnalyst]: t('search.globalSearch.placement.filterNames.credAnalyst'),
        [PlacementSearchFilterName.accountManager]: t('search.globalSearch.placement.filterNames.acctManager'),
        [PlacementSearchFilterName.recruiter]: t('search.globalSearch.placement.filterNames.recruiter'),
        [PlacementSearchFilterName.startDate]: t('search.globalSearch.placement.filterNames.startDate'),
        [PlacementSearchFilterName.endDate]: t('search.globalSearch.placement.filterNames.endDate'),

        [PlacementSearchFilterName.extensionStatus]: t(
          'search.globalSearch.placement.filterNames.extensionStatusPrefix',
        ),
        [PlacementSearchFilterName.candidate]: t('search.globalSearch.placement.filterNames.candidate'),
        [PlacementSearchFilterName.archiveStatus]: t('search.globalSearch.placement.filterNames.archive'),
        [PlacementSearchFilterName.placementCuedTo]: t('search.globalSearch.placement.filterNames.cuedTo'),
        [PlacementSearchFilterName.pid]: t('search.globalSearch.placement.filterNames.pid'),
        [PlacementSearchFilterName.region]: t('search.globalSearch.placement.filterNames.region'),
      })(null)(key);
    default:
      return null;
  }
};

export const getPostfixLabel = t => (key: any, category: SearchType) => {
  switch (category) {
    case SearchType.order:
      return customSwitch({
        [orderSearchFilterName.radius]: ' Miles',
      })(null)(key);
    case SearchType.candidate:
      return customSwitch({
        [candidateSearchFilterName.radius]: ' Miles',
        [candidateSearchFilterName.activePlacements]: ' Active Placements',
      })(null)(key);
    default:
      return null;
  }
  //Todo : Need to add logic based on category.
};

export const getMultiSelectOptions = (
  options,
  {
    labelPrefix,
    labelPostfix,
    withoutAllOption,
  }: { labelPrefix?: string; labelPostfix?: string; withoutAllOption?: boolean } = {
      labelPrefix: null,
      labelPostfix: null,
      withoutAllOption: false,
    },
) => {
  const opts = (options || []).map((opt, index) => ({
    id: index + 1,
    labelPrefix,
    labelPostfix,
    ...opt,
  }));
  if (!withoutAllOption) opts.unshift({ id: 0, name: 'All', value: 'All', labelPrefix });
  return opts;
};

export const getDisciplinesMultiSelectOptions = options => {
  const array = options?.reduce(
    (resultArray, item) => [
      ...resultArray,
      {
        value: item?.value?.slice(item?.value?.indexOf('_') + 1),
        name: item.name,
        corelation: item.value,
      },
    ],
    [],
  );
  const sortedData = getSortedData(array || [], FilterType.string);
  return sortedData;
};

/**
 * Below function is to create initial notification info data
 */
export const searchNotificationData = user => {
  const sender: IEmployee = {
    name: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    email: user.userInfo?.email,
    senderId: user.userInfo?.employeeId?.toString(),
    userId: user.userInfo?.employeeId?.toString(),
    NtUserName: user.userInfo?.ntUserName,
    deviceName: '',
    lineNumber: '',
  };
  const tos: ContactProps[] = [];
  const ccs: ContactProps[] = [];
  return {
    channel: ChannelType.email,
    sender: sender,
    tos: tos,
    ccs: ccs,
    bccs: [sender],
    body: '<br/><br/> {{signature}}',
    sendType: SendType.bulk,
    useType: UseType.General,
    userRole: user?.userInfo?.roles?.[0] ?? '',
  };
};

export const kofaxNotificationData = user => {
  const sender: IEmployee = {
    name: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    email: user.userInfo?.email,
    senderId: user.userInfo?.employeeId?.toString(),
    userId: user.userInfo?.employeeId?.toString(),
    NtUserName: user.userInfo?.ntUserName,
    deviceName: '',
    lineNumber: '',
  };
  const tos: ContactProps[] = [];
  const ccs: ContactProps[] = [];
  return {
    channel: ChannelType.email,
    sender: sender,
    tos: tos,
    ccs: ccs,
    bccs: [sender],
    body: '<br/><br/> {{signature}}',
    sendType: SendType.one_to_one,
    useType: UseType.General,
    userRole: user?.userInfo?.roles?.[0] ?? '',
  };
};

export const getFacilitySearchLookups = async key => {
  const data = await getSearchLookups('facilities', key);
  return data?.map(d => {
    return {
      value: d.value,
      name: d.name + ', ' + d.value,
    };
  });
};

export const getOrderIdSearchLookups = async key => {
  const data = await getSearchLookups('OID', key);
  return data?.map(d => {
    return {
      value: d.value,
      name: d.name,
    };
  });
};

export const getFacilityIDSearchLookups = async key => {
  const data = await getSearchLookups('facilities', key);
  return data?.map(d => {
    return {
      value: d.value,
      name: d.value,
    };
  });
};

export const getMspClientSearchLookups = async key => {
  const data = await getSearchLookups('mspclient', key);
  return data?.map(d => {
    return {
      value: d.value,
      name: d.name + ', ' + d.value,
    };
  });
};

export const sortOrderData = (tabledata: any) => {
  var array = tabledata;
  array.sort(function (a, b) {
    var nameA = a?.weekly_gross?.toLowerCase(),
      nameB = b?.weekly_gross?.toLowerCase();
    //sort string ascending
    if (nameA < nameB) return 1;
    if (nameA > nameB) return -1;
    return 0; //default return value (no sorting)
  });

  array.sort(function (a, b) {
    var skillsetsA = a?.skillset?.toLowerCase(),
      skillsetsB = b?.skillset?.toLowerCase();
    //sort string ascending
    if (skillsetsA < skillsetsB) return -1;
    if (skillsetsA > skillsetsB) return 1;
    return 0; //default return value (no sorting)
  });

  array.sort(function (a, b) {
    var cityA = a?.city?.toLowerCase(),
      cityB = b?.city?.toLowerCase();
    //sort string ascending
    if (cityA < cityB) return -1;
    if (cityA > cityB) return 1;
    return 0; //default return value (no sorting)
  });

  array.sort(function (a, b) {
    var stateA = a?.state?.toLowerCase(),
      stateB = b?.state?.toLowerCase();
    //sort string ascending
    if (stateA < stateB) return -1;
    if (stateA > stateB) return 1;
    return 0; //default return value (no sorting)
  });
  return array;
};

export const signatureSubstitutionToken = (user, userPreference) => {
  return {
    sender_name:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Sender Name')[0]?.value === ''
          ? Concatenate([user?.userInfo?.firstName || '', user?.userInfo?.lastName || ''], ' ')
          : userPreference.emailSignature?.value.filter(val => val.name === 'Sender Name')[0]?.value
        : '',
    brand: user.userInfo?.companyBrandName,
    brand_id: `${user.userInfo?.companyId}`,
    direct_number: user.userInfo?.directNumber || '',
    mobile_number:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Mobile Number')[0]?.value
        : '',

    text_number:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Text Number')[0]?.value
        : '',

    branded_signature:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Branded Signature')[0]?.value
          ? 'true'
          : 'false'
        : 'false',
    division:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Division')[0]?.value
        : '',

    passport_link:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Passport Link')[0]?.value
          ? 'true'
          : 'false'
        : 'false',
    referral_link:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Referral Link')[0]?.value
          ? 'true'
          : 'false'
        : 'false',

    title:
      userPreference.emailSignature && userPreference.emailSignature.value
        ? userPreference.emailSignature?.value.filter(val => val.name === 'Title')[0]?.value
        : '',
  };
};

const updateAttachment = (attachmentUrl: string, attachments: IMessageAttachment[], channel): IMessageAttachment => {
  const attachmentUrlGuid = attachmentUrl?.split(`/${channel.toLowerCase()}-attachments/`)[1]?.split('/')[0];
  const attachmentFileName = attachmentUrl
    ?.split(`/${channel.toLowerCase()}-attachments/`)[1]
    ?.split('/')[1]
    ?.split('?')[0];
  const index =
    channel.toLowerCase() === ChannelType.sms
      ? attachments?.findIndex(
        x =>
          x.contentUrl?.split(`/${channel.toLowerCase()}-attachments/`)[1]?.split('/')[0] === attachmentUrlGuid &&
          x.contentUrl?.split(`/${channel.toLowerCase()}-attachments/`)[1]?.split('/')[1] === attachmentFileName,
      )
      : attachments?.findIndex(
        x => x.contentUrl?.split(`/${channel.toLowerCase()}-attachments/`)[1]?.split('/')[0] === attachmentUrlGuid,
      );

  if (index !== -1) {
    attachments[index].signedUrl = attachmentUrl;
    return attachments[index];
  }
  const splitUrl = attachmentUrl?.split('sv')[0];
  const attachmentWithoutUrl = splitUrl?.split('?').length > 1 ? splitUrl?.split('?')[0] : splitUrl?.split('%3F')[0];
  return {
    signedUrl: attachmentUrl,
    contentUrl: attachmentWithoutUrl,
    dateDeliveredToKofax: null,
    thumbnailUrl: null,
  };
};
//TODO Anuj (refer todo item #AMN007) This function will not be needed once attachmentURL property is depreciated
export const CompareAttachmentsInInbox = (response: any) => {
  const cloneRes = _.cloneDeep(response);
  const results = (cloneRes ? cloneRes?.results : []) as ISmsResult[];
  for (let i = 0; i < results.length; i++) {
    const channel = results[i].channel;
    let { attachmentUrls, attachments } = results[i].message;
    const mappAttachmentUrlsToAttachment: IMessageAttachment[] = [];
    if (!attachmentUrls) continue;
    attachments = attachments || [];
    for (let j = 0; j < attachmentUrls.length; j++) {
      mappAttachmentUrlsToAttachment[j] = updateAttachment(attachmentUrls[j], attachments, channel);
    }
    results[i].message.attachments = mappAttachmentUrlsToAttachment;
  }
  cloneRes.results = results;
  return cloneRes;
};

export const searchTitle = (category: string) => {
  switch (category) {
    case SearchType.placement:
      return pageNames.searchPlacements;
    case SearchType.candidate:
      return pageNames.searchCandidates;
    case SearchType.facility:
      return pageNames.searchFacilities;
    case SearchType.order:
      return pageNames.searchOrders;
    default:
      return undefined;
  }
};

export const globalSearchTitle = (category: string) => {
  switch (category) {
    case SearchType.placement:
      return pageNames.globalSearchPlacements;
    case SearchType.candidate:
      return pageNames.globalSearchCandidates;
    case SearchType.facility:
      return pageNames.globalSearchFacilities;
    case SearchType.order:
      return pageNames.globalSearchOrders;
    default:
      return undefined;
  }
};

export const recalculateFilterQuickDates = (filters: { [key: string]: any }, category) => {
  if (!filters) return filters;
  switch (category) {
    case SearchType.candidate:
      // Last application date
      if (filters[candidateSearchFilterName.lastApplicationDate]) {
        filters[candidateSearchFilterName.lastApplicationDate] = adjustDateForQuickSelect(
          filters[candidateSearchFilterName.lastApplicationDate],
          new CandidatesFilter({}, i18next.t, null, null, true)
            .getCandidateFilterSpecs()
            .find(item => item.name === candidateSearchFilterName.lastApplicationDate),
        );
      }
      // engagement date
      if (filters[candidateSearchFilterName.engagementDate]) {
        filters[candidateSearchFilterName.engagementDate] = adjustDateForQuickSelect(
          filters[candidateSearchFilterName.engagementDate],
          new CandidatesFilter({}, i18next.t, null, null, true)
            .getCandidateFilterSpecs()
            .find(item => item.name === candidateSearchFilterName.engagementDate),
        );
      }

      // Available to start date
      if (filters[candidateSearchFilterName.availableToStart]) {
        filters[candidateSearchFilterName.availableToStart] = adjustDateForQuickSelect(
          filters[candidateSearchFilterName.availableToStart],
          new CandidatesFilter({}, i18next.t, null, null, true)
            .getCandidateFilterSpecs()
            .find(item => item.name === candidateSearchFilterName.availableToStart),
        );
      }

      // Call back date
      if (filters[candidateSearchFilterName.callBackDate]) {
        filters[candidateSearchFilterName.callBackDate] = adjustDateForQuickSelect(
          filters[candidateSearchFilterName.callBackDate],
          new CandidatesFilter({}, i18next.t, null, null, true)
            .getCandidateFilterSpecs()
            .find(item => item.name === candidateSearchFilterName.callBackDate),
        );
      }

      // Last placement end date
      if (filters[candidateSearchFilterName.lastPlacementEndDate]) {
        filters[candidateSearchFilterName.lastPlacementEndDate] = adjustDateForQuickSelect(
          filters[candidateSearchFilterName.lastPlacementEndDate],
          new CandidatesFilter({}, i18next.t, null, null, true)
            .getCandidateFilterSpecs()
            .find(item => item.name === candidateSearchFilterName.lastPlacementEndDate),
        );
      }

      // Non-contacted
      if (filters[candidateSearchFilterName.nonContacted]) {
        filters[candidateSearchFilterName.nonContacted] = adjustDateForQuickSelect(
          filters[candidateSearchFilterName.nonContacted],
          new CandidatesFilter({}, i18next.t, null, null, true)
            .getCandidateFilterSpecs()
            .find(item => item.name === candidateSearchFilterName.nonContacted),
        );
      }
      return filters;

    case SearchType.placement:
      // Non-contacted
      if (filters[PlacementSearchFilterName.nonContacted]) {
        filters[PlacementSearchFilterName.nonContacted] = adjustDateForQuickSelect(
          filters[PlacementSearchFilterName.nonContacted],
          new PlacementsFilter({}, i18next.t, null, null, true)
            .getPlacementSearchFilterSpecs()
            .find(item => item.name === PlacementSearchFilterName.nonContacted),
        );
      }
      return filters;
    default:
      return filters;
  }
};

export const setDefaultFilters = (filters: { [key: string]: any }, category, arbitrationAgreementFlag: boolean) => {
  if (!filters) return filters;
  switch (category) {
    case SearchType.facility:
      if (!filters[facilitySearchFilterName.credentialingAnalyst]) {
        filters[facilitySearchFilterName.credentialingAnalyst] = [];
      }
      if (!filters[facilitySearchFilterName.clinicalManager]) {
        filters[facilitySearchFilterName.clinicalManager] = [];
      }
      if (!filters[facilitySearchFilterName.contractAffiliateId]) {
        filters[facilitySearchFilterName.contractAffiliateId] = [];
      }
      if (!filters[facilitySearchFilterName.contractAffiliateName]) {
        filters[facilitySearchFilterName.contractAffiliateName] = [];
      }
      if (!filters[facilitySearchFilterName.regionalDirector]) {
        filters[facilitySearchFilterName.regionalDirector] = [];
      }
      if (!filters[facilitySearchFilterName.clientContractAdmin]) {
        filters[facilitySearchFilterName.clientContractAdmin] = [];
      }
      if (!filters[facilitySearchFilterName.clientContractSpecialist]) {
        filters[facilitySearchFilterName.clientContractSpecialist] = [];
      }
      if (!filters[facilitySearchFilterName.hospitalSetting]) {
        filters[facilitySearchFilterName.hospitalSetting] = [];
      }
      if (!filters[facilitySearchFilterName.requirementType]) {
        filters[facilitySearchFilterName.requirementType] = [];
      }
      if (!filters[facilitySearchFilterName.facilityType]) {
        filters[facilitySearchFilterName.facilityType] = [];
      }
      if (!filters[facilitySearchFilterName.description]) {
        filters[facilitySearchFilterName.description] = [];
      }
      if (!filters[facilitySearchFilterName.staffingVendor]) {
        filters[facilitySearchFilterName.staffingVendor] = [];
      }
      if (!filters[facilitySearchFilterName.healthSystemID]) {
        filters[facilitySearchFilterName.healthSystemID] = [];
      }
      if (!filters[facilitySearchFilterName.healthSystem]) {
        filters[facilitySearchFilterName.healthSystem] = [];
      }
      if (!filters[facilitySearchFilterName.techVendor]) {
        filters[facilitySearchFilterName.techVendor] = [];
      }
      if (!filters[facilitySearchFilterName.mspClient]) {
        filters[facilitySearchFilterName.mspClient] = [];
      }
      if (!filters[facilitySearchFilterName.includeRequirementTypeUnits])
        filters[facilitySearchFilterName.includeRequirementTypeUnits] = false;
      return filters;
    case SearchType.placement:
      if (!arbitrationAgreementFlag) filters[PlacementSearchFilterName.arbitrationStatus] = [];
      return filters;
    default:
      return filters;
  }
};

export function dropDownFilterOptions(options, inputValue) {
  if (!inputValue) {
    return options;
  }
  const inputValueLower = inputValue.toLowerCase();
  const valueMatches = options.filter(item => !item?.isCompactOpt && item.value?.toLowerCase() === inputValueLower);
  const nameMatches = options.filter(
    item => !item?.isCompactOpt && item.name?.toLowerCase().includes(inputValueLower) && !valueMatches?.includes(item),
  );
  return [...valueMatches, ...nameMatches];
}

const adjustDateForQuickSelect = (object, definedFilters) => {
  if (object?.label && !object?.useLabelAsValue) {
    return {
      ...object,
      startDate: moment(definedFilters.definedRanges.find(item => item.label === object.label)?.startDate).format(
        'YYYY-MM-DDTHH:mm:ss',
      ),
      endDate: moment(definedFilters.definedRanges.find(item => item.label === object.label)?.endDate).format(
        'YYYY-MM-DDTHH:mm:ss',
      ),
    };
  }
  return object;
};

export const getDescriptionSearchLookups = async key => {
  if (key) {
    const data = await getDescriptionsByKeyword(key, false);
    return data?.map(d => {
      return {
        value: d.value,
        name: d.name,
      };
    });
  } else return [];
};

export const getFacilityCommonSearchLookups = async (type, key) => {
  const data = await getSearchLookups(type, key);
  return data?.map(d => {
    return {
      value: d.value,
      name: type === 'contractaffiliates' ? d.name + ', ' + d.value : d.name,
    };
  });
};

export const formatMultiSortXGridColumns = options =>
  options.map(item => {
    const option = {
      field: item.column,
      sort: item.direction,
    };
    return option;
  });

export const formatMultiSortColumns = options =>
  options.map(item => {
    const option = {
      column: item.field,
      direction: item.sort,
    };
    return option;
  });

export async function getFilteredOptions(options, keyword) {
  let result = [];
  if (keyword) {
    const filteredData = options.filter(
      opt =>
        opt.value.toLowerCase() === keyword.toLowerCase() ||
        opt.name.toLowerCase().includes(keyword.toLowerCase()) ||
        opt.id === 0,
    );
    if (filteredData?.length >= 1) {
      result = filteredData;
    }
  } else {
    result = options;
  }
  return result?.map(opt => ({ ...opt }));
}
