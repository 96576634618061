import { ErrorMessage } from '@hookform/error-message';
import { TextField, TextFieldProps, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type FormInputTextFieldProps = Omit<
  TextFieldProps & {
    required?: boolean;
    characterLimit?: number;
    showHelperText?: boolean;
    maxLength?: number;
    min?: number;
    max?: number;
    maxLengthErrorMessage?: string;
    invalidValueErrorMessage?: string;
    customClass?: string;
  },
  'variant'
>;

const FormInputTextField = (props: FormInputTextFieldProps) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required, maxLength: props.maxLength, min: props.min, max: props.max }}
      render={rest => (
        <>
          <TextField
            defaultValue={props.defaultValue ?? ''}
            variant="filled"
            fullWidth
            label={props.label}
            type={props.type ?? 'text'}
            error={errors[props.name]}
            disabled={props.disabled}
            helperText={
              <LayoutGrid container direction="row" justifyContent="space-between" spacing={2} xs={12}>
                {props.showHelperText && rest?.value?.length != undefined ? (
                  <>
                    <LayoutGridItem item></LayoutGridItem>
                    <LayoutGridItem item mr="-28px">{`${rest?.value?.length}/${props.characterLimit}`}</LayoutGridItem>
                  </>
                ) : (
                  <LayoutGridItem item></LayoutGridItem>
                )}
              </LayoutGrid>
            }
            className={props.customClass ?? ''}
            {...rest}
            {...props}
          />
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={({ message }) => (
              <Typography style={{ color: '#f44336', fontSize: '0.75rem' }}>
                {errors[props.name].type === 'required'
                  ? 'Required'
                  : errors[props.name].type === 'maxLength'
                  ? props.maxLengthErrorMessage
                  : errors[props.name].type === 'min' || errors[props.name].type === 'max'
                  ? props.invalidValueErrorMessage
                  : message}
              </Typography>
            )}
          />
        </>
      )}
    />
  );
};

export default FormInputTextField;
