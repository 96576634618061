import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import { trackPromise } from 'react-promise-tracker';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchActionsList,
  getFacilityUnitsData,
  saveClientContactsData,
  getClientContactsByFacilityId,
  getFacilityClientContactById,
  updateClientContacts,
  deleteClientContactById,
} from '../services/ClientContact.services';
import { formatActionList, generateUnits } from '../Transformers/FormatData';
import { FacilityContactsActions } from 'app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { clientContactActions } from './ClientContact.redux';
import { getMultiSelectOptions, getSigleSelectOptions, getUnitMultiSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { formatFacilityContacts } from 'app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Grid/FacilityContactsHeaders';
import { IOptionsList } from 'app/models/Tasks/Tasks';
import { IClientContactDetails, ResponseCodes } from 'app/models/ClientContact/ClientContact';
import { httpSuccess } from 'app/services/serviceHelpers';

const TrackSaveClientContact = (fn, ...args) => trackPromise(fn(...args), 'save-client-contact');
const TrackUpdateClientContact = (fn, ...args) => trackPromise(fn(...args), 'update-client-contact');

export const TrackGetFacilityAndUnitClientContacts = (fn, ...args) =>
  trackPromise(fn(...args), 'get-fau-client-contacts');
export const TrackGetClientContactById = (fn, ...args) => trackPromise(fn(...args), 'get-client-contacts-by-id');
export const TrackDeleteClientContactById = (fn, ...args) => trackPromise(fn(...args), 'delete-client-contacts-by-id');
const TrackFetchActionsList = (fn, ...args) => trackPromise(fn(...args), 'fetch-actions-list');

export function* saveClientContact(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackSaveClientContact, saveClientContactsData, action.payload);
    yield put(clientContactActions.setCreatedContactId(response.data));
    if (response.status === 200) {
      yield put(
        globalActions.setSnackBar({
          message: 'Client Contact created successfully',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: Client Contact is not created',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveClientContact',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
  }
}

export function* updateClientContact(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackUpdateClientContact, updateClientContacts, action.payload);
    if (response.status === 200) {
      yield put(
        clientContactActions.setClientContactUpdateStatus({
          closeModal: true,
          refreshModal: false,
          responseStatus: response.status,
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: 'Client Contact has been updated successfully',
          severity: 'success',
        }),
      );
    } else {
      const errorResponse = response?.response;
      if (
        errorResponse?.status === 409 &&
        errorResponse?.data &&
        errorResponse?.data?.responseCode === ResponseCodes.conflict
      ) {
        yield put(
          clientContactActions.setClientContactUpdateStatus({
            closeModal: false,
            refreshModal: true,
            responseStatus: errorResponse?.data?.responseCode,
          }),
        );
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateClientContact',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
  }
}

export function* getClientContactById(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackGetClientContactById, getFacilityClientContactById, action.payload);
    if (response.status === 200) {
      const responseData: IClientContactDetails = response?.data;
      yield put(clientContactActions.setClientContactDetails(responseData));
    } else {
      const errorResponse = response?.response;
      yield put(
        clientContactActions.setClientContactUpdateStatus({
          closeModal: true,
          refreshModal: false,
          responseStatus: errorResponse?.data?.status,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getClientContactById',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
  }
}

export function* deleteContactById(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackGetClientContactById, deleteClientContactById, action.payload);
    if (httpSuccess(response?.status)) {
      yield put(
        globalActions.setSnackBar({
          message: 'Client Contact was deleted successfully',
          severity: 'success',
        }),
      );
      yield put(
        clientContactActions.setClientContactUpdateStatus({
          closeModal: true,
          refreshModal: true,
          responseStatus: response.status,
        }),
      );
    } else {
      const errorResponse = response?.response;
      if (errorResponse?.data?.responseCode === ResponseCodes.inActiveStatus) {
        yield call(getClientContactById, {
          payload: action.payload,
          type: clientContactActions.getClientContactById.type,
        });
      }
      yield put(
        clientContactActions.setClientContactUpdateStatus({
          closeModal: false,
          refreshModal: false,
          responseStatus: errorResponse?.data?.responseCode,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteContactById',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
  }
}

export function* getFacilityAndUnitClientContacts(action: PayloadAction<any>) {
  try {
    yield put(FacilityContactsActions.setLoading(true));
    const response = yield call(TrackGetFacilityAndUnitClientContacts, getClientContactsByFacilityId, action.payload);

    if (response.status === 200) {
      const responseData = response?.data;
      const filterSpecs = {
        contactPreferenceOpts: getMultiSelectOptions(responseData.filterSpecs.contactPreferenceOpts),
        nameOpts: getMultiSelectOptions(responseData.filterSpecs.nameOpts),
        titleOpts: getMultiSelectOptions(responseData.filterSpecs.titleOpts),
        statusOpts: getSigleSelectOptions(responseData.filterSpecs.statusOpts),
        unitNameOpts: getUnitMultiSelectOptions(responseData.filterSpecs.unitNameOpts, true),
        actionsOpts: getMultiSelectOptions(responseData.filterSpecs.actionsOpts),
      };
      const UnitFilterLookups = getUnitMultiSelectOptions(responseData.filterSpecs.unitNameOpts, true);
      yield put(FacilityContactsActions.setFilterLookUps(UnitFilterLookups));
      yield put(FacilityContactsActions.setFilterSpecs(filterSpecs));
      yield put(FacilityContactsActions.setStateID('Active'));
      const _flattenedData = formatFacilityContacts(responseData.clientContacts);
      yield put(FacilityContactsActions.setFlattenedData(_flattenedData));
      yield put(FacilityContactsActions.setLoading(false));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getFacilityAndUnitClientContacts',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
    yield put(FacilityContactsActions.setLoading(false));
  }
}

export function* fetchActionsListOptions() {
  try {
    const response = yield call(TrackFetchActionsList, fetchActionsList);
    const formatResponse = formatActionList(response) as IOptionsList[];
    yield put(clientContactActions.setActionList(formatResponse));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'fetchActionsListOptions',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
  }
}

export function* getFacilityUnits(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackSaveClientContact, getFacilityUnitsData, action.payload);
    if (response.status === 200) {
      const formatedUnits = generateUnits(response.data);
      yield put(clientContactActions.setFacilityUnits(formatedUnits));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'fetchUnitsListOptions',
        area: 'CreateClientContactstoreClientContact.saga.ts',
      },
    });
  }
}

export function* clientContactSaga() {
  yield takeLatest(clientContactActions.saveClientContact.type, saveClientContact);
  yield takeLatest(clientContactActions.updateClientContactDetails.type, updateClientContact);
  yield takeLatest(clientContactActions.getFacilityAndUnitClientContacts.type, getFacilityAndUnitClientContacts);
  yield takeLatest(clientContactActions.getFacilityUnits.type, getFacilityUnits);
  yield takeLatest(clientContactActions.getClientContactById.type, getClientContactById);
  yield takeLatest(clientContactActions.deleteClientContactById.type, deleteContactById);
  yield takeLatest(clientContactActions.getActionsList.type, fetchActionsListOptions);
}
