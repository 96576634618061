import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from 'amn-ui-core';
import React, { useMemo, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import _ from 'lodash';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { useStyles } from './UnitStyle';
import { IUnitSubSearch, UnitSubSearch } from './UnitSubSearch';
import { theme } from 'styles/global-styles';

export interface IUnitOption extends Omit<ISelection, 'value'> {
  value: string;
  unitId: number;
  unitName: string;
}
export interface IUnitAggregateSearch extends IUnitSubSearch {
  selections: IUnitOption[];
}

export const AVOID_CLICK_AWAY_LABEL = 'avoid-click-away-type-ahead-drop-down';
export const UNIT_SEARCH_TRACKER = 'unit-aggregate-search';

export function instanceOfAggregateUnit(object: any): object is IUnitAggregateSearch {
  return object && typeof object === 'object' && 'selections' in object && 'unitName' in object && 'unitId' in object;
}

const initialUnitSubSearch: IUnitSubSearch = {
  unitName: null,
  unitId: null,
};

export const initialUnitAggregateSearch: IUnitAggregateSearch = {
  ...initialUnitSubSearch,
  selections: [],
};

export const UnitAggregateSearch: React.FC<{
  value: IUnitAggregateSearch;
  onChange: (selections: IUnitAggregateSearch) => void;
  [key: string]: any;
}> = props => {
  const {
    value,
    selectAllValue,
    filterName,
    isDisabled,
    /** Prop to utilize external anchor element */
    anchorRef,
    removeActionContainer = false,
    customClass,
    hasControlError,
    isNested = false,
    controlErrorText,
    minWidth,
    isMultiSelect,
    optionLookups,
  } = props;

  const { t } = useTranslation();
  const aggregate = value ?? initialUnitAggregateSearch;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [options, setOptions] = React.useState<IUnitOption[]>([]);
  const [prevState, setPrevState] = useState<IUnitAggregateSearch>(aggregate);
  const [cachedSubSearch, cacheSubSearchFilter] = useState<IUnitAggregateSearch | undefined>(undefined);
  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(true);
  const [isNewSubFilterApplied, setIsNewSubFilterApplied] = useState<boolean>(true);
  const { classes } = useStyles({
    isSelected: isMultiSelect
      ? aggregate &&
        (aggregate?.selections?.length > 0 ||
          Object.keys(aggregate).some(key => (aggregate[key] ? (key === 'selections' ? false : true) : false)))
      : aggregate !== null,
    open,
    anchorRef,
    removeActionContainer,
  });
  const [{ trackerArea, id }] = React.useState({
    trackerArea: `${UNIT_SEARCH_TRACKER}-${props.name}`,
    id: `unit-filter-${props.name}`,
  });
  const [selectedCountTag, setSelectedCountTag] = useState<string>(filterName);
  const [subFilterCount, setSubfilterCount] = useState<number>(0);
  const [checkedOptIds, setCheckedOptIds] = useState<number[]>([]);
  const { promiseInProgress: loadingOpts } = usePromiseTracker({ area: trackerArea, delay: 0 });

  const prevAbortControllerRef = React.useRef<AbortController>(null);

  const fetchUnits = async (filters: IUnitSubSearch) => {
    setOptions([]);
    prevAbortControllerRef.current?.abort('UnitAggregateSearch - throttling');
    const abortController = new AbortController();
    prevAbortControllerRef.current = abortController;
    const filterData = (filters: IUnitSubSearch, filterSpecs: ISelection[]) => {
      if (filters.unitName && filters.unitId) {
        return filterSpecs.filter(
          (spec: ISelection) =>
            spec.name.toLowerCase().includes(filters.unitName.toLowerCase()) &&
            spec.id.toString().startsWith(filters.unitId.trim()),
        );
      } else if (filters.unitName) {
        return filterSpecs.filter((spec: ISelection) =>
          spec.name.toLowerCase().includes(filters.unitName.toLowerCase()),
        );
      } else if (filters.unitId) {
        return filterSpecs.filter((spec: ISelection) => spec.id.toString().startsWith(filters.unitId.trim()));
      }
      return filterSpecs;
    };

    const filteredData = await filterData(filters, optionLookups);

    setOptions(
      (filteredData || []).map(opt => ({
        id: Number(opt.value),
        unitId: Number(opt.value),
        value: opt.value,
        unitName: opt.name,
        name: opt.name,
      })),
    );
  };

  const throttleService = useMemo(
    () =>
      _.throttle(request => {
        trackPromise(fetchUnits(request), trackerArea);
      }, 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionLookups],
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!open) {
      setAnchorEl(anchorRef ? anchorRef.current : event.currentTarget);
      setIsNewSelectionApplied(false);
      setPrevState(aggregate);
      throttleService({ ...aggregate });
      DisableAppScroll();
    } else {
      if (props.hideApply) {
        onApplyEvent();
        return;
      }
      cancelChanges();
    }
  };

  const onClickAway = (event: MouseEvent) => {
    if (props.hideApply || (props.applyOnClickAway && aggregate?.selections?.length === 0)) {
      onApplyEvent();
      return;
    }
    if (props.hideApply || (props.applyOnClickAway && aggregate?.selections?.length > 0)) {
      const keys = Object.keys(aggregate);
      const isUnEqual = prevState && aggregate ? keys.some(key => !_.isEqual(prevState[key], aggregate[key])) : false;
      if (isUnEqual) {
        setIsNewSelectionApplied(true);
        setIsNewSubFilterApplied(true);
        props.apply({ ...initialUnitAggregateSearch, selections: aggregate?.selections });
        closeActions();
        return;
      } else {
        closeActions();
      }
    }
    const target = event.target as HTMLInputElement;
    if (target.type !== 'submit' && target.id !== AVOID_CLICK_AWAY_LABEL) {
      /** Temp fix check on anchorRef --> avoid reset action if using external ref */
      !anchorRef && !isNewSelectionApplied && props.onChange(prevState);
      closeActions();
    }
  };

  const closeActions = () => {
    setAnchorEl(null);
    EnableAppScroll();
  };

  const cancelChanges = () => {
    props.onChange(prevState);
    closeActions();
  };

  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    setIsNewSubFilterApplied(true);
    props.apply(aggregate);
    closeActions();
  };

  const handleSelectAll = () => {
    props.onChange({ ...initialUnitAggregateSearch, selections: options });
  };

  const handleClearAll = () => {
    cacheSubSearchFilter(undefined);
    setSubfilterCount(0);
    props.onChange(initialUnitAggregateSearch);
    throttleService(initialUnitAggregateSearch);
  };

  const stringifiedSelections = JSON.stringify(aggregate);

  const handleChange = React.useCallback(
    (current: IUnitOption, newState: boolean) => {
      const newSelections = newState
        ? [...(aggregate?.selections || []), current]
        : (aggregate?.selections || []).filter(x => x.id !== current.id);
      props.onChange({
        ...(newSelections.length > 0 ? initialUnitAggregateSearch : aggregate),
        selections: newSelections,
      });
      setSubfilterCount(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedSelections],
  );

  function countNonNullValues(obj: Record<string, any>) {
    let nonNullCount = 0;
    for (const key in obj) {
      if (key !== 'selections' && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        nonNullCount++;
      }
    }
    return nonNullCount;
  }

  const handleSubFilterChange = React.useCallback(
    newOptFilter => {
      props.onChange({ ...aggregate, ...newOptFilter, selections: aggregate?.selections });
      setSubfilterCount(countNonNullValues(newOptFilter));
      cacheSubSearchFilter(newOptFilter);
      setIsNewSubFilterApplied(false);
      throttleService({ ...newOptFilter });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aggregate, throttleService],
  );

  React.useEffect(
    () => {
      const selected = aggregate?.selections ?? [];
      const appliedSubFilterCount = countNonNullValues(aggregate);
      setSubfilterCount(appliedSubFilterCount);
      /** Condition ensures selectAllValue is removed if any options are manually removed - say chip deletion */
      if (
        selectAllValue &&
        options &&
        selected.length !== options.length &&
        selected.some(item => item.value === selectAllValue)
      )
        props.onChange({ ...aggregate, selections: _.cloneDeep(selected).splice(1, selected.length - 1) });
      if (!selected?.length && !subFilterCount) {
        setSelectedCountTag(filterName);
      } else if (selected?.length > 0) {
        setSelectedCountTag(`(${selected.length}) ${filterName}`);
      } else if (!selected?.length && subFilterCount) {
        setSelectedCountTag(`(${subFilterCount}) ${filterName}`);
      }
      return EnableAppScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedSelections, JSON.stringify(options), subFilterCount],
  );

  const handleKeyDown = React.useCallback(
    event => {
      if (open) {
        /** Code: Apply on click on enter key */
        if (event.key === 'Enter') {
          onApplyEvent();
        } else if (event.key === 'Escape') {
          /** Code: Cancel on click on Esc key */
          cancelChanges();
        } else if (event.altKey && event.key.toLowerCase() === 'c') {
          /** Code: Apply on click on aly + "c" key */
          cancelChanges();
        }
      }
      /** Add future key down features below */
    },
    [cancelChanges, onApplyEvent, open],
  );

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    const checkedOptIds = aggregate?.selections?.map(o => o.id) || [];
    setCheckedOptIds([...checkedOptIds]);
  }, [aggregate]);

  return (
    <Box>
      <div className={classes.root}>
        <Button
          disableRipple
          variant="outlined"
          style={{
            borderColor: hasControlError ? theme.palette.system.guardsmanRed : '',
            color: hasControlError ? theme.palette.system.guardsmanRed : '',
            height: isNested ? '10px' : '',
            minWidth: minWidth ? minWidth : '',
          }}
          aria-describedby={id}
          disabled={!!isDisabled}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          classes={{ root: classes.outlineAnchor }}
          onClick={handleOpen}
          id={props.anchorAccesorid}
        >
          {isNested ? (
            <pre style={{ font: 'normal normal normal 12px Roboto' }}>{selectedCountTag}</pre>
          ) : (
            selectedCountTag
          )}
        </Button>
        {hasControlError ? (
          <div style={{ fontSize: '0.7rem', color: 'red', marginLeft: '3px' }}>
            <span>{controlErrorText}</span>
          </div>
        ) : null}
      </div>
      <Popper
        className={customClass ?? classes.popper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom-end', 'bottom', 'bottom-start', 'top-start', 'top', 'top-end'],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} in={open} timeout={300}>
              <Paper aria-label={id} classes={{ root: classes.paperDropdown }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ padding: '10px 0px 5px 16px', fontWeight: '400' }}
                >
                  {selectedCountTag}
                </Typography>
                <UnitSubSearch value={aggregate} onChange={handleSubFilterChange} />
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  classes={{
                    root: classes.topActionContainer,
                  }}
                >
                  <Button
                    className="clear-all-button"
                    aria-label="apply-button"
                    variant="text"
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    classes={{ text: `${classes.button} ${classes.actionButton}` }}
                    onClick={handleSelectAll}
                  >
                    {t('search.filter.facilitySearch.selectAll')}
                  </Button>
                  <Button
                    className="clear-all-button"
                    aria-label="cancel-button"
                    variant="text"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    color={aggregate?.selections?.length > 0 ? 'primary' : 'secondary'}
                    onClick={handleClearAll}
                    classes={{ text: `${classes.button} ${classes.actionButton}` }}
                  >
                    {t('search.filter.facilitySearch.clearAll')}
                  </Button>
                </Grid>
                {loadingOpts ? (
                  <div className={classes.loadingLabel}>{t('Loading...')}</div>
                ) : options.length === 0 ? (
                  <div className={classes.loadingLabel}>{t('No Options')}</div>
                ) : (
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 300,

                      '& ul': { padding: 0 },
                    }}
                  >
                    {options.map(opt => {
                      const labelId = `checkbox-list-label-${opt}`;
                      return (
                        <ListItem key={opt.id} disablePadding>
                          <ListItemSecondaryAction sx={{ fontWeight: '400', fontSize: '14px' }}>
                            {opt.id}
                          </ListItemSecondaryAction>
                          <ListItemButton
                            role={undefined}
                            onClick={e => {
                              // @ts-ignore
                              if (e.key !== 'Enter') {
                                handleChange(opt, !checkedOptIds?.includes(opt.id));
                              }
                            }}
                            sx={{ paddingBottom: '0px', paddingTop: '0px' }}
                            dense
                          >
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                checked={checkedOptIds?.includes(opt.id)}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{ display: 'flex', gap: '3px', width: '400px', fontWeight: '400' }}
                                >
                                  <span>{opt.name}</span>
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                )}

                {removeActionContainer ? null : (
                  <div className={classes.actionContainer}>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                      <Button
                        className="clear-all-button"
                        aria-label="cancel-button"
                        variant="text"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => cancelChanges()}
                        classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                      >
                        {t('search.filter.cancel')}
                      </Button>
                      <Button
                        className="clear-all-button"
                        aria-label="apply-button"
                        variant="text"
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => onApplyEvent()}
                        classes={{ text: `${classes.button} ${classes.actionButton}` }}
                        id={props.hiddenApplyBtn}
                      >
                        {t('search.filter.apply')}
                      </Button>
                    </Grid>
                  </div>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
