import { takeLatest, put, call, getContext } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { IOrderBonusCommand, IOrderBonusSave, IStrikeDetailsRequest, orderDataActions } from './Order.redux';
import {
  createOrder,
  getOrderDetailsWithPlacements,
  getOrders,
  getOrderStrikeData,
  updateOrder,
  updateStrikeOrder,
} from 'app/services/OrderServices/OrderServices';
import { editOrder, SubmitOrder } from 'app/models/Orders/OrderDetails';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { trackPromise } from 'react-promise-tracker';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { OrderService } from 'app/services/OrderServices/order-service';
import { httpClientError, httpSuccess } from 'app/services/serviceHelpers';
import { OrderRateManipulator } from './helpers/rateCard/helper';
import { getOrderType, getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import i18next from 'i18next';
import { lookupActions } from 'store/redux-store/lookup/lookup.slice';

const TrackGetOrderStrike = (fn, ...args) => trackPromise(fn(...args), 'get-order-strike');
const TrackUpdateOrderStrike = (fn, ...args) => trackPromise(fn(...args), 'update-order-strike');
const TrackGetOrderBonuses = (fn, ...args) => trackPromise(fn(...args), 'get-order-bonuses');
const TrackGetOrderBonusList = (fn, ...args) => trackPromise(fn(...args), 'get-order-bonus-list');
const TrackGetOrderBonusShiftList = (fn, ...args) => trackPromise(fn(...args), 'get-order-bonus-shift-list');
const TrackGetOrderBonusCalculatedAmount = (fn, ...args) =>
  trackPromise(fn(...args), 'get-order-bonus-calculated-amount');
const TrackSaveOrderBonus = (fn, ...args) => trackPromise(fn(...args), 'save-order-bonus');
const TrackCopyOrderBonus = (fn, ...args) => trackPromise(fn(...args), 'copy-order-bonus');
const TrackDeleteOrderBonus = (fn, ...args) => trackPromise(fn(...args), 'delete-order-bonus');
const TrackGetOrderBonusChangeHistory = (fn, ...args) => trackPromise(fn(...args), 'get-order-bonus-change-history');
const TrackOrderTypes = (fn, ...args) => trackPromise(fn(...args), 'order-types');
const TrackRegions = (fn, ...args) => trackPromise(fn(...args), 'order-regions');

//
// ─── GET ORDERS ─────────────────────────────────────────────────────────────────
//
export function* requestGetOrders(action: PayloadAction<{ searchString: string; serverPageSize: number }>) {
  try {
    yield put(orderDataActions.setLoading(true));
    const res = yield call(async () => {
      const r = await getOrders(action.payload.searchString, action.payload.serverPageSize);
      return {
        data: r,
      };
    });

    if (res && res?.data) {
      yield put(orderDataActions.setOrders(res?.data));
      yield put(orderDataActions.setLoading(false));
    }
  } catch (error) {
    yield put(orderDataActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetOrders',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

//
// ─── GET ORDER DETAILS WITH PLACEMENTS ──────────────────────────────────────────
//
export function* requestGetOrderDetailsWithPlacements(action: PayloadAction<{ id: string }>) {
  try {
    yield put(orderDataActions.setLoading(true));
    const res = yield call(async () => {
      const r = await getOrderDetailsWithPlacements(action.payload.id);
      return {
        data: r,
      };
    });

    if (res && res?.data) {
      //yield put(orderDataActions.setOrders(res?.data));
      yield put(orderDataActions.setLoading(false));
    }
  } catch (error) {
    yield put(orderDataActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetOrderDetailsWithPlacements',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

//
// ─── CREATE ORDER ───────────────────────────────────────────────────────────────
//
export function* requestCreateOrder(action: PayloadAction<{ order: SubmitOrder }>) {
  try {
    yield put(orderDataActions.setLoading(true));
    const res = yield call(async () => {
      const r = await createOrder(action.payload.order);
      return {
        data: r,
      };
    });

    if (res && res?.data) {
      //yield put(orderDataActions.setOrders(res?.data));
      yield put(orderDataActions.setLoading(false));
    }
  } catch (error) {
    yield put(orderDataActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCreateOrder',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

//
// ─── UPDATE ORDER ────────────────────────────────────────────────────────────────
//
export function* requestUpdateOrder(action: PayloadAction<{ order: editOrder; id: string }>) {
  try {
    yield put(orderDataActions.setLoading(true));
    const res = yield call(async () => {
      const r = await updateOrder(action.payload.order, action.payload.id);
      return {
        status: r.status,
        data: r,
      };
    });

    if (res && res?.data) {
      //yield put(orderDataActions.setOrders(res?.data));
      yield put(orderDataActions.setLoading(false));
    }
    if (!httpSuccess(res.status)) {
      yield put(
        globalActions.setBanner({
          message:
            res?.data?.responseCode === 60119 ? res?.data?.message : i18next.t('order.newOrderCreation.orderFailed'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(orderDataActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestUpdateOrder',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* getOrderStrike(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackGetOrderStrike, getOrderStrikeData, action.payload);
    if (response.status === 200) {
      yield put(orderDataActions.setOrderStrikeDetails(response?.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getOrderStrike',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* updateOrderStrike(action: PayloadAction<IStrikeDetailsRequest>) {
  try {
    const response = yield call(TrackUpdateOrderStrike, updateStrikeOrder, action.payload);
    if (response.status === 200) {
      yield put(orderDataActions.setStrikeUpdateStatus(true));
      yield put(
        orderDataActions.getOrderStrike({
          facilityId: action.payload?.facilityId,
          orderId: action.payload?.orderId,
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('order.createUnit.strikeDetails.successMessage'),
          severity: 'success',
        }),
      );
    }
  } catch (error) {
    yield put(orderDataActions.setStrikeUpdateStatus(false));
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('order.createUnit.strikeDetails.errorMessage'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateOrderStrike',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestRateElements(action: PayloadAction<number>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(orderService.getEditableRateElements, action.payload);
    if (httpSuccess(response.status)) {
      const rateElements = response.data.rateTableDisplayDetails || [];
      const shifts = (response.data.shifts || []).map(x => x.id);
      const rateManipulator = new OrderRateManipulator(rateElements, shifts);

      const { rates, rateColumns, skillsetColumns, skillsets } = rateManipulator.get();
      // @ts-ignore
      yield put(
        orderDataActions.setOrderRateElements({ rateElements, rates, rateColumns, skillsets, skillsetColumns }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRateElements',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

//
// ─── GET ORDER BONUSES ──────────────────────────────────────────
//
export function* requestOrderBonuses(action: PayloadAction<IOrderBonusCommand>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(TrackGetOrderBonuses, orderService.getOrderBonuses, action.payload);
    if (httpSuccess(response.status)) {
      const orderBonuses = response.data;
      yield put(orderDataActions.setOrderBonuses(orderBonuses));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderBonuses',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

//
//  ─── GET Order Types ──────────────────────────────────────────
//
export function* requestOrderTypes() {
  try {
    const response = yield call(TrackOrderTypes, getOrderType);
    const formatResponse = response.map(item => {
      return {
        ID: item?.value || 0,
        Description: item?.name || '',
      };
    });
    yield put(orderDataActions.setOrderTypes(formatResponse));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderTypes',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

//
//  ─── GET Order Regions ──────────────────────────────────────────
//
export function* requestRegions() {
  try {
    const response = yield call(TrackRegions, getSearchFilterOptions, 'regions');
    const formatResponse = response?.map(item => {
      return {
        ID: item?.value || 0,
        Description: item?.name || '',
      };
    });
    yield put(orderDataActions.setRegions(formatResponse));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRegions',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestOrderBonusList(action: PayloadAction<number>) {
  try {
    yield put(lookupActions.getAllLookupOptions());
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(TrackGetOrderBonusList, orderService.getOrderBonusList, action.payload);
    if (httpSuccess(response.status)) {
      const orderBonuses = response.data;
      yield put(orderDataActions.setOrderBonusList(orderBonuses));
    } else if (httpClientError(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: `Failed to fetch order bonus list`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderBonusList',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestOrderBonusShiftList(action: PayloadAction<{ orderId: number; bonusId: number }>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    yield put(orderDataActions.setOrderBonusShifts([]));
    const response = yield call(
      TrackGetOrderBonusShiftList,
      orderService.getOrderBonusShiftList,
      action.payload.orderId,
      action.payload.bonusId,
    );
    if (httpSuccess(response.status)) {
      const orderBonusShifts = response.data.map(item => ({
        ID: item?.shiftId,
        Description: item?.descShift,
      }));
      yield put(orderDataActions.setOrderBonusShifts(orderBonusShifts));
    } else if (httpClientError(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: `Failed to fetch order bonus shifts list`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderBonusShiftList',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}
export function* requestOrderBonusCalculatedAmount(
  action: PayloadAction<{ orderId: number; amount: number; type: string }>,
) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    yield put(orderDataActions.setOrderBonusCalculatedAmount(null));
    const response = yield call(
      TrackGetOrderBonusCalculatedAmount,
      orderService.getOrderBonusCalculatedAmount,
      action.payload.orderId,
      action.payload.amount,
      action.payload.type,
    );
    if (httpSuccess(response.status)) {
      const calculatedAmount = response.data;
      yield put(
        orderDataActions.setOrderBonusCalculatedAmount({
          type: action.payload.type,
          rate: calculatedAmount?.rate,
          returnValue: calculatedAmount?.returnValue,
          error: null,
        }),
      );
    } else if (httpClientError(response.status)) {
      yield put(
        orderDataActions.setOrderBonusCalculatedAmount({
          type: action.payload.type,
          rate: null,
          returnValue: null,
          error: response?.data?.errorMessage,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderBonusCalculatedAmount',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestSaveOrderBonus(action: PayloadAction<IOrderBonusSave>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    let allowSave = true;
    if (action.payload.deletedOrderBonusShifts.length > 0) {
      const deleteShiftResponse = yield call(TrackSaveOrderBonus, async () => {
        return orderService.deleteOrderBonusShifts({
          orderId: action.payload.orderId,
          bonusId: action.payload.bonusId,
          shiftIds: action.payload.deletedOrderBonusShifts,
        });
      });
      if (httpClientError(deleteShiftResponse.status)) {
        allowSave = false;
        yield put(
          globalActions.setSnackBar({
            message: `Unable to ${
              action?.payload.timeStamp ? 'update' : 'create '
            } Order Bonus. Please contact Administrator`,
            severity: 'error',
          }),
        );
      }
    }
    if (allowSave) {
      const res = yield call(TrackSaveOrderBonus, async () => {
        return orderService.saveOrderBonus(action.payload);
      });
      if (httpSuccess(res.status) && res?.data && res?.data?.returnValue === 0) {
        if (action.payload.shiftIds.length > 0) {
          const response = yield call(TrackSaveOrderBonus, async () => {
            return orderService.saveOrderBonusShifts(
              action.payload.orderId,
              res?.data?.orderBonusId,
              action.payload.shiftIds,
            );
          });
          if (response && response?.data === 0) {
            yield put(orderDataActions.setOrderBonusCreated(true));
          } else {
            yield put(orderDataActions.setOrderBonusCreated(false));
          }
        } else {
          yield put(orderDataActions.setOrderBonusCreated(true));
        }
      } else {
        yield put(
          globalActions.setSnackBar({
            message: `Unable to ${
              action?.payload.timeStamp ? 'update' : 'create '
            } Order Bonus. Please contact Administrator`,
            severity: 'error',
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: `Unable to update Order Bonus. Please contact Administrator`,
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSaveOrderBonus',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestCopyOrderBonus(
  action: PayloadAction<{ sourceOrderId: number; destinationOrderId: number; bonusTemplateId: number; userId: Number }>,
) {
  try {
    yield put(orderDataActions.setCopyOrderBonus(false));
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(TrackCopyOrderBonus, async () => {
      return orderService.copyOrderBonus({
        sourceOrderId: action.payload.sourceOrderId,
        destinationOrderId: action.payload.destinationOrderId,
        bonusTemplateId: action.payload.bonusTemplateId,
        userId: action.payload.userId,
      });
    });

    if (httpSuccess(response.status) && response?.data === 0) {
      yield put(orderDataActions.setCopyOrderBonus(true));
      yield put(
        globalActions.setSnackBar({
          message: `Order bonus copied successfully`,
          severity: 'success',
        }),
      );
    } else if (httpClientError(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: `Error encountered when copying order bonus`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCopyOrderBonus',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestDeleteOrderBonus(
  action: PayloadAction<{ orderId: number; bonusIds: number[]; totalRows: number }>,
) {
  try {
    yield put(orderDataActions.setDeleteOrderBonus(false));
    const orderService: OrderService = yield getContext('orderService');
    if (action?.payload?.bonusIds?.length > 0 && action?.payload?.totalRows > 0) {
      const response = yield call(TrackDeleteOrderBonus, async () => {
        return orderService.deleteOrderBonus({ orderId: action.payload.orderId, bonusIds: action.payload.bonusIds });
      });

      if (httpSuccess(response.status) && response?.data === 0) {
        yield put(orderDataActions.setDeleteOrderBonus(true));
        yield put(
          globalActions.setSnackBar({
            message: `Selected order bonuses were deleted`,
            severity: 'success',
          }),
        );
      } else if (httpClientError(response.status)) {
        yield put(
          globalActions.setSnackBar({
            message: `Order bonus deletion is failed`,
            severity: 'error',
          }),
        );
      }
    } else {
      yield put(
        globalActions.setSnackBar({
          message: `Order bonus deletion is failed`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestDeleteOrderBonus',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* requestGetOrderBonusChangeHistory(action: PayloadAction<{ orderId: string; bonusId: string }>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(TrackGetOrderBonusChangeHistory, async () => {
      return orderService.getOrderBonusChangeHistory(action.payload.orderId, action.payload.bonusId);
    });

    if (httpSuccess(response.status) && response?.data?.length > 0) {
      yield put(
        orderDataActions.setOrderBonusChangeHistory({
          open: true,
          changeHistoryRows: response?.data,
        }),
      );
    } else if (httpClientError(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: `Failed to fetch order bonus history`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetOrderBonusChangeHistory',
        area: 'src/app/components/Order/Store/Order.saga.ts',
      },
    });
  }
}

export function* orderDataSaga() {
  yield takeLatest(orderDataActions.getOrdersAction.type, requestGetOrders);
  yield takeLatest(orderDataActions.getOrderStrike.type, getOrderStrike);
  yield takeLatest(orderDataActions.updateOrderStrike.type, updateOrderStrike);
  yield takeLatest(orderDataActions.getRateElementsAction.type, requestRateElements);
  yield takeLatest(orderDataActions.getOrderBonuses.type, requestOrderBonuses);
  yield takeLatest(orderDataActions.getOrderTypes, requestOrderTypes);
  yield takeLatest(orderDataActions.getRegions, requestRegions);
  yield takeLatest(orderDataActions.getOrderBonusList.type, requestOrderBonusList);
  yield takeLatest(orderDataActions.getOrderBonusShiftList.type, requestOrderBonusShiftList);
  yield takeLatest(orderDataActions.getOrderBonusCalculatedAmount.type, requestOrderBonusCalculatedAmount);
  yield takeLatest(orderDataActions.saveOrderBonus.type, requestSaveOrderBonus);
  yield takeLatest(orderDataActions.copyOrderBonus.type, requestCopyOrderBonus);
  yield takeLatest(orderDataActions.deleteOrderBonus.type, requestDeleteOrderBonus);
  yield takeLatest(orderDataActions.getOrderBonusChangeHistory.type, requestGetOrderBonusChangeHistory);
}
