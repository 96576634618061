import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Chip, Grid, IconButton } from 'amn-ui-core';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { makeStyles } from 'tss-react/mui';
import { findDateRangeAlreadyExists, getNumberOfDays } from './helper';
import { IRequestTimeOffApply, ITimeOffChip } from './IRequest';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from '@mui/icons-material';

const useStyles = makeStyles()(() => ({
  datePicker: {
    width: 300,
  },
  chip: {
    margin: '3px',
    color: '#333333',
    border: '1px solid #CCCCCC',
    fontSize: '14px',
    background: '#FFFFFF',
    '&:hover': {
      color: 'white !important',
      background: '#009AD9',
      '& .MuiChip-deleteIcon': {
        color: 'white !important',
        display: 'block',
      },
    },
  },
  selectedChip: {
    fontSize: '12px',
    margin: '3px',
    color: 'white !important',
    background: '#009AD9',
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  label: {
    fontSize: '14px',
    color: '#333333',
  },
  addButton: {
    width: '38px',
    height: '38px',
    marginTop: '2px',
  },
  disabledButton: {
    backgroundColor: '#8E9195 !important',
    '&:hover': {
      backgroundColor: '#8E9195 !important',
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'not-allowed',
    marginTop: '2px',
  },
}));

/**
 * Component to render request time off content in pop-modal
 */
export const RequestTimeOffWrapper = ({ requestedTimeOff, updateReqList }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [selectedChip, setChipSelected] = useState<ITimeOffChip>(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{
    endDate: Date | string | null;
    startDate: Date | string | null;
    label: string;
    useLabelAsValue: boolean;
  } | null>(null);
  const [requestedTimeOffList, setRequestedTimeOffList] = useState<ITimeOffChip[]>(requestedTimeOff);
  const [isEdit, setEdit] = useState(false);
  const [addTimeOff, setAddTimeOff] = useState<IRequestTimeOffApply>();
  const isAddEnabled = useRef<boolean>();

  const {
    setValue,
    clearErrors,
    watch,
    control,
    setError,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setRequestedTimeOffList(requestedTimeOff);
  }, []);

  const checkIfDateRangeExists = (newStartDate: Date, newEndDate: Date, selectedChip?: ITimeOffChip) => {
    let rangeAlreadyExists;
    if (selectedChip) {
      rangeAlreadyExists = requestedTimeOffList
        .filter(item => item.id !== selectedChip.id)
        .some(item => findDateRangeAlreadyExists(item.startDate, item.endDate, newStartDate, newEndDate));
    } else {
      rangeAlreadyExists = requestedTimeOffList?.some(item =>
        findDateRangeAlreadyExists(item.startDate, item.endDate, newStartDate, newEndDate),
      );
    }
    if (rangeAlreadyExists) {
      setError('timeOffRequest', { type: 'custom', message: 'Time off date already exists.' });
      return true;
    }
    return false;
  };

  const patchDatePickerValuesToChip = data => {
    if (isEdit) {
      // checks if date overlaps with other chips
      const dateRangeExists = checkIfDateRangeExists(data?.startDate, data?.endDate, selectedChip);
      if (!dateRangeExists) {
        const timeOffList = requestedTimeOffList.map(item => {
          if (item?.id == selectedChip?.id) {
            const noOfDays = getNumberOfDays(data.startDate, data.endDate);
            const formattedDate = `${data?.label} (${noOfDays} days)`;
            return {
              ...item,
              key: formattedDate,
              startDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
              endDate: moment(data.endDate).format(moment.HTML5_FMT.DATE),
              label: data?.label,
            };
          }
          return item;
        });
        setRequestedTimeOffList(timeOffList);
        setChipSelected(null);
        setDateRange(null);
        setEdit(false);
      }
    } else {
      const dateRangeExists = checkIfDateRangeExists(data?.startDate, data?.endDate);
      if (!dateRangeExists) {
        const noOfDays = getNumberOfDays(data.startDate, data.endDate);
        const formattedDate = `${data?.label} (${noOfDays} days)`;
        if (requestedTimeOffList?.length > 0) {
          setRequestedTimeOffList(prevState => [
            {
              id: 0,
              key: formattedDate,
              label: data?.label,
              startDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
              endDate: moment(data.endDate).format(moment.HTML5_FMT.DATE),
            },
            ...prevState?.map(item => ({
              ...item,
              id: item?.id + 1,
            })),
          ]);
        } else {
          setRequestedTimeOffList([
            {
              id: 0,
              key: formattedDate,
              label: data?.label,
              startDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
              endDate: moment(data.endDate).format(moment.HTML5_FMT.DATE),
            },
          ]);
        }
        setDateRange(null);
      }
    }
  };

  const handleFormValidations = data => {
    clearErrors('timeOffRequest');
    setValue(
      'timeOffRequest',
      {
        timeOffRequest: {
          endDate: data.endDate,
          startDate: data.startDate,
          label: data.label,
          useLabelAsValue: false,
        },
      },
      { shouldDirty: true },
    );
  };

  /**
   *  Method to handle on date change apply
   * @param data
   */
  const handleApplyDateChange = () => {
    handleFormValidations(addTimeOff);
    patchDatePickerValuesToChip(addTimeOff);
    isAddEnabled.current = false;
  };

  /**
   * Method to delete time off data
   */
  const handleDelete = (itemName: string) => {
    setValue('timeOffRequest', undefined, { shouldDirty: true });
    setChipSelected(null);
    clearErrors('timeOffRequest');
    const filteredTimeOff = requestedTimeOffList.filter(currentItem => currentItem.key !== itemName);
    setRequestedTimeOffList(filteredTimeOff);
  };

  const handleChipSelection = index => {
    setDatePickerOpen(false);
    if (selectedChip?.id >= 0 && selectedChip?.id == index) {
      setDateRange(null);
      setChipSelected(null);
      setEdit(false);
    } else {
      const currentChip = requestedTimeOffList.find(item => item.id === index);
      setDateRange({
        startDate: currentChip ? currentChip?.startDate : null,
        endDate: currentChip ? currentChip?.endDate : null,
        label: currentChip?.label || '',
        useLabelAsValue: false,
      });
      setEdit(true);
      handleFormValidations(currentChip);
      setChipSelected(currentChip);
      setTimeout(() => setDatePickerOpen(true), 0);
    }
  };

  useEffect(() => {
    updateReqList(requestedTimeOffList);
  }, [requestedTimeOffList]);

  const checkIfDateRangeIsOfSameMonth = useCallback(
    dateRange => {
      const start = moment(dateRange?.startDate);
      const end = moment(dateRange?.endDate);
      if (dateRange && start?.month == end?.month && start?.year == end?.year) {
        return true;
      }
      return false;
    },
    [dateRange],
  );

  const getNextMonth = date => {
    const currentDate = new Date(date);
    return new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  };

  const patchDataToAddButton = (data: IRequestTimeOffApply) => {
    isAddEnabled.current = true;
    if (!data?.endDate) {
      const label = `${moment(data?.startDate).format('MM/DD/YYYY')} - ${moment(data?.startDate).format('MM/DD/YYYY')}`;
      setAddTimeOff({
        ...data,
        label,
        endDate: moment(data?.startDate).toDate(),
      });
      setDateRange({
        ...data,
        label,
        endDate: moment(data?.startDate).toDate(),
      });
      return;
    }
    setAddTimeOff(data);
    setDateRange(data);
  };

  return (
    <Grid container padding={'24px'} rowSpacing={2}>
      <Grid container item sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item>
          <Controller
            control={control}
            name={'timeOffRequest'}
            defaultValue={dateRange}
            render={({ ref, onChange, value, ...rest }) => (
              <DateRangePicker
                variant="filled"
                textFieldSize="medium"
                quickSelect={false}
                disablePortal={false}
                actionBar={true}
                // @ts-ignore
                onApply={data => patchDataToAddButton(data)}
                // @ts-ignore
                initialDateRange={dateRange}
                // value={selectedChip?.label}
                error={!!errors.timeOffRequest}
                helperText={errors.timeOffRequest ? errors.timeOffRequest?.message : undefined}
                onChange={() => clearErrors('timeOffRequest')}
                dualValidation
                trailingIconShow
                placeholder="Requested Time Off"
                // maxDate={maxDate}
                clickAway
                // isDisabled={disabled}
                className={classes.datePicker}
                labelClass={classes.label}
                useMaxWidth
                maxWidth={300}
                open={datePickerOpen}
                defaultFirstMonth={dateRange?.startDate ? new Date(dateRange?.startDate) : undefined}
                defaultSecondMonth={
                  checkIfDateRangeIsOfSameMonth(dateRange)
                    ? getNextMonth(dateRange?.startDate)
                    : dateRange?.endDate
                    ? new Date(dateRange?.endDate)
                    : undefined
                }
              />
            )}
          />
        </Grid>
        <Grid item ml={'24px'}>
          {isEdit ? (
            <>
              <CheckIcon
                id="check-icon-time-off-request-btn"
                style={{ width: '30px', height: '22px', color: '#8E9195' }}
                onClick={() => {
                  handleApplyDateChange();
                  setEdit(false);
                }}
              />
              <CloseIcon
                style={{ width: '30px', height: '22px', color: '#8E9195' }}
                onClick={() => setEdit(false)}
                id="close-icon-time-off-request-btn"
              />
            </>
          ) : (
            <IconButton
              color="primary"
              id="add-request-time-off-personal-profile-btn"
              onClick={() => handleApplyDateChange()}
              aria-label="add-button"
              className={isAddEnabled.current ? classes.addButton : classes.disabledButton}
              size="large"
              disabled={!isAddEnabled.current}
            >
              <Add />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid container item>
        {requestedTimeOffList?.length > 0 &&
          requestedTimeOffList.map((timeOffItem, index: number) => (
            <Chip
              id="request-time-off-chip-personal-profile"
              key={index}
              onClick={() => handleChipSelection(index)}
              className={selectedChip?.id === index ? classes.selectedChip : classes.chip}
              label={timeOffItem?.key}
              onDelete={() => handleDelete(timeOffItem?.key)}
              deleteIcon={<HighlightOffIcon />}
              sx={{ marginBottom: '6px !important' }}
            />
          ))}
      </Grid>
    </Grid>
  );
};
