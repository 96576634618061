import { GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchResults, selectUnitsGridData, selectUnitsLoading } from '../Store/FacilityUnit.selector';
import { XGrid, XGridToolbar, calculateComponentRemainingHeight } from '@AMIEWEB/Common';
import { materialUiXGrid } from 'amn-ui-core';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { unitColumns } from './utils';
import { DataGridProProps, GridSortModel } from '@mui/x-data-grid-pro';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { formatOrdersTitle, formatPositionsTitle } from './FacilityUnitsHeader';
import FacilityOrderUnitGrid from './FacilityOrderUnitGrid';
import { FacilityUnitActions } from '../Store/FacilityUnit.redux';
import { useParams } from 'react-router-dom';

export interface IUnitGridFields {
  id: number;
  name: string;
  orders: number;
  positionsAvailable: number;
  skillset: string;
  yearsOfExperience: number;
  lastUpdated: string;
  lastUpdatedBy: string;
  fyreIntegration: string;
  bestBets: string;
  voiceAdvantage: string;
  unitSize: number;
  costCenter: string;
  floor: string;
}

export const FacilityUnitsList = () => {
  const params = useParams<{ id: string }>();
  const DEFAULT_PAGE_SIZE = 250;
  const gridState = useSelector(selectGridState);
  const loading = useSelector(selectUnitsLoading);
  const apiRef = materialUiXGrid.useGridApiRef();
  const unitsFlattenedData = useSelector(selectUnitsGridData);
  const [gridHeight, setGridHeight] = useState<number | undefined>(50);
  const [pageSize, setPageSize] = useState<number>(250);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [expandedRowsId, setExpandedRowsId] = useState([]);
  const defaultSortModel: any[] = [{ field: 'lastUpdated', sort: 'desc' }];
  const [sortModel, setSortModel] = useState<any>([{ field: 'lastUpdated', sort: 'desc' }]);
  const searchResults = useSelector(selectSearchResults);

  const updateGridHeight = () =>
    setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 12));
  useWindowResize(updateGridHeight, updateGridHeight);
  const dispatch = useDispatch();

  const [columns, setColumns] = useState<any>(unitColumns());

  useEffect(() => {
    if (params?.id) {
      dispatch(FacilityUnitActions.requestFacilityUnitsList({ id: Number(params.id) }));
      setPageNumber(0);
      setPageSize(DEFAULT_PAGE_SIZE);
    }
    return () => {
      dispatch(FacilityUnitActions.setFlattenedData([]));
      dispatch(FacilityUnitActions.setFilteredData([]));
    };
  }, [params?.id]);

  /**
   * Method to trigger on page change
   * @param page
   */
  const onPageChange = (page: number) => {
    setPageNumber(page);
  };

  /**
   * Method to trigger on page size change
   * @param page
   */
  const onPageSizeChange = (offset: number) => {
    setPageSize(offset);
  };

  /**
   * Method to trigger on sort model change
   * @param page
   */
  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  /**
   * Function that renders the component for order units grid for facility
   */
  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({ row }) => {
    return <FacilityOrderUnitGrid unitId={Number(row?.unitId)} />;
  }, []);

  const patchSecondaryTitle = useMemo(() => {
    return `${formatOrdersTitle(searchResults?.orders)} ${formatPositionsTitle(searchResults?.positions)}`;
  }, [searchResults?.orders, searchResults?.positions]);

  return (
    <XGrid
      gridTag={GridTag.FacilityUnitsGrid}
      apiRef={apiRef}
      loading={loading}
      columns={columns}
      rows={unitsFlattenedData}
      secondaryTitle={patchSecondaryTitle}
      page={pageNumber}
      pageSize={pageSize}
      autoHeight={false}
      height={gridHeight}
      rowsPerPageOptions={[10, 25, 50, 100, 250]}
      defaultRowsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
      disableSelectionOnClick
      rowCount={unitsFlattenedData?.length ? unitsFlattenedData?.length : 0}
      sortModel={sortModel}
      defaultSortModel={defaultSortModel}
      title="Units"
      pagination={true}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      toolbarProps={{
        refreshButton: true,
        resetButton: true,
        refreshGrid: () => dispatch(FacilityUnitActions.requestFacilityUnitsList({ id: Number(params?.id) })),
        resetColumns: () => setColumns(unitColumns()),
      }}
      detailPanelExpandedRowIds={expandedRowsId}
      onDetailPanelExpandedRowIdsChange={id => setExpandedRowsId(id)}
      components={{
        DetailPanelExpandIcon: ExpandMoreOutlinedIcon,
        DetailPanelCollapseIcon: ExpandLessOutlinedIcon,
        Toolbar: XGridToolbar,
      }}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
    />
  );
};
