import React, { useContext } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Grid, Toolbar } from 'amn-ui-core';
import { Concatenate } from 'utils/string/string';
import { ChangeBanner } from '../../Common/ChangeBanner';
import { nameInitial } from '../../Common/StringHelper';
import { ChannelType } from '../../Constants';
import {
  notificationSelection,
  selectSmsCandidateConsents,
} from 'store/redux-store/notification/notification.selector';
import ChooseCandidate from './Helpers/AssociateCandidate';
import AddBrand from './Helpers/AddBrand';
import SendKofaxEmail from './Helpers/SendKofax';
import ManualEntryField from './Helpers/ManualEntryField';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { ModalAppBar, useMessagingStyles } from './Messaging.styles';
import { SmsHeader } from './SmsHeader';
import { ToasterHeader } from '@AMIEWEB/Notification/Common/ToasterHeader';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { toast, Zoom } from 'react-toastify';
import { ToasterContent } from '@AMIEWEB/Notification/Common/ToasterContent';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { NotificationContext } from '@AMIEWEB/Notification/NotificationWrapper';
import { selectUser } from 'oidc/user.selectors';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { useSmsFeatureFlags } from './useSmsDropzone';
import CloseIcon from '@mui/icons-material/Close';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchLogByPageNumber } from './Helpers/MessagingHelper';
import messageStack from '../../MessageInbox/MessageStack';

interface ISmsAppBarProps {
  chatResults: any;
  selectedKofaxLinks: [string, string, string][];
  isNotesOpen: boolean;
  setNotesOpen: (value: boolean) => void;
  scrollToBottom: () => void;
  attachments: File;
  outboundMessage: any;
  updateReadStatus: () => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  setLoader: (value: boolean) => void;
}

export const SmsAppBar = ({
  chatResults,
  isNotesOpen,
  selectedKofaxLinks,
  setNotesOpen,
  scrollToBottom,
  attachments,
  outboundMessage,
  updateReadStatus,
  phoneNumber,
  setPhoneNumber,
  setLoader,
}: ISmsAppBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useMessagingStyles({ unknownNum: false, warningMessage: false, enableBrandSelection: false });

  const { onClose, enableBrandSelection, handleBrandSelection, handleSmsClose, handleSubmitResponse } =
    useContext(NotificationContext);

  const user = useSelector(selectUser);
  const coveredInbox = useSelector(selectCoveredInbox);
  const consents = useSelector(selectSmsCandidateConsents);
  const globalData = useSelector(notificationSelection);

  const { hasActiveInbox } = useSmsFeatureFlags(coveredInbox, user);

  const smsData = globalData?.sms?.data;
  const candidateDetails = globalData.sms.chatHistory!.candidateDetails;
  const manualEntry = globalData?.sms?.manualEntry;
  const candidateName = Concatenate([candidateDetails?.name?.first, candidateDetails?.name?.last], ' ');

  const handleMinimize = () => {
    const toasterId = smsData?.channel;
    dispatch(
      notificationDataActions.setSmsInteraction({
        open: false,
        minimized: true,
        showDisposition: false,
        readonly: false,
      }),
    );
    dispatch(notificationDataActions.setManuallyEnteredNumber(phoneNumber));
    dispatch(notificationDataActions.incrementActiveToaster());
    dispatch(notificationDataActions.setSnackBarData(null));
    toast(
      <ToasterContent
        channel={ChannelType.sms}
        id={toasterId}
        info={globalData?.sms?.data}
        header={
          globalData?.sms?.data?.tos?.find(Boolean)?.contactId
            ? ToasterHeader(
                globalData?.sms?.data?.tos?.find(Boolean)?.name,
                globalData?.sms?.data?.tos?.find(Boolean)?.contactId,
              )
            : formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber)
        }
        onClose={onClose}
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };

  const handleClose = () => {
    if (
      outboundMessage?.body?.length > 0 ||
      attachments !== undefined ||
      (isNotesOpen &&
        (globalData.sms.newNotes !== undefined ||
          globalData.sms.newNotes !== '' ||
          globalData.sms.notes !== globalData.sms.newNotes))
    ) {
      dispatch(notificationDataActions.setAttachments(undefined));
      onClose(ChannelType.sms, false, t('notification.sms.discardMessage'));
    } else {
      if (!manualEntry) updateReadStatus();
      dispatch(
        notificationDataActions.setSmsInteraction({
          open: false,
          minimized: false,
          readonly: false,
        }),
      );
      dispatch(notificationDataActions.setManualEntry(false));
      dispatch(notificationDataActions.setManuallyEnteredNumber(null));
      dispatch(notificationDataActions.setSmsInititationPoint(null));
      handleSmsClose();
    }
  };

  return (
    <ModalAppBar id="draggableArea" position="relative">
      <Toolbar disableGutters className={classes.modalToolbar}>
        <Avatar className={!consents?.hasTextConcents ? classes.disableAvatar : classes.avatar}>
          {candidateName ? nameInitial(candidateName) : !manualEntry ? <PersonIcon /> : nameInitial('', manualEntry)}
        </Avatar>
        <Grid>
          <Grid item>
            <SmsHeader isNotesOpen={isNotesOpen} setNotesOpen={setNotesOpen} />
          </Grid>
        </Grid>
        <Grid item className={classes.headerNavigation}>
          <RefreshIcon
            onClick={() => {
              fetchLogByPageNumber(
                true,
                globalData.sms.chatHistory!.chats!.pageNumber,
                globalData,
                candidateDetails,
                hasActiveInbox,
                coveredInbox,
                smsData,
                setLoader,
                handleSubmitResponse,
                dispatch,
                notificationDataActions,
                messageStack,
              );
              scrollToBottom();
            }}
            className={classes.modalIcon}
          />
          <IndeterminateCheckBoxOutlinedIcon onClick={handleMinimize} className={classes.modalIcon} />
          <CloseIcon onClick={() => handleClose()} className={classes.modalIcon} />
        </Grid>
      </Toolbar>
      {!globalData?.sms?.data?.tos?.find(Boolean)?.contactId && !manualEntry && <ChooseCandidate />}
      {!manualEntry && enableBrandSelection && <AddBrand handleBrandSelection={handleBrandSelection} />}
      {manualEntry && (
        <ManualEntryField
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          disabled={!isNullOrUndefined(chatResults)}
        />
      )}
      {!!selectedKofaxLinks.length && (
        <SendKofaxEmail
          attachmentUrls={selectedKofaxLinks}
          travelerId={candidateDetails?.travelerId}
          name={candidateName}
        />
      )}
      {selectedKofaxLinks.length === 5 && <ChangeBanner message={t('notification.sms.maxAttachmentValidation')} />}
      {globalData?.snackbarData?.channel === ChannelType.sms && globalData.snackbarData?.changeWarning && (
        <ChangeBanner message={t('notification.finishSmsWarning')} />
      )}
    </ModalAppBar>
  );
};
