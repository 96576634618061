import { reasonForLeavingDropDownOptions } from './Constants';
import countriesData from 'app/assets/jsons/Country.json';
import statesData from 'app/assets/jsons/State.json';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';

enum CountryCodes {
  USA = 1,
  CANADA = 2,
}

export const setDefaultValues = (selectedWorkExperience, disciplines, specialtiesLookup, travelCompanies) => {
  const defaultReason = reasonForLeavingDropDownOptions.find(
    option => option?.label === selectedWorkExperience?.reasonForLeaving,
  );

  const defaultTravelCompany = travelCompanies?.find(option => option?.name === selectedWorkExperience?.travelCompany);
  const defaultFormattedTravelCompany = {
    id: defaultTravelCompany?.value,
    label: defaultTravelCompany?.name,
  };
  const defaultDiscipline = disciplines?.find(option => option?.label === selectedWorkExperience?.discipline);
  const defaultSpecialty = !defaultDiscipline
    ? null
    : specialtiesLookup?.find(option => option?.label === selectedWorkExperience?.specialtyAbbr);
  const updatedCountriesData = countriesData?.countries?.map(e => ({
    label: e?.Description,
    value: e?.CountryDialingCode,
    id: e?.ID,
  }));
  const defaultCountryValue = updatedCountriesData?.find(
    country => country?.label === selectedWorkExperience?.location?.country,
  );
  let defaultState;
  if (defaultCountryValue?.id === CountryCodes.USA || defaultCountryValue?.id === CountryCodes.CANADA) {
    const states = statesData?.states
      .filter(e => e?.CountryID === defaultCountryValue?.id)
      .map(e => ({ id: e?.ID, label: e?.Description, value: e?.StateNumber }));
    defaultState = states.find(option => option?.id === selectedWorkExperience?.location?.state);
  } else {
    defaultState = selectedWorkExperience?.location?.state;
  }
  let defaultCity;
  if (defaultCountryValue?.id === CountryCodes.USA) {
    defaultCity = { id: 1, label: selectedWorkExperience?.location?.city };
  } else {
    defaultCity = selectedWorkExperience?.location?.city;
  }
  return {
    currentlyWorking: selectedWorkExperience?.currentlyEmployed,
    pFacilityEmployer: selectedWorkExperience?.facilityName,
    pUnitFloorDept: selectedWorkExperience?.unit,
    pBeds: selectedWorkExperience?.numberOfBedsInFacility,
    pnotes: selectedWorkExperience?.equipmentProcedures,
    pStart: selectedWorkExperience?.startedOn,
    pEnd: selectedWorkExperience?.endedOn,
    pOngoing: selectedWorkExperience?.currentlyEmployed,
    pPosition: selectedWorkExperience?.position,
    pState: defaultState,
    piCity: defaultCity,
    piZip: selectedWorkExperience?.location.zip,
    pCountry: defaultCountryValue,
    pReason: defaultReason,
    piTravelerCompany: defaultFormattedTravelCompany,
    piDiscipline: defaultDiscipline,
    piSpecialty: defaultSpecialty,
  };
};

export const hasEditModalAccess = (user: any) => {
  if (
    Authorized(
      [
        userRoles.recruitment,
        userRoles.recruitment_TeamMember,
        userRoles.recruitment_Leadership,
        userRoles.clinical_Director,
        userRoles.clinical_Manager,
        userRoles.clinical_QualificationsSpecialist,
        userRoles.clinical_ReviewAnalyst,
        userRoles.accountManagement,
        userRoles.accountManagement_Leadership,
        userRoles.accountManagement_TeamMember,
      ],
      user.userInfo,
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const hasAddModalAccess = (user: any) => {
  if (
    Authorized(
      [
        userRoles.recruitment,
        userRoles.recruitment_TeamMember,
        userRoles.recruitment_Leadership,
        userRoles.clinical_Director,
        userRoles.clinical_Manager,
        userRoles.clinical_QualificationsSpecialist,
        userRoles.clinical_ReviewAnalyst,
      ],
      user.userInfo,
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const dateFormatter = date => {
  var formattedDate = '';
  if (date) {
    const startDate = new Date(date);

    const day = startDate.getDate();
    const month = startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
  } else {
    formattedDate = '--/--/----';
  }
  return formattedDate;
};

export const getDateDifference = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  let diffYears = endDate.getFullYear() - startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  let diffMonths = endMonth - startMonth;

  if (diffMonths < 0 || (diffMonths === 0 && endDate.getDate() < startDate.getDate())) {
    diffMonths += 12;
    diffYears--;
  }

  return `${diffYears} yr, ${diffMonths} mo`;
};

export const validateWorkForm = (data, setError, isTravelWorkExp) => {
  var reasonForLeaving = data.pReason?.label;
  var isWorkFormValid = true;
  const endFromDate = new Date(data.pEnd);
  const startDate = new Date(data.pStart);
  let currentlyWorking = data?.currentlyWorking;

  if (!data?.pEnd && currentlyWorking === false) {
    currentlyWorking = true;
  }

  if (!currentlyWorking && !data?.pOngoing && endFromDate?.getTime() < startDate?.getTime()) {
    setError('pEnd', {
      type: 'validate',
      message: 'End date must be greater than start date',
    });
    isWorkFormValid = false;
  }

  const StartFromDate = new Date(data.pStart);
  const endDate = data.pEnd ? new Date(data.pEnd) : null;
  if (!data.pOngoing && StartFromDate?.getTime() > endDate?.getTime()) {
    setError('pStart', {
      type: 'validate',
      message: 'Start date must be less than end date',
    });
    isWorkFormValid = false;
  }
  if (isTravelWorkExp) {
    if (data.pPosition && data.pPosition?.length > 50) {
      setError('pPosition', {
        type: 'maxLength',
        message: 'max character limit: 50',
      });
      isWorkFormValid = false;
    }
    if (data.pUnitFloorDept && data.pUnitFloorDept?.length > 50) {
      setError('pUnitFloorDept', {
        type: 'maxLength',
        message: 'max character limit: 50',
      });
      isWorkFormValid = false;
    }
    if (!currentlyWorking && reasonForLeaving?.length > 100) {
      setError('pReason', {
        type: 'maxLength',
        message: 'max character limit: 100',
      });
      isWorkFormValid = false;
    }
    if (data.piCity?.length > 100) {
      setError('piCity', {
        type: 'maxLength',
        message: 'max character limit: 100',
      });
      isWorkFormValid = false;
    }
    if (data.pFacilityEmployer?.length > 100) {
      setError('pFacilityEmployer', {
        type: 'maxLength',
        message: 'character limit: 100',
      });
      isWorkFormValid = false;
    }
    if (data?.pnotes?.length > 1000) {
      setError('pnotes', {
        type: 'maxLength',
        message: 'Maximum Characters Reached',
      });
      isWorkFormValid = false;
    }

    if (data.pBeds && data.pBeds > 9999) {
      setError('pBeds', {
        type: 'maxLength',
        message: '# of Beds must be less than 10,000',
      });
      isWorkFormValid = false;
    } else if (data.pBeds && data.pBeds < 0) {
      setError('pBeds', {
        type: 'minLength',
        message: '# of Beds must be greater than 0',
      });
      isWorkFormValid = false;
    }
    var countryIDFilter = data.pCountry?.id;
    if (countryIDFilter === 1 || countryIDFilter === 2) {
      if (parseInt(data.piZip) > 99999) {
        setError('piZip', {
          type: 'maxLength',
          message: 'Zip must be less than 5 digits',
        });
        isWorkFormValid = false;
      }
    } else if (data.piZip?.length > 20) {
      setError('piZip', {
        type: 'maxLength',
        message: 'Zip must be less than 20 digits',
      });
      isWorkFormValid = false;
    }
  }
  return isWorkFormValid;
};
