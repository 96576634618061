import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';

const TypeAheadListBox = ({ customList, classes, ...props }) => {
  return (
    <LayoutGrid container className={classes.listBoxContainer}>
      {customList && <LayoutGridItem item className={classes.colorTag}>{customList}</LayoutGridItem>}
      <ul {...props} />
    </LayoutGrid>
  );
};

export default TypeAheadListBox;
