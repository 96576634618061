import { createAction } from '@reduxjs/toolkit';
import { IPlacementStatus, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { IUpdateCreatePlacement, ISavePlacement } from './types';
import { IRequestCueNotePayload } from '@AMIEWEB/Placement/CreateNewPlacement/CuteNotesConfirmation/model';

const getCandidateAction = createAction<{
  candidateId: number;
  brandId: number;
  useNewCreatePlacementModalFunctions?: boolean;
}>('GET_NEW_PLACEMENT_CANDIDATE');

const getOrderAction = createAction<{
  orderId: number;
}>('GET_NEW_PLACEMENT_ORDER');

const getValidationsAction = createAction<{
  candidateId: number;
  brandId: number;
  orderId: number;
  facilityId: number;
  recruiterId?: number;
  availabilityDate?: string;
  disciplineId?: number;
  specialtyId?: number;
  subSpecialtyId?: number;
}>('GET_NEW_PLACEMENT_VALIDATIONS');

const calculatePlacementStatusList = createAction<{ status: IPlacementStatus | undefined }>(
  'CALCULATE_PLACEMENT_STATUS',
);

const declinePlacement = createAction<{
  placementId: number;
  placementStatus: IPlacementStatus;
  placementOrderType: PlacementOrderType;
  candidateId: number;
  placementUpdate?: {
    updates: IUpdateCreatePlacement | null;
  };
}>('DECLINE_PLACEMENT');

const savePlacementAction = createAction<ISavePlacement>('SAVE_PLACEMENT');

const onInitAction = createAction<{ orderId?: number; candidateId?: number; brandId?: number }>(
  'OnInIt_Create_Placement',
);

const requestScreenOptions = createAction('REQUEST_SCREEN_OPTIONS');

const getPlacementChangeId = createAction<{
  copyPlacementOption: string;
  startDate: string;
  endDate: string;
  oldPlacementID: number;
  copyPlacementFacilityID: string;
  copyPlacementOrderID: string;
  currentEmployeeId: number;
}>('PLACEMENT_CHANGE');

const getCueNotesEmployee = createAction<IRequestCueNotePayload>('GET_CUE_NOTE_EMPLOYEE');

export const serviceActions = {
  getCandidateAction,
  getOrderAction,
  getValidationsAction,
  calculatePlacementStatus: calculatePlacementStatusList,
  savePlacementAction,
  declinePlacement,
  onInitAction,
  requestScreenOptions,
  getPlacementChangeId,
  getCueNotesEmployee,
};
