import { Collapse, Grid } from 'amn-ui-core';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { createFilterChips } from '@AMIEWEB/RecruiterWorkDesk/MyPlacementsDesk/WorkdeskFilter/filterHelper';
import { selectFilterChips } from '../store/FacilityCommunicationHistory.selector';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { determineChipSelected } from '@AMIEWEB/AmWorkDesk/PlacementsDesk/filterHelper';
import { facilityCommunicationHistoryActions } from '../store/FacilityCommunicationHistory.redux';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const FacilityCommunicationHistoryChips = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const appliedFilter = useSelector(selectFilters);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  const [showChipsCollapseBtn, setShowChipsCollapseBtn] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const gridState = useSelector(selectGridState);
  const chips = useSelector(selectFilterChips);

  const getNewSelection = (key: string, chip: IChip) => ({
    ...appliedFilter?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      appliedFilter?.selectedChipFilters ? appliedFilter?.selectedChipFilters[key] : undefined,
    ),
  });

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  const handleChipDelete = (field, chip) => {
    const { value: chipValue } = chip;
    let newUnitsChips = {};
    let newFieldValues: any = null;
    const chosenValue = getValues(field)?.selections?.length > 0 ? getValues(field)?.selections : getValues(field);

    if (Array.isArray(chosenValue)) {
      newFieldValues = [...chosenValue];

      if (field === 'units') {
        newFieldValues?.splice(
          newFieldValues.findIndex(opt => opt.value === chip?.value?.value?.unitId),
          1,
        );
        newUnitsChips = {
          selections: newFieldValues?.map(opt => ({
            id: opt?.id,
            unitId: opt?.unitId,
            value: opt?.value,
            unitName: opt?.unitName,
            name: opt?.name,
          })),
          unitId: null,
          unitName: null,
        };
      } else {
        newFieldValues.splice(
          newFieldValues.findIndex(opt => opt?.value === chipValue?.value),
          1,
        );
      }
      setValue(field, field === 'units' ? newUnitsChips : newFieldValues);
    } else if (chosenValue && field === 'units') {
      newFieldValues = Array.isArray(chosenValue) ? [...chosenValue] : chosenValue;

      setValue(field, {
        ...newFieldValues,
        unitId: chip?.value?.value === newFieldValues?.unitId ? null : newFieldValues?.unitId,
        unitName: chip?.value?.value === newFieldValues?.unitName ? null : newFieldValues?.unitName,
      });
    } else setValue(field, null);

    if (chip?.isSelected) {
      const newSelections = getNewSelection(field, chip);
      props.onApplyFilter(
        { ...getValues(), [field]: Array.isArray(chosenValue) ? [...(newFieldValues || [])] : null },
        newSelections,
      );
    } else hiddenSubmitButton.current?.click();
  };

  const handleChipClick = (key: string, chip: IChip) => {
    const newChipSelection = getNewSelection(key, chip);
    props.onApplyFilter(getValues(), newChipSelection);
  };

  useEffect(() => {
    if (gridState.gridTag === 'FacilityCommunicationHistoryGrid') {
      appliedFilter?.filters &&
        dispatch(
          facilityCommunicationHistoryActions.setChips({
            ...chips,
            ...createFilterChips(appliedFilter?.filters, appliedFilter?.selectedChipFilters, t),
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter?.filters]);

  useEffect(() => {
    const chipsData = Object.keys(chips).some(key => {
      const value = chips[key];
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }
      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        return true;
      }
      return false;
    });
    setShowChipsCollapseBtn(chipsData);
  }, [chips]);

  return (
    <>
      {showChipsCollapseBtn ? (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Collapse in={checked} collapsedSize={38}>
              <div ref={divRef}>
                <ChipsContainer
                  chips={chips}
                  onClick={handleChipClick}
                  onDelete={handleChipDelete}
                  isCollapse={!checked}
                />
              </div>
              <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
            </Collapse>
          </Grid>
          <Grid item xs={1} className={classes.fillterCollapse}>
            {(divRef?.current?.clientHeight ?? 0) > 38 && (
              <SearchPageIcons KeyboardArrowIcon onAdvancedChipsClick={() => handleChange()} checked={checked} />
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <ChipsContainer chips={chips} onClick={() => {}} onDelete={() => {}} />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </>
      )}
    </>
  );
};

export default FacilityCommunicationHistoryChips;
