import { Avatar, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { makeStyles } from 'tss-react/mui';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { formatLogPhoneNumber, getAvatar, RecordName } from './Helper';
import { UseType } from '@AMIEWEB/Notification/Constants';

const useStyles = makeStyles()(theme => ({
  headerContainer: {
    padding: '16px 24px',
    backgroundColor: theme.palette.framework.system.backgroundGrey,
    margin: '0px !important',
    width: '100%',
  },
  avatar: {
    color: theme.palette.components.avatar.color,
    fontSize: '30px',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
    background: theme.palette.framework.system.grassGreen,
  },
  headerText: {
    fontSize: '16px',
    color: theme.palette.framework.system.grassGreen,
    fontWeight: 500,
  },
  titleContainer: {
    marginLeft: '6px',
  },
  tooltipBackground: {
    backgroundColor: theme.palette.framework.system.white,
    color: theme.palette.framework.system.doveGray,
    border: `1px solid ${theme.palette.framework.system.doveGray}`,
    fontSize: '12px',
  },
}));

interface ILogHeader {
  isManualLog?: boolean;
}

const LogHeader: React.FC<ILogHeader> = props => {
  const { isManualLog } = props;
  const { t } = useTranslation();
  const globalData = useSelector(notificationSelection);
  const data = isManualLog ? globalData?.log?.data : globalData?.voice?.data;
  const { classes } = useStyles();
  const contactName = data?.tos?.find(Boolean)?.name?.toLowerCase();
  const contactNumber = formatLogPhoneNumber(data?.tos?.find(Boolean).phoneNumber);
  const secondaryText = `${contactNumber} | ${t('notification.voice.manualLog.contactId')} ${
    data?.tos?.find(Boolean)?.contactId
  }`;

  const getFacilitySubHeader = () => {
    const title = data?.associatedRecords?.filter(
      value => value?.name === RecordName.facility || RecordName.unit || RecordName.placement,
    )[0];
    return <Typography fontWeight={500}>{`${contactNumber} | ${title.value}`}</Typography>;
  };

  return (
    <React.Fragment>
      <LayoutGrid container className={classes.headerContainer}>
        <Avatar className={classes.avatar}>{getAvatar(data)}</Avatar>
        <LayoutGridItem className={classes.titleContainer}>
          <LayoutGridItem item>
            <Typography className={classes.headerText}>{`${
              !isManualLog ? t('notification.voice.log.callWith') : ''
            }${CapitalizeEveryFirstLetter(contactName || t('notification.voice.log.unknown'))}`}</Typography>
          </LayoutGridItem>
          <LayoutGridItem item>
            <Typography fontWeight={500}>
              {isManualLog
                ? secondaryText
                : data?.useType === UseType.ClientContacts
                ? getFacilitySubHeader()
                : secondaryText}
            </Typography>
          </LayoutGridItem>
        </LayoutGridItem>
      </LayoutGrid>
    </React.Fragment>
  );
};

export default LogHeader;
