import { CustomTooltip } from '@AMIEWEB/Common';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, createSvgIcon, Link } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme as globalTheme } from 'styles/global-styles';
import { makeStyles } from 'tss-react/mui';

const Directions = createSvgIcon(
  <svg
    id="directions_FILL0_wght0_GRAD0_opszNaN"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>{`.directions-driving-distance{fill:${globalTheme.palette.framework.system.lightNavyBlue};}`}</style>
    </defs>
    <path
      id="directions_FILL0_wght0_GRAD0_opszNaN-2"
      data-name="directions_FILL0_wght0_GRAD0_opszNaN"
      className="directions-driving-distance"
      d="M84.236,188.573h1.787V184.7H91.5v2.531l3.454-3.424L91.5,180.355v2.561H85.129a.877.877,0,0,0-.893.893ZM89,197a2.111,2.111,0,0,1-.7-.119,1.508,1.508,0,0,1-.581-.357L77.476,186.28a1.507,1.507,0,0,1-.357-.582,2.111,2.111,0,0,1,0-1.4,1.508,1.508,0,0,1,.357-.581L87.72,173.476a1.507,1.507,0,0,1,.582-.357,2.111,2.111,0,0,1,1.4,0,1.508,1.508,0,0,1,.581.357l10.243,10.243a1.507,1.507,0,0,1,.357.582,2.111,2.111,0,0,1,0,1.4,1.508,1.508,0,0,1-.357.581L90.28,196.524a1.507,1.507,0,0,1-.582.357A2.125,2.125,0,0,1,89,197Zm-5.12-6.878L89,195.243,99.243,185,89,174.757,78.757,185ZM89,185Z"
      transform="translate(-77 -173)"
    />
  </svg>,
  'Directions',
);

const useStyles = makeStyles()(theme => ({
  disabledDirectionsIcon: {
    color: theme.palette.framework.system.coolGray,
    '& .directions-driving-distance': {
      fill: theme.palette.framework.system.silver,
    },
  },
}));
export const DrivingDistanceMapLinkRender = React.memo(function DrivingDistanceMapLinkRender(
  cellParams: GridCellParams,
) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {!cellParams?.row?.drivingDistanceMapLink ? (
        <CustomTooltip
          isInlineContent
          contentStyle={{ fontSize: 'inherit', color: 'inherit', width: 'min-content' }}
          tooltipContent={t('candidate.order.disabledDirectionsTooltip')}
        >
          <Box className={cx(classes.disabledDirectionsIcon)}>
            <Directions />
          </Box>
        </CustomTooltip>
      ) : (
        <Link href={cellParams?.row?.drivingDistanceMapLink} target="_blank">
          <Directions />
        </Link>
      )}
    </>
  );
});
export const DrivingDistanceMapLinkCell = (cellParams: GridCellParams) => {
  return <DrivingDistanceMapLinkRender {...cellParams} />;
};