import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComposeEmailStyles } from './ComposeEmail.styles';
import { ITemplate } from 'app/models/Notification/Notification';
import { theme } from 'styles/global-styles';
import { useDispatch } from 'react-redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';

export const SaveTemplateModal = props => {
  const { t } = useTranslation();
  const { classes } = useComposeEmailStyles();
  const dispatch = useDispatch();
  const {
    isExpanded,
    templateSaveTitleError,
    templateSaveDescError,
    templateSaveCategoryError,
    emailTemplates,
    setEmailTemplates,
    setTemplateSaveTitleError,
    setTemplateSaveDescError,
    selectedTemplateSaveCategory,
    setSelectedTemplateSaveCategory,
    setTemplateSaveCategoryError,
  } = props;

  const handleInputChange = event => {
    const template: ITemplate = JSON.parse(JSON.stringify(emailTemplates.selectedTemplate));
    setEmailTemplates(prevState => ({ ...prevState, selectedTemplate: template }));
    template.isPrivate = true;
    if (event.target.getAttribute('id') === 'templateTitle') {
      template.title = event.target.value;
      if (event.target.value.length === 0) {
        setTemplateSaveTitleError('Template title is required');
      } else if (event.target.value.length < 31) {
        setTemplateSaveTitleError('');
      } else {
        setTemplateSaveTitleError('Template title maximum allowed length is 30');
      }
    }
    if (event.target.getAttribute('id') === 'templateDescription') {
      template.description = event.target.value;
      setEmailTemplates(prevState => ({ ...prevState, selectedTemplate: template }));
      if (event.target.value.length === 0) {
        setTemplateSaveDescError('Template description is required');
      } else if (event.target.value.length < 301) {
        setTemplateSaveDescError('');
      } else {
        setTemplateSaveDescError('Template description maximum allowed length is 300');
      }
    }
  };

  const handleTSaveTemplateCategoryChange = event => {
    const value = event.target.value;
    let template = JSON.parse(JSON.stringify(emailTemplates.selectedTemplate));
    template.isPrivate = true;

    if (value.length <= 0) {
      setTemplateSaveCategoryError(t('notification.emails.categoryRequired'));
    } else {
      setSelectedTemplateSaveCategory(value);
      dispatch(notificationDataActions.getTemplatesByCategory({ category: value }));
      template.category = value;
      setEmailTemplates(prevState => ({
        ...prevState,
        selectedTemplate: template,
      }));
      setTemplateSaveCategoryError('');
    }
  };
  return (
    <Grid container className={isExpanded ? classes.expandedSaveTemplateGrid : classes.saveTemplateGrid} spacing={2}>
      <Grid item xs={9}>
        <TextField
          id="templateTitle"
          fullWidth
          required
          value={emailTemplates.selectedTemplate?.title}
          sx={{ background: theme.palette.framework.system.platinum }}
          variant="outlined"
          placeholder={t('Template Title')}
          helperText={templateSaveTitleError}
          error={templateSaveTitleError.length > 0}
          onChange={event => handleInputChange(event)}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="filled">
          {selectedTemplateSaveCategory === '' && (
            <InputLabel className={classes.inputLabel}>{t('notification.templateCategorySelector')}</InputLabel>
          )}
          <Select
            variant="outlined"
            id="templateCategory"
            required
            classes={{ select: classes.select }}
            labelId="device-label-id"
            value={selectedTemplateSaveCategory}
            onChange={handleTSaveTemplateCategoryChange}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            disableUnderline
          >
            {[...emailTemplates.personalCategory, ...emailTemplates.publicCategory, 'Miscellaneous']
              .filter(function (item, index) {
                return (
                  [...emailTemplates.personalCategory, ...emailTemplates.publicCategory, 'Miscellaneous'].indexOf(
                    item,
                  ) === index
                );
              })
              .map((listItem, index) => (
                <MenuItem key={index} value={listItem}>
                  {listItem}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{templateSaveCategoryError}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          multiline
          rows={5}
          value={emailTemplates.selectedTemplate?.description}
          id="templateDescription"
          sx={{ background: theme.palette.framework.system.platinum }}
          variant="outlined"
          placeholder={t('Description')}
          helperText={templateSaveDescError}
          error={templateSaveDescError.length > 0}
          onChange={event => handleInputChange(event)}
        />
      </Grid>
    </Grid>
  );
};
