/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react/jsx-key */
import { InputAdornment, Autocomplete, Button, Chip, TextField, ClickAwayListener, Typography, Box } from 'amn-ui-core';
import { CustomOption } from 'app/components/Placement/CreatePlacement/CustomComponents/CustomTypeAhead';
import { missingField } from 'app/constants';
import { getCandidateOptions } from 'app/services/CandidateServices/CandidateServices';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Concatenate } from 'utils/string/string';
import {
  selectCommunicationLimitsEmail,
  selectEmailBCC,
  selectEmailConsentWithdrawn,
} from 'store/redux-store/notification/notification.selector';
import { useSelector } from 'react-redux';
import EmailRedChip from 'app/assets/images/Email-Red-Chip.svg';
import EmailBlueShiftChip from 'app/assets/images/Email-Blue-Shift-Chip.svg';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useEmailValidatorStyles } from './EmailValidator.Styles';
import { getUniqueEmails, MaxRecipientCount } from './helper';

export function EmailValidator(props) {
  const { name, fixed, value, disabled, onChange, populateDropdown, warningText } = props;
  const ref = React.useRef(null);
  const { t } = useTranslation();

  const { classes } = useEmailValidatorStyles();
  const emailConsents = useSelector(selectEmailConsentWithdrawn);
  const communicationLimitsEmail = useSelector(selectCommunicationLimitsEmail);
  const bccData = useSelector(selectEmailBCC);

  const [error, setError] = useState(false);
  const [validEmail, setValidEmail] = useState<any>([]);
  const [showMoreRecipient, setShowMoreRecipient] = useState<boolean>(true);
  const [option, setOptions] = useState<[]>([]);
  const [emailNotFounderror, setEmailNotFoundError] = useState<boolean>(false);
  const [emailConsentWithdrawnError, setEmailConsentWithdrawnError] = useState<boolean>(false);
  const [emailLimitsReached, setEmailLimitsReached] = useState<boolean>(false);
  const [textEntered, setTextEntered] = React.useState('');

  const fixedValue = (value && value[0]) ?? null;

  useEffect(() => {
    if (name === 'tos') {
      setValidEmail(value ? getUniqueEmails(value, emailConsents ?? [], communicationLimitsEmail ?? []) : []);
    } else {
      setValidEmail(value ? getUniqueEmails(value) : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bccData, value]);

  useEffect(() => {
    if ((emailConsents?.length > 0 || communicationLimitsEmail?.length > 0) && name === 'tos')
      setValidEmail(value ? getUniqueEmails(value, emailConsents, communicationLimitsEmail) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailConsents?.length, communicationLimitsEmail?.length]);

  const handleChange = (event, data) => {
    data = (fixed && fixedValue) || fixedValue?.fixed === true ? data.concat(fixedValue) : data;
    const filteredData = data.reduce((acc, current) => {
      const validateEmails = acc.find(item => item.email.toLowerCase() === current?.email.toLowerCase());
      if (!validateEmails) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidEmail(filteredData);
    setShowMoreRecipient(filteredData.length > MaxRecipientCount);
    onChange(name, filteredData);
  };
  const fetchOptions = async request => {
    if (populateDropdown) {
      const result = await getCandidateOptions(request);
      if (result) {
        // eslint-disable-next-line no-lone-blocks
        {
          setOptions(
            result?.map(opt => ({
              object: opt,
              primaryLabel: `${opt.name} (${opt.candidateId})`,
              secondaryLabel: Concatenate([opt.brand, opt.recruiter, opt.disciplines], ' - '),
            })),
          );
        }
      }
    }
  };
  const handleDelete = (index: number) => {
    const data = JSON.parse(JSON.stringify(validEmail));
    if (data[index]?.fixed != true) {
      data.splice(index, 1);
      setValidEmail(data);
      setShowMoreRecipient(data > MaxRecipientCount);
      onChange(name, data);
    }
  };

  const handleTextChange = e => {
    const recipient = { email: e.target.value };
    if (e.target.value && /^[A-Z0-9._%+-]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}$/i.test(e.target.value)) {
      const data = validEmail || [];
      data.concat(recipient);
      setError(false);
      handleChange(e, data);
    } else {
      e.target.value === '' ? setError(false) : setError(true);
    }
  };

  const handleRequestChange = event => {
    if (event.target.value) {
      fetchOptions(event.target.value);
    }
  };

  const recipientTags = () => {
    return [
      ...(validEmail.length > MaxRecipientCount && showMoreRecipient
        ? _.cloneDeep(validEmail).splice(0, MaxRecipientCount)
        : validEmail),
    ].map((option, index: number) => {
      if (!option?.email || option.email === missingField) {
        setEmailNotFoundError(true);
        setError(true);
      }
      if (option?.hasConsentWithdrawn) {
        setEmailConsentWithdrawnError(true);
        setError(true);
      }
      if (option?.limitReached) {
        setEmailLimitsReached(true);
        setError(true);
      }

      const draggableId = `${option.email}-${name}-${index}`;
      const editFlag = option?.editFlag;
      const hasDragDisabled = editFlag ? !editFlag : fixed || option?.fixed === true;

      return (
        <>
          <Box key={draggableId} className={classes.chipsContainer}>
            {(option?.hasConsentWithdrawn || option?.limitReached) &&
            option?.email &&
            option?.email !== missingField ? (
              <Draggable
                draggableId={draggableId}
                index={index}
                isDragDisabled={hasDragDisabled || option?.hasConsentWithdrawn}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      left: snapshot.isDragDisabled ? 'unset' : undefined,
                      top: snapshot.isDragDisabled ? 'unset' : undefined,
                    }}
                  >
                    <Chip
                      style={{
                        cursor: !hasDragDisabled ? 'pointer' : 'default',
                      }}
                      className={classes.chipError}
                      {...((!fixed && option?.fixed !== true) || (option?.editFlag && !option?.hasConsentWithdrawn)
                        ? {
                            onDelete: () => {
                              handleDelete(index);
                              option.hasConsentWithdrawn && setEmailConsentWithdrawnError(false);
                            },
                          }
                        : {})}
                      variant="outlined"
                      label={option.name || option.email || option.primaryLabel}
                      avatar={
                        <div className={classes.consentWithdrawnIcon}>
                          <img
                            src={option?.hasConsentWithdrawn ? EmailRedChip : EmailBlueShiftChip}
                            alt={option?.hasConsentWithdrawn ? 'Consent Withdrawn' : 'Communication Limit Reached'}
                          />
                        </div>
                      }
                    />
                  </div>
                )}
              </Draggable>
            ) : (
              <Draggable draggableId={draggableId} index={index} isDragDisabled={hasDragDisabled}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      left: snapshot.isDragDisabled ? 'unset' : undefined,
                      top: snapshot.isDragDisabled ? 'unset' : undefined,
                    }}
                  >
                    <Chip
                      style={{
                        cursor: !hasDragDisabled ? 'pointer' : 'default',
                      }}
                      className={!option?.email || option.email === missingField ? classes.chipError : classes.chip}
                      {...((!fixed && option?.fixed != true) || editFlag
                        ? { onDelete: () => handleDelete(index) }
                        : {})}
                      variant="outlined"
                      label={option.name || option.email || option.primaryLabel}
                    />
                  </div>
                )}
              </Draggable>
            )}
          </Box>
          {validEmail.length > MaxRecipientCount &&
            showMoreRecipient &&
            index === _.cloneDeep(validEmail).splice(0, MaxRecipientCount).length - 1 && (
              <Button
                size="small"
                variant="text"
                disableElevation
                disableRipple
                disableFocusRipple
                className={classes.showMore}
                onClick={() => setShowMoreRecipient(prevState => !prevState)}
              >
                {`+ ${validEmail.length - MaxRecipientCount}`}
              </Button>
            )}
        </>
      );
    });
  };

  const handleKeyDown = e => {
    if ((e?.key == ';' || e?.key == ' ' || e?.clickAway) && textEntered != '') {
      const error = !/^[A-Z0-9._%+-]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}$/i.test(textEntered);
      if (error) {
        setError(true);
      } else {
        setError(false);
        const data = [...validEmail, { email: textEntered }];
        handleChange(e, data);
        setTextEntered('');
      }
    } else {
      setError(false);
    }
  };

  return (
    <div className={props.className}>
      <ClickAwayListener onClickAway={e => handleKeyDown({ ...e, clickAway: true })}>
        <Droppable key={name} direction="horizontal" droppableId={name} type="dropeZone">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Autocomplete
                multiple
                freeSolo
                disabled={disabled}
                value={validEmail}
                options={option}
                getOptionLabel={opt => opt.primaryLabel || opt.secondaryLabel}
                onChange={(e, data) => {
                  setTextEntered('');
                  if (data[data.length - 1]?.object?.email) {
                    setError(false);
                    const obj = {
                      brand: data[data.length - 1]?.object?.brand,
                      brandId: data[data.length - 1]?.object?.brandId,
                      contactId: data[data.length - 1]?.object?.candidateId,
                      email: data[data.length - 1]?.object?.email,
                      name: data[data.length - 1]?.object?.name,
                      recruiter: data[data.length - 1]?.object?.recruiter,
                      recruiterId: data[data.length - 1]?.object?.recruiterId,
                    };
                    data[data.length - 1] = obj;
                    handleChange(e, data);
                  } else {
                    if (!(fixed && validEmail.length === 1)) {
                      if (
                        data.length &&
                        Object.prototype.toString.call(data[data.length - 1]) === '[object String]' &&
                        data[data.length - 1] &&
                        /^[A-Z0-9._%+-]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}$/i.test(data[data.length - 1])
                      ) {
                        setError(false);
                        data[data.length - 1] = { email: data[data.length - 1] };
                        setTextEntered('');
                        handleChange(e, data);
                      } else if (
                        data.length &&
                        Object.prototype.toString.call(data[data.length - 1]) === '[object String]' &&
                        data[data.length - 1] &&
                        !/^[A-Z0-9._%+-]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}$/i.test(data[data.length - 1])
                      ) {
                        setError(true);
                      } else {
                        setTextEntered('');
                        handleChange(e, data);
                      }
                    }
                  }
                }}
                inputValue={textEntered}
                renderTags={recipientTags}
                renderOption={(p, opt) => (
                  <CustomOption
                    primaryLabel={opt?.primaryLabel}
                    {...opt}
                    {...p}
                    classes={classes}
                    showHistory={false}
                  />
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    style={props.inputStyle}
                    type="email"
                    variant={props.variant}
                    helperText={
                      warningText && name === 'tos'
                        ? t('notification.emails.minimumRecipient')
                        : emailConsentWithdrawnError || emailNotFounderror || emailLimitsReached
                        ? `${emailLimitsReached ? t('notification.emails.EmailLimitsReached') : ''} ${
                            emailConsentWithdrawnError ? t('notification.emails.EmailConsentWithdrawn') : ''
                          } ${emailNotFounderror ? t('notification.emails.EmailNotFound') : ''}`
                        : error
                        ? props.errorText
                        : props.helperText
                    }
                    error={error || (warningText && name === 'tos')}
                    value={textEntered}
                    ref={ref}
                    className={classes.textFieldNew}
                    InputProps={{
                      ...params.InputProps,
                      classes: { input: classes.textFieldResize },
                      startAdornment: (
                        <>
                          <InputAdornment style={{ color: '#b9bbbc', paddingLeft: '16px' }} position="start">
                            <Typography className={name === 'tos' && warningText ? classes.typography : ''}>
                              {props.label}
                            </Typography>
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      endAdornment: <>{props.action}</>,
                    }}
                    onChange={e => {
                      e.target.value !== ' ' && e.target.value !== ';' && setTextEntered(e.target.value);
                      handleRequestChange(e);
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        handleTextChange(e);
                      } else {
                        handleKeyDown(e);
                      }
                    }}
                    onBlur={e => {
                      handleTextChange(e);
                    }}
                  />
                )}
              />
            </div>
          )}
        </Droppable>
      </ClickAwayListener>
    </div>
  );
}
