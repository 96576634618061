import React, { useEffect, useState } from 'react';
import AutoCompleteRenderChips from '@AMIEWEB/Order/OrderCreationForm/Common/AutoCompleteRenderChips';
import { useTranslation } from 'react-i18next';
import { virtualRatingList, virtualRatingListWithVoiceAdvantage } from 'app/constants';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { Grid } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { makeStyles } from 'tss-react/mui';
import { commonVIPriority } from '@AMIEWEB/Unit/helper';
import { useSelector } from 'react-redux';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { getOPObjectbyLabel, updateArrayLabels } from './Helper';
import { UseAtoIValuePresent } from './hook';

export const transformDataForVirtualRatings = virtualRatings => {
  const result = virtualRatings?.map(item => {
    const option: ITypeAheadOption = {
      object: { value: 0, name: item },
      label: item,
    };
    return option;
  });
  return result;
};

const useStyles = makeStyles()(theme => ({
  checkbox: {
    maxWidth: '120px',
    minWidth: '120px',
    '& label': {
      paddingLeft: '0px',
      margin: '0px',
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
    '& .MuiTypography-root': {
      fontSize: '14px !important',
    },
    '&:hover .MuiButtonBase-root.MuiCheckbox-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
}));

const VirtualRatings = () => {
  const { t } = useTranslation();
  const [virtualRatingOptions, setVirtualRatingOptions] = useState([]);
  const {
    control,
    watch,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const { classes } = useStyles();
  const orderPriorities = useSelector(selectOrderPriorities);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const watchValue = !!watch('virtualRating') ? watch('virtualRating') : [];
  const watchOrderPrioritiesValue = !!watch('orderPriorities')
    ? updateArrayLabels(watch('orderPriorities'), orderPriorities)
    : [];
  const isVoiceAdvantageDisabled = UseAtoIValuePresent(watchValue);
  const virtualRatingDisabledOption = isVoiceAdvantageDisabled ? [commonVIPriority] : [];

  const onVirtualRatingUpdate = data => {
    const virtualRatingSelectionData = [...data];
    const isAtoIDataPresent = data?.some(x => virtualRatingList?.includes(x?.object?.name));
    const isVoiceAdvantageDataPresent = data?.some(x => x?.object?.name === commonVIPriority);
    if (isAtoIDataPresent && !isVoiceAdvantageDataPresent) {
      setValue(
        'virtualRating',
        [
          ...virtualRatingSelectionData,
          {
            object: { value: '45', name: commonVIPriority },
            label: commonVIPriority,
          },
        ],
        { shouldDirty: true },
      );
    } else if (isVoiceAdvantageDisabled && !isAtoIDataPresent) {
      setValue(
        'virtualRating',
        virtualRatingSelectionData.filter(x => x?.object?.name !== commonVIPriority),
        { shouldDirty: true },
      );
    } else {
      setValue('virtualRating', virtualRatingSelectionData, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (isDirty) {
      if (watchOrderPrioritiesValue?.length > 0) {
        let orderPrioritiesToAdd = watchOrderPrioritiesValue
          .map(orderPriorities => orderPriorities?.object?.name)
          .filter(viFromAtoI => virtualRatingListWithVoiceAdvantage.includes(viFromAtoI))
          .filter(item => !watchValue.map(virtualRatings => virtualRatings.label).includes(item));
        orderPrioritiesToAdd = virtualRatingOptions.filter(item => orderPrioritiesToAdd.includes(item?.object?.name));
        const orderPrioritiesToRemove = virtualRatingListWithVoiceAdvantage.filter(
          item => !watchOrderPrioritiesValue.map(virtualRatings => virtualRatings?.object?.name).includes(item),
        );
        if (!!orderPrioritiesToAdd && orderPrioritiesToAdd.length > 0) {
          setValue('virtualRating', [...watchValue, ...orderPrioritiesToAdd], { shouldDirty: true });
        } else if (!!orderPrioritiesToRemove && orderPrioritiesToRemove.length > 0) {
          let filteredValues = watchValue.filter(item => !orderPrioritiesToRemove.includes(item.label));
          const commonVIPresent = filteredValues.map(item => item.label).includes(commonVIPriority);
          const hasVirtualRatingList = filteredValues.some(item => virtualRatingList.includes(item.label));
          if (isVoiceAdvantageDisabled && !hasVirtualRatingList && commonVIPresent) {
            filteredValues = filteredValues.filter(item => item.label !== commonVIPriority);
          }
          setValue('virtualRating', [...filteredValues], { shouldDirty: true });
        }
      }
    }
  }, [watchOrderPrioritiesValue?.length]);

  useEffect(() => {
    if (watchValue?.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('submissionRequirement', false);
    }
  }, [watchValue]);

  useEffect(() => {
    setVirtualRatingOptions(
      virtualRatingListWithVoiceAdvantage.map(item => ({
        object: getOPObjectbyLabel(item, orderPriorities),
        label: item,
      })),
    );
  }, [orderPriorities]);

  return (
    <Grid container xs={12}>
      <Grid item xs={8.75} marginRight={2}>
        <AutoCompleteRenderChips
          label={`${t('facility.units.unitDetails.additionalInformation.virtualInterviewRating')}${
            watch('submissionRequirement') ? ' *' : ''
          }`}
          options={virtualRatingOptions}
          id="virtual-rating-dropdown"
          name="virtualRating"
          disabledValue={virtualRatingDisabledOption}
          getOptionDisabled={option => !!virtualRatingDisabledOption?.includes(option.label)}
          handleDataChangeInParent={true}
          handleChange={data => onVirtualRatingUpdate(data)}
        />
      </Grid>
      <Grid item className={classes.checkbox}>
        <Controller
          name={'submissionRequirement'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SimpleCheckbox
              isChecked={rest.value || false}
              handleChecked={event => {
                onChange(event.target.checked);
              }}
              checkboxLabel={t('facility.units.unitDetails.additionalInformation.submissionRequirement')}
              checkboxName={'submissionRequirement'}
              id={'submissionRequirement'}
              isdisabled={isDisabled}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default VirtualRatings;
