import React, { useState } from 'react';
import { Grid, Backdrop, Button, IconButton, Toolbar } from 'amn-ui-core';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { EmailSettings } from '../EmailSettings';
import { ModalAppBar, ModalClose, ModalTitle } from '@AMIEWEB/Notification/styles';
import EmailMenu from 'app/assets/images/EmailMenu.svg';
import { getModalTitle } from '../EmailHelper';
import { Compose } from '../ComposeContainer';
import { EmailActionButtons } from '../EmailActions';
import Minimize from 'app/assets/images/Notification/MinimizeIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeBanner } from '@AMIEWEB/Notification/Common/ChangeBanner';
import { ChannelType, SendType } from '@AMIEWEB/Notification/Constants';
import { useSelector } from 'react-redux';
import { notificationSelection, selectEmailType } from 'store/redux-store/notification/notification.selector';
import { useEmailContext } from './useEmailContext';

const EmailSettingTemplate = () => {
  const {
    isExpanded,
    emailTemplates,
    setEmailTemplates,
    action,
    setAction,
    handleMinimize,
    loader,
    setLoader,
    templateChangeTracker,
    handleEmailPropertyChange,
    handleAttachments,
    setTemplateChangeTracker,
    getTemplatesWithCategory,
    handleSelectedTemplate,
    activeStep,
    handleEmailModalClose,
    notificationData,
    attachments,
    urlAttachments,
    handleUrlAttachments,
    isDragActive,
    isOverflowActive,
    getInputProps,
    senderMenuValue,
    setSenderMenuValue,
    setDefaultEmailData,
    anchorSaveEl,
    saveMenuOpen,
    handleSaveMenuClose,
    CancelTemplateModel,
    handleSaveButtonClick,
    canSaveTemplate,
    templateSaveTitleError,
    templateSaveDescError,
    templateSaveCategoryError,
    handleSaveTemplateMenu,
    handleSaveDraftMenu,
    titlesList,
    handleSaveTemplate,
    handleSubmit,
    setIsExpanded,
    t,
    classes,
  } = useEmailContext();
  const emailType = useSelector(selectEmailType);
  const globalData = useSelector(notificationSelection);

  const [groupEmail, setGroupEmail] = useState<boolean>(emailType === SendType.one_to_one || false);

  return (
    <div
      className={isExpanded ? classes.expandedBodyContainer : classes.bodyContainer}
      style={{ paddingRight: isExpanded ? '' : emailTemplates.showTemplate ? '' : '' }}
    >
      <Grid container spacing={2}>
        {loader.backdropLoader && <Backdrop className={classes.backdrop} open />}

        {emailTemplates.showTemplate && (
          <EmailSettings
            isExpanded={isExpanded}
            emailTemplates={emailTemplates}
            setEmailTemplates={setEmailTemplates}
            groupEmail={groupEmail}
            setGroupEmail={setGroupEmail}
            action={action}
            setAction={setAction}
            handleMinimize={handleMinimize}
            loader={loader}
            setLoader={setLoader}
            templateChangeTracker={templateChangeTracker}
            handleEmailPropertyChange={handleEmailPropertyChange}
            handleAttachments={handleAttachments}
            setTemplateChangeTracker={setTemplateChangeTracker}
            getTemplatesWithCategory={getTemplatesWithCategory}
            handleSelectedTemplate={handleSelectedTemplate}
          />
        )}
        <Grid item xs={emailTemplates.showTemplate ? (isExpanded ? 10 : 9) : 12} className={classes.emailSettings}>
          <ModalAppBar elevation={0} id="draggableArea" position="static" className={classes.modalAppbar}>
            <Toolbar disableGutters className={classes.newToolbar}>
              {!emailTemplates.showTemplate && (
                <Button className={classes.button}>
                  <img
                    src={EmailMenu}
                    alt=""
                    onClick={() => setEmailTemplates({ ...emailTemplates, showTemplate: true })}
                    data-testid="email-settings-icon"
                  />
                </Button>
              )}
              <ModalTitle variant="h6" className={classes.modalTitle}>
                {getModalTitle(activeStep, emailTemplates.selectedTemplate.title, t)}
              </ModalTitle>
              {activeStep < 3 &&
                (isExpanded ? (
                  <IconButton
                    className={classes.closeSelection}
                    disableRipple
                    disableFocusRipple
                    onClick={() => setIsExpanded(false)}
                  >
                    <FullscreenExit />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.closeSelection}
                    disableRipple
                    disableFocusRipple
                    onClick={() => setIsExpanded(true)}
                  >
                    <Fullscreen />
                  </IconButton>
                ))}
              {activeStep < 3 && (
                <IconButton className={classes.closeSelection} style={{ marginLeft: 8 }}>
                  <img src={Minimize} alt="Email" className={classes.minimizedIcon} onClick={handleMinimize} />
                </IconButton>
              )}
              <ModalClose>
                <IconButton className={classes.closeSelection}>
                  <CloseIcon onClick={handleEmailModalClose} />
                </IconButton>
              </ModalClose>
            </Toolbar>
          </ModalAppBar>
          {activeStep === 1 &&
            globalData?.snackbarData?.channel === ChannelType.email &&
            globalData.snackbarData?.changeWarning && (
              <ChangeBanner message={t('notification.finishEmailLogWarning')} />
            )}

          {activeStep === 1 &&
            globalData?.snackbarData?.channel === ChannelType.voice &&
            !globalData?.snackbarData?.manual &&
            globalData?.snackbarData?.changeWarning && (
              <ChangeBanner message={t('notification.finishEmailLogWarning')} />
            )}

          <div
            className={isExpanded ? classes.expandedScroll : classes.scroll}
            style={{
              height: isExpanded
                ? globalData.snackbarData?.changeWarning
                  ? '77vh'
                  : emailTemplates.showTemplate
                  ? '84vh'
                  : '84vh'
                : emailTemplates.showTemplate
                ? '580px'
                : '580px',
              padding: isExpanded
                ? emailTemplates.showTemplate
                  ? '10px 16px 10px 22px'
                  : '10px 16px 10px 30px'
                : '10px 22px',
            }}
          >
            <Compose
              data={notificationData}
              onChange={handleEmailPropertyChange}
              attachments={attachments}
              urlAttachments={urlAttachments}
              handleAttachments={handleAttachments}
              handleUrlAttachments={handleUrlAttachments}
              html={notificationData?.body}
              subject={notificationData?.subject}
              loader={loader}
              handlers={{
                handleEmailPropertyChange,
                handleAttachments,
                setAction,
                setLoader,
                setTemplateChangeTracker,
              }}
              action={action}
              showUploadBackdrop={isDragActive}
              showOverlay={isOverflowActive}
              getInputProps={getInputProps}
              expanded={isExpanded}
              changeTracker={templateChangeTracker}
              senderMenuValue={senderMenuValue}
              setSenderMenuValue={setSenderMenuValue}
              setDefaultEmailData={setDefaultEmailData}
            />
          </div>
          <hr />
          <div className={classes.emailActionButton}>
            <EmailActionButtons
              isExpanded={isExpanded}
              anchorSaveEl={anchorSaveEl}
              loader={loader}
              saveMenuOpen={saveMenuOpen}
              handleSaveMenuClose={handleSaveMenuClose}
              CancelTemplateModel={CancelTemplateModel}
              handleSaveButtonClick={handleSaveButtonClick}
              canSaveTemplate={canSaveTemplate}
              templateSaveTitleError={templateSaveTitleError}
              templateSaveDescError={templateSaveDescError}
              templateSaveCategoryError={templateSaveCategoryError}
              handleSaveTemplateMenu={handleSaveTemplateMenu}
              handleSaveDraftMenu={handleSaveDraftMenu}
              emailTemplates={emailTemplates}
              titlesList={titlesList}
              handleSaveTemplate={handleSaveTemplate}
              notificationData={notificationData}
              handleSubmit={handleSubmit}
              handleModalClose={handleEmailModalClose}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmailSettingTemplate;
