import { CircularProgress, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { navigationStickActions } from 'app/components/GlobalNavigationMenu/NavigationStick.redux';
import { SearchResultsGrid } from 'app/components/GlobalSearch/SearchResultsGrid';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { GlobalSearchGridType, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { DrawerFitted } from '../Common/Drawer/Drawer';
import { SortColumn, gridStateActions } from '../Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState } from '../Common/Grid/GridStateManagement/GridState.selectors';
import { CandidateDrawerPreviewer } from './Candidate/CandidateDrawerPreviewer';
import { FacilityPreviewDrawerViewer } from './Facility/FacilityPreviewDrawerViewer';
import { getFilterRequest, recalculateFilterQuickDates, revalidateChipSelection, setDefaultFilters } from './helper';
import { OrderPreviewDrawerViewer } from './Order/OrderPreviewDrawerViewer';
import { SearchResultsChips } from './SearchResultsChips';
import _ from 'lodash';
import { SearchFilterAction } from './FilterSpecComponents/SearchFilterAction';
import {
  getSessionValue,
  removeSessionValue,
  SessionKey,
  setSessionValue,
  StorageType,
} from 'utils/customHooks/sessionStorage/sessionHelpers';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { CreatePlacementWrapper } from '../Placement/CreatePlacement';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { gridSelectionActions } from '../Common/Grid/GridSelection/GridSelection.redux';
import { RootState } from 'types';
import { TaskEntity } from 'app/models/Tasks/Tasks';
import { CueNotesDrawerWrapper } from '../Tasks/CueNotes/CueNotesDrawerWrapper';
import { taskDetailsActions } from '../Tasks/store/Tasks.redux';
import { setEntityStickChoosen } from '../Tasks/TaskSidebar/Transformers';

import {
  candidateDetailReducer,
  candidateDetailSliceKey,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { candidateDetailsSaga } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.saga';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_arbitration_agreement_grids } from 'app/constants/FeatureFlags/Global/keys';
import { candidateSearchFilterName } from './Candidate/SearchUtils';
import {
  ICandidateSkillSet,
  getSelectedSkillSetFilter,
  getSkillSetFilter,
} from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { ICandidateAggregateSearch } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { globalSearchFilters } from './SearchResultsWrapper';
import { facilitySearchFilterName } from './Facility/SearchUtils';
import { IUserPreferenceState } from 'oidc/UserDevicePreference/userPreference.redux';
import { facilityLookUpSelector } from 'store/redux-store/lookup/lookup.selectors';
import { FacilityDetails } from 'app/enums/Common';

const useStyles = makeStyles()({
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
});

const getMenuKey = customSwitch({
  [SearchType.candidate]: SearchType.candidate,
  [SearchType.placement]: SearchType.placement,
  [SearchType.order]: SearchType.order,
  [SearchType.facility]: SearchType.facility,
})('home');

export const SearchDrawerContent = ({ category, data }: { category: string; data: any }) => {
  const content = React.useMemo(() => {
    return customSwitch({
      [SearchType.candidate]: <CandidateDrawerPreviewer isDrawer={false} isSearchGrid={true} isWorkDesk={false} />,
      [SearchType.placement]: data.displayValue ? (
        <CandidateDrawerPreviewer isDrawer={false} isSearchGrid={true} isWorkDesk={false} />
      ) : (
        <CueNotesDrawerWrapper clearCueField={false} container={TaskEntity.PLACEMENTS} setChangePanel={() => { }} />
      ),

      [SearchType.order]: <OrderPreviewDrawerViewer />,
      [SearchType.facility]: <FacilityPreviewDrawerViewer />,
    })(null)(category);
  }, [category, data.displayValue]);

  return content;
};

// To be used as grid identifier for state mngt. - GridState.redux
export const DEFAULT_PAGE_SIZE = 250;
export const DEFAULT_PAGE_NUMBER = 1;

export class GlobalSearchSort {
  sort: { column: string; direction: 'asc' | 'desc' }[] = [];

  constructor(category) {
    this.sort = customSwitch({
      [SearchType.candidate]: [{ column: 'applicationDate', direction: 'desc' }],
      [SearchType.placement]: [{ column: 'statusAge', direction: 'asc' }],
      [SearchType.order]: [{ column: 'orderId', direction: 'desc' }],
      [SearchType.facility]: [{ column: 'facility', direction: 'asc' }],
    })(null)(category);
  }

  toAPI() {
    return this.sort[0];
  }

  toMultiSortAPI() {
    return this.sort;
  }

  toGrid() {
    return [
      {
        field: this.sort[0].column,
        sort: this.sort[0].direction,
      },
    ];
  }
}

export interface IGlobalSearchFetch {
  pageNumber: number;
  pageSize: number;
  sortModel: SortColumn;
  multiSortedColumns: SortColumn[];
}

const _getFilterRequest = (category, values, facilityLookupIds, selectedChipFilters = undefined) => {
  let filterRequest = getFilterRequest(values, selectedChipFilters);

  //Candidate Filter - pass candidate ids from the filterValues
  const candidateFilter = values[candidateSearchFilterName.candidateAgggreggate];
  const selectedCandidateChipId =
    selectedChipFilters?.[candidateSearchFilterName.candidateAgggreggate]?.[0]?.value?.value;

  const facilityFilter = values[facilitySearchFilterName.facilityAgggreggate];

  //Skill Set Filter - map form data to payload and selection data
  const candidateSkillSetFilter = values[candidateSearchFilterName.candidateSkillSet];
  const selectedSkillSetChips = selectedChipFilters?.[candidateSearchFilterName.candidateSkillSet];
  let skillsets: ICandidateSkillSet[] = [];
  if (selectedSkillSetChips && selectedSkillSetChips?.length > 0) {
    skillsets = getSelectedSkillSetFilter(
      candidateSkillSetFilter?.skillsets,
      selectedSkillSetChips,
      candidateSearchFilterName.candidateSkillSet,
    );
  } else {
    skillsets = getSkillSetFilter(candidateSkillSetFilter?.skillsets, candidateSearchFilterName.candidateSkillSet);
  }
  const skillSetFilterKey = category === SearchType.candidate ? 'skillsetFilter' : 'orderSkillsetFilter';
  const voidedFilterKey = category === SearchType.candidate ? 'orderSkillsetFilter' : 'skillsetFilter';
  const isVerifiedOnlyOrIsExactMatchKey = category === SearchType.candidate ? 'isVerifiedOnly' : 'isExactMatch';
  let candidateAgggreggate: ICandidateAggregateSearch = null;
  if (category === SearchType.candidate) {
    if (
      selectedChipFilters &&
      Object.keys(selectedChipFilters).length > 0 &&
      selectedChipFilters?.candidateFilter &&
      selectedChipFilters?.candidateFilter?.length > 0
    ) {
      candidateAgggreggate = {
        ...candidateFilter,
        cid: null,
        email: null,
        firstName: null,
        goesBy: null,
        lastName: null,
        otherName: null,
        phoneNumber: null,
        selections: [],
      };
      let key = selectedChipFilters?.candidateFilter[0].key;
      let value = selectedChipFilters?.candidateFilter[0].value;
      if (key !== 'selections') {
        candidateAgggreggate = {
          ...candidateAgggreggate,
          [key]: value,
        };
      }
    }
  }
  if (category === SearchType.facility) {
    if (selectedChipFilters?.facilityFilter) {
      let key = selectedChipFilters?.facilityFilter?.[0]?.key;
      if (key !== 'selections') {
        // When a chip is selected, the facility ID is sent as an empty array ([]), 
        // and the facility keyword contains the chip's selected value. 
        // This ensures that records are fetched and returned in a single search call, 
        // instead of making two separate calls sequentially.
        filterRequest.facilityIds = [];
        filterRequest.facilityKeyword = `${selectedChipFilters?.facilityFilter?.[0]?.key === FacilityDetails.fid ? FacilityDetails.facilityId : FacilityDetails.facilityName}-${selectedChipFilters?.facilityFilter?.[0]?.value}`
      } else {
        filterRequest.facilityIds = [selectedChipFilters?.facilityFilter?.[0]?.value?.value];
      }
    }
    else if (facilityFilter?.facilityName || facilityFilter?.fid) {
      if (facilityFilter?.facilityName && facilityFilter?.fid) {
        // If no records are present in the lookup, ["0"] is sent to indicate that no matches were found.
        // Otherwise, the facility IDs from the lookup are used.
        filterRequest.facilityIds = facilityLookupIds?.length ? facilityLookupIds : ["0"]
      }
      else {
        filterRequest.facilityIds = [];
        filterRequest.facilityKeyword = facilityFilter?.fid ? `${FacilityDetails.facilityId}-${facilityFilter?.fid}` : `${FacilityDetails.facilityName}-${facilityFilter?.facilityName}`
      }
    } else {
      filterRequest.facilityIds = facilityFilter?.selections?.map((each) => each.value)
    }
    delete filterRequest?.facilityFilter;
  }

  return category === SearchType.candidate || category === SearchType.order
    ? {
      ...filterRequest,
      [candidateSearchFilterName.candidateAgggreggate]: candidateAgggreggate
        ? candidateAgggreggate
        : { ...filterRequest[candidateSearchFilterName.candidateAgggreggate] },
      [skillSetFilterKey]: {
        allowableMismatch: candidateSkillSetFilter?.allowableMismatch || false,
        [isVerifiedOnlyOrIsExactMatchKey]:
          (candidateSkillSetFilter && candidateSkillSetFilter[isVerifiedOnlyOrIsExactMatchKey]) || false,
        operation: candidateSkillSetFilter?.operation || 'Or',
        skillsets:
          category === SearchType.order ? (skillsets && skillsets.length === 0 ? null : skillsets) : skillsets,
      },
      [voidedFilterKey]: undefined,
      candidateIds: selectedCandidateChipId
        ? [selectedCandidateChipId]
        : candidateFilter
          ? candidateFilter?.selections?.map(x => x.candidateId)
          : undefined,
      states:
        category === SearchType.candidate
          ? values?.states?.map(x => {
            return x?.name;
          })
          : filterRequest?.states,
    }
    : filterRequest;
};

export const SearchResultsPage = ({
  category,
  gridTag,
  searchString,
  title,
  Filters,
  gridTitleTranslationKey,
  defaultFilterValues,
}: {
  category: SearchType;
  gridTag: string;
  searchString: string;
  title: string;
  gridTitleTranslationKey: string;
  Filters: React.ReactNode;
  defaultFilterValues: any;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;
  const facilityLookupIds = useSelector(facilityLookUpSelector);
  const gridState = useSelector(selectGridState);
  const { promiseInProgress } = usePromiseTracker({ area: 'global-search-call', delay: 100 });
  const userPreference = useSelector(selectUserPreference);
  const filterPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const [reExaminedUserPreference, setReExaminedUserPreference] = React.useState<boolean>(false);
  const [openCreatePlacementFormX, setOpenCreatePlacementFormX] = React.useState<boolean>(false);
  const [candidateIdR, setCandidateIdR] = React.useState<number>(0);
  const [brandIdR, setBrandIdR] = React.useState<number>(0);
  const [orderIdR, setOrderIdR] = React.useState<number>(0);
  const [arbitrationAgreementFlag] = useDecision(ff_placement_ui_arbitration_agreement_grids);
  const [searchFilters, setSearchFilters] = React.useState<React.ReactNode>(null);

  const dispatch = useDispatch();
  const theme = useTheme();
  const { drawerData } = useSelector(selectSearchResults);

  const confirmExit = event => {
    removeSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
  };

  useInjectReducer({ key: candidateDetailSliceKey, reducer: candidateDetailReducer });
  useInjectSaga({ key: candidateDetailSliceKey, saga: candidateDetailsSaga });

  React.useEffect(() => {
    window.addEventListener('beforeunload', confirmExit);
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      removeSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
      dispatch(gridStateActions.reset());
      dispatch(globalSearchActions.reset());
      dispatch(globalActions.closeBanner());
      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: [],
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridType = React.useMemo(() => {
    return customSwitch({
      [SearchType.candidate]: GlobalSearchGridType.xGrid,
      [SearchType.placement]: GlobalSearchGridType.xGrid,
      [SearchType.facility]: GlobalSearchGridType.xGrid,
      [SearchType.order]: GlobalSearchGridType.xGrid,
    })(GlobalSearchGridType.standard)(category);
  }, [category]);

  React.useEffect(() => {
    const resetVal = getSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
    const filters = Array.isArray(gridState?.filterAttributes?.filters)
      ? Object.fromEntries(gridState?.filterAttributes?.filters ?? [])
      : gridState?.filterAttributes?.filters;
    if (
      gridState?.filterAttributes?.filters &&
      !resetVal &&
      _.isEqual(Object.keys(filters ?? []), Object.keys(defaultFilterValues))
    ) {
      setSessionValue(SessionKey.globalSearchReset, gridState?.filterAttributes?.filters, StorageType.sessionStorage);
    }

    if (category === SearchType.candidate) {
      const candidateTagFilter = gridState?.filterAttributes?.filters?.filter(item => item[0] === 'candidateTags');
      if (candidateTagFilter?.at(0)?.at(1)?.length > 0) {
        dispatch(
          gridStateActions.setColumnVisibilityModel({
            ...gridState.columnVisibilityModel,
            candidateTags: true,
          }),
        );
      }
    }
  }, [defaultFilterValues, gridState?.filterAttributes?.filters]);

  React.useEffect(() => {
    let sessionFilters = getSessionValue(SessionKey[gridTag], StorageType.sessionStorage);
    if (sessionFilters && !Array.isArray(sessionFilters)) {
      removeSessionValue(SessionKey[gridTag], StorageType.sessionStorage);
      sessionFilters = null;
    }
    if (userPreference.loaded) {
      dispatch(gridStateActions.setGrid(gridTag));
      handleUserPreferenceChanges(sessionFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userPreference.loaded,
    // filterPreferences?.value?.filterAttributes?.filterSet,
    filterPreferences?.value?.selectedFilter?.name,
    filterPreferences?.value?.sortedColumn,
    filterPreferences?.value?.multiSortedColumns,
  ]);

  const handleUserPreferenceChanges = (sessionFilters) => {
    if (searchString) {
      dispatch(gridStateActions.setSortedColumn(new GlobalSearchSort(category).toAPI()));
      dispatch(gridStateActions.setMultiSortedColumn(new GlobalSearchSort(category).toMultiSortAPI()));
      dispatch(
        gridStateActions.setFilterAttributes({
          ...gridState.filterAttributes,
          filterObject: undefined,
        }),
      );
      reset();
      setSearchFilters(globalSearchFilters(category, defaultFilterValues));
    } else if (sessionFilters) {
      dispatch(
        gridStateActions.setSortedColumn(
          filterPreferences?.value?.sortedColumn ?? new GlobalSearchSort(category).toAPI(),
        ),
      );
      dispatch(
        gridStateActions.setMultiSortedColumn(
          filterPreferences?.value?.multiSortedColumns ?? new GlobalSearchSort(category).toMultiSortAPI(),
        ),
      );
      sessionFilters = Object.entries(recalculateFilterQuickDates(Object.fromEntries(sessionFilters), category));
      sessionFilters = Object.entries(
        setDefaultFilters(Object.fromEntries(sessionFilters), category, arbitrationAgreementFlag.enabled),
      );
      dispatch(
        gridStateActions.setFilterAttributes({
          ...gridState.filterAttributes,
          filterObject: sessionFilters,
          associatedGridName: gridState.gridTag,
        }),
      );
      reset(Object.fromEntries(sessionFilters), { isDirty: true });
      setSearchFilters(globalSearchFilters(category, Object.fromEntries(sessionFilters)));
    } else if (filterPreferences?.value?.selectedFilter?.name) {
      const filterSetFromSelectedFilter = filterPreferences?.value?.filterAttributes?.filterSet?.find(
        item => item.name === filterPreferences?.value?.selectedFilter?.name,
      );
      if (filterSetFromSelectedFilter) {
        let adjustedFilterSet = recalculateFilterQuickDates(
          Object.fromEntries(filterSetFromSelectedFilter.value),
          category,
        );
        adjustedFilterSet = setDefaultFilters(adjustedFilterSet, category, arbitrationAgreementFlag.enabled);

        if (category === SearchType.candidate) {
          if (filterPreferences?.value?.selectedFilter?.name === 'my default') {
            adjustedFilterSet = {
              ...adjustedFilterSet,
              activePlacements: {
                id: 0,
                name: 'Exclude',
                value: {
                  include: false,
                },
              },
            };
          }
          //tmp fix for active placements filter chips
          if (
            filterPreferences?.value?.selectedFilter?.name !== 'my default' &&
            !adjustedFilterSet.hasOwnProperty('activePlacements')
          ) {
            adjustedFilterSet = { ...adjustedFilterSet, activePlacements: null };
          }
        }

        dispatch(
          gridStateActions.setSortedColumn(
            filterSetFromSelectedFilter?.sortedColumn ?? new GlobalSearchSort(category).toAPI(),
          ),
        );
        dispatch(
          gridStateActions.setMultiSortedColumn(
            filterSetFromSelectedFilter?.multiSortedColumns ?? new GlobalSearchSort(category).toMultiSortAPI(),
          ),
        );
        dispatch(
          gridStateActions.setFilterAttributes({
            ...gridState.filterAttributes,
            filterObject: Object.entries(adjustedFilterSet),
            associatedGridName: gridState.gridTag,
          }),
        );
        reset(adjustedFilterSet);
        setSearchFilters(globalSearchFilters(category, adjustedFilterSet));
      } else {
        // No filter set found with selected filter name, set to default
        setFilterState({ filterAttributes: gridState.filterAttributes, filterPreferences, defaultFilterValues, category });
      }
    } else {
      // No selected filter from user preferences, set to default
      setFilterState({ filterAttributes: gridState.filterAttributes, defaultFilterValues, category });
    }
  };

  const setFilterState = ({
    filterAttributes,
    filterPreferences,
    defaultFilterValues,
    category,
  }: {
    filterAttributes: any;
    filterPreferences?: IUserPreferenceState<any>;
    defaultFilterValues: any;
    category: SearchType;
  }) => {
    dispatch(
      gridStateActions.setSortedColumn(
        filterPreferences?.value?.sortedColumn ?? new GlobalSearchSort(category).toAPI(),
      ),
    );
    dispatch(
      gridStateActions.setMultiSortedColumn(
        filterPreferences?.value?.multiSortedColumns ?? new GlobalSearchSort(category).toMultiSortAPI(),
      ),
    );
    dispatch(
      gridStateActions.setFilterAttributes({
        ...filterAttributes,
        filterObject: Object.entries(defaultFilterValues),
        associatedGridName: gridState.gridTag,
      }),
    );
    reset(defaultFilterValues);
    setSearchFilters(globalSearchFilters(category, defaultFilterValues));
  };

  React.useEffect(() => {
    if (userPreference.loaded) {
      getSearchResults({
        pageNumber: gridState.multiSortedColumns?.length > 0 ? gridState.pageNumber : DEFAULT_PAGE_NUMBER,
        pageSize: gridState.multiSortedColumns?.length > 0 ? gridState.pageSize : DEFAULT_PAGE_SIZE,
        sortModel: gridState.sortedColumn, // new GlobalSearchSort(category).toAPI(),
        multiSortedColumns: gridState.multiSortedColumns,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(gridState?.filterAttributes?.filters),
    searchString,
    userPreference.loaded,
    gridState?.multiSortedColumns,
    gridState.pageNumber,
    gridState.pageSize,
  ]);

  React.useEffect(() => {
    if (facilityLookupIds?.length >= 0) {
      getSearchResults({
        pageNumber: gridState.multiSortedColumns?.length > 0 ? gridState.pageNumber : DEFAULT_PAGE_NUMBER,
        pageSize: gridState.multiSortedColumns?.length > 0 ? gridState.pageSize : DEFAULT_PAGE_SIZE,
        sortModel: gridState.sortedColumn,
        multiSortedColumns: gridState.multiSortedColumns,
      });
    }
  }, [typeof (facilityLookupIds)]);

  const reEvaluatePreference = () => {
    let sessionFilters = getSessionValue(SessionKey[gridTag], StorageType.sessionStorage);
    if (sessionFilters && !Array.isArray(sessionFilters)) {
      removeSessionValue(SessionKey[gridTag], StorageType.sessionStorage);
      sessionFilters = null;
    }
    if (userPreference.loaded) {
      handleUserPreferenceChanges(sessionFilters);
    }
    setReExaminedUserPreference(true);
  };

  React.useEffect(() => {
    if (gridTag === gridState?.filterAttributes?.associatedGridName) {
      setSessionValue(SessionKey[gridTag], gridState?.filterAttributes?.filters, StorageType.sessionStorage);
    } else if (!reExaminedUserPreference && !!gridState?.filterAttributes?.associatedGridName && gridState?.gridTag !== 'Default' && gridState?.filterAttributes?.associatedGridName !== gridState?.gridTag) {
      // Re-read the filter preference when the grid changes before a previous filter preference selection has completed updating the state
      reEvaluatePreference();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState?.filterAttributes?.filters, reExaminedUserPreference]);

  const onApplyFilter = React.useCallback(
    (values, newSelectedChipFilters?) => {
      // Uncomment to stop a filtered search when a keyword is applied
      // if (searchString) {
      //   dispatch(globalActions.setSnackBar({ severity: 'error', message: 'Search failed. Keyword search is applied' }));
      //   return;
      // }

      /** selectedChips overload for onApplyFilter is called with click on /deleting chip
       * revalidateChipSelection() ensures blue chips removed due to internal operations
       * such as ClearAll or manually unchecked from dropdown
       * are avoid from filter since dispatch is still on hold within onApplyFilter
       */
      const selectedChipFilters =
        newSelectedChipFilters ||
        revalidateChipSelection(values, gridState.filterAttributes?.selectedChipFilters || []);
      dispatch(gridStateActions.setPageNumber(1));
      const candidateFilter = values[candidateSearchFilterName.candidateAgggreggate];
      if (
        candidateFilter &&
        Object.keys(candidateFilter).some(key => (key !== 'selections' && candidateFilter[key] ? true : false))
      ) {
        candidateFilter.selections = [];
      }

      dispatch(
        gridStateActions.setFilterAttributes({
          ...gridState.filterAttributes,
          filterObject: Object.entries(values),
          selectedChipFilters,
          associatedGridName: gridState.gridTag,
        }),
      );

      /**Service dispatch to get records from API */
      dispatch(
        globalSearchActions.getSearchResults({
          searchType: category,
          filter: _getFilterRequest(category, values, facilityLookupIds, selectedChipFilters),
          keyword: searchString || '',
          pageNumber: DEFAULT_PAGE_NUMBER,
          pageSize: gridState.pageSize || DEFAULT_PAGE_SIZE,
          sortedColumn:
            gridState.sortedColumn?.column && gridState.sortedColumn?.direction
              ? gridState.sortedColumn
              : new GlobalSearchSort(category).toAPI(),
          translation: t,
          navigationDataLoad: true,
          multiSortedColumns: gridState.multiSortedColumns,
        }),
      );
      if (category === SearchType.candidate) {
        if (values?.candidateTags?.length) {
          dispatch(
            gridStateActions.setColumnVisibilityModel({
              ...gridState.columnVisibilityModel,
              candidateTags: true,
            }),
          );
        } else {
          dispatch(
            gridStateActions.setColumnVisibilityModel({
              ...gridState.columnVisibilityModel,
              candidateTags: true,
            }),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      category,
      gridState.columnVisibilityModel,
      gridState.filterAttributes,
      gridState.multiSortedColumns,
      gridState.pageSize,
      gridState.sortedColumn,
      searchString,
      facilityLookupIds
    ],
  );

  const getSearchResults = ({ pageNumber, pageSize, sortModel }: IGlobalSearchFetch) => {
    if (!userPreference.loaded) return;
    // TODO: REMOVE THIS
    if (!!searchString) {
      setSessionValue(SessionKey.searchString, { stringSearched: true });
    } else {
      setSessionValue(SessionKey.searchString, {});
    }
    const sort = !sortModel
      ? new GlobalSearchSort(category).toAPI()
      : Array.isArray(sortModel)
        ? sortModel[0].column && sortModel[0].direction
          ? sortModel[0]
          : new GlobalSearchSort(category).toAPI()
        : sortModel.column && sortModel.direction
          ? sortModel
          : new GlobalSearchSort(category).toAPI();

    if (gridState?.filterAttributes?.filters || searchString) {
      dispatch(
        globalSearchActions.getSearchResults({
          searchType: category,
          keyword: searchString || '',
          pageNumber: pageNumber || DEFAULT_PAGE_NUMBER,
          pageSize: pageSize || DEFAULT_PAGE_SIZE,
          sortedColumn: sort,
          filter: gridState?.filterAttributes?.filters
            ? _getFilterRequest(
              category,
              Object.fromEntries(gridState?.filterAttributes?.filters),
              facilityLookupIds,
              gridState?.filterAttributes?.selectedChipFilters,
            )
            : {},
          translation: t,
          navigationDataLoad: true,
          multiSortedColumns: gridState.multiSortedColumns,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(taskDetailsActions.setCurrentContainer(TaskEntity.ALL));
    setEntityStickChoosen(TaskEntity.ALL, dispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!drawerData?.open) {
      dispatch(gridStateActions.setHighlightedRow('Test'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData]);

  const onFilterSetChange = val => {
    onApplyFilter(val);
  };

  useEffect(() => {
    dispatch(navigationStickActions.setSelectedMenuByKey(getMenuKey(category)));
    setSearchFilters(Filters);
    return () => {
      dispatch(globalSearchActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chips = useMemo(
    () => <SearchResultsChips onApplyFilter={onApplyFilter} category={category} hideDelete={false} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, onApplyFilter],
  );

  const saveAsModalChips = useMemo(
    () => <SearchResultsChips onApplyFilter={onApplyFilter} category={category} hideDelete={true} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, onApplyFilter],
  );

  React.useEffect(() => {
    if (category === 'order') {
      const refreshStatusData = getSessionValue(SessionKey.createPlacementMFS, StorageType.sessionStorage);
      if (
        refreshStatusData &&
        refreshStatusData.orderId > 0 &&
        refreshStatusData.candidateId > 0 &&
        refreshStatusData.brandId > 0
      ) {
        setCandidateIdR(refreshStatusData.candidateId);
        setBrandIdR(refreshStatusData.brandId);
        setOrderIdR(refreshStatusData.orderId);
        setOpenCreatePlacementFormX(true);
      }
    }
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <DetailsPage
            title={title}
            gridName={gridTag}
            searchString={searchString}
            onFilterSetChange={val => onFilterSetChange(val)}
            category={category}
            filters={searchFilters}
            chips={chips}
            fullPage
            titleActions={
              <SearchFilterAction
                onFilterSetChange={val => onFilterSetChange(val)}
                category={category}
                searchString={searchString}
                chips={saveAsModalChips}
              />
            }
          >
            {promiseInProgress && gridType !== GlobalSearchGridType.xGrid ? (
              <CircularProgress className={classes.MuiCircularProgressRoot} />
            ) : (
              <>
                <SearchResultsGrid
                  searchCategory={category}
                  searchString={searchString}
                  gridTitle={t(gridTitleTranslationKey)}
                  gridTag={gridTag}
                  gridType={gridType}
                  loading={promiseInProgress}
                  getSearchResults={getSearchResults}
                />
                {category === 'order' &&
                  openCreatePlacementFormX &&
                  candidateIdR > 0 &&
                  brandIdR > 0 &&
                  orderIdR > 0 && (
                    <CreatePlacementWrapper
                      isDefaultOrder={true}
                      isDefaultCandidate={true}
                      isOrderRefreshRequest={false}
                      isCandidateRefreshRequest={true}
                      open
                      handleClose={() => setOpenCreatePlacementFormX(false)}
                      initialArgs={{ orderId: orderIdR, candidateId: candidateIdR, brandId: brandIdR }}
                      sessionKey={SessionKey.createPlacementMFS}
                    />
                  )}
              </>
            )}
          </DetailsPage>
        </form>
      </FormProvider>
      {
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
          }}
          width={
            category === 'candidate'
              ? 450
              : category === 'placement'
                ? drawerData?.data?.displayValue
                  ? 450
                  : 300
                : 450
          }
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={drawerData?.open ?? false}
          shadow={category === 'candidate'}
        >
          {drawerData?.open && drawerData?.data ? (
            <SearchDrawerContent category={category} data={drawerData?.data} />
          ) : null}
        </DrawerFitted>
      }
    </>
  );
};
