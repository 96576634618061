import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, ClickAwayListener, Fade, Grid, Paper, Popper, Checkbox, FormControl } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { CustomTypeAhead, ISelection } from './CustomTypeAhead';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import _, { isArray, throttle } from 'lodash';

const useStyles = makeStyles<{
  isSelected: boolean;
  open: boolean;
  anchorRef: boolean;
  removeActionContainer: boolean;
}>()((theme, { isSelected, open, anchorRef, removeActionContainer }) => ({
  root: {},
  outlineAnchor: {
    borderRadius: 4,
    width: '100%',
    minHeight: '36.7px',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: open ? '#52429A' : isSelected ? '#555555' : '#C0C0C0',
    fontSize: 12,
    fontWeight: 400,
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    visibility: anchorRef ? 'hidden' : undefined,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'black',
    },
  },
  popper: {
    marginTop: 4,
    zIndex: 5000,
  },
  paperDropdown: {
    boxShadow: '0px 2px 8px #0000004D',
  },
  headerContainer: {
    minWidth: 250,
    padding: '12px 20px 6px',
  },
  headerContainerNoSearchBoxSingleOption: {
    minWidth: 250,
    padding: '0px 20px 0px',
  },
  actionContainer: {
    visibility: removeActionContainer ? 'hidden' : undefined,
    backgroundColor: '#ffffff',
    borderTop: removeActionContainer ? undefined : '1px solid #cccccc',
    padding: removeActionContainer ? 0 : '6px 20px',
    height: removeActionContainer ? 0 : undefined,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  inActive: {
    color: '#888888',
  },
  clearAllButton: {
    padding: 0,
  },
  actionButton: {
    padding: '6px 0px',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
  },
}));

export const AVOID_CLICK_AWAY_LABEL = 'avoid-click-away-type-ahead-drop-down';

export const TypeAheadDropdown = props => {
  const {
    isMultiSelect,
    value: selected,
    selectAllValue,
    options: providedOptions,
    filterName,
    hiddenInput,
    isDisabled,
    /** Prop to utilize external anchor element */
    anchorRef,
    removeActionContainer = false,
    customClass,
    hasControlError,
    isNested = false,
    controlErrorText,
    getOptionDisabled,
    searchBoxHiddenWithSingleOption = false,
    fallbackPlacements = ['bottom-end', 'bottom', 'bottom-start', 'top-start', 'top', 'top-end', 'left', 'right'],
    minWidth,
    includeUnits = false,
    includeUnitsText = '',
    unitsFilterName = '',
    isSelectAll = false,
    applyOnEnter = false,
    showListBox = false,
    customList,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [options, setOptions] = useState<ISelection[]>([]);
  const trackerArea = `${props.name}-async-typeAhead`;
  const { promiseInProgress } = usePromiseTracker({ area: trackerArea, delay: 0 });
  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(false);
  const [selectedCountTag, setSelectedCountTag] = useState<string>(filterName);
  const [prevState, setPrevState] = useState<any>(selected);
  const { control, setValue, getValues } = useFormContext() ?? {};
  const [units, setUnits] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { classes } = useStyles({
    isSelected: isMultiSelect ? selected && selected.length > 0 : selected !== null,
    open,
    anchorRef,
    removeActionContainer,
  });

  const id = open ? `custom-type-ahead-${props.name}` : undefined;

  const fetchOptions = async request => {
    // TODO: @RIJASH - Add logging specifics : Due Date: 28th Oct 2022
    const response = await props.fetchOptions(request);
    setOptions(
      (response || []).map(opt => ({
        ...opt,
        id: opt.value,
        name: opt.name,
        value: opt.value,
      })),
    );
  };

  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    if (isArray(selected)) {
      const selectedValues = _.cloneDeep(selected).sort(function (a, b) {
        return a.value === b.value ? 0 : a.value > b.value ? 1 : -1;
      });
      props.apply(selectedValues);
    } else {
      props.apply(selected);
    }
    closeActions();
  };

  const closeActions = () => {
    setAnchorEl(null);
    EnableAppScroll();
  };

  const cancelChanges = () => {
    props.onChange(prevState);
    closeActions();
  };

  const clearAll = () => {
    setUnits(false);
    if (control) {
      setValue(unitsFilterName, false);
    }
    props.onChange(isMultiSelect ? [] : null);
  };

  const handleClose = () => {
    !isNewSelectionApplied && props.onChange(prevState);
    if (anchorEl) {
      anchorEl.focus();
    }
    closeActions();
  };

  const handleOpen = event => {
    if (!open) {
      trackPromise(fetchOptions(undefined), trackerArea);
      setAnchorEl(anchorRef ? anchorRef.current : event.currentTarget);
      // Setup for probable cancelChanges
      setIsNewSelectionApplied(false);
      if (unitsFilterName) {
        setPrevState([...selected, { unitsFilterName: getValues(unitsFilterName) }]);
      } else {
        setPrevState(selected);
      }
      DisableAppScroll();
    } else {
      if (props.hideApply) {
        onApplyEvent();
        return;
      }
      cancelChanges();
    }
  };

  const onClickAway = event => {
    if (props.hideApply || props.applyOnClickAway) {
      const isEqual = _.isEqual(prevState, selected);
      if (!isEqual) {
        onApplyEvent();
      } else {
        handleClose();
      }
      return;
    }
    if (event.target.type !== 'submit' && event.target.id !== AVOID_CLICK_AWAY_LABEL) {
      /** Temp fix check on anchorRef --> avoid reset action if using external ref */
      !anchorRef && !isNewSelectionApplied && props.onChange(prevState);
      closeActions();
    }
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), trackerArea), 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Refer Task #70351 */
  /** Start Task #70351 */
  const configureMultiSelectTag = () =>
    (options && options.length && selectAllValue && selected && selected.length === options.length - 1) ||
    (selected && selected.some(item => item.value === selectAllValue))
      ? `${t('search.filter.allTag')} ${filterName} (${options.length ? options.length - 1 : 0})`
      : selected && selected.length
      ? `(${selected.length}) ${filterName}`
      : filterName;

  useEffect(
    () => {
      /** Condition ensures selectAllValue is removed if any options are manually removed - say chip deletion */
      if (
        isMultiSelect &&
        selectAllValue &&
        options &&
        selected?.length !== options.length &&
        selected?.some(item => item.value === selectAllValue)
      )
        props.onChange(_.cloneDeep(selected).splice(1, selected.length - 1));
      setSelectedCountTag(
        isMultiSelect ? configureMultiSelectTag() : selected ? `(1) ${filterName}` : filterName + ' '.repeat(6),
      );
      return EnableAppScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(selected), JSON.stringify(options)],
  );
  /** End Task #70351 */

  // updated options to be a state in the component
  // so asyncOptions can work the same
  useEffect(() => {
    if ((providedOptions || []).length > 0 && !props.fetchOptions) {
      setOptions(providedOptions);
    }
  }, [props.fetchOptions, providedOptions]);

  useEffect(() => {
    if (control && unitsFilterName) {
      setUnits(!selected || selected?.length <= 0 ? false : getValues(unitsFilterName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, selected]);

  const handleKeyDown = React.useCallback(
    event => {
      /**
       * Code: click on Enter key invokes submission
       * if open && applyOnEnter
       */
      if (open && applyOnEnter && event.key === 'Enter') {
        onApplyEvent();
      }
      /** Add future key down features below */
    },
    [applyOnEnter, onApplyEvent, open],
  );

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Box>
      <div className={classes.root}>
        <Button
          disableRipple
          variant="outlined"
          //TODO need to discuss sx is not getting applied and not able to use theme in style
          style={{
            borderColor: hasControlError ? '#D90000' : '',
            color: hasControlError ? '#D90000' : '',
            height: isNested ? '10px' : '',
            minWidth: minWidth ? minWidth : '',
          }}
          aria-describedby={id}
          disabled={!!isDisabled}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          classes={{ root: classes.outlineAnchor }}
          onClick={handleOpen}
          id={props.anchorAccesorid}
        >
          {isNested ? (
            <pre style={{ font: 'normal normal normal 12px Roboto' }}>{selectedCountTag}</pre>
          ) : (
            selectedCountTag
          )}
        </Button>
        {hasControlError ? (
          <div style={{ fontSize: '0.7rem', color: '#D90000', marginLeft: '3px' }}>
            <span>{controlErrorText}</span>
          </div>
        ) : null}
      </div>
      <Popper
        className={customClass ? `${customClass}` : `${classes.popper}`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: fallbackPlacements,
            },
          },
        ]}
        // sx={{ position: 'relative !important', transform: 'unset !important' }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} in={open} timeout={300}>
              <Paper aria-label={id} classes={{ root: classes.paperDropdown }}>
                <Grid
                  item
                  container
                  justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                  alignItems="center"
                  classes={{
                    root: searchBoxHiddenWithSingleOption
                      ? classes.headerContainerNoSearchBoxSingleOption
                      : classes.headerContainer,
                  }}
                >
                  {isMultiSelect ? (
                    <div className={classes.countTag}>{`${
                      selectAllValue &&
                      options &&
                      selected &&
                      selected.length &&
                      options?.length &&
                      selected.length === options?.length
                        ? options?.length - 1
                        : selected?.length || 0
                    } ${t('search.filter.Selected')}`}</div>
                  ) : null}
                  {!props.hideClearAll ? (
                    <Button
                      className="clear-all-button"
                      aria-label="clear-all-button"
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => clearAll()}
                      disabled={!isMultiSelect}
                      classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
                    >
                      {t('search.filter.clearAll')}
                    </Button>
                  ) : null}
                </Grid>
                {includeUnits && isMultiSelect && control ? (
                  <FormControl>
                    <Controller
                      control={control}
                      name={unitsFilterName}
                      render={({ ref, onChange, value: checkboxSelected, ...rest }) => {
                        return (
                          <Grid
                            item
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            classes={{
                              root: searchBoxHiddenWithSingleOption
                                ? classes.headerContainerNoSearchBoxSingleOption
                                : classes.headerContainer,
                            }}
                          >
                            <Checkbox
                              edge="start"
                              key={id}
                              tabIndex={-1}
                              disableRipple
                              checked={units}
                              onChange={event => {
                                setUnits(event.target.checked);
                                setValue(unitsFilterName, event.target.checked, { shouldDirty: true });
                              }}
                              disabled={!selected || selected.length <= 0}
                            />
                            <span>{includeUnitsText}</span>
                          </Grid>
                        );
                      }}
                    />
                  </FormControl>
                ) : null}
                <CustomTypeAhead
                  enableCustomScroll
                  removeCloseIcon
                  variant="outlined"
                  size="small"
                  handleClose={handleClose}
                  options={options}
                  isLoading={promiseInProgress}
                  onInputChange={throttleService}
                  hiddenInput={hiddenInput}
                  getOptionDisabled={getOptionDisabled}
                  isSelectAll={isSelectAll}
                  showListBox={showListBox}
                  customList={customList}
                  {...props}
                />
                {!props.hideApply ? (
                  <div className={classes.actionContainer}>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                      <Button
                        className="clear-all-button"
                        aria-label="cancel-button"
                        variant="text"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => cancelChanges()}
                        classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                      >
                        {t('search.filter.cancel')}
                      </Button>
                      <Button
                        className="clear-all-button"
                        aria-label="apply-button"
                        variant="text"
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => onApplyEvent()}
                        classes={{ text: `${classes.button} ${classes.actionButton} ` }}
                        id={props.hiddenApplyBtn}
                      >
                        {t('search.filter.apply')}
                      </Button>
                    </Grid>
                  </div>
                ) : null}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
