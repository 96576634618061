import React, { useEffect, useRef } from 'react';
import { Button, Typography } from 'amn-ui-core';
import UnitDetailLayout from '../../Common/UnitDetailLayout';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonSection } from '@AMIEWEB/Order/OrderDesign';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBestBets,
  selectEditUnit,
  selectFacilityDetails,
  selectNewUnit,
  selectOrderRequirementTypes,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Element } from 'react-scroll';
import { UnitNavigationMenu } from './UnitNavigationMenu';
import { AdditionalInformationSection } from './AdditionalInformation/AdditionalInformationSection';
import { useSelectedUnitDetails } from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import { UnitRequirementsSection } from './UnitRequirementSection';
import { defaultValuesForCreate, defaultValuesForEdit } from './UnitDetailDefaultValues';
import {
  selectLicenseData,
  selectCancelPopupStatus,
  selectSkillsSetsForUnits,
  selectTransformedIssuedBy,
  selectUnitSaveNameError,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { selectUser } from 'oidc/user.selectors';
import { transformDataForPayload } from './UnitDetailDataTransformer';
import { selectFacility, selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import UnitDetailSection from './AdditionalInformation/UnitDetailsSection';
import QualificationAndJobDetailsSection from './AdditionalInformation/QualificationAndJobDetailsSection';
import { useUnitDetailsWrapperStyles } from './Styles';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { subCertValidator } from './UnitDetailsFormValidator';

const UnitDetailWrapper = () => {
  const { t } = useTranslation();
  const { classes } = useUnitDetailsWrapperStyles();
  const dispatch = useDispatch();
  const newUnitObj = useSelector(selectNewUnit);
  const editUnit = useSelector(selectEditUnit);
  const facilityDetails = useSelector(selectFacilityDetails);
  const user = useSelector(selectUser);
  const location = useLocation();
  const [openCancelConfirmation, setOpenCancelConfirmation] = React.useState(false);
  const history = useHistory();
  const params = useParams<{ id: string; unitId: string }>();
  const currentPath = location.pathname;
  const isEditUnit = currentPath.includes('edit');
  const selectedUnitDetail = useSelectedUnitDetails();
  const skillSetGroup = useSelector(selectSkillsSetsForUnits);
  const bestBets = useSelector(selectBestBets);
  const issuedByData = useSelector(selectTransformedIssuedBy);
  const licenseData = useSelector(selectLicenseData);
  const facilityData = useSelector(selectFacility);
  const { states } = useSelector(selectOrderRequirementTypes);
  const facilityDetail = useSelector(selectFacilityDetail);
  const initialValuesForCreate = defaultValuesForCreate(facilityDetail);
  const popUpStatus = useSelector(selectCancelPopupStatus);
  const unitNameError = useSelector(selectUnitSaveNameError);
  const methods = useForm({
    defaultValues: isEditUnit
      ? defaultValuesForEdit(
          editUnit,
          selectedUnitDetail,
          getBestBets,
          skillSetGroup,
          issuedByData,
          licenseData,
          states,
          facilityData,
        )
      : initialValuesForCreate,
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (!isEditUnit) reset(initialValuesForCreate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditUnit,
    facilityDetail?.numberOfReferenceRequired,
    facilityDetail?.submissionRequiredReference,
    facilityDetail?.supervisorAndAboveReference,
    facilityDetail?.withinLastMonthsReferenceChanged,
    facilityDetail?.numberOfReferenceToRFO,
    facilityDetail?.entireWorkHistory,
    facilityDetail?.bestBets,
    facilityDetail?.bestBetsId,
  ]);

  useEffect(() => {
    if (isDirty) dispatch(FacilityUnitActions.setUnitFormValidity(true));
    return () => {
      dispatch(FacilityUnitActions.setUnitFormValidity(false));
    };
  }, [isDirty]);

  const formatDataOnSave = _formData => {
    _formData.orderPriorities = _formData?.orderPriorities?.map(item => {
      return {
        id: Number(item.object.value),
        description: item.object.name,
      };
    });
    _formData.virtualRating = _formData?.virtualRating?.map(item => item.object.name);
    return _formData;
  };
  const handleReferanceNullValue = data => {
    data.reference.numberOfReferenceRequired = isNaN(parseInt(data?.reference?.numberOfReferenceRequired))
      ? 0
      : parseInt(data?.reference?.numberOfReferenceRequired);
    data.reference.numberOfReferenceToRFO = isNaN(parseInt(data?.reference?.numberOfReferenceToRFO))
      ? 0
      : parseInt(data?.reference?.numberOfReferenceToRFO);
    return data;
  };
  const modifyFieldsOnCondition = data => {
    if (data?.reference?.numberOfReferenceToRFO >= 1 && data?.reference?.withinNumberOfMonths === null) {
      data.reference.entireWorkHistory = true;
      return data;
    }
    return data;
  };
  const onSave = formData => {
    const hasError = subCertValidator(formData, setError, t) || unitNameError;
    if (hasError) return;
    const formattedFormData = formatDataOnSave(formData);
    const cleanedFormData = handleReferanceNullValue(formattedFormData);
    const modifyFieldValues = modifyFieldsOnCondition(cleanedFormData);
    const unitData = isEditUnit
      ? {
          ...editUnit,
          ...modifyFieldValues,
        }
      : {
          ...newUnitObj,
          ...modifyFieldValues,
          unitId: 0,
        };

    dispatch(
      FacilityUnitActions.requestSaveUnitDetails({
        payloadForSave: transformDataForPayload(unitData, facilityDetails, editUnit, user, isEditUnit),
      }),
    );
  };

  function getBestBets(bestBetsId: number) {
    const bets = bestBets.find(x => x.value === bestBetsId?.toString());
    return bets?.name;
  }

  const handleCancelConfirmClose = () => {
    if (popUpStatus) {
      dispatch(globalActions.resetFacilityDrawerData());
      dispatch(FacilityUnitActions.setOpenCancelModel({ openCancelModel: false }));
      history.push({
        pathname: `/facility/${params.id}`,
        state: 'navigationCycle',
      });
    } else {
      isEditUnit
        ? history.push({
            pathname: `/facility/${params.id}/unit/${params.unitId}`,
            state: 'navigationCycle',
          })
        : history.push({
            pathname: `/facility/${params.id}`,
            state: 'navigationCycle',
          });
    }
  };

  const handleClose = () => {
    dispatch(globalActions.resetFacilityDrawerData());
    dispatch(FacilityUnitActions.setOpenCancelModel({ openCancelModel: false }));
    setOpenCancelConfirmation(false);
  };

  const unitRequirementsRef = useRef<HTMLDivElement | null>(null);
  const unitInformationRef = useRef<HTMLDivElement | null>(null);
  const unitExperienceRef = useRef<HTMLDivElement | null>(null);
  const jobDescriptionRef = useRef<HTMLDivElement | null>(null);
  const additionalInformationRef = useRef<HTMLDivElement | null>(null);
  const virtualInterviewRatingRef = useRef<HTMLDivElement | null>(null);
  const orderPrioritiesRef = useRef<HTMLDivElement | null>(null);
  const clinicianPatientRatioRef = useRef<HTMLDivElement | null>(null);
  const bestBetsRef = useRef<HTMLDivElement | null>(null);
  const referencesRef = useRef<HTMLDivElement | null>(null);
  const internalNotesRef = useRef<HTMLDivElement | null>(null);
  const workHistoryGapRef = useRef<HTMLDivElement | null>(null);
  const unitDetailRef = useRef<HTMLDivElement | null>(null);
  const firstDayInstructionsRef = useRef<HTMLDivElement | null>(null);
  const clientContactsRef = useRef<HTMLDivElement | null>(null);
  const qualificationAndJobDetailsRef = useRef<HTMLDivElement | null>(null);
  const additionalRequiredQualifications = useRef<HTMLDivElement | null>(null);
  const preferredQualifications = useRef<HTMLDivElement | null>(null);

  const navRefs = React.useMemo(() => {
    return [
      {
        label: `${t('facility.units.unitDetails.unitRequirements.title')}`,
        navRef: unitRequirementsRef,
        mainMenuItem: true,
        categoryIndex: 1,
      },
      {
        label: `${t('facility.units.unitDetails.unitRequirements.information')}`,
        navRef: unitInformationRef,
        categoryIndex: 1,
      },
      {
        label: `${t('facility.units.unitDetails.unitRequirements.experience')}`,
        navRef: unitExperienceRef,
        categoryIndex: 1,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.title2')}`,
        navRef: additionalInformationRef,
        mainMenuItem: true,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.virtualInterviewRating')}`,
        navRef: virtualInterviewRatingRef,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.orderPriorities')}`,
        navRef: orderPrioritiesRef,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.clinicianPatientRatio')}`,
        navRef: clinicianPatientRatioRef,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.references.title')}`,
        navRef: referencesRef,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.workHistoryGap.title')}`,
        navRef: workHistoryGapRef,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.atAGlance.bestBets')}`,
        navRef: bestBetsRef,
        categoryIndex: 2,
      },
      {
        label: `${t('facility.units.unitDetails.qualificationAndJobDetails.title')}`,
        navRef: qualificationAndJobDetailsRef,
        mainMenuItem: true,
        categoryIndex: 3,
        multiLine: true,
      },
      {
        label: `${t('facility.units.unitDetails.qualificationAndJobDetails.additionalRequiredQualifications')}`,
        navRef: additionalRequiredQualifications,
        categoryIndex: 3,
        multiLine: true,
      },
      {
        label: `${t('facility.units.unitDetails.qualificationAndJobDetails.preferredQualifications')}`,
        navRef: preferredQualifications,
        categoryIndex: 3,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.internalNotes')}`,
        navRef: internalNotesRef,
        categoryIndex: 3,
      },
      {
        label: `${t('facility.units.unitDetails.unitDetails.jobDescription.title2')}`,
        navRef: jobDescriptionRef,
        categoryIndex: 3,
      },
      {
        label: `${t('facility.units.unitDetails.title')}`,
        navRef: unitDetailRef,
        mainMenuItem: true,
        categoryIndex: 4,
      },
      {
        label: `${t('facility.units.unitDetails.additionalInformation.clientContacts')}`,
        navRef: clientContactsRef,
        categoryIndex: 4,
      },
      {
        label: `${t('facility.instructionsTab.firstDayInstructions')}`,
        navRef: firstDayInstructionsRef,
        categoryIndex: 4,
      },
    ];
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSave)}>
          <div className={classes.container} id="unit-edit">
            <UnitNavigationMenu navRefs={navRefs} />
            <Element id="containerElement" className={classes.containerElement}>
              <div className={classes.sectionsContainer}>
                <div ref={unitRequirementsRef}>
                  <UnitDetailLayout
                    title={t('facility.units.unitDetails.unitRequirements.title')}
                    section={
                      <UnitRequirementsSection
                        unitInformationRef={unitInformationRef}
                        unitExperienceRef={unitExperienceRef}
                      />
                    }
                  />
                </div>
                <div ref={additionalInformationRef} className={classes.section}>
                  <UnitDetailLayout
                    title={t('facility.units.unitDetails.additionalInformation.title2')}
                    section={
                      <AdditionalInformationSection
                        bestBetsRef={bestBetsRef}
                        virtualInterviewRatingRef={virtualInterviewRatingRef}
                        orderPrioritiesRef={orderPrioritiesRef}
                        referencesRef={referencesRef}
                        workHistoryGapRef={workHistoryGapRef}
                        clinicianPatientRatioRef={clinicianPatientRatioRef}
                      />
                    }
                  />
                </div>
                <div ref={qualificationAndJobDetailsRef} className={classes.section}>
                  <UnitDetailLayout
                    title={t('facility.units.unitDetails.qualificationAndJobDetails.title')}
                    section={
                      <QualificationAndJobDetailsSection
                        jobDescriptionRef={jobDescriptionRef}
                        internalNotesRef={internalNotesRef}
                        preferredQualifications={preferredQualifications}
                        additionalRequiredQualifications={additionalRequiredQualifications}
                      />
                    }
                  />
                </div>
                <div ref={unitDetailRef} className={classes.section}>
                  <UnitDetailLayout
                    title={t('facility.units.unitDetails.title')}
                    section={
                      <UnitDetailSection
                        firstDayInstructionsRef={firstDayInstructionsRef}
                        clientContactsRef={clientContactsRef}
                      />
                    }
                  />
                </div>
              </div>
            </Element>
          </div>
          <ButtonSection>
            <div>
              <Button
                variant="contained"
                color="tertiary"
                size="large"
                className={`${classes.buttonWidth} ${classes.addJustButton} `}
                data-testid={`test ${t(`autoTaskManagement.button.cancel`)} button`}
                onClick={() => {
                  isDirty ? setOpenCancelConfirmation(true) : handleCancelConfirmClose();
                }}
              >
                {t(`autoTaskManagement.button.cancel`)}
              </Button>
              <Button
                variant="contained"
                color={'primary'}
                size="large"
                className={`${classes.buttonWidth} ${classes.addJustButton} `}
                data-testid={`test ${
                  isEditUnit ? t(`autoTaskManagement.button.save`) : t('order.createUnit.createUnit')
                } button`}
                type="submit"
              >
                {isEditUnit ? t(`autoTaskManagement.button.save`) : t('order.createUnit.createUnit')}
              </Button>
            </div>
          </ButtonSection>
        </form>
      </FormProvider>
      <GenericDialog
        open={openCancelConfirmation || popUpStatus}
        onClose={handleClose}
        dialogTitleProps={{ text: t('cancelModal.undoConfirmation') }}
        children={<Typography> {t('cancelModal.saveConfirmation')} </Typography>}
        fullWidth
        maxWidth={'sm'}
        disableEscapeKeyDown
        dialogActions={[
          {
            text: t('undoModal.confirmation.cancel'),
            color: 'tertiary',
            onClick: () => {
              handleClose();
            },
          },
          {
            text: t('undoModal.confirmation.yes'),
            variant: 'contained',
            onClick: () => {
              handleCancelConfirmClose();
            },
          },
        ]}
      />
    </>
  );
};

export default UnitDetailWrapper;
