/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { Theme } from 'amn-ui-core';

export const useComposeEmailStyles = makeStyles()((theme: Theme) => ({
  changeTemplateGrid: {
    boxShadow: `2px 0px 0px 0px ${theme.palette.framework.system.neutralGray}`,
    padding: '10px 0px 0px 20px',
  },
  expandedChangeTemplateGrid: {
    boxShadow: `2px 0px 0px 0px ${theme.palette.framework.system.neutralGray}`,
    height: '102vh',
    padding: '20px 0px 0px 30px !important',
  },
  expandedBodyContainer: {
    padding: '0% 0% 1% 0%',
    height: '80vh',
  },
  templateSelectionTabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingRight: '25px',
    },
  },
  templateScroll: {
    marginBottom: '2.2%',
    height: '457px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  container: {
    marginTop: '5px',
  },
  templateSnap: {
    width: '100%',
    height: '100%',
    marginBottom: '5%',
  },
  categorySelection: {
    marginBottom: '5%',
  },
  inputLabel: {
    color: theme.palette.framework.system.doveGray,
    '&.Mui-focused': {
      color: theme.palette.framework.system.doveGray,
    },
    '&:focus': {
      color: `${theme.palette.framework.system.doveGray} !important`,
    },
  },
  select: {
    background: theme.palette.framework.system.platinum,
  },
  tab: {
    minWidth: '100px',
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingRight: '25px',
    },
  },
  saveButton: { height: '4.5vh' },
  saveButtonPlacement: { margin: '0 0.5%' },
  saveTemplateGrid: { padding: '2%', marginBottom: '20%' },
  expandedSaveTemplateGrid: { padding: '2%', marginBottom: '51vh' },
  MuiCircularProgressRoot: {
    top: '15vh',
    position: 'relative',
    left: '40%',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.components.typography.color,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: '1rem 2rem 1rem 2rem',
    },
  },
  skeleton: { margin: '5px', padding: '10px', width: '100%', height: '160px' },
  scroll: {
    overflowY: 'auto',
    overflowX: 'auto',
    margin: '0.5% 0 1% 0',
    borderBottom: `1px solid ${theme.palette.framework.system.lightGrey}`,
    '& div.tox-tinymce': {
      border: 'none',
      borderRadius: '0px',
    },
    '& div.tox-editor-header': {
      boxShadow: 'none !important',
      borderBottom: '1px solid lightgrey !important',
    },
    '& div.tox-editor-header, div.tox-toolbar': {
      backgroundColor: '#fbfbfb !important',
    },
    '& div.tox-toolbar__group:last-child': {
      marginLeft: 'auto',
      borderLeft: 'none',
    },
  },
  emailField: {
    width: '100%',
    marginBottom: '0.2%',
    backgroundColor: '#FBFBFB',
    paddingBottom: '0px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    borderRadius: '5px',
  },
  subjectResize: {
    height: '35px',
    backgroundColor: '#FBFBFB',
    padding: '4px 15px 5px',
    borderBottom: `1px solid ${theme.palette.framework.system.lightGrey}`,
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    padding: '60px 20px 20px 20px',
  },
  closeSelection: {
    padding: 0,
    color: theme.palette.framework.system.white,
    backgroundColor: 'transparent',
    '&:hover': {
      padding: 0,
      color: theme.palette.framework.system.white,
      backgroundColor: 'transparent',
    },
  },
  expandedScroll: {
    marginBottom: '1%',
    height: '72vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));
