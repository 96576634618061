import { Avatar, Divider, Grid, Skeleton, Typography, useMediaQuery, useTheme } from 'amn-ui-core';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveColumnContainer } from 'app/layout/ResposiveColumnContainer';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useSelector } from 'react-redux';
import HeaderButton from '@AMIEWEB/Common/Buttons/HeaderButton';
import { useInformationalHeaderStyles } from '@AMIEWEB/Unit/Header/InformationalHeader.Styles';
import { missingField } from 'app/constants';
import {
  selectIsUnitFormDirty,
  selectUnit,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { selectFacility, selectHttpStatus } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { HeaderContactDrawerContent, IFacilitySidePanelTabs } from '@AMIEWEB/Common/Drawer/HeaderContactDrawerContent';
import { HeaderContactDrawer } from '@AMIEWEB/Common/Drawer/HeaderContactDrawer';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { StatusDropDownValues, StatusDropdown } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import {
  useIsUserAccountManager,
  useSelectedUnitDetails,
  useSelectedUnitSkillSetGroups,
} from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import {
  SkillSetContent,
  formatPhoneNumber,
  getDate,
  getName,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityHeader/HeaderUtils';
import { selectUser } from 'oidc/user.selectors';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { usePromiseTracker } from 'react-promise-tracker';
import { deactivateUnitPromiseKey } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga';
import UnitDetailSidePanel from './UnitDetailSidePanel';
import { SkillSetType } from 'app/models/Unit/CreateUnit';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { FacilityTabs } from '@AMIEWEB/Facility/FacilityDetails/FacilityBody/FacilityBody';
import {
  HeaderSection,
  getFacilityHeaderAddressTemplate,
  getSkeletonVariant,
  getSkeletonWidthTitle,
  getSkeletonWidthValue,
} from './UnitDetailsHeaderHelper';
import { IUnitHeaderRow } from './UnitDetails.types';
import { httpSuccess } from 'app/services/serviceHelpers';

export enum FacilityUnitAPITypes {
  FACILITY_UNIT_STATUS_UPDATE = 'FACILITY_UNIT_STATUS_UPDATE',
}

const UnitDetailHeader = () => {
  const { classes } = useInformationalHeaderStyles();
  const { t } = useTranslation();
  const selectedUnit = useSelector(selectUnit);
  const facilityData = useSelector(selectFacility);
  const isUnitFormDirty = useSelector(selectIsUnitFormDirty);
  const facilityResponse = facilityData?.facilityResponse;
  const dispatch = useDispatch();
  const params = useParams<{ id: string; unitId: string }>();
  const { pathname } = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const [status, setStatus] = useState<string | StatusDropDownValues>(selectedUnit?.status);
  const selectedUnitDetail = useSelectedUnitDetails();
  const selectedUnitSkillSetDetails = useSelectedUnitSkillSetGroups();
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const isEdit = pathname.includes('edit');
  const isNewUnit = pathname.includes('newUnit');
  const userData = useSelector(selectUser);
  const { promiseInProgress: isBusyDeactivating } = usePromiseTracker({ area: deactivateUnitPromiseKey, delay: 0 });
  const { promiseInProgress: isFacilityUnitDetailsLoading } = usePromiseTracker({
    area: 'get-facility-unit-details',
    delay: 0,
  });
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [disassociateAllContacts, setDisassociateAllContacts] = useState<boolean>(false);
  const unitName = selectedUnit?.name ?? missingField;
  const facilityName = selectedUnit?.facilityName ?? facilityResponse?.facilityName ?? missingField;
  const contractAffiliate = selectedUnit?.contractAffiliate ?? facilityResponse?.affiliationName ?? missingField;
  const facilityPhone = selectedUnit?.phone ?? facilityResponse?.phone;
  const isDesktopSize = useMediaQuery('(min-width: 1920px)');
  const isAuthorized = useIsUserAccountManager();
  const apiHttpStatus = useSelector(selectHttpStatus);

  useEffect(() => {
    if (!selectedUnit?.unitId && params.unitId)
      dispatch(FacilityUnitActions.requestFacilityUnitDetails({ id: parseInt(params.unitId) }));
    dispatch(FacilityUnitActions.requestFacilityUnitsList({ id: parseInt(params.id) }));
    dispatch(facilityActions.getFacilityDetailsWithOpenOrderCountAction({ id: parseInt(params.id) }));

    return () => {
      dispatch(FacilityUnitActions.setFlattenedData([]));
      dispatch(FacilityUnitActions.setFilteredData([]));
    };
  }, []);

  useEffect(() => {
    if (!isBusyDeactivating) {
      setShouldShowDialog(false);
    }
  }, [isBusyDeactivating]);

  useEffect(() => {
    if (selectedUnit?.status) {
      setStatus(selectedUnit?.status);
    }
  }, [selectedUnit?.status]);

  useEffect(() => {
    if (
      apiHttpStatus?.pageName === FacilityUnitAPITypes.FACILITY_UNIT_STATUS_UPDATE &&
      httpSuccess(apiHttpStatus?.statusCode)
    ) {
      dispatch(facilityActions.setHttpStatus({ pageName: undefined, statusCode: undefined }));
      history.push({ pathname: `/facility/${params.id}`, search: `tab=${FacilityTabs.Unit_Tab}` });
    }
  }, [apiHttpStatus]);

  const headerRowData: IUnitHeaderRow[] = [
    {
      rowHead: t('facility.unitHeader.staffingVendor'),
      rowContent: selectedUnit?.staffingVendor ?? facilityResponse?.staffingVendor,
      classes: classes,
      id: HeaderSection.unitHeaderStaffingVendor,
    },
    {
      rowHead: t('facility.unitHeader.contractAffiliate'),
      rowContent: (
        <CustomTooltip
          tooltipContent={contractAffiliate}
          hideToolTip={contractAffiliate?.length < (isDesktopSize ? 68 : 53)}
        >
          <Typography className={classes.contractAffiliateFieldContent}>
            {contractAffiliate?.length > (isDesktopSize ? 68 : 53)
              ? `${contractAffiliate?.slice(0, isDesktopSize ? 68 : 53)}...`
              : contractAffiliate}
          </Typography>
        </CustomTooltip>
      ),
      classes: classes,
      id: HeaderSection.unitHeaderContractAffiliate,
      type: HeaderSection.unitHeaderContractAffiliate,
    },
    {
      rowHead: t('facility.unitHeader.mspClient'),
      rowContent: selectedUnit?.mspClient ?? facilityResponse?.mspClient,
      classes: classes,
      id: HeaderSection.unitHeaderMSPClient,
    },
    {
      rowHead: (
        <CustomTooltip tooltipContent={'AMN Division'}>
          <BusinessOutlinedIcon sx={theme => ({ color: theme.palette.system.buttonDarkGray })} />
        </CustomTooltip>
      ),
      rowContent: selectedUnit?.amnDivision ?? facilityResponse?.division,
      classes: classes,
      id: HeaderSection.unitHeaderAMNDivision,
    },
    {
      rowHead: (
        <CustomTooltip tooltipContent={'Technology Vendor'}>
          <ApiOutlinedIcon sx={theme => ({ color: theme.palette.system.buttonDarkGray })} />
        </CustomTooltip>
      ),
      rowContent: selectedUnit?.technologyVendor ?? facilityResponse?.technologyVendor ?? missingField,
      classes: classes,
      id: HeaderSection.unitHeaderTechnologyVendor,
    },
    {
      rowHead: (
        <CustomTooltip tooltipContent={'Healthcare System'}>
          <WysiwygIcon sx={theme => ({ color: theme.palette.system.buttonDarkGray })} />
        </CustomTooltip>
      ),
      rowContent: selectedUnit?.healthSystemName ?? facilityResponse?.healthSystemName,
      classes: classes,
      id: HeaderSection.unitHeaderHealthcareSystem,
    },
    {
      rowHead: (
        <CustomTooltip tooltipContent={'Business Relationship'}>
          <HandshakeOutlinedIcon sx={theme => ({ color: theme.palette.system.buttonDarkGray })} />
        </CustomTooltip>
      ),
      rowContent: selectedUnit?.businessRelationship ?? facilityResponse?.businessRelationship,
      classes: classes,
      id: HeaderSection.unitHeaderBusinessRelationship,
    },
    {
      rowHead: t('facility.facilityHeader.phone'),
      rowContent: facilityPhone,
      classes: classes,
      id: HeaderSection.unitHeaderPhone,
      type: 'phone',
    },
    {
      rowHead: (
        <CustomTooltip tooltipContent={'Account Manager'}>
          <Avatar
            sx={theme => ({
              fontWeight: '400',
              width: '24px',
              height: '24px',
              background: theme.palette.system.buttonDarkGray,
              color: theme.palette.components.avatar.color,
              fontSize: '12px !important',
            })}
          >
            AM
          </Avatar>
        </CustomTooltip>
      ),
      rowContent: getName(
        selectedUnit?.employeeContacts?.accountManager ?? facilityResponse?.employeeRoles?.accountManager,
      ),
      classes: classes,
      id: HeaderSection.unitHeaderAccountManager,
    },
    {
      rowHead: (
        <CustomTooltip tooltipContent={'Regional Director'}>
          <Avatar
            sx={theme => ({
              fontWeight: '400',
              width: '24px',
              height: '24px',
              background: theme.palette.system.buttonDarkGray,
              color: theme.palette.components.avatar.color,
              fontSize: '12px !important',
            })}
          >
            RD
          </Avatar>
        </CustomTooltip>
      ),
      rowContent: getName(
        selectedUnit?.employeeContacts?.regionalDirector ?? facilityResponse?.employeeRoles?.regionalDirector,
      ),
      classes: classes,
      id: HeaderSection.unitHeaderRegionalDirector,
    },
  ];

  const HeaderRow: FC<IUnitHeaderRow> = props => {
    const [showTooltip, setShowTooltip] = useState(false);
    const rowSectionRef = useRef(null);
    const rowHeadRef = useRef(null);
    useEffect(() => {
      if (rowHeadRef.current && typeof props.onMeasureWidth === 'function') {
        // Measure and send this to a parent component
        const width = rowHeadRef.current.offsetWidth;
        props.onMeasureWidth(width);
      } else {
        console.warn('rowHeadRef.current or props.onMeasureWidth is not available');
      }
    }, []);

    useEffect(() => {
      setShowTooltip(rowSectionRef?.current?.scrollWidth > rowSectionRef?.current?.clientWidth);
    }, [rowSectionRef.current]);

    return (
      <Grid container className={classes.fieldContainer} data-testid="table-test-id" id={props.id}>
        {isFacilityUnitDetailsLoading ? (
          <Skeleton variant={getSkeletonVariant(props.id)} width={getSkeletonWidthTitle(props.id)} height={19} />
        ) : (
          <Grid item className={classes.fieldHead} data-id="content">
            <div ref={rowHeadRef} style={{ minWidth: `${props.fixedWidth}px` }}>
              {props.rowHead}
            </div>
          </Grid>
        )}
        <Grid item className={classes.fieldContentContainer} data-id="content">
          {isFacilityUnitDetailsLoading ? (
            <Skeleton variant="rectangular" width={getSkeletonWidthValue(props.id)} height={19} />
          ) : (
            <CustomTooltip hideToolTip={!showTooltip} placement="bottom" arrow={true} tooltipContent={props.rowContent}>
              <div
                className={props.type == 'phone' && props.rowContent ? classes.headerPhoneNumber : classes.fieldContent}
                ref={rowSectionRef}
              >
                {props.rowContent ? props.rowContent : missingField}
              </div>
            </CustomTooltip>
          )}
        </Grid>
      </Grid>
    );
  };

  const HeaderColumn: FC<{ rowData: IUnitHeaderRow[] }> = props => {
    const [minWidth, setMinWidth] = useState(0);
    const handleMeasureWidth = (width: number) => {
      setMinWidth(oldWidth => Math.max(oldWidth, width));
    };

    return (
      <Grid className={classes.fieldSection} direction="column">
        {props.rowData.map((item, index) => {
          return (
            <HeaderRow
              key={index}
              rowHead={item.rowHead}
              rowContent={item.rowContent}
              classes={item.classes}
              id={item.id}
              onMeasureWidth={handleMeasureWidth}
              fixedWidth={minWidth}
              type={item.type}
            />
          );
        })}
      </Grid>
    );
  };

  const openFacilityDrawer = () => {
    trackEvent({ type: 'click', name: 'Key Contacts', properties: { pageName: 'Unit Details' } });
    dispatch(facilityActions.setUnitDrawerVisibility({ open: false, tabId: 0 }));
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(facilityActions.setFacilityDrawerVisibility({ open: true, tabId: IFacilitySidePanelTabs.CONTACTS }));
    dispatch(globalActions.setDetailsPageDrawer({ open: true }));
  };

  const openFacilityDetailsDrawer = () => {
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(globalActions.resetFacilityDrawerData());
    const payload = { open: true, facility: { facilityId: facilityResponse?.facilityId, hideNavigation: true } };
    dispatch(globalActions.setFacilityDrawerData(payload));
    dispatch(facilityActions.setFacilityDrawerVisibility({ open: false, tabId: 0 }));
  };

  const getSkillSetContent = (): SkillSetType[] => {
    if (selectedUnitDetail?.experience) return selectedUnitDetail?.experience?.skillSets;
    else {
      const skills: SkillSetType[] = selectedUnitSkillSetDetails.flatMap(_ => {
        const sets = _.skillsets.map(x => {
          return {
            disciplineId: x.skillSet.disciplineId,
            discipline: x.skillSet.discipline,
            specialtyId: x.skillSet.specialtyId,
            specialty: x.skillSet.specialty,
            yearsOfExperience: x.skillSet.yearsOfExperience,
            subSpecialtyId: x.skillSet.subSpecialtyId,
            subSpecialty: x.skillSet.subSpecialty,
            isRequired: x.skillSet.isRequired,
            isPrimary: x.isPrimary,
          };
        });
        return sets;
      });
      return skills;
    }
  };
  const openUnitDrawer = () => {
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(facilityActions.setUnitDrawerVisibility({ open: true, tabId: 0 }));
    dispatch(globalActions.setDetailsPageDrawer({ open: true }));
  };

  const closeProfileDrawer = () => {
    dispatch(facilityActions.setFacilityDrawerVisibility({ open: false, tabId: 0 }));
    dispatch(globalActions.setDetailsPageDrawer({ open: false }));
  };

  const closeUnitDrawer = () => {
    dispatch(facilityActions.setUnitDrawerVisibility({ open: false, tabId: 0 }));
    dispatch(globalActions.setDetailsPageDrawer({ open: false }));
  };

  const handleStatus = (value: string) => {
    setStatus(StatusDropDownValues.INACTIVE);
    if (value === StatusDropDownValues.INACTIVE && isUnitFormDirty) {
      setCancelModal(true);
    } else {
      setShouldShowDialog(true);
    }
  };

  const handleCancel = () => {
    setShouldShowDialog(false);
    setStatus(StatusDropDownValues.ACTIVE);
  };

  const handleModalResponse = (response: boolean) => {
    setDisassociateAllContacts(response);
    dispatch(
      FacilityUnitActions.deactivateFacilityUnit({
        facilityId: Number(params.id),
        unitId: Number(params.unitId),
        isDisableContactsRequired: response,
        currentEmployeeId: userData.userInfo.employeeId,
        currentEmployeeName: userData.userInfo.firstName + ' ' + userData.userInfo.lastName,
        t: t,
      }),
    );
    setStatus(StatusDropDownValues.INACTIVE);
  };

  const yesAction = () => {
    setCancelModal(false);
    setShouldShowDialog(true);
  };

  const noAction = () => {
    setCancelModal(false);
    setStatus(StatusDropDownValues.ACTIVE);
  };

  return (
    <>
      <Grid data-testid="header-id" container xs={12} direction="row" classes={{ container: classes.container }}>
        <Grid item direction="column" classes={{ root: classes.containerAvatar }}>
          {isFacilityUnitDetailsLoading ? (
            <Skeleton variant="rectangular" width={142} height={50} />
          ) : (
            <Grid item direction="row" classes={{ root: classes.myAvatarAlignment }}>
              <HeaderButton
                headerAbbr=""
                headerAbbrBackground={
                  isNewUnit || isEdit ? theme.palette.system.violet : theme.palette.system.azureBlue
                }
                headerId={`UID ${selectedUnit?.unitId ?? 'N/A'}`}
                customSearchType={`unit`}
                headerIdBackground={
                  isNewUnit || isEdit ? theme.palette.system.mutedVoilet : theme.palette.system.mutedAzureBlue
                }
                isDisabled={isNewUnit}
              />
            </Grid>
          )}
          {isFacilityUnitDetailsLoading && (
            <Grid container gap={1.5} display={'flex'} mt={2}>
              <Skeleton variant="circular" width={32} height={32} />
              <Skeleton variant="circular" width={32} height={32} />
            </Grid>
          )}
          {!isFacilityUnitDetailsLoading && (
            <Grid container mt={2}>
              <Grid
                item
                className={classes.circleContainer}
                onClick={() => {
                  trackEvent({ type: 'click', name: 'Unit Drawer', properties: { pageName: 'Unit Details' } });
                  openUnitDrawer();
                }}
              >
                <PersonOutlinedIcon sx={{ color: theme.palette.primary.main }} />
              </Grid>
              <Grid
                item
                className={facilityPhone ? `${classes.circleContainer}` : `${classes.circleContainerDisabled}`}
                ml={2}
              >
                <CustomTooltip tooltipContent={formatPhoneNumber(facilityPhone)} disabled={!facilityPhone}>
                  <CallOutlinedIcon className={facilityPhone ? `${classes.icon}` : `${classes.iconDisabled}`} />
                </CustomTooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item direction="column" classes={{ root: classes.detailsContainerWrap }}>
          <Grid item container direction="row">
            {isFacilityUnitDetailsLoading && (
              <Grid gap={1} display={'grid'}>
                <Skeleton variant="rectangular" width={315} height={19} />
                <Skeleton variant="rectangular" width={114} height={19} />
                <Skeleton variant="rectangular" width={317} height={19} />
                <Skeleton variant="rectangular" width={223} height={19} />
              </Grid>
            )}
            {!isFacilityUnitDetailsLoading && (
              <Grid item xs={4} style={{ minWidth: '170px' }}>
                <CustomTooltip tooltipContent={unitName} hideToolTip={unitName?.length < (isDesktopSize ? 55 : 40)}>
                  <Typography
                    className={classes.unitName}
                    sx={{
                      color: theme.palette.framework.system.charcoal,
                    }}
                  >
                    {unitName?.length > (isDesktopSize ? 55 : 40)
                      ? `${unitName?.slice(0, isDesktopSize ? 55 : 40)}...`
                      : unitName}
                  </Typography>
                </CustomTooltip>
                <CustomTooltip
                  tooltipContent={facilityName}
                  hideToolTip={facilityName?.length < (isDesktopSize ? 150 : 113)}
                >
                  <Typography
                    mt={1}
                    className={classes.facilityName}
                    sx={{
                      '&:hover': { textDecoration: 'underline' },
                    }}
                    onClick={openFacilityDetailsDrawer}
                  >
                    {facilityName?.length > (isDesktopSize ? 150 : 113)
                      ? `${facilityName?.slice(0, isDesktopSize ? 150 : 113)}...`
                      : facilityName}
                  </Typography>
                </CustomTooltip>

                <Typography
                  mt={1}
                  variant="body2"
                  sx={{ color: theme.palette.system.buttonDarkGray, fontStyle: 'italic' }}
                >
                  {`Last updated on:  ${getDate(selectedUnit?.lastUpdated?.toString())} by ${getName(
                    selectedUnit?.lastUpdatedBy,
                  )}`}
                </Typography>
              </Grid>
            )}
            <Divider orientation="vertical" flexItem classes={{ root: classes.unitDivider }} />
            <Grid item xs={2}>
              <HeaderColumn rowData={headerRowData.slice(3, 7)} />
            </Grid>
            <Divider orientation="vertical" flexItem classes={{ root: classes.unitDivider }} />
            <Grid item xs={3}>
              <HeaderColumn rowData={headerRowData.slice(0, 3)} />
            </Grid>
            <Divider orientation="vertical" flexItem classes={{ root: classes.unitDivider }} />
            <Grid item xs={2}>
              <Grid mb={2}>
                {isFacilityUnitDetailsLoading ? (
                  <Skeleton variant="rectangular" width={135} height={19} />
                ) : (
                  <CustomTooltip
                    tooltipContent={t('facility.tooltip.noPermissionToEdit')}
                    placement="bottom"
                    isStandardContent={true}
                    isInlineContent={true}
                    disabled={isAuthorized}
                  >
                    <StatusDropdown
                      isNewStatusIndicator={false}
                      handleStatus={handleStatus}
                      defaultValue={isNewUnit ? 'Status N/A' : status}
                      isDisabled={!isAuthorized || isNewUnit || status === StatusDropDownValues.INACTIVE ? true : false}
                      width="250px"
                      isReadOnly={!isAuthorized}
                    />
                  </CustomTooltip>
                )}
              </Grid>
              <HeaderColumn rowData={headerRowData.slice(8, 10)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        direction="row"
        classes={{ root: classes.actionContainer }}
        style={{ marginBottom: 0, backgroundColor: isFacilityUnitDetailsLoading ? theme.palette.system.gray : '' }}
      >
        <Grid item style={{ display: 'flex' }}>
          {isFacilityUnitDetailsLoading ? (
            <Grid mt={0.5} gap={3} display={'flex'}>
              <Skeleton variant="rectangular" width={89} height={19} />
              <Skeleton variant="rectangular" width={223} height={19} />
            </Grid>
          ) : (
            <ResponsiveColumnContainer
              id={'facilityHeaderAddress'}
              rowHead={t('facility.unitHeader.fidAddress')}
              rowContent={
                <Typography variant="body1" className={classes.blueBarText} pl={1}>
                  {getFacilityHeaderAddressTemplate(selectedUnit?.location ?? facilityResponse?.location)}
                </Typography>
              }
              classes={classes}
              customRowHeadClass={classes.rowHeadCustom}
              customRowContentClass={`${classes.rowContentCustom} ${classes.rowContentDefaultColor}`}
            />
          )}
        </Grid>
        <Grid item style={{ display: 'flex' }}>
          {isFacilityUnitDetailsLoading ? (
            <Grid mt={0.5} gap={3} display={'flex'}>
              <Skeleton variant="rectangular" width={71} height={19} />
              <Skeleton variant="rectangular" width={535} height={19} />
            </Grid>
          ) : (
            <ResponsiveColumnContainer
              id={'unitHeaderAddress'}
              rowHead={t('facility.unitHeader.skillSet')}
              rowContent={<SkillSetContent skillSets={isNewUnit ? [] : getSkillSetContent()} />}
              classes={classes}
              customRowHeadClass={classes.rowHeadCustom}
              customRowContentClass={`${classes.rowContentCustom} ${classes.rowContentDefaultColor}`}
            />
          )}
        </Grid>
      </Grid>
      {facilityData?.facilityDrawerState?.open && (
        <HeaderContactDrawer
          closeProfileDrawer={closeProfileDrawer}
          openProfileDrawer={facilityData?.facilityDrawerState?.open}
          isBorderAvatar={false}
          avatarOffSet={`FID`}
          avatarText={`${selectedUnit?.facilityId ?? facilityResponse?.facilityId}`}
          title={facilityName}
          phone={facilityPhone ?? 'N/A'}
          children={<HeaderContactDrawerContent />}
        />
      )}
      {facilityData?.unitDrawerState?.open && (
        <UnitDetailSidePanel
          closeProfileDrawer={closeUnitDrawer}
          openProfileDrawer={facilityData?.unitDrawerState?.open}
          facilityName={facilityName}
          handleDropDownStatus={handleStatus}
          status={status}
          openFacilityDrawer={openFacilityDrawer}
        />
      )}
      {shouldShowDialog && (
        <GenericDialog
          open={shouldShowDialog}
          dialogActions={[
            {
              text: t('global.button.cancel'),
              variant: 'text',
              color: 'tertiary',
              useDialogOnCloseEvent: true,
              classes: { root: classes.dialogActionsRoot },
              disabled: isBusyDeactivating,
            },
            {
              text: t('global.No'),
              variant: 'contained',
              color: 'tertiary',
              onClick: () => handleModalResponse(false),
              classes: { root: classes.dialogActionsRoot },
              loading: isBusyDeactivating && !disassociateAllContacts,
              disabled: isBusyDeactivating,
            },
            {
              text: t('global.Yes'),
              variant: 'contained',
              color: 'primary',
              onClick: () => handleModalResponse(true),
              classes: { root: classes.dialogActionsRoot },
              loading: isBusyDeactivating && disassociateAllContacts,
              disabled: isBusyDeactivating,
            },
          ]}
          dialogTitleProps={{ text: t('facility.units.statusUpdate.modalTitle') }}
          draggable
          onClose={() => handleCancel()}
        >
          <Typography> {t('facility.units.statusUpdate.modalBody')} </Typography>
        </GenericDialog>
      )}
      {cancelModal && <Cancel openDialog={cancelModal} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
    </>
  );
};

export default UnitDetailHeader;
