import { InlineDropdown } from '@AMIEWEB/Common/EditPage/InlineDropdown';
import { Checkbox, ListItemIcon } from 'amn-ui-core';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { makeStyles } from 'tss-react/mui';
import { TagOption } from './PlacementTagsWrapper/PersonalTags';

const useStyles = makeStyles()({
  iconRoot: {
    minWidth: '34px',
  },
});

export const ContactCheckbox = ({ contacts, editContacts, onValueChange, disabled }) => {
  const { classes } = useStyles();
  return (
    <ListItemIcon classes={{ root: classes.iconRoot }}>
      <Checkbox
        disabled={disabled}
        checked={editContacts?.checked}
        id={contacts?.id}
        disableRipple
        onChange={newValue =>
          onValueChange(contacts?.keyAttribute, { ...editContacts, checked: newValue.target.checked })
        }
        edge="start"
      />
    </ListItemIcon>
  );
};

export const ContactEditor = ({ value, isDirty, helperText, designation, onChange, onCancel, onApply, ...props }) => {
  const { promiseInProgress: isSaving } = usePromiseTracker({ area: `save-contact-${props.keyAttribute}` });

  const _onApply = () => onApply({ ...value, key: props.keyAttribute });

  return (
    <InlineDropdown
      {...props}
      placeholder="Select"
      name={designation}
      value={value}
      onChange={onChange}
      onCancel={onCancel}
      onApply={_onApply}
      removeGutters
      isDirty={isDirty}
      isSaving={isSaving}
      helperText={helperText}
      isContactSidePanel
    />
  );
};

const getTypeOptions = async () => {
  const tagLookups = await getSearchFilterOptions('placementFlagTypes', true);
  return tagLookups;
};

export const getPlacementFlagTypes = async (setOptions: (options: TagOption[]) => void) => {
  const options = await getTypeOptions();
  const filteredOptions = options?.map(e => {
    return { ...e, value: parseInt(e.value) };
  });
  setOptions(filteredOptions);
};
