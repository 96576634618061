import eventIcon from 'app/assets/images/event-icon.png';
import { ILastVerifiedValues, IOrderDetails } from 'app/models/Orders/OrderDetails';

export const missingDate = '0001-01-01T00:00:00';
export const missingField = '--';
export const defaultCountry = 'us';
export const CONSTANT = { POSITIONS_AVAILABLE : 'Positions Available'}
export enum EChangeHistoryName {
  amieWeb = 'AMIE Web',
  classic = 'AMIE Classic',
}

const testEmptyOrderDetails = (): IOrderDetails => {
  return {
    orderId: '',
    orderTypeId: 0,
    facility: {
      facilityId: 0,
      name: '',
      facilityBestBetsId: 0,
      creditStatusId: 0,
      businessRelationship: '',
    },
    unit: {
      name: '',
      unitId: 0,
      costCenter: '',
      floor: '',
      experience: {
        isNewGrad: false,
        skillSets: [
          {
            disciplineId: 0,
            discipline: '',
            specialtyId: 0,
            specialty: '',
            isRequired: false,
          },
        ],
      },
      reference: {
        isRequired: false,
        numberOfReferenceRequired: 0,
        numberOfReferenceToRFO: 0,
        withinMonths: false,
        referenceFromSupervisor: false,
      },
      nurseRatio: null,
      unitSize: null,
      travelExperience: null,
      qualification: {
        activeLicense: false,
        compactLicense: false,
        emr: null,
        licenses: [
          {
            requirementTypeId: 0,
            abbreviation: '',
            isRequired: false,
            documentAttachmentRequired: null,
            isMultiState: null,
            isError: false,
            stateAbbreviation: '',
            state: '',
          },
        ],
        certifications: [
          {
            requirementTypeId: 0,
            abbreviation: '',
            isRequired: false,
            documentAttachmentRequired: null,
          },
        ],
        skillChecklists: [
          {
            questionId: 0,
            text: '',
            isRequired: false,
            yearsOfExperience: 0,
          },
        ],
      },
      virtualRating: [''],
      float: null,
      flexibleSchedule: null,
      nonStandardRequirement: null,
      createdByVersion: '',
    },
    location: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      regionId: 0,
      region: '',
      phoneNumber: '',
    },
    numOfJobMatches: 0,
    positionsAvailable: 0,
    numOfFilesWfcRfo: 0,
    numOfFilesSent: 0,
    weeklyGrossPay: 0,
    orderBillRate: 0,
    startDate: null,
    endDate: null,
    numOfWeeks: 0,
    dateCreated: '',
    maxFilesSent: false,
    division: '',
    orderType: '',
    technologyVendor: '',
    activeBonus: false,
    payCycle: 0,
    fees: 0,
    radiusRules: 0,
    gww: false,
    contacts: {
      recruiter: { userId: 0, firstName: '', lastName: '', email: '' },
      accountManager: { userId: 0, firstName: '', lastName: '', email: '' },
      regionalDirector: { userId: 0, firstName: '', lastName: '', email: '' },
      credentialingAnalyst: { userId: 0, firstName: '', lastName: '', email: '' },
      clinicalManager: { userId: 0, firstName: '', lastName: '', email: '' },
      verifiedBy: { userId: 0, firstName: '', lastName: '', email: '' },
    },
    dateVerified: '',
    jobDescription: '',
    yearsOfExperience: 0,
    shifts: [
      {
        id: 14,
        description: '10 E',
      },
    ],
    submissionRequirements: '',
    preferredQualifications: '',
    internalNotes: '',
    skillsets: [{ disciplineId: 0, discipline: '', specialtyId: 0, specialty: '' }],
    clinicalManager: null,
    flexibleDates: false,
    asap: false,
    reasonForMaxFilesSent: '',
    hideFacilityDescription: false,
    suppressExternalPosting: false,
    clinicalFellowship: null,
    newGrad: null,
    expectedHours: null,
    guaranteedHours: null,
    orderReasonId: null,
    vmsOrderNumber: '',
    vmsBillRate: null,
    webLink: '',
    orderPriorities: [{ orderPriorityTypeId: 0, description: '' }],
    lastUpdatedMaxFilesSent: '',
    orderScore: 0,
    orderCurrentPlacements: {
      totalPlacements: 0,
      booked: 0,
      interviewing: 0,
      offered: 0,
      onAssignment: 0,
      inSubmission: 0,
      lastBookedDate: null,
      placementStartDate: null,
    },
    orderDecrementReasonID: 0,
    declineFiles: true,
    amieOrdersTimestamp: '',
    groupedSkillsets: [],
  };
};
interface atAGlanceSection {
  icon?: string;
  title: string;
  value: string;
  editable?: boolean;
  editableType?: {
    type: editType;
    textFieldType?: string;
  };
}

export const candidatePreferenceMatch = {
  recordCount: 20,
  filterByField: 'rank',
};

export const shift = [
  '10 D',
  '10 D/E',
  '10 D/E/N',
  '10 D/N',
  '10 E',
  '10 E/N',
  '10 N',
  '11.5 D',
  '11.5 D/E',
  '11.5 D/E/N',
  '11.5 D/N',
  '11.5 E',
  '11.5 E/N',
  '11.5 N',
  '12 D',
  '12 D/E',
  '12 D/E/N',
  '12 D/N',
  '12 E',
  '12 E/N',
  '12 N',
  '7 D',
  '7 E',
  '7.5 D',
  '7.5 D/E',
  '7.5 D/E/N',
  '7.5 D/N',
  '7.5 E',
  '7.5 E/N',
  '7.5 N',
  '7.5/10/11.5 D',
  '7.5/10/11.5 D/E',
  '7.5/10/11.5 D/E/N',
  '7.5/10/11.5 D/N',
  '7.5/10/11.5 E',
  '7.5/10/11.5 E/N',
  '7.5/10/11.5 N',
  '7.5/11.5 D',
  '7.5/11.5 D/E',
  '7.5/11.5 D/E/N',
  '7.5/11.5 D/N',
  '7.5/11.5 E',
  '7.5/11.5 E/N',
  '7.5/11.5 N',
  '8 D',
  '8 D/E',
  '8 D/E/N',
  '8 D/N',
  '8 E',
  '8 E/N',
  '8 N',
  '8/10/12 D',
  '8/10/12 D/E',
  '8/10/12 D/E/N',
  '8/10/12 D/N',
  '8/10/12 E',
  '8/10/12 E/N',
  '8/10/12 N',
  '8/12 D',
  '8/12 D/E',
  '8/12 D/E/N',
  '8/12 D/N',
  '8/12 E',
  '8/12 E/N',
  '8/12 N',
  '9.5 D',
  '9.5 D/E',
  '9.5 D/E/N',
  '9.5 D/N',
  '9.5 E',
  '9.5 E/N',
  '9.5 N',
];

export const yearsOfExperienceList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const virtualRatingList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
export const virtualRatingListWithVoiceAdvantage = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'Voice Advantage (Interview)', 'VA-Auto Offer'];
export const disabledOrderPrioritiesList = ['Bed size less than 200', 'Teaching facility', 'Trauma center', 'Magnet Status'];
export const nonStandardCategories = ['Select', 'Experience', 'Certifications', 'Skills', 'EMR', 'Float', 'References'];

export enum editType {
  textField = 'textField',
  picklist = 'picklist',
  switch = 'switch',
  datePicker = 'datePicker',
  radio = 'radio',
  fileOutReason = 'fileOutReason',
}

export const atAGlanceSection1: atAGlanceSection[] = [
  { icon: eventIcon, title: 'Last Verified Date', value: 'dateVerified' },
  { title: 'Last Verified By', value: 'verifiedBy' },
];

export const atAGlanceSection2: atAGlanceSection[] = [
  {
    title: 'Positions Available',
    value: 'positionsAvailable',
    editable: true,
    editableType: { type: editType.textField, textFieldType: 'number' },
  },
  {
    title: 'File Out*',
    value: 'declineFiles',
    editable: true,
    editableType: { type: editType.radio },
  },
  {
    title: 'Reason*',
    value: 'orderDecrementReasonID',
    editable: true,
    editableType: { type: editType.fileOutReason },
  },
  { title: `Start Date`, value: 'startDate', editable: true, editableType: { type: editType.datePicker } },
  { title: `End Date`, value: 'endDate', editable: true, editableType: { type: editType.datePicker } },
  {
    title: `Placement Length`,
    value: 'placementLength',
    editable: true,
    editableType: { type: editType.textField, textFieldType: 'number' },
  },
  { title: 'Flexible Dates', value: 'flexibleDates', editable: true, editableType: { type: editType.switch } },
  { title: 'ASAP', value: 'asap', editable: true, editableType: { type: editType.switch } },
];

export const atAGlanceSectionPreview: atAGlanceSection[] = [
  {
    title: 'Unit',
    value: 'unit',
  },
  {
    title: 'Skillsets',
    value: 'skillsets',
  },
  {
    title: 'Shifts',
    value: 'shifts',
  },
];

export const atAGlanceSectionPreview1: atAGlanceSection[] = [
  {
    title: 'Assignment',
    value: 'unit',
  },
  {
    title: 'Flexible Dates',
    value: 'skillsets',
  },
  {
    title: 'ASAP Start Date',
    value: 'shifts',
  },
  {
    title: 'MFS',
    value: 'shifts',
  },
  {
    title: 'Order Age',
    value: 'shifts',
  },
  {
    title: 'Last Verified Date',
    value: 'shifts',
  },
  {
    title: 'Last Verified By',
    value: 'verifiedBy',
  },
];

export const atAGlanceSection3: atAGlanceSection[] = [
  { title: 'Date Created', value: 'dateCreated' },
  { title: 'Max Files Sent?', value: 'maxFilesSent', editable: true, editableType: { type: editType.switch } },
  {
    title: 'Max Files Sent Reason',
    value: 'reasonForMaxFilesSent',
    editable: true,
    editableType: { type: editType.picklist },
  },
  { title: 'Max Files Sent Date', value: 'maxFilesSentdate' },
  { title: 'Order Score', value: 'orderScore' },
  { title: 'Business Relationship', value: 'businessRelationship' },
  { title: 'Region', value: 'region', editable: true, editableType: { type: editType.picklist } },
];
export const atAGlanceSection4: atAGlanceSection[] = [
  { title: 'Pay Cycle', value: 'payCycle' },
  { title: 'Fees', value: 'fees' },
  { title: 'Radius Rules', value: 'radiusRules' },
];
export const atAGlanceSection5: atAGlanceSection[] = [
  {
    title: 'Hide Facility Description',
    value: 'hideFacilityDescription',
    editable: true,
    editableType: { type: editType.switch },
  },
  {
    title: 'Suppress External Posting',
    value: 'suppressExternalPosting',
    editable: true,
    editableType: { type: editType.switch },
  },
  {
    title: 'Clinical Fellowship',
    value: 'clinicalFellowship',
    editable: true,
    editableType: { type: editType.switch },
  },
  {
    title: 'Expected Hours',
    value: 'expectedHours',
    editable: true,
    editableType: { type: editType.textField, textFieldType: 'number' },
  },
  {
    title: 'Guaranteed Hours',
    value: 'guaranteedHours',
    editable: true,
    editableType: { type: editType.textField, textFieldType: 'number' },
  },
  { title: 'Order Reason', value: 'orderReason', editable: true, editableType: { type: editType.picklist } },
  { title: 'Nurse to Patient Ratio', value: 'nurseToPatientRatio' },
  { title: 'Unit Size', value: 'unitSize' },
  { title: 'Cost Center', value: 'costCenter' },
];

export const testOrderDescription = {
  testData: {
    facility: {
      facilityId: 0,
      name: '',
      facilityBestBetsId: 0,
      creditStatusId: 0,
    },
    unit: {
      unitId: 0,
      name: '',
      unitBestBetsId: 0,
    },
    orderReasonId: 0,
    orderTypeId: 0,
    gww: false,
    type: '',
    region: 'Unassigned',
    skillsets: [],
    orderPriorities: [],
    orderBillRate: '0',
    onCallRate: 0,
    callBackMinimum: '',
    completionBonus: '',
    hideFacilityDescription: false,
    suppressExternalPosting: false,
    CF: false,
    newGrad: false,
    maxFilesSent: false,
    reasonForMaxFilesSent: '',
    VMSOrderNumber: '',
    weblink: '',
    yearsOfExperience: 0,
    positionsAvailable: 1,
    requiredQualifications: '',
    preferredQualifications: '',
    jobDescription: '',
    internalNotes: '',
    shifts: [],
    placementLength: 13,
    latestDate: {
      startDate: null,
      endDate: null,
    },
    startDate: null,
    endDate: null,
    dates: [],
    ASAP: false,
    flexibleDates: false,
    expectedHours: null,
    guaranteedHours: null,
    orderReason: null,
    negotiableRate: 'No',
    notes: null,
    addBillRateRange: false,
    nonContractedBillRates: [],
  },

  testFunction: () => {
    return;
  },
};

export const initialDataState = {
  facilityResponse: {
    facilityId: 0,
    facilityName: '',
    healthSystemName: '',
    bestBets: '',
    bestBetsId: 0,
    contacts: {
      hospitalAccountManager: {
        firstName: '',
        lastName: '',
      },
      regionalDirector: {
        firstName: '',
        lastName: '',
      },
    },
    location: {
      address1: '',
      address2: '',
      state: '',
      city: '',
      zipCode: '',
      region: '',
    },
    facilityStatus: '',
    division: '',
    //gww: false,
    bedSize: 0,
    isTeaching: false,
    isMagnetStatus: false,
    isTraumaCenter: false,
    bookingLimit: 0,
    creditStatus: '',
    technologyVendor: '',
  },
};

export const testOrderMidPageDetails = {
  handleOrderUpdate: () => { },
  jobDescription: '',
  yearsOfExperience: 0,
  shifts: [],
  submissionRequirements: '',
  preferredQualifications: '',
  internalNotes: '',
};

export const testAtAGlance = {
  startEndDate: '',
  dateVerified: '',
  placementLength: '',
  dateCreated: '',
  maxFileSent: '',
  facilityId: 0,
  division: '',
  bestBets: '',
  region: '',
  type: '',
  technologyVendor: '',
  activeBonus: '',
  payCycle: '',
  fees: '',
  radiusRules: 0,
  gww: '',
  accountManager: '',
  regionalDirector: '',
  credentialingAnalyst: '',
  clinicalManager: '',
};

export const testOrderDetailsDataViewProps = {
  testProps: {
    testUpdateFunction: (property: string, value: any) => { },
    testLastVerifiedUpdateFunction: (value: ILastVerifiedValues) => { },
    midPageDetails: {
      jobDescription: '',
      yearsOfExperience: 0,
      shifts: [],
      submissionRequirements: '',
      preferredQualifications: '',
      internalNotes: '',
      orderPriorities: [],
    },
    atAGlance: {
      positionsAvailable: 0,
      startEndDate: '',
      dateVerified: '',
      verifiedBy: '',
      placementLength: '',
      dateCreated: '',
      maxFilesSent: false,
      facilityBestBets: '',
      unitBestBets: '',
      region: '',
      technologyVendor: '',
      activeBonus: '',
      payCycle: '',
      fees: '',
      radiusRules: 0,
      gww: '',
      accountManager: '',
      regionalDirector: '',
      credentialingAnalyst: '',
      clinicalManager: '',
      flexibleDates: false,
      asap: false,
      reasonForMaxFilesSent: '',
      hideFacilityDescription: false,
      suppressExternalPosting: false,
      clinicalFellowship: null,
      newGrad: null,
      expectedHours: null,
      guaranteedHours: null,
      orderReason: null,
      vmsOrderNumber: '',
      webLink: '',
      orderPriorities: [],
    },
    lastVerifiedUpdateStatus: false,
    orderDetails: testEmptyOrderDetails(),
    candidateData: [
      {
        candidateId: 0,
        candidateName: '',
        brandId: 0,
        brand: '',
        candidateStatus: '',
        placementId: 5,
        placementStatus: '',
        requestFileOutDate: '',
        filesInProgress: 0,
        netWeeksBooked: '',
        recruiter: '',
        predictiveInterestScore: 0,
        predictiveOfferScore: 0,
        rank: 0,
        availabilityDate: '',
        availability: null,
        yearsOfExperience: 0,
        placementIdValue: 0,
        weeksBooked: 5,
      },
    ],
  },
};

export const testGlanceValueProps = {
  testProps: [
    {
      value: '',
      testEnableFunction(editstate: boolean): void { },
      testFunction: (property: string, value: any) => { },
      editable: true,
      editedOrder: testEmptyOrderDetails(),
      editTypeValue: { type: editType.datePicker },
      disableEdit: true,
    },
    {
      value: '',
      testEnableFunction(editstate: boolean): void { },
      testFunction: (property: string, value: any) => { },
      editable: true,
      editedOrder: testEmptyOrderDetails(),
      editTypeValue: { type: editType.textField, textFieldType: 'string' },
      disableEdit: true,
    },
    {
      value: '',
      testEnableFunction(editstate: boolean): void { },
      testFunction: (property: string, value: any) => { },
      editable: true,
      editedOrder: testEmptyOrderDetails(),
      editTypeValue: { type: editType.picklist },
      disableEdit: true,
    },
    {
      value: '',
      testEnableFunction(editstate: boolean): void { },
      testFunction: (property: string, value: any) => { },
      editable: true,
      editedOrder: testEmptyOrderDetails(),
      editTypeValue: { type: editType.switch },
      disableEdit: true,
    },
  ],
};
