import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { toast } from 'react-toastify';
import { selectUser } from 'oidc/user.selectors';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import ClearIcon from '@mui/icons-material/Clear';
import {
  INotificationFeedResponse,
  ITaskNotificationPopupProps,
  NotificationCategory,
  TaskEntity,
  TaskNotificationType,
} from 'app/models/Tasks/Tasks';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { SignalREventType } from 'app/enums/Common';
import { StyledToastContainer } from '../utils';
import { getEmployeeId, getNotificationType } from '../Common_v2/utils';
import { AlertFeed } from '../Common_v2/Feeds/AlertFeed';
import { selectDismissNotificationId } from '@AMIEWEB/Tasks/store/Tasks.selectors';
import VisibilityContext from '@AMIEWEB/VisblityChange/VisiblityContext';

const useStyles = makeStyles()(() => ({
  centerItem: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: '0 15px',
  },
  closeIcon: {
    display: 'block',
    height: '27px',
    width: '27px',
    background: '#FFFFFF',
    border: '1px solid #E2DFDF',
    borderRadius: '15px',
    padding: '3px',
    boxShadow: '0px 0px 6px #00000040',
    cursor: 'pointer',
    transition: '0.3s ease',
    position: 'absolute',
    left: '-12px',
    top: '-12px',
  },
}));

export const CueNotesNotifications = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });

  const user = useSelector(selectUser);
  const dismissedNotificationId = useSelector(selectDismissNotificationId);
  const userPreference = useSelector(selectUserPreference);
  const notificationPreference = userPreference?.notificationPreference?.value;
  const preference = React.useRef(notificationPreference);
  const { isVisible } = useContext(VisibilityContext);
  const isVisibleRef = React.useRef<boolean>(false);

  

  useEffect(() => {
    if (dismissedNotificationId) {
      toast.dismiss(dismissedNotificationId);
      dispatch(taskDetailsActions.setDismissNotificationId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedNotificationId]);

  let multiLine = false;
  let popUp = true;

  const closeNotification = (e, closeToast) => {
    closeToast();
    e.stopPropagation();
  };

  const CloseButton = ({ closeToast }) => {
    return <ClearIcon className={classes.closeIcon} onClick={e => closeNotification(e, closeToast)} />;
  };

  React.useEffect(() => {
    preference.current = notificationPreference;
  }, [notificationPreference]);

  useEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);


  useEffect(
    () => {
      const hubConnection = user?.userInfo?.hubConnection;
      if (hubConnection) {
        hubConnection.on('BroadcastCueNote', (connectedUser, eventName, log) => {
          trackEvent({
            type: 'event',
            name: `SignalR Hub`,
            properties: {
              NotificationType: SignalREventType.CueNote,
              UserId: connectedUser,
              CorrelationId: log?.correlationId,
              Log: log,
              Message: `Received cue notes notification message for user: ${connectedUser}`,
            },
          });
          const contentProps: ITaskNotificationPopupProps = {
            id: log?.id,
            name: log?.message?.tos.find(Boolean).name,
            taskId: log?.message?.tos.find(Boolean).contactId,
            body: log?.message?.body,
            associateRecords: log?.associatedRecords,
            attachments: log?.message?.attachmentUrls,
            useSubType: log?.useSubType,
          };
          //*Increment the unread count in redux store
          if (connectedUser === getEmployeeId(user?.userInfo)) {
            dispatch(
              taskDetailsActions.incrementNotificationCount({
                notificationType: getNotificationType({
                  useType: log?.useType,
                  useSubType: log?.useSubType,
                  channelType: log?.channel,
                }),
              }),
            );

            contentProps?.associateRecords?.forEach(record => {
              if (parseInt(contentProps?.useSubType) === TaskNotificationType?.CUENOTEREPLY) {
                if (record?.name === 'Description') {
                  if (record?.value?.length > 30) {
                    multiLine = true;
                  } else {
                    multiLine = false;
                  }
                }
              }
              if (record?.name === 'PrimaryContext') {
                dispatch(taskDetailsActions.setNotificationPrimaryContext(record?.value));
              }
              if (record?.name === 'CurrentContainer') {
                switch (record?.value) {
                  case 'Placement':
                    dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.PLACEMENTS));
                    break;
                  case 'Candidate':
                    dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.CANDIDATES));
                    break;
                  case 'Facility':
                    dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.FACILITIES));
                    break;
                  case 'Order':
                    dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.ORDERS));
                    break;
                  default:
                    dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.PLACEMENTS));
                    break;
                }
              }
            });

            if (parseInt(contentProps?.useSubType) === TaskNotificationType?.CUENOTEREPLY)
              popUp = preference.current.filter(val => val.name === 'Cue Note Replies')[0]?.enabled;
            if (parseInt(contentProps?.useSubType) === TaskNotificationType?.CUENOTE)
              popUp = preference.current.filter(val => val.name === 'Cued To Me')[0]?.enabled;

            const notification: INotificationFeedResponse = {
              contentProps: { ...contentProps, useType: SignalREventType.CueNote, isNotificationUnread: true },
              channel: NotificationCategory.Alert,
              multiLine: false,
            };
              popUp &&
              isVisibleRef.current &&
              toast(<AlertFeed key={0} index={0} notifyData={notification} pushNotification={true} />, {
                toastId: log.id,
                containerId: 'task',
                position: 'top-right',
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 5000,
              });
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Grid item className={classes.centerItem}></Grid>
      <div style={{ position: 'fixed' }}>
        {multiLine && (
          <StyledToastContainer enableMultiContainer containerId={'task'} limit={3} closeButton={CloseButton} />
        )}
      </div>
    </>
  );
};
