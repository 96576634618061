import React from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import OrderMatchesGrid from './OrderMatchesGrid';
import {
  candidateOrderMatchReducer,
  candidateOrderMatchSliceKey,
  IexpectedHours,
  IguaranteedHours,
  IBillRatePayload,
  INumberOfWeeks,
  IweeklyGrossPay,
} from '../store/candidateOrderMatch.redux';
import { candidateOrderMatchSaga } from '../store/candidateOrderMatch.saga';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { ILocations, ISkillSets } from 'app/models/Candidate/CandidateJobPreference';
import { gridSelectionActions } from 'app/components/Common/Grid/GridSelection/GridSelection.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectCandidateOrderMatchId } from '../store/candidateOrderMatch.selector';
import {
  getOrderMatchAlertActions,
  orderMatchAlertReducer,
  orderMatchAlertSliceKey,
} from '../AlertStore/OrderMatchAlert.redux';
import { orderMatchAlertSaga } from '../AlertStore/OrderMatchAlert.saga';
import { selectJobPreferencesData } from '../../JobPreferencesTab/store/JobPreferences.selectors';
import { selectUser } from 'oidc/user.selectors';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { ICandidateSkillSetSearch } from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
export interface OrderMatchesPayload {
  keyword?: string;
  pageNumber: number;
  pageSize: number;
  sortedColumn?: { column: string; direction: string } | undefined | null;
  skillSets?: ISkillSets[];
  orderSkillsetFilter: ICandidateSkillSetSearch;
  locations?: ILocations[];
  orderBillRate?: IBillRatePayload;
  placementLength?: INumberOfWeeks;
  shift?: number[];
  candidateAvailableStartDate?: string | null;
  education?: string[];
  specialtyIds?: any[] | [];
  disciplineIds?: any[] | [];
  states?: any[] | [];
  city: any[] | [];
  zipCode: any | null;
  billrate?: any;
  radius?: any;
  divisionIds?: any[] | [];
  businessRelationshipIds?: any[] | [];
  facilityStatusIds?: string[] | [];
  orderTypeIds?: string[] | [];
  subSpecialtyIds?: string[] | [];
  facilityIds?: string[] | [];
  mspClientIds?: string[] | [];
  affiliateIds?: string[] | [];
  dateEnteredRange?: Date;
  endDateRange?: Date;
  paysGww?: boolean;
  guaranteedHours?: IguaranteedHours;
  expectedHours?: IexpectedHours;
  reasonids?: string[] | [];
  amUserIds?: string[] | [];
  orderPriorityIds?: string[] | [];
  maxFilesSentId?: any;
  positions?: any;
  orderStartDateRange?: Date;
  shiftIds?: string[] | [];
  weeklyGrossPay?: IweeklyGrossPay;
  vaccinatedIds?: any;
  translation: TFunction;
  orderIds: any[];
  multiSortedColumns?: SortColumn[];
  region?: any[] | [];
  candidateId?:number;
  candidateBrandId?:number;
}

export const mappedPayload = data => {
  let mappedVal = {
    locations: data?.locations,
  };
  return mappedVal;
};

export const OrderMatches = props => {
  const { userInfo } = useSelector(selectUser);

  useInjectReducer({ key: candidateOrderMatchSliceKey, reducer: candidateOrderMatchReducer });
  useInjectSaga({ key: candidateOrderMatchSliceKey, saga: candidateOrderMatchSaga });

  useInjectReducer({ key: orderMatchAlertSliceKey, reducer: orderMatchAlertReducer });
  useInjectSaga({ key: orderMatchAlertSliceKey, saga: orderMatchAlertSaga });

  let prefId = useSelector(selectCandidateOrderMatchId);
  const { jobPreferencesData } = useSelector(selectJobPreferencesData);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const data = jobPreferencesData?.filter(item => item?.id === prefId)?.[0];
  const unreadCount = data?.alertsSent - data?.alertsSeen;
  const assignedRecruiter = candidateDetails.recruiter.recruiterId;
  const checkAssignedRecruiter = parseInt(getEmployeeId(userInfo)) == assignedRecruiter;
  const dispatch = useDispatch();

  const alertPayload = {
    associatedRecords: [
      {
        name: 'PreferenceId',
        value: prefId,
      },
    ],
    readStatus: false,
    pageSize: unreadCount,
    pageNumber: 1,
  };

  React.useEffect(() => {
    dispatch(
      gridSelectionActions.setGridSelections({
        selectedData: [],
        isCompareGridOpen: false,
      }),
    );

    //handleFetchData();
    if (checkAssignedRecruiter && unreadCount > 0) {
      dispatch(getOrderMatchAlertActions.getOrderMatchAlertAction(alertPayload));
    }
    return () => {
      // dispatch(getCandidateOrderMatchActions.reset());
      dispatch(globalActions.closeBanner());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <OrderMatchesGrid candidateId={props.candidateId} />;
};
