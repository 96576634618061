import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import XGridDeliveryStatusCell from './DeliveryStatusCell';

export const getDeliveryStatusModalColumns = (t, flexColumns): GridColumns => {
  const defaultDeliveryStatusColumn = {
    ...XGridDefaultColumn,
    // replace this to have flex column layout for default columns
    // flex: flexColumns ? 1 : 0
    flex: 0,
  };
  return [
    {
      ...defaultDeliveryStatusColumn,
      headerName: t('home.deliveryStatusModalColumns.recipientName'),
      field: 'name',
      width: 170,
    },
    {
      ...defaultDeliveryStatusColumn,
      headerName: t('home.deliveryStatusModalColumns.recipientType'),
      field: 'reciepientType',
      width: 150,
    },
    {
      ...defaultDeliveryStatusColumn,
      headerName: t('home.deliveryStatusModalColumns.email'),
      field: 'email',
      width: 330,
    },
    {
      ...defaultDeliveryStatusColumn,
      headerName: t('home.deliveryStatusModalColumns.deliveyStatus'),
      field: 'deliveryStatus',
      width: 158,
      renderCell: XGridDeliveryStatusCell('deliveryStatus'),
    },
    {
      ...defaultDeliveryStatusColumn,
      headerName: 'Error',
      field: 'error',
      width: 0,
      hide: true,
    },
  ];
};
