/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { Theme } from 'amn-ui-core';

export const useEmailStyles = makeStyles<{
  isExpanded?: boolean;
  showTemplate?: boolean;
}>()((theme: Theme, { isExpanded = false, showTemplate = false }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    paddingLeft: '2px',
    paddingBottom: '2%',
    paddingTop: '0.5%',
  },
  newToolbar: {
    paddingLeft: '2px',
    paddingTop: '0px',
    height: '60px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '1200px',
    outline: 'none',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px 6px #8888',
  },
  expandedPaper: {
    backgroundColor: theme.palette.background.paper,
    width: '100vw',
    height: '100vh',
    outline: 'none',
    borderRadius: '2px',
    boxShadow: '0px 0px 2px 6px #8888',
    display: 'flex',
    flexDirection: 'column',
    transform: 'none !important',
  },
  templateExpandedPaper: {
    backgroundColor: theme.palette.background.paper,
    width: '100vw',
    height: '100vh',
    outline: 'none',
    borderRadius: '2px',
    boxShadow: '0px 0px 2px 6px #8888',
    display: 'flex',
    flexDirection: 'column',
    transform: 'none !important',
  },
  bodyContainer: {
    padding: '0% 0% 0% 1%',
    marginTop: '12px',
  },
  expandedBodyContainer: {
    padding: '0% 0% 1% 0%',
    height: '80vh',
  },
  scroll: {
    marginBottom: '0.2%',
    height: '580px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  expandedScroll: {
    marginBottom: '1%',
    height: '72vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  container: {
    marginTop: '5px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9,
    background: 'transparent',
  },
  minimizedIcon: {
    cursor: 'pointer',
  },
  navigationSpace: {
    marginTop: '0.5%',
    height: '8vh',
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandedNavigationSpace: {
    marginTop: 'auto',
    height: '8vh',
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeSelection: {
    padding: 0,
    color: theme.palette.framework.system.white,
    backgroundColor: 'transparent',
    '&:hover': {
      padding: 0,
      color: theme.palette.framework.system.white,
      backgroundColor: 'transparent',
    },
  },
  closeDialog: {
    position: 'fixed',
    margin: '-5%',
  },
  expandedCloseDialog: {
    position: 'fixed',
    margin: '80vh 0 0 0',
  },
  emailSettings: {
    paddingLeft: isExpanded ? '0px !important' : '0px !important',
    paddingTop: isExpanded ? '12px !important' : '0px !important',
  },
  modalAppbar: {
    height: '55px !important',
    justifyContent: 'center !important',
    paddingBottom: '0% !important',
  },
  button: {
    minWidth: '10px',
    padding: '6px 0px'
  },
  modalTitle: {
    padding: showTemplate ? '0px' : ''
  },
  emailActionButton: {
    alignItems: 'end',
    justifyContent: 'right',
    display: 'flex'
  },
  closeSelectionPreview: {
    paddingRight: '8px',
    color: theme.palette.components.button.primary.color,
    backgroundColor: 'transparent',
  },
}));
