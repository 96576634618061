import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, call, takeLatest, put, cancelled } from 'redux-saga/effects';
import { IEditorPreference, userDevicePreferenceActions } from './userPreference.redux';
import { saveUserPreferences } from 'app/services/SharedServices/SharedServices';
import { trackPromise } from 'react-promise-tracker';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import axios from 'axios';
import { IUserPreferenceState } from 'app/models/Notification/Notification';
import { saveConfirmationEditorPreference, saveEditorWidthPreference } from './business.saga';

const TrackGridPreferenceSaver = (fn, ...args) => trackPromise(fn(...args), 'save-grid-preference');
const TrackFilterPreferenceSaver = (fn, ...args) => trackPromise(fn(...args), 'save-filter-preference');
const TrackEmailSignaturePreferenceSaver = (fn, ...args) =>
  trackPromise(fn(...args), 'save-email-signature-preference');
const TrackNotificationPreferenceSaver = (fn, ...args) => trackPromise(fn(...args), 'save-notificatoin-preference');

export function* saveUserGridPreferences(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield put(userDevicePreferenceActions.setGridPreferencesById(action.payload));
    yield call(TrackGridPreferenceSaver, saveUserPreferences, [action.payload], cancellationSource.token);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveUserGridPreferences',
        area: 'src/oidc/UserDevicePreference/userPreference.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveUserDevicePreference(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(TrackGridPreferenceSaver, saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setUserDevicePreferenceAction(action.payload));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveUserGridPreferences',
        area: 'src/oidc/UserDevicePreference/userPreference.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveEmailSignaturePreferences(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(TrackEmailSignaturePreferenceSaver, saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setEmailSignaturePreferences(action.payload));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveEmailSignaturePreferences',
        area: 'src/oidc/UserDevicePreference/userPreference.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveFilterPreferences(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(TrackFilterPreferenceSaver, saveUserPreferences, action.payload, cancellationSource.token);
    yield put(userDevicePreferenceActions.setUserGridPreferences(action.payload));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveFilterPreferences',
        area: 'src/oidc/UserDevicePreference/userPreference.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveGlobalSearchPreference(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setGlobalSearchDropDown(action.payload));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveGlobalSearchPreference',
        area: 'src/oidc/UserDevicePreference/userPreference.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* savePinnedMessagePreference(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setPinnedMessages(action.payload));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'savePinnedMessagePreference',
        area: 'src/oidc/UserDevicePreference/userPreference.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveNotificationPreference(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(TrackNotificationPreferenceSaver, saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setNotificationPreferences(action.payload));
  } catch (error) {
    console.error('ERROR', error);
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveRecentHistoryPreference(action: PayloadAction<any>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setRecentHistoryPreferences(action.payload));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveInboxFilters(action: PayloadAction<IUserPreferenceState<any[]>>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(TrackNotificationPreferenceSaver, saveUserPreferences, [action.payload], cancellationSource.token);
    yield put(userDevicePreferenceActions.setInboxFilterPreferences(action.payload));
  } catch (error) {
    console.error('ERROR', error);
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveEditorPreferences(
  action: PayloadAction<{ preference: IUserPreferenceState<IEditorPreference>[] }>,
) {
  const cancellationSource = axios.CancelToken.source();
  try {
    yield call(
      TrackNotificationPreferenceSaver,
      saveUserPreferences,
      action.payload.preference,
      cancellationSource.token,
    );
  } catch (exception) {
    trackException({
      exception,
      properties: {
        functionName: 'saveConfirmationEditorPreference',
        area: 'src/oidc/UserDevicePreference/business.saga',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* userDevicePreferenceSaga() {
  /** Business functions - start */
  yield takeLatest(
    userDevicePreferenceActions.business_saveConfirmationEditorPreference.type,
    saveConfirmationEditorPreference,
  );
  yield takeLatest(userDevicePreferenceActions.business_saveEditorWidthPreference.type, saveEditorWidthPreference);
  /** Business functions - end */
  yield takeEvery(userDevicePreferenceActions.saveEmailSignaturePreferenceAction.type, saveEmailSignaturePreferences);
  yield takeEvery(userDevicePreferenceActions.saveFilterPreferenceAction.type, saveFilterPreferences);
  yield takeEvery(userDevicePreferenceActions.saveGlobalSearchPreferenceAction.type, saveGlobalSearchPreference);
  yield takeEvery(userDevicePreferenceActions.savePinnedMessagePreferenceAction.type, savePinnedMessagePreference);
  yield takeLatest(userDevicePreferenceActions.saveUserGridPreferenceAction.type, saveUserGridPreferences);
  yield takeEvery(userDevicePreferenceActions.saveNotificationPreferenceAction.type, saveNotificationPreference);
  yield takeEvery(userDevicePreferenceActions.saveUserDevicePreferenceAction.type, saveUserDevicePreference);
  yield takeEvery(userDevicePreferenceActions.saveUserRecentHistoryPreferenceAction.type, saveRecentHistoryPreference);
  yield takeEvery(userDevicePreferenceActions.saveInboxFiltersAction.type, saveInboxFilters);
  yield takeEvery(userDevicePreferenceActions.saveEditorPreferences.type, saveEditorPreferences);
}
