import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { SendType } from 'app/components/Notification/Constants';
import { amClientContactsFilterName } from '../Filters/ClientContactFilterConfig';
import { UnitContact } from 'app/models/Unit/CreateUnit';
import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { IFacilityClientContactsList } from 'app/models/Facility/FacilityDetails';
import { IClientPhoneNumber } from 'app/models/ClientContact/ClientContact';

export interface IFilterChipSelected {
  [amClientContactsFilterName.ContactPreference]: any[] | null;
  [amClientContactsFilterName.Status]: any[] | null;
  [amClientContactsFilterName.Units]: any | null;
  [amClientContactsFilterName.Actions]: any[] | null;
  [amClientContactsFilterName.Name]: any[] | null;
  [amClientContactsFilterName.Title]: any[] | null;
}

export interface IFilterSpecs {
  contactPreferenceOpts: ISelection[];
  nameOpts: ISelection[];
  titleOpts: ISelection[];
  statusOpts: ISelection[];
  unitNameOpts: ISelection[];
  actionsOpts: ISelection[];
}

export interface IClientContactRequest {
  firstName: string;
  lastName: string;
  title: string;
  email?: string;
  phoneNumbers?: IClientPhoneNumber[];
  facilityId?: number;
  unitIds?: number[];
  phoneTypeID: number;
  extensionNotes: string;
  countryDialingCode: string;
  createdBy: number;
  createdDate: string;
  activeSSC: boolean;
  roleActionIds: number[];
  statusId: number;
  contactPreferenceTypeId: number;
  contactPreference: string;
  stateProvinceID?: string;
  state?: string;
  country?: string;
}

export const defaultFilterChipSelected = {
  [amClientContactsFilterName.ContactPreference]: null,
  [amClientContactsFilterName.Status]: null,
  [amClientContactsFilterName.Units]: null,
  [amClientContactsFilterName.Actions]: null,
  [amClientContactsFilterName.Name]: null,
  [amClientContactsFilterName.Title]: null,
} as IFilterChipSelected;

export interface IContactFilterChips {
  pipelineSelected: IChip[];
  [amClientContactsFilterName.ContactPreference]: IChip[];
  [amClientContactsFilterName.Status]: IChip[];
  [amClientContactsFilterName.Units]: IChip[];
  [amClientContactsFilterName.Actions]: IChip[];
  [amClientContactsFilterName.Name]: IChip[];
  [amClientContactsFilterName.Title]: IChip[];
}

export const defaultChips: IContactFilterChips = {
  pipelineSelected: [],
  [amClientContactsFilterName.ContactPreference]: [],
  [amClientContactsFilterName.Status]: [],
  [amClientContactsFilterName.Units]: [],
  [amClientContactsFilterName.Actions]: [],
  [amClientContactsFilterName.Name]: [],
  [amClientContactsFilterName.Title]: [],
};

export interface IFacilityContactPage {
  flattenedData: any[];
  filteredData?: any[];
  filterSpecs?: IFilterSpecs;
  filterChipSelected: IFilterChipSelected;
  chips: IContactFilterChips;
  state: any;
  loading: boolean;
  selectedContacts: any[];
  selectedEmailType: string;
  clientContractDrawer: IClientContractDrawer;
  UnitLookups?: ISelection[];
}

export const initialState: IFacilityContactPage = {
  flattenedData: [],
  filteredData: undefined,
  filterSpecs: undefined,
  filterChipSelected: defaultFilterChipSelected,
  chips: defaultChips,
  state: null,
  loading: false,
  selectedContacts: [],
  selectedEmailType: SendType.one_to_one,
  clientContractDrawer: {
    open: false,
    notes: '',
    status: StatusDropDownValues.ACTIVE,
    contactId: undefined,
    statusLastUpdated: '',
    name: '',
    role: '',
    nameAbbreviation: '',
  },
};

interface IClientContractDrawer {
  open: boolean;
  notes: string;
  status: string;
  contactId: number;
  statusLastUpdated?: string;
  name: string;
  role: string;
  nameAbbreviation: string;
}

//Actions
const requestFacilityClientContactsList = createAction<{
  id: number;
}>('GET_FACILITY_CONTACT_DATA');
const requestFacilityClientContactsListForUnitEdit = createAction<{
  id: number;
  unitContacts: UnitContact[];
}>('GET_FACILITY_CONTACT_DATA_FOR_UNIT_EDIT');
const updateStarIconForContactsGrid = createAction<{ contactId: number }>('UPDATE_STAR_ICON');
const updateStarIconForContactsGridForUnitEdit = createAction<{
  contactId: number;
  id: number;
  isNewUnitEdit: boolean;
}>('UPDATE_STAR_ICON_FOR_UNIT_EDIT');
const updateFacilityUnitNotesStatus = createAction<{ notes?: string; status?: string }>(
  'GET_FACILITY_UNIT_NOTES_STATUS',
);

export const FacilityClientContact = createSlice({
  name: 'facilityClientContacts',
  initialState: initialState,
  reducers: {
    setFlattenedData(state, action: PayloadAction<IFacilityClientContactsList[]>) {
      state.flattenedData = action.payload;
    },
    setStateID(state, action: PayloadAction<any>) {
      state.state = action.payload;
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilterChipSelected(state, action: PayloadAction<IFilterChipSelected>) {
      state.filterChipSelected = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IContactFilterChips>) {
      state.chips = action.payload;
    },
    setSelectedContacts(state, action: PayloadAction<any>) {
      state.selectedContacts = action.payload;
    },
    setSelectedEmailType(state, action: PayloadAction<string>) {
      state.selectedEmailType = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setFilteredData(state, action: PayloadAction<any[] | undefined>) {
      state.filteredData = action.payload;
      //state.counts = state.flattenedData.length;
    },
    reset() {
      return { ...initialState };
    },
    setFacilityClientContactDrawer(state, action: PayloadAction<IClientContractDrawer>) {
      state.clientContractDrawer = action.payload;
    },
    setFilterLookUps(state, action: PayloadAction<ISelection[]>) {
      state.UnitLookups = action.payload;
    },
  },
});

const { actions } = FacilityClientContact;

export const { reducer: FacilityContactsReducer, name: FacilityContactSliceKey } = FacilityClientContact;
export const FacilityContactsActions = {
  ...actions,
  requestFacilityClientContactsList,
  requestFacilityClientContactsListForUnitEdit,
  updateStarIconForContactsGridForUnitEdit,
  updateStarIconForContactsGrid,
  updateFacilityUnitNotesStatus,
};
