import React from 'react';
import { Autocomplete, Box, Checkbox, Chip, TextField } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTooltip } from '@AMIEWEB/Common';

interface IAutoCompleteOption {
  object: { value: string; name: string };
  label: string;
}

const useAutoCompleteStyles = makeStyles()(theme => ({
  autocompleteInput: {
    maxHeight: '159px',
    '& .MuiInputBase-input': {
      color: theme.palette.system.black,
    },
    '& input': {
      caretColor: theme.palette.system.black,
    },
    '& .MuiChip-root': {
      color: theme.palette.system.davyGray,
      fontWeight: '400',
    },
  },
  tooltipContent: {
    padding: '6px',
  },
  tooltipPlacementBottom: {
    width: 'fit-content !important',
  },
  disabledChip: {
    pointerEvents: 'none',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteRenderChips = ({
  label,
  options,
  id,
  name,
  disabledValue,
  optionsWithHoverEffect,
  disabledOptionsTooltipString = '',
  getOptionDisabled,
  handleDataChangeInParent = false,
  handleChange = () => {},
}: {
  label: string;
  options: IAutoCompleteOption[];
  id: string;
  name: string;
  disabledValue?: any;
  optionsWithHoverEffect?: string[];
  disabledOptionsTooltipString?: string;
  getOptionDisabled?: (option: IAutoCompleteOption) => boolean;
  handleDataChangeInParent?: boolean;
  handleChange?: (e: any) => void;
}) => {
  const { classes } = useAutoCompleteStyles();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, ...props }) => (
        <Autocomplete
          {...props}
          multiple
          id={id}
          fullWidth
          options={options}
          disableCloseOnSelect
          disableClearable={disabledValue?.length}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionLabel={option => option?.label}
          getOptionDisabled={getOptionDisabled}
          onChange={(e, data, reason) => {
            if (reason === 'removeOption' && e.type === 'keydown') {
              e.stopPropagation();
            } else {
              if (!handleDataChangeInParent) {
                onChange(data);
              }
              handleChange(data);
            }
          }}
          popupIcon={false ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          renderOption={(prop, option, { selected }) => (
            <li {...prop}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ marginRight: '1px' }} checked={selected} />
              {option?.label}
            </li>
          )}
          renderInput={params => (
            <TextField {...params} label={label} variant="filled" className={classes.autocompleteInput} />
          )}
          renderTags={(value, getTagProps) => (
            <Box sx={{ p: '6px 2px', overflowY: 'scroll', maxHeight: '110px' }}>
              {value?.map((option, index) => (
                <CustomTooltip
                  key={index}
                  disabled={!disabledValue?.includes(option.label)}
                  tooltipContent={disabledOptionsTooltipString}
                  tooltipPlacementBottom={classes.tooltipPlacementBottom}
                  classes={{
                    tooltip: classes.tooltipContent,
                  }}
                  isInlineContent={true}
                >
                  <span className={disabledValue?.includes(option.label) ? classes.disabledChip : ''}>
                    <Chip
                      {...getTagProps({ index })}
                      label={option.label}
                      variant="outlined"
                      deleteIcon={<CloseIcon />}
                      onDelete={
                        disabledValue?.includes(option.label)
                          ? undefined
                          : () => {
                              const newValue = [...value];
                              newValue.splice(index, 1);
                              if (!handleDataChangeInParent) {
                                onChange(newValue);
                              } else {
                                handleChange(newValue);
                              }
                            }
                      }
                    />
                  </span>
                </CustomTooltip>
              ))}
            </Box>
          )}
        />
      )}
    />
  );
};

export default AutoCompleteRenderChips;
