import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  selectIsCueNoteCreated,
  newCueNoteData,
  selectCueNoteId,
  selectCueNoteReplyParent,
} from 'app/components/Tasks/store/Tasks.selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

export const useResetCueNoteData = () => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const isCueNoteCreated = useSelector(selectIsCueNoteCreated);
  const cueNoteData = useSelector(newCueNoteData);
  const createdId = useSelector(selectCueNoteId);
  const cueNoteParent = useSelector(selectCueNoteReplyParent);

  const dispatch = useDispatch();
  useEffect(() => {
    if ((isCueNoteCreated && cueNoteData) || (createdId && cueNoteParent)) {
      dispatch(taskDetailsActions.resetCueNoteData());
    } else {
      dispatch(taskDetailsActions.setIsCueNoteCreated(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCueNoteCreated,createdId]);
};
