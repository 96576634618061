import { useDecision } from '@optimizely/react-sdk';
import {
  ff_placement_ui_candidatecontracts_sidemenu,
  ff_placement_ui_clientconfirmation_sidemenu,
  ff_placement_ui_templates_sidemenu,
  ff_pricing_ui_pricingworkdesk,
} from 'app/constants/FeatureFlags/Sidenav/Keys';
import React from 'react';

export const useFetchSubMenuList = menuItem => {
  const [pricingWorkDeskDecision] = useDecision(ff_pricing_ui_pricingworkdesk);
  const [templateDecision] = useDecision(ff_placement_ui_templates_sidemenu);
  const [clientConfirmationTemplateDecision] = useDecision(ff_placement_ui_clientconfirmation_sidemenu);
  const [candidateContractsTemplateDecision] = useDecision(ff_placement_ui_candidatecontracts_sidemenu);
  const subMenuItems = React.useMemo(() => {
    return menuItem.subMenu
      .filter(item => {
        if (item.key === 'pricingWorkDesk' && !pricingWorkDeskDecision.enabled) return false;
        if (
          item.key === 'templateEditor' &&
          (!templateDecision.enabled ||
            (!clientConfirmationTemplateDecision.enabled && !candidateContractsTemplateDecision.enabled))
        )
          return false;
        return true;
      })
      .map(item => {
        if (item.key === 'templateEditor' && templateDecision.enabled) {
          const filteredSubMenu = item.subMenu.filter(subMenuItem => {
            if (subMenuItem.key === 'templateEditor.clientConfirmation' && !clientConfirmationTemplateDecision.enabled)
              return false;
            if (subMenuItem.key === 'templateEditor.candidateContracts' && !candidateContractsTemplateDecision.enabled)
              return false;
            return true;
          });
          return { ...item, subMenu: filteredSubMenu };
        }
        return item;
      });
  }, [
    menuItem,
    pricingWorkDeskDecision,
    templateDecision,
    clientConfirmationTemplateDecision,
    candidateContractsTemplateDecision,
  ]);

  return subMenuItems;
};
