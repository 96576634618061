export const getUniqueEmails = (arr, consents = [], limits = []) => {
    const uniqueEmail = arr.reduce((acc, value) => {
        const index = acc.find(
            obj => obj?.name === value?.name && obj?.email?.toLowerCase() == value?.email?.toLowerCase(),
        );
        const consentw = consents?.find(item => item?.contactId == value?.contactId && item?.brandId == value?.brandId);
        const limitr = limits?.find(item => item?.contactId == value?.contactId && item?.brandId == value?.brandId);
        if (!index) {
            if (!!consentw) {
                acc.push({ ...value, hasConsentWithdrawn: true });
            } else if (!!limitr) {
                acc.push({ ...value, limitReached: true });
            } else {
                acc.push(value);
            }
        } else {
            if ((acc[index]?.hasConsentWithdrawn || acc[index]?.limitReached) && !consentw && !limitr) {
                acc[index] = value;
            }
        }
        return acc;
    }, []);
    return uniqueEmail;
};


export const MaxRecipientCount = 6;