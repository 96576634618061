import { useDecision } from '@optimizely/react-sdk';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { ICoveredUserInboxes } from 'app/models/UserCoverage/UserCoverage';
import { IUser } from 'oidc/user.redux';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { FILE_SIZE_EXCEEDED, INVALID_FILE_FORMAT } from './Helpers/MessagingHelper';

const acceptedTypes = { images: ['.png', '.jpeg', '.jpg'] };

export const useSmsDropzone = (attachments, setAttachments, setOutboundMessage) => {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedTypes,
    maxSize: 614400,
    noClick: true,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length !== 0) {
        fileRejections.forEach(f => {
          f.errors.forEach(e => {
            if (e.message === INVALID_FILE_FORMAT) {
              dispatch(
                globalActions.setSnackBar({
                  message: 'notification.sms.validation.validFileTypes',
                  severity: 'error',
                }),
              );
            } else if (e.message === FILE_SIZE_EXCEEDED) {
              dispatch(
                globalActions.setSnackBar({
                  message: 'notification.sms.validation.maxFileSize',
                  severity: 'error',
                }),
              );
            }
          });
        });
      }

      if (acceptedFiles.length !== 0) {
        if (acceptedFiles.length > 1 || attachments) {
          dispatch(
            globalActions.setSnackBar({
              message: 'notification.sms.validation.attachmentLimit',
              severity: 'error',
            }),
          );
        } else {
          const newAttachment = acceptedFiles[0];
          setAttachments(newAttachment);
          dispatch(
            notificationDataActions.setAttachments({
              url: URL.createObjectURL(acceptedFiles[0]),
              name: acceptedFiles[0].name,
              type: newAttachment.name.split('.')[1].toLowerCase(),
            }),
          );

          setOutboundMessage(prevState => ({
            ...prevState,
            attachment: newAttachment,
            message: { attachmentUrls: [newAttachment.name] },
          }));
        }
      }
    },
  });

  return { getRootProps, getInputProps, isDragActive };
};

export const useSmsFeatureFlags = (coveredInbox: ICoveredUserInboxes, user: IUser) => {
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);
  const [smsCoverageFlag] = useDecision(ff_notifications_coverage_sms);
  const isLoggedUserInActiveInbox = coveredInbox?.activeInbox.coveredUserId === String(user?.userInfo?.employeeId);
  const hasActiveInbox =
    (smsCoverageFlag?.enabled || sharedDeskFlag?.enabled) && !isLoggedUserInActiveInbox && !!coveredInbox?.activeInbox;
  const hasActiveSharedInbox =
    sharedDeskFlag?.enabled &&
    !!coveredInbox?.activeInbox &&
    coveredInbox?.activeInbox?.coveredUserId == user.userInfo?.sharedProfile?.employeeId;

  return { sharedDeskFlag, smsCoverageFlag, hasActiveInbox, hasActiveSharedInbox };
};
