import { Grid, Divider } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Controller, useFormContext } from 'react-hook-form';
import { useSkillHeaderStyles } from './Experience.styles';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { AndOrOperator } from 'app/enums/Common';
import { SimpleCheckbox } from 'app/components/Unit/Common/SimpleCheckbox';

export const SkillHeader = ({
  groupIndex,
  skillSetIndex,
  onDeleteSkillSet,
  operationValue,
  handleDuplicate,
  numberOfFilteredSpeciality,
  enableDuplicateSkillSet
}: {
  skillSetIndex: number;
  groupIndex: number;
  onDeleteSkillSet: () => void;
  operationValue: any;
  handleDuplicate: (skillSetId: number, isAnd: boolean) => void;
  numberOfFilteredSpeciality: number,
  enableDuplicateSkillSet: any
}) => {
  const { t } = useTranslation();
  const { classes } = useSkillHeaderStyles();
  const [hover, setHover] = React.useState(false);
  const { control, watch, getValues, setValue, register } = useFormContext();
  const isAnd = (operationValue === undefined || operationValue === null) ? true : `${operationValue}` === AndOrOperator.And;
  const isDisabled = !(
    (getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.discipline`) &&
      getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.specialty`)) ||
    (getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.discipline`) &&
      numberOfFilteredSpeciality === 0)
  );

  const onMouseOver = () => {
    setHover(!hover);
  };

  const isPrimary = getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].isPrimary`);
  const changeValue = () => {
    setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].isPrimary`, !isPrimary);
    if (!isPrimary) {
      const skillsets = watch(`skillSetGroup.groups[${groupIndex}].skillsets`);
      skillsets.forEach((value, index) => {
        if (index != skillSetIndex) {
          setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${index}].isPrimary`, false);
        }
      });
    }
  };

  const renderSection = () => {
    return (
      <SimpleCheckbox
        isChecked={isPrimary || false}
        handleChecked={() => {
          changeValue();
        }}
        checkboxLabel={t('facility.units.unitDetails.unitRequirements.primarySkillSet')}
        checkboxName={'primarySkillSetCaps'}
        id={'setPrimarySkillSet'}
      />
    );
  };

  return (
    <Grid container sx={{ justifyContent: 'space-between' }}>
      <Grid item sx={{ fontWeight: '500', fontSize: '16px !important' }}>
        {t('facility.units.unitDetails.unitRequirements.skillSet', { skillCount: skillSetIndex + 1 })}
      </Grid>
      <Grid item display="flex" alignItems="center">
        <Controller
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].isPrimary`}
          control={control}
          defaultValue={isPrimary}
          render={({ onChange, ...rest }) => (
            <Grid item onMouseEnter={onMouseOver} onMouseLeave={onMouseOver} display="flex" className={classes.font}>
              {renderSection()}
            </Grid>
          )}
        />
        {skillSetIndex > 0 && !isDisabled && enableDuplicateSkillSet && enableDuplicateSkillSet.enabled && (
          <>
            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            <Grid
              item
              onClick={() => {
                if (operationValue === undefined || operationValue === null) {
                  register(`skillSetGroup.groups[${groupIndex}].operation`, null);                  
                  setValue(`skillSetGroup.groups[${groupIndex}].operation`, AndOrOperator.And);
                }
                handleDuplicate(skillSetIndex, isAnd);
              }}
            >
              <CustomTooltip tooltipContent={t('facility.units.unitDetails.unitRequirements.duplicateSkillSet')}>
                <div className={classes.copySection}>
                  <ContentCopyRoundedIcon />
                </div>
              </CustomTooltip>
            </Grid>
          </>
        )}
        {!isPrimary && skillSetIndex > 0 && (
          <Grid item onClick={onDeleteSkillSet}>
            <CustomTooltip tooltipContent={t('facility.units.unitDetails.unitRequirements.deleteSkillSet')}>
              <div className={classes.deleteSection}>
                <DeleteOutlinedIcon />
              </div>
            </CustomTooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
