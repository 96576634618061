/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newPlacementActions } from '../../../../../store/redux-store/new-placement/slice';
import { useFormContext, useWatch } from 'react-hook-form';
import { ICustomOption } from '../CustomComponents/CustomTypeAhead';
import { ITypeAheadOption } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { selectCreatePlacementOrderType } from '../../../../../store/redux-store/new-placement/selectors';

export const useValidations = () => {
  const { control } = useFormContext();
  /** useWatch should be used instead of useSelector - to fire service call instantly*/
  const selectedCandidate = useWatch({ name: 'candidate', control }) as ICustomOption;
  const selectedOrder = useWatch({ name: 'order', control }) as ICustomOption;
  const orderType = useSelector(selectCreatePlacementOrderType);
  const recruiterId = useWatch({ name: 'recruiter', control }) as ITypeAheadOption;
  const availabilityDate = useWatch({ name: 'availabilityDate', control }) as string;
  const skillset = useWatch({ name: 'skillset', control }) as ICustomOption;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (selectedCandidate?.object && selectedOrder?.object && orderType) {
      dispatch(
        newPlacementActions.getValidationsAction({
          orderId: selectedOrder.object.orderId,
          facilityId: selectedOrder.object.facilityId,
          candidateId: selectedCandidate.object.candidateId,
          brandId: selectedCandidate.object.brandId,
          recruiterId: parseInt(recruiterId?.object?.value || '0'),
          availabilityDate,
          disciplineId: skillset?.object?.disciplineId,
          specialtyId: skillset?.object?.specialtyId,
          subSpecialtyId: skillset?.object?.subSpecialtyId,
        }),
      );
    } else dispatch(newPlacementActions.setValidation(null));
    /** Stringification of dependancy avoid the reference issue of useWatch in heap m/y */
  }, [
    JSON.stringify(selectedCandidate),
    JSON.stringify(selectedOrder),
    JSON.stringify(recruiterId),
    JSON.stringify(availabilityDate),
    JSON.stringify(skillset),
    orderType,
  ]);
};
