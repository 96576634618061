import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { unitPreferenceActions } from './slice';
import { IActiveCountRequest, ICopyPreferencesRequest, IUnitOrderPreference, IUnitPreferenceRequest, IUpdateUnitPreferences } from './types';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { OrderService } from 'app/services/OrderServices/order-service';
import { IOrderPreferenceRequest } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { getReadyToSubmitPlacementIds } from 'app/services/PlacementServices/PlacementServices';
import { placementSubStatuses } from '@AMIEWEB/RecruiterWorkDesk/MyPlacementsDesk/utils';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { AMNDivisionType, ActiveStatusType } from 'app/models/enums/AMNDivisionType';
import { PlacementService } from 'app/services/PlacementServices/placement-service';

const TrackActivePlacementCounts = (fn, ...args) =>
  trackPromise(fn(...args), 'unit-preference-active-placement-counts');

const TrackSendToFaciltyUnitClientContacts = (fn, ...args) =>
  trackPromise(fn(...args), 'send-to-facility-unit-client-contacts');

const TrackUpdateUnitPreferences = (fn, ...args) => trackPromise(fn(...args), 'update-unit-preferences');

const TrackUnitPreferencesWrapper = (fn, ...args) =>
  trackPromise(
    fn(...args),
    !!args[args.length - 1] ? `get-unit-preferences-${args[args.length - 1]}` : 'get-unit-preferences',
  );

const TrackGetExistingOrdersWrapper = (fn, ...args) =>
  trackPromise(fn(...args), `get-existing-orders--${args[args.length - 1]}`);

const TrackUpdateUnitPreferencesToOrders = (fn, ...args) =>
  trackPromise(fn(...args), 'update-unit-preferences-to-orders');

const TrackRequestExistingPlacements = (fn, ...args) =>
  trackPromise(fn(...args), 'request-existing-unit-placement');

const TrackRequestOrderActivePlacementCounts = (fn, ...args) =>
  trackPromise(fn(...args), 'request-order-placement-count');

const TrackRequestReadyToSubmitRfoPIds = (fn, ...args) =>
  trackPromise(fn(...args), 'unit-order-preference-get-ready-to-submit-rfo-pids');

export function* requestActivePlacementCounts(action: PayloadAction<IUnitPreferenceRequest>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackActivePlacementCounts,
      facilityService.getUnitActivePlacementCounts,
      action.payload.unitId,
    );
    if (httpSuccess(response.status)) {
      yield put(unitPreferenceActions.setActivePlacementCounts({ ...response.data }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestActivePlacementCounts',
        area: 'unit-preference/saga',
      },
    });
  }
}

export function* requestClientContacts(action: PayloadAction<{ facilityId: number; unitId: number }>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackSendToFaciltyUnitClientContacts,
      facilityService.getFacilityUnitContacts,
      action.payload.facilityId,
      action.payload.unitId,
    );
    if (httpSuccess(response.status)) {
      const facilityUnitclientContacts = response?.data?.filter(item =>
        item?.actions?.some((ac: { description: string }) => ac.description === 'Send file to'),
      );
      yield put(unitPreferenceActions.setFacilityUnitClientContacts([...facilityUnitclientContacts]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestClientContacts',
        area: 'unit-preference/saga',
      },
    });
  }
}

export function* saveUnitPreferences(
  action: PayloadAction<{
    data: IUpdateUnitPreferences;
    isCopyingPreferences: boolean;
  }>,
) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(TrackUpdateUnitPreferences, facilityService.updateUnitPreferences, action.payload.data);
    if (httpSuccess(response.status)) {
      yield call(requestUnitPreferences, {
        payload: { unitId: action.payload.data.unitId },
        type: unitPreferenceActions.getUnitPreferences.type,
      });
      if (action.payload.isCopyingPreferences) {
        yield put(unitPreferenceActions.setPreferenceUpdateDialog(true));
      }
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Preferences were saved successfully.',
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Preferences. Please try again.',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveUnitPreferences',
        area: 'unit-preference/saga',
      },
    });
  }
}

export function* requestUnitPreferences(
  action: PayloadAction<{
    unitId: number;
  }>,
) {
  try {
    if (!isNullOrUndefined(action.payload.unitId)) {
      const facilityService: FacilityService = yield getContext('facilityService');
      const response = yield call(
        TrackUnitPreferencesWrapper,
        facilityService.getUnitPreferences,
        action.payload.unitId,
      );
      if (httpSuccess(response.status)) {
        yield put(unitPreferenceActions.setPreferences({ ...response.data }));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestUnitPreferences',
        area: 'unit-preference/saga',
      },
    });
  }
}

export function* requestExistingOrders(
  action: PayloadAction<{
    unitId: number;
    facilityId: number;
  }>,
) {
  try {
    if (!isNullOrUndefined(action.payload.unitId) && !isNullOrUndefined(action.payload.facilityId)) {
      const orderService: OrderService = yield getContext('orderService');
      const response = yield call(
        TrackGetExistingOrdersWrapper,
        orderService.getExistingOrdersForUnit,
        action.payload.unitId,
        action.payload.facilityId,
      );
      if (httpSuccess(response.status)) {
        yield put(unitPreferenceActions.setExsistingOrders([...response.data]));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestExistingOrders',
        area: 'unit-preference/saga',
      },
    });
  }
}

function* updateUnitPreferencesToOrders(
  action: PayloadAction<{
    data: ICopyPreferencesRequest;
    facilityId: number;
  }>,
) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(
      TrackUpdateUnitPreferencesToOrders,
      orderService.updatePreferencesToExistingOrders,
      action.payload.data,
    );
    if (httpSuccess(response.status)) {
      yield call(requestExistingOrders, {
        payload: { unitId: action.payload.data.unitId, facilityId: action.payload.facilityId },
        type: unitPreferenceActions.getExistingOrders.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Preferences successfully saved to selected Orders.',
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Preferences to selected Orders. Please try again.',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateUnitPreferencesToOrders',
        area: 'unit-preference/saga',
      },
    });
  }
}

export function* requestExistingPlacements(
  action: PayloadAction<{
    data: IUnitOrderPreference
  }>,
) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    const { facilityResponse } = yield select(selectFacility);
    const isStrike = facilityResponse?.amnDivisionTypeId === AMNDivisionType.hsg;
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(true));

    const response = yield call(
      TrackRequestExistingPlacements,
      orderService.getPlacementsForAutomationOfOrders,
      action.payload.data.orderIds,
      isStrike ? ActiveStatusType.WFC : ActiveStatusType.RFO,
    );
    if (httpSuccess(response.status)) {
      yield put(unitPreferenceActions.setOrderAutomationPlacements([...response.data]));
      yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false))
    } else yield put(globalActions.setSnackBar({ message: 'Failed to load placements', severity: 'error' }));
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestExistingPlacements',
        area: 'unit-preference/saga',
      },
    });
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));
  }
}
export function* requestOrderActivePlacementCounts(
  action: PayloadAction<{
    data: IActiveCountRequest
  }>,
) {
  try {
    const orderService: OrderService = yield getContext('orderService');

    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(true));
    const response = yield call(
      TrackRequestOrderActivePlacementCounts,
      orderService.getActiveUnitPlacementCounts,
      action.payload.data.orderIds
    );
    if (httpSuccess(response.status)) {
      yield put(unitPreferenceActions.setOrderActivePlacementCounts({ ...response.data }));
      yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));
    }
    else yield put(globalActions.setSnackBar({ message: 'Failed to load active count', severity: 'error' }));
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderActivePlacementCounts',
        area: 'unit-preference/saga',
      },
    });
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));
  }
}

export function* requestReadyToSubmitRfoPlacementIds(
  action: PayloadAction<{
    data: IUnitOrderPreference
  }>,) {
  try {
    const { facilityResponse } = yield select(selectFacility);
    const isStrike = facilityResponse?.amnDivisionTypeId === AMNDivisionType.hsg;
    const placementService: PlacementService = yield getContext('placementService');

    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(true));

    const response = yield call(
      TrackRequestReadyToSubmitRfoPIds,
      placementService.getReadyToSubmitUnitPlacementIds,
      action.payload.data.orderIds,
      isStrike ? ActiveStatusType.WFC : ActiveStatusType.RFO,
    );
    if (httpSuccess(response.status)) {
      yield put(unitPreferenceActions.setReadyToSubmitRfoPlacementIds([...response.data]));
      yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));
    }
    else yield put(globalActions.setSnackBar({ message: 'Failed to validate placements', severity: 'error' }));
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestReadyToSubmitRfoPlacementIds',
        area: 'unit-preference/saga',
      },
    });
    yield put(unitPreferenceActions.setUpdateAutomationApiProgressStatus(false));
  }
};

export function* unitPreferenceSaga() {
  yield takeLatest(unitPreferenceActions.getActivePlacementCounts.type, requestActivePlacementCounts);
  yield takeLatest(unitPreferenceActions.getFacilityUnitClientContacts.type, requestClientContacts);
  yield takeLatest(unitPreferenceActions.updateUnitPreferences.type, saveUnitPreferences);
  yield takeLatest(unitPreferenceActions.getUnitPreferences.type, requestUnitPreferences);
  yield takeLatest(unitPreferenceActions.getExistingOrders.type, requestExistingOrders);
  yield takeLatest(unitPreferenceActions.updatePrefrencesToOrders.type, updateUnitPreferencesToOrders);
  yield takeLatest(unitPreferenceActions.requestExistingPlacements.type, requestExistingPlacements);
  yield takeLatest(unitPreferenceActions.requestOrderActivePlacementCounts.type, requestOrderActivePlacementCounts);
  yield takeLatest(unitPreferenceActions.requestReadyToSubmitRfoPlacementIds.type, requestReadyToSubmitRfoPlacementIds);
}
