import { editOrder, IOrderFreeText, SubmitOrder, updateLastVerifiedData } from 'app/models/Orders/OrderDetails';
import baseAxiosInstance from 'utils/BaseApi';
import { IOrderAutomationRequest } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { IAutomationStatusByStage, IDistributionContact } from 'app/models/Orders/OrderPreference';
import { placementSubStatuses } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/utils';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPostOrderUnitDetail } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Grid/FacilityOrderUnitGrid';

const orderBase = globalThis?.app?.env?.REACT_APP_ORDERS_SERVICE_BASE_URL;

export async function getOrders(searchString: string, pageNumber = 1) {
  try {
    const url = `${orderBase}/order/search`;
    const response = await baseAxiosInstance.post(url, { keyword: searchString, pageNumber });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrders',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function getOpenOrdersCountForFacility(facilityId: number) {
  try {
    const url = `${orderBase}/order/facility/${facilityId}/open-order-count`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOpenOrdersCountForFacility',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function getOrderDetailsWithPlacements(id: string) {
  const getOrderDetails = (id: string) => {
    const url = `${orderBase}/order/${id}`;
    return baseAxiosInstance.get(url).catch(Error => {
      trackException({
        exception: Error,
        properties: {
          name: ExceptionType.APIResponseError,
          functionName: 'getOrderDetailsWithPlacements',
          area: 'src/app/services/OrderServices/OrderServices.ts',
        },
      });
    });
  };
  const [orderDetails, candidatePreferenceMatch] = await Promise.all([
    getOrderDetails(id),
    getCandidatePreferenceMatch(id),
  ]);
  return {
    orderDetails: orderDetails && orderDetails.data,
    candidatePreferenceMatch: candidatePreferenceMatch && candidatePreferenceMatch.data,
  };
}

export async function createOrder(order: SubmitOrder) {
  const url = `${orderBase}/order/save`;
  const response = await baseAxiosInstance.post(url, order);
  return response;
}

export async function getCandidatePreferenceMatch(id: string) {
  const url = `${orderBase}/order/candidates/preferences/order/${id}`;
  return baseAxiosInstance.get(url).catch(Error => {
    trackException({
      exception: Error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidatePreferenceMatch',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  });
}

export async function updateLastVerified(lastVerifiedValues: updateLastVerifiedData) {
  const url = `${orderBase}/order/update/last-verified`;
  const response = await baseAxiosInstance.post(url, lastVerifiedValues);
  return response.status;
}

export async function updateOrder(order: editOrder, id: string) {
  try {
    const url = `${orderBase}/order/${id}/update`;
    const response = await baseAxiosInstance.post(url, order);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateOrder',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
    return error?.response;
  }
}

export async function updateOrderDetails(order: any, id: string) {
  try {
    const url = `${orderBase}/order/${id}/update`;
    const response = await baseAxiosInstance.post(url, order);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateOrderDetails',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
    return error?.response;
  }
}

export async function updateOrderFreeText(order: IOrderFreeText) {
  const url = `${orderBase}/order/update/freetext-details`;
  const response = await baseAxiosInstance.post(url, order);
  return response;
}

export async function bulkVerifyOrderService(order: any) {
  const url = `${orderBase}/order/verify`;
  const response = await baseAxiosInstance.post(url, order);
  return response;
}

export async function getOrderDetailsById(id: string) {
  const url = `${orderBase}/order/${id}`;
  const response = await baseAxiosInstance.get(url);
  return response?.data;
}

export async function getOrderReportDetailsById(id: string) {
  try {
    const url = `${orderBase}/order/${id}/report`;
    const response = await baseAxiosInstance.get(url);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderReportDetailsById',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
    return { status: error.response.status };
  }
}

export async function getOrderOptions(candidateQuery: string) {
  try {
    const url = `${orderBase}/order/lookups/${candidateQuery}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderOptions',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
    return { status: error.response.status };
  }
}

export async function getOrderForPlacementCreation(orderId: number) {
  try {
    const url = `${orderBase}/order/${orderId}/placement-modal`;
    const response = await baseAxiosInstance.get(url);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderForPlacementCreation',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

/** Order Automation - Start*/

export async function getActivePlacementCounts(orderId: number) {
  try {
    const url = `${orderBase}/order/${orderId}/active-placement-counts`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getActivePlacementCounts',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function getOrderAutomationStatus({ orderId }: IOrderAutomationRequest) {
  try {
    const url = `${orderBase}/order/${orderId}/placement-automation`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAutomationStatusByStage',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function updateAutomationStatusByStage(body: {
  orderId: number;
  submission?: IAutomationStatusByStage;
  interview?: IAutomationStatusByStage;
  offer?: IAutomationStatusByStage;
  booking?: IAutomationStatusByStage;
  assignment?: IAutomationStatusByStage;
}) {
  try {
    const url = `${orderBase}/order/placement-automation/set-automation-status`;
    const response = await baseAxiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateAutomationStatusByStage',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function checkAutomationEditStatusByStage({ orderId, placementStage }: IOrderAutomationRequest) {
  try {
    const url = `${orderBase}/order/placement-automation/${orderId}/check-edit-status${
      placementStage ? `?stage=${placementStage}` : ''
    }`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'checkAutomationEditStatusByStage',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function postDistributionList(body: { orderId: number; id: any; contacts: IDistributionContact[] }) {
  try {
    const url = `${orderBase}/order/placement-automation/upsert-automation-contacts`;
    return await baseAxiosInstance.post(url, body);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postDistributionList',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function getOrderAutomationPlacementsByStatus(orderId: number, activityStatus: placementSubStatuses) {
  try {
    const url = `${orderBase}/order/placement-automation/${orderId}/order-automation-placements?activityStatus=${activityStatus}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderAutomationPlacementsByStatus',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function getOrderStrikeData(payload) {
  try {
    const url = `${orderBase}/order/strike`;
    const response = await baseAxiosInstance.post(url, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderStrikeData',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function updateStrikeOrder(payload) {
  try {
    const url = `${orderBase}/order/upsert-strike-order`;
    const response = await baseAxiosInstance.post(url, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateStrikeOrder',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

/** Order Automation - End*/

export async function getBillRates(payload) {
  try {
    const url = `${orderBase}/order/facility/bill-rate`;
    const response = await baseAxiosInstance.post(url, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getBillRates',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

export async function updateOrderDates(payload) {
  try {
    const url = `${orderBase}/order/update-dates`;
    const response = await baseAxiosInstance.post(url, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateOrderDates',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}

/**
 * Method to get orders for unit facility
 * @param unitId
 */
export async function getOrderDataForUnitsInFacility(payload: IPostOrderUnitDetail) {
  try {
    const url = `${orderBase}/order/unit/orders`;
    const response = await baseAxiosInstance.post(url, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderDataForUnitsInFacility',
        area: 'src/app/services/OrderServices/OrderServices.ts',
      },
    });
  }
}
