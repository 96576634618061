import { IDirection } from '@AMIEWEB/Notification/Constants';
import { IDisposition, ISaveCallLogPayload, UserDeviceProps } from 'app/models/Notification/Notification';
import { IUserPreference } from 'oidc/UserDevicePreference/userPreference.redux';

export const formatVoiceData = (data: ISaveCallLogPayload) => {
  if (data?.CTIActivityHistoryId === '') {
    data.CTIActivityHistoryId = undefined;
  }

  if (data?.tos !== undefined && Array.isArray(data?.tos)) {
    const newTos = data?.tos?.map(item => {
      return {
        ...item,
        brandId: !!item?.brandId && typeof item?.brandId !== 'string' ? item?.brandId?.toString() : item?.brandId,
        contactId:
          !!item?.contactId && typeof item?.contactId !== 'string' ? item?.contactId?.toString() : item?.contactId,
      };
    });
    data = { ...data, tos: newTos };
  }
  if (data?.associatedRecords) {
    data.associatedRecords = data?.associatedRecords
      .filter(
        record =>
          record?.name !== undefined && record?.name !== '' && record?.value !== undefined && record?.value !== '',
      )
      .map(record => ({
        name: typeof record?.name === 'string' ? record?.name : String(record?.name),
        value: typeof record?.value === 'string' ? record?.value : String(record?.value),
      }));
  }
  if (!!data?.sender?.userId && typeof data?.sender?.userId !== 'string') {
    data.sender = { ...data?.sender, userId: String(data?.sender.senderId) };
  }

  if (!!data?.sender?.senderId && typeof data?.sender?.senderId !== 'string') {
    data.sender = { ...data?.sender, senderId: String(data?.sender.senderId) };
  }

  return data;
};

export function formatLogPhoneNumber(phoneNumberString?: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
}

export const getAvatar = (data: any) => {
  return data?.tos?.find(Boolean)?.name
    ? data.tos
        .find(Boolean)
        ?.name?.split(' ')
        .map(n => n[0])
        .join('')
        .replace(/[^a-zA-Z]/g, '')
        .substring(0, 2)
    : 'UNK';
};

export const getDefaultDisposition = (data: any) => {
  return {
    ...data,
    date: data?.date ?? new Date(),
    time: data?.time ?? new Date(),
    direction: data?.direction ?? IDirection.Outbound,
    phoneNumber: data?.tos?.[0].phoneNumber ?? '',
    sender: data?.sender,
    notes: data?.notes ?? '',
    disposition: data?.disposition ?? null,
    initiatedOn: new Date(),
  } as IDisposition;
};

export const closeModalData = {
  log: {
    data: null,
    open: false,
    minimized: false,
  },
};

export const voiceCloseModalData = {
  voice: {
    data: null,
    open: false,
    minimized: false,
    showDisposition: false,
  },
};

export enum RecordName {
  facility = 'facilityName',
  unit = 'unitName',
  placement = 'placementId',
}

export const getInitialDeviceLine = (userDevicePreference: IUserPreference) => {
  const initialValue: UserDeviceProps = {
    selectedDevice: userDevicePreference?.existing?.device || '',
    deviceList: [],
    selectedLine: userDevicePreference?.existing?.line || '',
    deviceLine: [],
    isDefaultDeviceAndLine: false,
  };
  return initialValue;
};

export const getDispositionPayload = (data: any) => {
  return {
    ...data,
    CTIActivityHistoryId: '',
    id: '',
    date: new Date(),
    time: new Date(),
    direction: IDirection.Outbound,
    user: '',
    phoneNumber: '',
    sender: data?.sender,
    notes: data?.notes ?? '',
    disposition: data?.disposition ?? null,
    initiatedOn: new Date(),
  };
};
