import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Chip, ClickAwayListener, Grid, IconButton, InputAdornment, TextField, Tooltip } from 'amn-ui-core';
import EmojiIcon from 'app/assets/images/Notification/SMS/Images/emoji.svg';
import SendIcon from 'app/assets/images/Notification/SMS/Images/Send.svg';
import SendIconDisabled from 'app/assets/images/Notification/SMS/Images/SendDisabled.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAttachmentIcon } from '../../Common/AttachmentFileTypes';
import { ChannelType } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { EMOJI_LIST } from './Helpers/EmojiList';
import { CandidateAvatar } from './CandidateAvatar';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { attachmentDetails, notificationSelection } from 'store/redux-store/notification/notification.selector';
import { useMessagingStyles } from './Messaging.styles';
import { selectUser } from 'oidc/user.selectors';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { useSmsFeatureFlags } from './useSmsDropzone';
import { MAX_FILE_SIZE, validFileFormats } from './Helpers/MessagingHelper';

interface ISmsInputFieldProps {
  attachments: File;
  setAttachments: (file: File) => void;
  getInputProps: any;
  phoneNumber: string;
  outboundMessage: any;
  setOutboundMessage: any;
  handleSend: () => void;
  scrollToBottom: () => void;
}

export const SmsInputField = ({
  attachments,
  setAttachments,
  getInputProps,
  phoneNumber,
  outboundMessage,
  setOutboundMessage,
  handleSend,
  scrollToBottom,
}: ISmsInputFieldProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputRef = useRef<any>(null);

  const { classes } = useMessagingStyles({ unknownNum: false, warningMessage: false, enableBrandSelection: false });

  const user = useSelector(selectUser);
  const coveredInbox = useSelector(selectCoveredInbox);
  const globalData = useSelector(notificationSelection);
  const attachment = useSelector(attachmentDetails);

  const { hasActiveInbox, hasActiveSharedInbox } = useSmsFeatureFlags(coveredInbox, user);

  const manualEntry = globalData?.sms?.manualEntry;

  const [textfieldCursorPosition, setTextfieldCursorPosition] = React.useState<any>(0);
  const [isEmojiListOpen, setEmojiListOpen] = useState<boolean>(false);

  const handleSmsAttachment = (event, attachmentFile?) => {
    if (event.target.id === 'sms-btn-upload' || attachmentFile) {
      const newAttachment = attachmentFile ?? event.target.files[0];
      if (attachment && attachmentFile) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.maxFilesSent',
            severity: 'error',
          }),
        );
        return;
      }
      if (!validFileFormats.exec(newAttachment.name.toLowerCase())) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.validFileTypes',
            severity: 'error',
          }),
        );
      } else if (newAttachment.size / 1024 > MAX_FILE_SIZE) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.maxFileSize',
            severity: 'error',
          }),
        );
      } else {
        setAttachments(newAttachment);
        dispatch(
          notificationDataActions.setAttachments({
            url: URL.createObjectURL(newAttachment),
            name: newAttachment.name,
            type: newAttachment!.name!.split('.')[1].toLowerCase(),
          }),
        );
        setOutboundMessage(prevState => ({
          ...prevState,
          attachment: newAttachment,
          message: { attachmentUrls: [newAttachment.name] },
        }));
      }
    }
  };
  const updateSelectionStart = () => {
    setTextfieldCursorPosition(inputRef.current.selectionStart);
  };

  const handleDeleteAttachment = () => {
    setAttachments(undefined);
    dispatch(notificationDataActions.setAttachments(undefined));
    setOutboundMessage(prevState => ({
      ...prevState,
      message: { attachmentUrls: [] },
    }));
  };

  const sendEnabled = () => {
    const enabled = outboundMessage?.body?.length > 0 || attachments !== undefined;
    if (!manualEntry) {
      return enabled;
    } else {
      return phoneNumber.length === 14 && enabled;
    }
  };

  const handleEmojiOnClick = val => {
    setTextfieldCursorPosition(textfieldCursorPosition + val.value.length);
    const body =
      outboundMessage?.body && outboundMessage.body.length !== textfieldCursorPosition
        ? outboundMessage?.body.substring(0, textfieldCursorPosition) +
          val.value +
          outboundMessage?.body.substring(textfieldCursorPosition)
        : outboundMessage?.body && outboundMessage?.body.length === textfieldCursorPosition
        ? outboundMessage?.body + val.value
        : val.value;
    setOutboundMessage({
      ...outboundMessage,
      body: body,
    });

    dispatch(
      notificationDataActions.setSmsData({
        ...globalData.sms,
        data: { ...outboundMessage, body: body },
      }),
    );
    inputRef.current.selectionStart = textfieldCursorPosition + val.value.length;
    inputRef.current.selectionEnd = textfieldCursorPosition + val.value.length;

    setEmojiListOpen(false);
    inputRef.current.focus();
  };

  useEffect(
    () => {
      if (isEmojiListOpen) {
        inputRef.current && inputRef.current.focus();
        inputRef.current && inputRef.current.setSelectionRange(textfieldCursorPosition, textfieldCursorPosition);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textfieldCursorPosition],
  );

  const handleSnippetPaste = event => {
    handleSmsAttachment(event, event.clipboardData.files[0]);
  };

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-start" className={classes.messageBar}>
      <Grid item xs={1} className={classes.attachFileIcon}>
        <Tooltip arrow title={attachments !== undefined ? t('notification.sms.attachmentLimitMessage') : ''}>
          <label htmlFor="sms-btn-upload" className={classes.attachmentLabel}>
            <input
              disabled={attachments !== undefined}
              value=""
              style={{ display: 'none' }}
              id="sms-btn-upload"
              name="sms-btn-upload"
              type="file"
              onChange={event => handleSmsAttachment(event)}
            />
            <input {...getInputProps()} type="file" value="" />
            <IconButton
              color="primary"
              disabled={attachments !== undefined}
              aria-label="upload picture"
              component="span"
              size="small"
              sx={{
                height: 45,
                width: 55,
              }}
            >
              <AttachFileIcon />
            </IconButton>
          </label>
        </Tooltip>
      </Grid>

      <Grid item xs={9}>
        {attachments && (
          <Chip
            className={classes.attachmentChip}
            icon={
              <img
                src={
                  attachments && getAttachmentIcon(attachments && attachments?.name?.split('.')?.pop()?.toLowerCase())
                }
                alt={t('FileIcon')}
              />
            }
            label={attachments?.name?.length > 15 ? attachments?.name.substring(0, 15) + ' ...' : attachments?.name}
            deleteIcon={<CloseIcon />}
            onDelete={() => handleDeleteAttachment()}
          />
        )}

        <TextField
          onPaste={handleSnippetPaste}
          onSelect={updateSelectionStart}
          inputRef={inputRef}
          className={classes.messageBox}
          value={outboundMessage?.body}
          placeholder={t('notification.sms.enterMessage')}
          maxRows={
            attachments !== undefined ||
            (globalData?.snackbarData?.channel === ChannelType.sms && globalData.snackbarData?.changeWarning)
              ? 19
              : 21
          }
          fullWidth
          onChange={event => {
            setOutboundMessage({
              ...outboundMessage,
              body: event.target.value,
            });
          }}
          onBlur={event => {
            dispatch(
              notificationDataActions.setSmsData({
                ...globalData.sms,
                data: { ...outboundMessage, body: event.target.value },
              }),
            );
          }}
          onKeyDown={e => {
            if (
              e.keyCode === 13 &&
              !e.shiftKey &&
              (outboundMessage?.body.replace(/\s+/g, '').length > 0 ||
                outboundMessage?.message?.attachmentUrls?.length > 0) &&
              sendEnabled()
            ) {
              e.preventDefault();
              scrollToBottom();
              handleSend();
            }
            if (e.keyCode === 13 && outboundMessage?.body.replace(/\s+/g, '').length === 0 && sendEnabled()) {
              e.preventDefault();
              setOutboundMessage({
                ...outboundMessage,
                body: '',
              });
            }
          }}
          variant="outlined"
          multiline
          inputProps={{ maxLength: 1600 }}
          InputProps={{
            className: classes.input,
            autoFocus: !manualEntry,
            startAdornment:
              hasActiveInbox && !hasActiveSharedInbox ? (
                <InputAdornment position="start">
                  <CandidateAvatar
                    firstName={coveredInbox?.activeInbox?.firstName}
                    lastName={coveredInbox?.activeInbox?.lastName}
                    avatarColor={coveredInbox?.activeInbox?.avatarColor}
                  />
                </InputAdornment>
              ) : null,
            endAdornment: (
              <InputAdornment position="end">
                <ClickAwayListener onClickAway={() => setEmojiListOpen(false)}>
                  <Tooltip
                    classes={{
                      tooltip: classes.emojiGrid,
                      arrow: classes.emojiTooltipArrow,
                    }}
                    PopperProps={{
                      disablePortal: true,
                    }}
                    arrow
                    onClose={() => setEmojiListOpen(false)}
                    open={isEmojiListOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <Grid className={classes.grid} alignItems="center" container spacing={3}>
                        {EMOJI_LIST.map(emoji => {
                          return (
                            <Grid
                              item
                              key={emoji.id}
                              className={classes.emoji}
                              onClick={() => handleEmojiOnClick(emoji)}
                            >
                              <Tooltip
                                title={emoji.name}
                                classes={{
                                  tooltip: classes.tooltipBackground,
                                  arrow: classes.tooltipArrow,
                                }}
                                arrow
                              >
                                <span>{emoji.value}</span>
                              </Tooltip>
                            </Grid>
                          );
                        })}
                      </Grid>
                    }
                  >
                    <IconButton
                      color="primary"
                      className={classes.expandedModeEmoji}
                      onClick={() => setEmojiListOpen(!isEmojiListOpen)}
                      size="small"
                    >
                      <img src={EmojiIcon} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1} style={{ paddingBottom: '1%', paddingRight: '10%' }}>
        <IconButton color="primary" className={classes.sendIcon} disabled={!sendEnabled()} size="small">
          {sendEnabled() ? (
            <img
              alt="SendIcon"
              src={SendIcon}
              onClick={() => {
                scrollToBottom();
                handleSend();
              }}
            />
          ) : (
            <img src={SendIconDisabled} alt="SendIconDisabled" />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};
