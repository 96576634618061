import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/models/Notification/Notification';
import { ILocations, ISkillSets } from 'app/models/Candidate/CandidateJobPreference';
import { TFunction } from 'i18next';
import { SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { ICandidateSkillSetSearch } from 'app/ComponentLibrary/Filter/CandidateSkillset';

export interface ICandidateOrderMatch {
  count: number;
  items: any[] | [];
  chips: IOrderMatchCips;
  id?: string | null;
  partialPayload?: any | null;
  appliedFilters?: any | null;
  tableRows?: any[] | [];
  tableData?: any[] | [];
  referenceData?: any | null;
  openModal?: boolean | false;
  candidateLookUpPrefilter: any;
}

export interface IOrderMatchCips {
  disciplineIds: IChip[] | [];
  specialtyIds: IChip[] | [];
  orderStartDateRange: Date;
  states: IChip[] | [];
  city: IChip[] | [];
  zipCode: IChip[] | [];
  radius: IChip[] | [];
  facilityIds: IChip[] | [];
  maxFilesSentId: any | null;
  positions: any | null;
  billrate: any | null;
  divisionIds: IChip[] | [];
  facilityStatusIds: IChip[];
  businessRelationshipIds: IChip[];
  education: IChip[] | [];
  orderTypeIds: IChip[];
  subSpecialtyIds: IChip[];
  mspClientIds: IChip[];
  affiliateIds: IChip[];
  dateEnteredRange: any | null;
  guaranteedHours: any | null;
  expectedHours: any | null;
  endDateRange: any | null;
  paysGww: any | null;
  reasonids: IChip[];
  amUserIds: IChip[];
  orderPriorityIds: IChip[];
  shiftIds: IChip[];
  weeklyGrossPay: any | null;
  vaccinatedIds: any | null;
  orderIds: any | null;
  skillsetFilter: any | null;
  region: any | null;
  newDisciplineSkill: any | null;
  newSpecialtySkill: any | null;
}
export interface IMapAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
}
export const initialFilterChips: IOrderMatchCips = {
  disciplineIds: [],
  specialtyIds: [],
  orderStartDateRange: null,
  states: [],
  city: [],
  zipCode: null,
  radius: [],
  facilityIds: [],
  maxFilesSentId: null,
  positions: null,
  billrate: null,
  divisionIds: [],
  facilityStatusIds: [],
  businessRelationshipIds: [],
  education: [],
  orderTypeIds: [],
  subSpecialtyIds: [],
  mspClientIds: [],
  affiliateIds: [],
  dateEnteredRange: null,
  guaranteedHours: null,
  expectedHours: null,
  endDateRange: null,
  paysGww: null,
  reasonids: [],
  amUserIds: [],
  orderPriorityIds: [],
  shiftIds: [],
  weeklyGrossPay: null,
  vaccinatedIds: null,
  orderIds: [],
  skillsetFilter: null,
  region: null,
  newSpecialtySkill: null,
  newDisciplineSkill: null,
};

export const initialState: ICandidateOrderMatch = {
  count: 0,
  items: [],
  chips: initialFilterChips,
  id: null,
  partialPayload: null,
  appliedFilters: null,
  tableData: [],
  tableRows: [],
  referenceData: null,
  openModal: false,
  candidateLookUpPrefilter: null,
};
export interface IBillRatePayload {
  min: number | undefined;
  max: number | undefined;
}

export interface IguaranteedHours {
  min: number | undefined;
  max: number | undefined;
}
export interface IweeklyGrossPay {
  min: number | undefined;
  max: number | undefined;
}
export interface IexpectedHours {
  min: number | undefined;
  max: number | undefined;
}
export interface IweeklyGrossPay {
  min: number | undefined;
  max: number | undefined;
}
export interface INumberOfWeeks {
  min: number | undefined;
  max: number | undefined;
}
export interface IPositions {
  min?: number | undefined;
  max?: number | undefined;
}

export interface IOrderStartDateRange {
  startDate?: string | null;
  endDate?: string | null;
}

const getCandidateOrderMatchAction = createAction<{
  keyword?: string;
  pageNumber: number;
  pageSize: number;
  sortedColumn?: { column: string; direction: string } | undefined | null;
  multiSortedColumns?: SortColumn[];
  orderSkillsetFilter: ICandidateSkillSetSearch;
  skillSets?: ISkillSets[];
  locations?: ILocations[];
  orderBillRate?: IBillRatePayload;
  candidateAvailableStartDate?: string | null;
  education?: string[];
  placementLength?: INumberOfWeeks;
  specialtyIds?: [];
  disciplineIds?: [];
  divisionIds?: string[];
  facilityStatusIds?: string[];
  businessRelationshipIds?: string[];
  orderTypeIds?: string[];
  subSpecialtyIds?: string[];
  mspClientIds?: string[];
  affiliateIds?: string[];
  dateEnteredRange?: Date;
  guaranteedHours?: IguaranteedHours;
  expectedHours?: IexpectedHours;
  states?: [];
  city?: [];
  zipCode?: string;
  radius?: number;
  facilityIds?: string[];
  maxFilesSentId: number;
  billRate?: IBillRatePayload;
  orderStartDateRange?: Date;
  endDateRange?: Date;
  paysGww?: boolean;
  reasonids?: string[];
  amUserIds?: string[];
  orderPriorityIds?: string[];
  shiftIds?: string[];
  weeklyGrossPay?: IweeklyGrossPay;
  positions?: IPositions;
  vaccinatedIds?: any;
  translation: TFunction;
}>('GET_CANDIDATE_ORDERMATCH');
const getLookUps = createAction('GET_CANDIDATE_ORDERMATCH_LOOKUPS');

const reduxSlice = createSlice({
  name: 'candidateOrderMatch',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<{ count: number; items: any[] }>) {
      state.items = action.payload.items;
      state.count = action.payload.count;
    },
    setId(state, action: PayloadAction<string | null>) {
      state.id = action.payload;
    },
    setChips(state, action: PayloadAction<IOrderMatchCips>) {
      state.chips = action.payload;
    },
    setPayload(state, action: PayloadAction<any>) {
      state.partialPayload = action.payload;
    },
    setSelectedFilters(state, action: PayloadAction<any>) {
      state.appliedFilters = action.payload;
    },
    setModalProps(state, action: PayloadAction<any>) {
      state.tableData = action.payload.tableData;
      state.tableRows = action.payload.tableRows;
      state.referenceData = action.payload.referenceData;
    },
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.openModal = action.payload;
    },
    setCandidateLooUps(state, action: PayloadAction<any>) {
      state.candidateLookUpPrefilter = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const getCandidateOrderMatchActions = { ...reduxSlice.actions, getCandidateOrderMatchAction, getLookUps };
export const { name: candidateOrderMatchSliceKey, reducer: candidateOrderMatchReducer } = reduxSlice;
