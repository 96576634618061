import { takeLatest, put, call, getContext, select } from 'redux-saga/effects';
import {
  updateFacilityUnitStatus,
  getUnitDetails,
  getUnitsFilterForFacility,
  getCertificatesFullName,
  getSkillSetsForUnitByUnitId,
  updateStarFacilityClientContacts,
} from 'app/services/FacilityServices/FacilityServices';
import { PayloadAction } from '@reduxjs/toolkit';
import { FacilityUnitActions } from './FacilityUnit.redux';
import { formatFacilityUnits, formatUnitsData, getOrdersCount, getPositionsCount } from '../Grid/FacilityUnitsHeader';
import { getMultiSelectOptions, getSigleSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { fyreOpts } from '../Filter/UnitFilterConfig';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { httpClientError, httpServerError, httpSuccess } from 'app/services/serviceHelpers';
import { Certification, NewUnit } from 'app/models/Unit/CreateUnit';
import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter, trackPromise } from 'react-promise-tracker';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import i18next, { TFunction } from 'i18next';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { ReqOrderFieldNames } from '@AMIEWEB/Order/Store/types';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { validNewUnitResponse } from '@AMIEWEB/Unit/UnitUtils';
import { selectFacilityDetails, selectProceedToUnitCreation } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { OrderConstants } from 'app/constants/OrderCreationForm';
import {
  getCompactStatusLookupValues,
  getIssuingBodiesLookupValues,
  getLicensesLookupValues,
} from 'app/services/SharedServices/SharedServices';
import {
  transformIssuedByLookupData,
  transformLicenseData,
} from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailDataTransformer';
import { IPostOrderUnitDetail } from '../Grid/FacilityOrderUnitGrid';
import { getOrderDataForUnitsInFacility } from 'app/services/OrderServices/OrderServices';
import { formatOrderUnitFacilityGridData } from '../../OrdersTab/helper';
import { IPostUnitDetailViewPageFields } from '../../CustomComponents/types';
import { selectUnitsData } from './FacilityUnit.selector';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { FacilityUnitAPITypes } from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailHeader';

const TrackGetFacilityUnitDetails = (fn, ...args) => trackPromise(fn(...args), 'get-facility-unit-details');
const TrackGetFacilityDetailsWithFilters = (fn, ...args) =>
  trackPromise(fn(...args), 'get-facility-details-with-filters');
export const TrackGetOrdersForUnit = (fn, ...args) => trackPromise(fn(...args), 'get-orders-for-unit');
export const TrackGetSkillSetForUnit = (fn, ...args) => trackPromise(fn(...args), 'get-skillset-for-unit');

export const deactivateUnitPromiseKey = 'deactivate-facility-unit';

/**
 * Placement Reminders.
 * @param action
 */

export function* requestFacilityUnitsList(action: PayloadAction<{ id: number }>) {
  try {
    yield put(FacilityUnitActions.setLoading(true));
    const response = yield call(TrackGetFacilityDetailsWithFilters, getUnitsFilterForFacility, action.payload.id);
    if (httpSuccess(response?.status)) {
      const responseData = response?.data;
      const filterSpecs = {
        unitIdOpts: getMultiSelectOptions(responseData.filterSpecs.unitNameOpts),
        statusOpts: getSigleSelectOptions(responseData.filterSpecs.statusOpts),
        disciplineOpts: getMultiSelectOptions(responseData.filterSpecs.disciplineOpts),
        specialtyOpts: getMultiSelectOptions(responseData.filterSpecs.specialtyOpts),
        subSpecialtyOpts: getMultiSelectOptions(responseData.filterSpecs.subSpecialtyOpts),
        lastUpdatedByOpts: getMultiSelectOptions(responseData.filterSpecs.lastUpdatedByOpts),
        fyreIntegrationOpts: getMultiSelectOptions(fyreOpts),
        costCenterOpts: getMultiSelectOptions(responseData.filterSpecs.costCenterOpts),
      };
      yield put(FacilityUnitActions.setFilterSpecs(filterSpecs));
      const unitsData = formatUnitsData(responseData.units);
      yield put(FacilityUnitActions.setUnitsSourceData(unitsData));
      const flattenedData = formatFacilityUnits(responseData.units);
      yield put(
        FacilityUnitActions.setSearchResults({
          positions: getPositionsCount(flattenedData),
          orders: getOrdersCount(flattenedData),
        }),
      );
      yield put(FacilityUnitActions.setFlattenedData(flattenedData ?? []));
      yield put(FacilityUnitActions.setLoading(false));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityUnitsList',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
    yield put(FacilityUnitActions.setLoading(false));
  }
}

export function* getFacilityUnitDetails(action: PayloadAction<{ id: number }>) {
  try {
    const res = yield call(TrackGetFacilityUnitDetails, getUnitDetails, action.payload.id);
    if (httpSuccess(res.status)) {
      const unitData = res.data;
      let unit = {
        name: unitData.unitName,
        unitId: action.payload.id,
        facilityId: unitData.facilityId,
        status: unitData.status,
        linkedFacilityDetails: unitData,
        lastUpdated: unitData.lastUpdated,
        lastUpdatedBy: unitData.lastUpdatedBy,
        firstDayInstructions: unitData.firstDayInstructions,
        reference: {
          numberOfReferenceRequired: unitData.numberOfReferenceRequired,
          numberOfReferenceToRFO: unitData.numberOfReferenceToRFO,
        },
        facilityName: unitData.facilityName,
        amnDivision: unitData.amnDivision,
        businessRelationship: unitData.businessRelationship,
        contractAffiliate: unitData.contractAffiliate,
        healthSystemName: unitData.healthSystemName,
        location: unitData.location,
        mspClient: unitData.mspClient,
        phone: unitData.phone,
        staffingVendor: unitData.staffingVendor,
        technologyVendor: unitData.technologyVendor,
        employeeContacts: unitData.employeeContacts,
      } as NewUnit;
      yield put(FacilityUnitActions.setSelectedUnit(unit));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'GetUnitDetailByUnitId',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* requestCertificatesFullName(action: PayloadAction<{ requirementTypeIds: number[] }>) {
  try {
    const response = yield call(getCertificatesFullName, action.payload.requirementTypeIds);
    if (httpSuccess(response.status)) {
      const certifications = response.data.map(certificate => ({
        requirementTypeId: certificate.requirementTypeId,
        description: certificate.description,
      })) as Certification[];

      yield put(FacilityUnitActions.setCertificatesFullName(certifications));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCertificatesFullName',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* deactivateFacilityUnit(
  action: PayloadAction<{
    facilityId: number;
    unitId: number;
    isDisableContactsRequired: boolean;
    currentEmployeeId: number;
    currentEmployeeName: string;
    t: TFunction;
  }>,
) {
  try {
    manuallyIncrementPromiseCounter(deactivateUnitPromiseKey);
    const res = yield call(updateFacilityUnitStatus, {
      facilityId: action.payload.facilityId,
      unitId: action.payload.unitId,
      isDisableContactsRequired: action.payload.isDisableContactsRequired,
      currentEmployeeId: action.payload.currentEmployeeId,
      currentEmployeeName: action.payload.currentEmployeeName,
    });
    if (httpSuccess(res?.status)) {
      yield put(
        facilityActions.setHttpStatus({
          statusCode: res?.status,
          pageName: FacilityUnitAPITypes.FACILITY_UNIT_STATUS_UPDATE,
        }),
      );

      if (action.payload.isDisableContactsRequired) {
        yield put(
          globalActions.setSnackBar({
            message: action.payload.t('facility.units.statusUpdate.successWithContacts'),
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: action.payload.t('facility.units.statusUpdate.successWithoutContacts'),
            severity: 'success',
          }),
        );
      }
      const unitResponse = yield call(getUnitDetails, action.payload.unitId);
      if (httpSuccess(unitResponse.status)) {
        const unitData = unitResponse.data;
        let unit = {
          name: unitData.unitName,
          unitId: action.payload.unitId,
          facilityId: action.payload.facilityId,
          status: unitData.status,
          linkedFacilityDetails: unitData,
          lastUpdated: unitData.lastUpdated,
          lastUpdatedBy: unitData.lastUpdatedBy,
          firstDayInstructions: unitData.firstDayInstructions,
          reference: {
            numberOfReferenceRequired: unitData.numberOfReferenceRequired,
            numberOfReferenceToRFO: unitData.numberOfReferenceToRFO,
          },
        } as NewUnit;
        yield put(FacilityUnitActions.setSelectedUnit(unit));
      }
    } else {
      yield put(
        globalActions.setSnackBar({
          message: action.payload.t('facility.units.statusUpdate.error'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: action.payload.t('facility.units.statusUpdate.error'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'UpdateUnitStatus',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  } finally {
    manuallyDecrementPromiseCounter(deactivateUnitPromiseKey);
  }
}

export function* getSkillSetsForFacilityUnits(action: PayloadAction<{ unitId: number }>) {
  try {
    const response = yield call(TrackGetSkillSetForUnit, getSkillSetsForUnitByUnitId, action.payload.unitId);
    if (httpSuccess(response.status)) {
      yield put(FacilityUnitActions.setSkillSetsForUnits(response.data));
    } else if (!(httpServerError(response.status) || httpClientError(response.status))) {
      //To handle Data refresh issues if Status Code is 204 (No-Content)
      yield put(FacilityUnitActions.setSkillSetsForUnits({}));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getSkillSetsForFacilityUnits',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* getIssuingBodies() {
  try {
    const response = yield getIssuingBodiesLookupValues();
    if (httpSuccess(response.status)) {
      yield put(FacilityUnitActions.setTransformedIssuedByLookupValue(transformIssuedByLookupData(response.data)));
    } else if (!(httpServerError(response.status) || httpClientError(response.status))) {
      yield put(FacilityUnitActions.setTransformedIssuedByLookupValue([]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getIssuingBodies',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* getLicensesData(action: PayloadAction<{ id: number }>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const facilityData = yield call(facilityService.getFacilityDetails, action.payload.id);
    const response = yield getLicensesLookupValues(facilityData?.data?.location?.state);
    if (httpSuccess(response.status)) {
      yield put(FacilityUnitActions.setLicenseData(transformLicenseData(response.data)));
    } else if (!(httpServerError(response.status) || httpClientError(response.status))) {
      yield put(FacilityUnitActions.setLicenseData([]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getLicensesData',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* getCompactStatus() {
  try {
    const response = yield getCompactStatusLookupValues();
    if (httpSuccess(response.status)) {
      yield put(FacilityUnitActions.setCompactSatus(response.data));
    } else if (!(httpServerError(response.status) || httpClientError(response.status))) {
      yield put(FacilityUnitActions.setCompactSatus([]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCompactStatus',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

//
// ─── SAVE UNIT DETAILS ─────────────────────────────────────────────────────────────────
//
export function* requestSaveUnitDetails(action: PayloadAction<{ payloadForSave: any }>) {
  try {
    const newUnit = action.payload.payloadForSave;
    const facilityDetails = yield select(selectFacilityDetails);
    const proceedToUnitCreation = yield select(selectProceedToUnitCreation);
    const incompleteReqFields: ReqOrderFieldNames[] = [];
    if (newUnit.submissionRequirement && newUnit.virtualRating.length === 0) {
      incompleteReqFields.push(ReqOrderFieldNames.VIRTUAL_RATING);
    }
    yield put(orderCreationActions.setIncompleteReqFields(incompleteReqFields));
    if (incompleteReqFields.length === 0) {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));
      yield put(orderCreationActions.updateStatus('newLoad'));
      yield put(orderCreationActions.setUnitNameErrorStatus(false));
      const unit = {
        firstDayInstructions: newUnit.firstDayInstructions,
        reference: {
          numberOfReferenceRequired: newUnit.reference.numberOfReferenceRequired ?? 0,
          numberOfReferenceToRFO: newUnit.reference.numberOfReferenceToRFO ?? 0,
        },
      } as NewUnit;
      yield put(FacilityUnitActions.setSelectedUnit(unit));
      const facilityService: FacilityService = yield getContext('facilityService');

      const response = yield call(facilityService.saveUnitDetails, action.payload.payloadForSave);
      if (validNewUnitResponse(response.data)) {
        yield put(orderCreationActions.setUnitNameErrorStatus(false));
        if (!!newUnit.unitContacts && newUnit.unitContacts.length > 0) {
          const starResult: any[] = [];
          newUnit.unitContacts?.forEach(unit => {
            starResult.push({
              clientContactId: unit.clientContactId,
              isPrimary: unit.isPrimary,
            });
          });
          const updatedStarClientContacts: any = {
            facilityId: facilityDetails.facilityId,
            primaryFacilityContacts: starResult,
          };

          yield call(async () => {
            await updateStarFacilityClientContacts(updatedStarClientContacts);
          });
        }

        if (!!proceedToUnitCreation && proceedToUnitCreation === true) {
          yield put(orderCreationActions.setCreatedUnitId(response.data.unitId));
          yield put(orderDataActions.setCurrentUnitId(`${response.data.unitId}`));
        }

        yield put(orderDataActions.setOrderLicenseDetails([]));
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('facility.units.unitDetails.updateSuccessUnitDetails'),
            severity: 'success',
          }),
        );
        yield put(orderCreationActions.updateStatus('proceedToParent'));
      } else if (response.data === 'Invalid unit name') {
        yield put(FacilityUnitActions.setInvalidUnitName(true));
        yield put(orderCreationActions.setUnitNameErrorStatus(true));
        yield put(orderCreationActions.updateStatus('failed'));
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('facility.units.unitDetails.updateFailUnitDetails'),
            severity: 'error',
          }),
        );
      } else {
        throw new Error(response);
      }
    } else {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(false));
      yield put(orderCreationActions.updateMessage('Please complete required fields.'));
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: OrderConstants.UNIT_UPDATE_FAILURE_MESSAGE,
        severity: 'error',
      }),
    );
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'unitUpdateSubmitUnitDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrdersByUnitId(action: PayloadAction<IPostOrderUnitDetail>) {
  try {
    const response = yield call(TrackGetOrdersForUnit, getOrderDataForUnitsInFacility, action.payload);
    if (httpSuccess(response.status)) {
      yield put(FacilityUnitActions.setOrdersByUnitId(formatOrderUnitFacilityGridData(response?.data)));
    } else if (!(httpServerError(response.status) || httpClientError(response.status))) {
      yield put(
        FacilityUnitActions.setOrdersByUnitId({
          items: [],
          paging: { pageNumber: 0, pageSize: 0, total: 0, positions: 0 },
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrdersByUnitId',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* requestUpdateUnitViewPageFields(action: PayloadAction<IPostUnitDetailViewPageFields>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(facilityService.updateUnitViewPageFields, action.payload);
    if (httpSuccess(response.status)) {
      const existingUnitDetails: NewUnit[] = yield select(selectUnitsData);
      const newUnitDetails = existingUnitDetails.map(unit => {
        if (unit.unitId === action.payload.unitId && unit.facilityId === action.payload.facilityId) {
          return {
            ...unit,
            requiredQualifications: action.payload.requiredQualifications,
            additionalRequiredQualifications: action.payload.additionalRequiredQualifications,
            preferredQualifications: action.payload.preferredQualifications,
            internalNotes: action.payload.internalNotes,
            externalJobDescription: action.payload.externalJobDescription,
          };
        }
        return unit;
      });
      yield put(FacilityUnitActions.setUnitsSourceData(newUnitDetails));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('facility.units.unitDetails.updateSuccessUnitDetails'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setBanner({
          message: OrderConstants.UNIT_UPDATE_FAILURE_MESSAGE,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setBanner({
        message: OrderConstants.UNIT_UPDATE_FAILURE_MESSAGE,
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestUpdateUnitViewPageFields',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* facilityUnitsSaga() {
  yield takeLatest(FacilityUnitActions.requestFacilityUnitsList.type, requestFacilityUnitsList);
  yield takeLatest(FacilityUnitActions.requestFacilityUnitDetails.type, getFacilityUnitDetails);
  yield takeLatest(FacilityUnitActions.deactivateFacilityUnit.type, deactivateFacilityUnit);
  yield takeLatest(FacilityUnitActions.requestFacilityUnitsCertificatesFullName.type, requestCertificatesFullName);
  yield takeLatest(FacilityUnitActions.getIssuedBy.type, getIssuingBodies);
  yield takeLatest(FacilityUnitActions.getLicenses.type, getLicensesData);
  yield takeLatest(FacilityUnitActions.getCompactStatus.type, getCompactStatus);
  yield takeLatest(FacilityUnitActions.getSkillSetsForUnits.type, getSkillSetsForFacilityUnits);
  yield takeLatest(FacilityUnitActions.requestSaveUnitDetails.type, requestSaveUnitDetails);
  yield takeLatest(FacilityUnitActions.getOrdersByUnitId.type, requestOrdersByUnitId);
  yield takeLatest(FacilityUnitActions.requestUpdateUnitViewPageFields.type, requestUpdateUnitViewPageFields);
}
