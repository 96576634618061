import { virtualRatingList } from 'app/constants';
import { useMemo } from 'react';

export const UseAtoIValuePresent = selectedValues => {
  return useMemo(() => {
    if (selectedValues && selectedValues?.length > 0) {
      return selectedValues?.some(item => {
        return virtualRatingList?.includes(item?.object?.name);
      });
    }
    return false;
  }, [selectedValues]);
};
