import { createAction } from '@reduxjs/toolkit';
import {
  IPlacementTagsUpdate,
  IPlacementUpdateAllRequest,
  ITimestampRequest,
  PlacementOrderType,
} from 'app/models/Placement/PlacementDetails';
import {
  ICSPNote,
  IUpdateComplianceStatusComments,
  IUpdateComplianceStatusNotes,
  IUpdateDirectHire,
  IUpdateDocDeadline,
  IUpdateOrderIdAction,
} from './types';

const onInitAction = createAction<{
  placementId: number;
  peoplesoftEnabled: boolean;
  requirementCheckFlagEnabled: boolean;
  checkClassicPacketInOkToSendEnabled: boolean;
  arbitrationAgreementFlagEnabled?: boolean;
  isPerformanceOptimized?: boolean;
}>('PLACEMENT_DETAILS_WRAPPER_ON_INIT');

const getRequiredPlacementData = createAction<{
  placementId: number;
  success: (orderType: PlacementOrderType) => Promise<void>;
}>('GET_REQUIRED_PLACEMENT_DATA');

const getPlacementProgressAction = createAction<{
  placementId: number;
  isInitLoad?: boolean | false;
}>('GET_PLACEMENT_PROGRESS');

const getHeaderDetailsAction = createAction<{
  placementId: number;
}>('GET_PLACEMENT_HEADER_DETAILS');

const getQuickGlanceDetailsAction = createAction<{
  placementId: number;
}>('GET_PLACEMENT_GLANCE_DETAILS');

const getPlacementContactsAction = createAction<{
  placementId: number;
  isInitLoad?: boolean | false;
}>('GET_PLACEMENT_CONTACTS');

const getCandidateRecordsRightAction = createAction<{
  placementId: number;
  isInitLoad?: boolean | false;
}>('GET_CANDIDATE_RECORDS_DATA_RIGHT');

const getPlacementSellingPointsDetailsAction = createAction<{
  placementId: number;
  isInitLoad?: boolean | false;
}>('GET_PLACEMENT_SELLING_POINT_DETAILS');

const getPlacementCandidatePlacementCountAction = createAction<{
  placementId: number;
  isInitLoad?: boolean | false;
}>('GET_PLACEMENT_CANDIDATE_PLACEMENT_COUNT_DETAILS');

const getOrderComparisonData = createAction<{
  placementId: number;
}>('GET_ORDER_COMPARISON_DATA');

const getModalCategoryData = createAction<{
  placementId: number;
  categoryId: number;
}>('GET_MODAL_CATEGORY_DATA');

const getCredentialCertifications = createAction<{
  placementId: number;
}>('GET_CREDENTIAL_CERTIFICATIONS');

const getChangeHistory = createAction<{
  placementId: number;
  pageSize: number;
  pageNumber: number;
}>('GET_PLACEMENT_CHANGE_HISTORY');

const getPlacementLandsGtgAction = createAction<{
  placementId: number;
  isInitLoad?: boolean | false;
}>('GET_PLACEMENT_LANDSGTG');

const getCandidateSSN = createAction<{
  candidateId: number;
}>('GET_CANDIDATE_SSN');

const updateCandidateSSN = createAction<{
  candidateId: number;
  brandId: number;
  ssn?: string;
  timestamp?: string;
}>('UPDATE_CANDIDATE_SSN');

const getCandidateDOB = createAction<{
  candidateId: number;
}>('GET_CANDIDATE_DOB');

const updateCandidateDOB = createAction<{
  candidateId: number;
  brandId: number;
  dob: string;
  timestamp?: string;
}>('UPDATE_CANDIDATE_DOB');

const getCandidatePreferenceAction = createAction<{ candidateId: number }>('GET_PLACEMENT_CANDIDATE_PREFERENCES');

const validatePlacementTimestamp = createAction<ITimestampRequest>('VALIDATE_PLACEMENT_TIMESTAMP');

const getPlacementTimestamp = createAction<{ placementId: number }>('GET_PLACEMENT_TIMESTAMP');

const updatePlacementAllAction = createAction<IPlacementUpdateAllRequest>('UPDATE_PLACEMENT_ALL');

const getPassportChecklist = createAction<{
  placementId: number;
}>('GET_PASSPORT_CHECKLIST');

const updateFinalizedByQs = createAction('UPDATE_FINALIZED_BY_QS');

const getExtTimeOffPayerOpts = createAction('GET_EXT_TIMEOFF_PAYER_OPTS');

const getExtDirectHireOpts = createAction('GET_EXT_DIRECT_HIRE_OPTS');

const updateKeyContact = createAction('UPDATE_PLACEMENT_KEY_CONTACT');

const updatePlacementRegion = createAction<any>('UPDATE_PLACEMENT_REGION');

const getPlacementSupervisorAction = createAction<{ placementId: number; isInitLoad?: boolean | false }>(
  'GET_PLACEMENT_SUPERVISOR',
);

const getPlacementSupervisorDetailsAction = createAction<{ placementId: number }>('GET_PLACEMENT_SUPERVISORDETAILS');

const getPlacementSupervisorLinkAction = createAction<{ supervisorPlacementId: number; superviseePlacementId: number }>(
  'GET_PLACEMENT_LINK',
);

const deletePlacementSupervision = createAction<{ placementId: number }>('DELETE_PLACEMENT_SUPERVISION');

const getPlacementSuperviseeAction = createAction<{ placementId: number; isInitLoad?: boolean | false }>(
  'GET_PLACEMENT_SUPERVISEE',
);

const createExternalTaskforArbitrationAgreement = createAction<{ placementId: string }>('CREATE_ARBITRATION_AGREEMENT');

const getPlacementTagsAction = createAction<{ placementId: number; isInitLoad?: boolean | false }>(
  'GET_PLACEMENT_TAGS',
);

const updatePlacementTags = createAction<IPlacementTagsUpdate>('UPDATE_PLACEMENT_TAGS');

const getComplianceStatusProgress = createAction<{ placementId: number }>('GET_COMPLIANCE_STATUS_PROGRESS');

const saveComplianceStatusNote = createAction<{ newNote: ICSPNote }>('SAVE_COMPLIANCE_STATUS_NOTE');

const saveComplianceStatusDocDeadline = createAction<IUpdateDocDeadline>('SAVE_COMPLIANCE_STATUS_DOC_DEADLINE');

const searchComplianceStatusNotes = createAction<IUpdateDocDeadline>('SEARCH_COMPLIANCE_STATUS_NOTE');

const updateComplianceStatusNotes = createAction<IUpdateComplianceStatusNotes>('UPDATE_COMPLIANCE_STATUS_NOTE');

const updateDirectHire = createAction<IUpdateDirectHire>('UPDATE_DIRECT_HIRE');

const updateComplianceStatusComments = createAction<IUpdateComplianceStatusComments>(
  'UPDATE_COMPLIANCE_STATUS_COMMENTS',
);

const updateOrderIdForPlacement = createAction<IUpdateOrderIdAction>('UPDATE_ORDERID_PLACEMENT');

export const serviceActions = {
  onInitAction,
  getRequiredPlacementData,
  getPlacementProgressAction,
  getHeaderDetailsAction,
  getQuickGlanceDetailsAction,
  getPlacementContactsAction,
  getCandidateRecordsRightAction,
  getPlacementSellingPointsDetailsAction,
  getPlacementCandidatePlacementCountAction,
  getOrderComparisonData,
  getModalCategoryData,
  getCredentialCertifications,
  getChangeHistory,
  getPlacementLandsGtgAction,
  getCandidateSSN,
  updateCandidateSSN,
  getCandidateDOB,
  updateCandidateDOB,
  getCandidatePreferenceAction,
  validatePlacementTimestamp,
  getPlacementTimestamp,
  updatePlacementAllAction,
  getPassportChecklist,
  updateFinalizedByQs,
  getExtTimeOffPayerOpts,
  getExtDirectHireOpts,
  updateKeyContact,
  updatePlacementRegion,
  getPlacementSupervisorAction,
  getPlacementSuperviseeAction,
  getPlacementSupervisorDetailsAction,
  getPlacementSupervisorLinkAction,
  deletePlacementSupervision,
  getPlacementTagsAction,
  updatePlacementTags,
  getComplianceStatusProgress,
  saveComplianceStatusNote,
  saveComplianceStatusDocDeadline,
  searchComplianceStatusNotes,
  updateComplianceStatusNotes,
  updateDirectHire,
  updateComplianceStatusComments,
  updateOrderIdForPlacement,
  createExternalTaskforArbitrationAgreement,
};
