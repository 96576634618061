import { IReferenceRequest } from 'app/models/Candidate/Tags';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  IArbitrationAgreementCreationBody,
  IPostReferenceWorkExpModalPayload,
  IWorkExperienceBatchDeletePayload,
} from 'store/redux-store/candidate-work-experience/types';
import baseAxiosInstance from 'utils/BaseApi';

export class CandidateService {
  private candidateBase: string;
  private placementBase: string;
  private passportBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: CandidateService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.candidateBase = globalThis?.app?.env?.REACT_APP_CANDIDATE_SERVICE_BASE_URL;
    this.placementBase = globalThis?.app?.env?.REACT_APP_PLACEMENTS_SERVICE_BASE_URL;
    this.passportBase = globalThis?.app?.env?.REACT_APP_PASSPORT_URL;
  }

  static createInstance(): CandidateService {
    const activeInstance = new CandidateService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): CandidateService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return CandidateService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getCandidateForPlacementCreation = (candidateId: number, brandId: number) =>
    baseAxiosInstance.get(`${this.candidateBase}/candidate/placement-modal/${candidateId}/${brandId}`);

  getCandidateSsn = (candidateId: number) =>
    baseAxiosInstance.get(`${this.candidateBase}/candidate/${candidateId}/ssn`);

  getCandidateDOB = (candidateId: number) =>
    baseAxiosInstance.get(`${this.candidateBase}/candidate/${candidateId}/dob`);

  postCandidateDOB = (body: { candidateId: any; brandId: any; dob: any; timestamp: any; ignoreTimestamp: boolean }) =>
    baseAxiosInstance.post(`${this.candidateBase}/candidate/dob`, body);

  postCandidateSSN = (body: { candidateId: any; brandId: any; ssn: any; timestamp: any; ignoreTimestamp: boolean }) =>
    baseAxiosInstance.post(`${this.candidateBase}/candidate/ssn/new`, body);

  getCandidateJobPreferences = (travelerId: number, brandId: number) =>
    baseAxiosInstance.get(`${this.candidateBase}/candidate/get-job-preferences/${travelerId}/${brandId}`);

  toggleCandidateJobPreferenceNotification = (body: {
    candidateId: string;
    brandId: string;
    id: string;
    isOff: boolean;
  }) => baseAxiosInstance.post(`${this.candidateBase}/candidate/toggle-job-preference`, body);

  updateCandidateWorkExperience = (body: any) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/candidate/workhistory`, body)
      .catch((error: AxiosError) => error.response);

  updateCandidateWorkExperienceBulk = (body: any) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/candidate/workhistory-list`, body)
      .catch((error: AxiosError) => error.response);

  deleteWorkExperienceBatch = (body: IWorkExperienceBatchDeletePayload) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/candidate/delete-work-history-batch`, body)
      .catch((error: AxiosError) => error.response);

  getWorkExperienceReferenceList = ({ candidateId, workHistoryId }: { candidateId: number; workHistoryId: number }) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${candidateId}/${workHistoryId}/reference`)
      .catch((error: AxiosError) => error.response);

  postReferenceFromWorkExpModal = (body: IPostReferenceWorkExpModalPayload) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/candidate/reference`, body)
      .catch((error: AxiosError) => error.response);

  getWorkExperienceList = (travelerId: number, brandId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/brand/${brandId}/employments`)
      .catch((error: AxiosError) => error.response);

  getCandidateDetails = (travelerId: number, brandId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/brand/${brandId}`)
      .catch((error: AxiosError) => error.response);

  getSSNValueByTravelerId = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/ssn`)
      .catch((error: AxiosError) => error.response);

  getCandidateAddress = (travelerId: number, brandId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/brand/${brandId}/addresses`)
      .catch((error: AxiosError) => error.response);

  getCandidateAuditDetails = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/audit`)
      .catch((error: AxiosError) => error.response);

  getSupervisorDetails = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/supervisor`)
      .catch((error: AxiosError) => error.response);

  getCandidateTaxData = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/tax-data`)
      .catch((error: AxiosError) => error.response);

  getCandidatePermanentTaxLetterData = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/tax-letter-info`)
      .catch((error: AxiosError) => error.response);

  getCandidateSkillsetTree = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/skillsettree`)
      .catch((error: AxiosError) => error.response);

  getCandidateSkillsChecklist = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/skillschecklist`)
      .catch((error: AxiosError) => error.response);

  getCandidateReferences = (travelerId: number, filter: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/reference?filter=${filter}`)
      .catch((error: AxiosError) => error.response);

  getCandidateRequestTimeOff = (travelerId: number, brandId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/timeoff/${travelerId}/brand/${brandId}`)
      .catch((error: AxiosError) => error.response);

  getCandidateEMRData = (travelerId: number, brandId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/${brandId}/emr`)
      .catch((error: AxiosError) => error.response);

  getCandidatePreferences = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/${travelerId}/preferences`)
      .catch((error: AxiosError) => error.response);

  getCandidateDocumentsPassport = (travelerId: string) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/submitted-documents/${travelerId}`)
      .catch((error: AxiosError) => error.response);

  getCandidateJobInterestCount = (travelerId: number) =>
    baseAxiosInstance
      .get(`${this.candidateBase}/candidate/job-interests-count/${travelerId}`)
      .catch((error: AxiosError) => error.response);

  postReferenceRequest = (reqBody: IReferenceRequest) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/candidate/reference-request`, reqBody)
      .catch((error: AxiosError) => error.response);

  getActivePlacementsByCandidate = (id: string, pageNumber: number = 1, pageSize: number = 1000) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placements/activeplacementsbycandidateid/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      )
      .catch((error: AxiosError) => error.response);

  getOneTrustConsentLinks = (reqBody: Array<string>) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/candidate/onetrust-consent-links`, reqBody)
      .catch((error: AxiosError) => error.response);

  postExternalTaskforArbitrationAgreementDetails = (reqBody: IArbitrationAgreementCreationBody) =>
    baseAxiosInstance
      .post(`${this.candidateBase}/placement/${reqBody?.placementId}/arbitration-agreement`, reqBody)
      .catch((error: AxiosError) => error.response);

  getPassportPreferences = (travelerId: string) =>
    baseAxiosInstance
      .get(`${this.passportBase}/api/amie-web/userpreferences/${travelerId}`)
      .catch((error: AxiosError) => error.response);
}
