import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';

export interface IFacilityCommunicationHistory {
  facilityCommunicationLog: IFacilityCommunicationLog;
  isGridLoading: boolean;
  facilityClientContacts: ISelection[];
  facilityInitiatedBy: ISelection[];
  facilityUserRoles: ISelection[];
  facilityUnits: ISelection[];
  communicationTypeOpts: ISelection[];
  chips?: IFacilityCommunicationChips;
}
export interface IFacilityCommunicationLog {
  totalRecords: number | undefined;
  pageSize: number;
  pageNumber: number;
  results: any[];
  nextPageExists: boolean;
  lastContactedOn?: string | Date;
}
export interface IFacilityCommunicationChips {
  contactNameOpts: IChip[] | [];
  units: IChip[] | [];
  communicationType: IChip[] | [];
  initiatedBy: IChip[] | [];
  date: Date;
  contactName: IChip[] | [];
  contactStatus: IChip;
  userRole: IChip[] | [];
}
export interface IContactNameChips {
  facilityClientContacts: IChip[] | [];
}
export interface IUnitsChips {
  facilityClientUnits: IChip[] | [];
}

export interface IDateRange {
  startDate: string;
  endDate: string;
  label: string;
}
export interface IUnitValue {
  unitName: string | null;
  unitId: number | null;
  selections: {
    id: number;
    unitId: number;
    value: number;
    unitName: string;
    name: string;
  }[];
}
export interface IValues {
  communicationType?: ISelection[];
  initiatedBy?: ISelection[];
  contactName?: ISelection[];
  units?: IUnitValue;
  date?: IDateRange;
  contactStatus?: number[];
  userRole?: string[];
}

export interface IRequestFilterPayload {
  channel?: string[];
  initiatedBy?: string[];
  contactNames?: string[];
  unitIds?: number[];
  startDate?: string;
  endDate?: string;
  label?: string;
  contactStatus?: string;
  userRoles?: string[];
}

export interface IFacilityLogs {
  facilityId?: number;
  pageSize?: number;
  pageNumber?: number;
  contactNames?: string[];
  startDate?: string;
  endDate?: string;
  label?: string;
  initiatedBy?: string[];
  channel?: string[];
  contactStatus?: string;
  unitIds?: string[];
  userRoles?: string[];
}
export interface IFacilityLookups {
  facilityId: number;
}
export const IFacilityCommunicationLogInitialState = {
  pageSize: 50,
  pageNumber: 1,
  totalRecords: 0,
  results: [],
  nextPageExists: false,
};
export const IChipsInitialState = {
  contactNameOpts: [],
  units: [],
  communicationType: [],
  initiatedBy: [],
  date: null,
  contactName: [],
  contactStatus: null,
  userRole: [],
};

export const initialState: IFacilityCommunicationHistory = {
  facilityCommunicationLog: IFacilityCommunicationLogInitialState,
  isGridLoading: false,
  facilityClientContacts: [],
  facilityUnits: [],
  facilityInitiatedBy: [],
  facilityUserRoles: [],
  communicationTypeOpts: [],
  chips: IChipsInitialState,
};

const getFacilityLogs = createAction<IFacilityLogs>('GET_FACILITY_LOGS');
const getUnitsAndClientContactLookups = createAction<number>('GET_FACILITY_UNIT_CLIENT_LOOKUPS');
const getUserRolesAndInitiatedByLookups = createAction<number>('GET_USERROLES_INITIATEDBY_LOOOKUPS');

export const FacilityCommunicationHistory = createSlice({
  name: 'facilityCommunicationHistory',
  initialState: initialState,
  reducers: {
    updateFacilityCommunicationLogs(state, action: PayloadAction<IFacilityCommunicationLog | any>) {
      state.facilityCommunicationLog = action.payload;
    },
    setGridLoading(state, action: PayloadAction<boolean>) {
      state.isGridLoading = action.payload;
    },
    setFacilityClientContactLookusps(state, action: PayloadAction<ISelection[]>) {
      state.facilityClientContacts = action.payload;
    },
    setFacilityUnitsLookups(state, action: PayloadAction<ISelection[]>) {
      state.facilityUnits = action.payload;
    },
    setFacilityUserRolesLookups(state, action: PayloadAction<ISelection[]>) {
      state.facilityUserRoles = action.payload;
    },
    setFacilityInitiatedByLookups(state, action: PayloadAction<ISelection[]>) {
      state.facilityInitiatedBy = action.payload;
    },
    setCommunicationTypeOpts(state, action: PayloadAction<ISelection[]>) {
      state.communicationTypeOpts = action.payload;
    },
    setChips(state, action: PayloadAction<IFacilityCommunicationChips>) {
      state.chips = action.payload;
    },
  },
});

const { actions } = FacilityCommunicationHistory;

export const { reducer: FacilityCommunicationHistoryReducer, name: FacilityCommunicationHistorySliceKey } =
  FacilityCommunicationHistory;

export const facilityCommunicationHistoryActions = {
  ...actions,
  getFacilityLogs,
  getUnitsAndClientContactLookups,
  getUserRolesAndInitiatedByLookups
};
