import { InputAdornment, TextField } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT } from '@AMIEWEB/Notification/Constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styledComponent from 'styled-components';

const useStyles = makeStyles()(theme => ({
  adorment: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: theme.palette.framework.system.errorRed,
  },
  charCountGrid: {
    width: '100%',
    marginLeft: '0px',
  },
  notes: {
    minHeight: '60px',
    maxHeight: '60px',
  },
  errorInfoIcon: {
    width: '16px',
    height: '16px',
  },
  errorText: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.framework.system.errorRed,
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.framework.system.tertiaryGrey,
  },
}));

interface IOutcomeProps {
  handleDisposition: (value: any, key: string) => void;
  logNotes: string;
  notes: string;
  setNotes: (value: string) => void;
  setAddToSummary: (value: boolean) => void;
  setSummaryCheckboxEnabled: (value: boolean) => void;
}

const Notes = styledComponent(TextField)`
  margin-top: 1%;
  width: 100%;
`;

const LogNotes: React.FC<IOutcomeProps> = (props: IOutcomeProps) => {
  const { t } = useTranslation();
  const { handleDisposition, logNotes, notes, setNotes, setAddToSummary, setSummaryCheckboxEnabled } = props;
  const { classes } = useStyles();

  return (
    <React.Fragment>
      <Notes
        label={t('notification.voice.enterNotes')}
        rows={3}
        value={notes || logNotes}
        inputProps={{
          maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
        }}
        InputProps={{
          classes: { input: classes.notes },
          startAdornment:
            notes.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
              <InputAdornment position="start" className={classes.adorment}>
                <InfoOutlinedIcon className={classes.errorInfoIcon} />
              </InputAdornment>
            ) : (
              <></>
            ),
        }}
        onChange={event => {
          setNotes(event.target.value);
          if (event.target.value?.trim()?.length > 0) setSummaryCheckboxEnabled(true);
          else {
            setAddToSummary(false);
            setSummaryCheckboxEnabled(false);
          }
        }}
        onBlur={() => handleDisposition(notes.trim(), 'notes')}
        variant="filled"
        multiline
        error={notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
      />
      <LayoutGrid container justifyContent="space-between" className={classes.charCountGrid} xs={12}>
        <LayoutGridItem style={{ paddingLeft: '12px' }}>
          <label className={classes.errorText}>{`${
            notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? t('notification.voice.maxCharLimit') : ' '
          }`}</label>
        </LayoutGridItem>
        <LayoutGridItem>
          <label
            className={
              notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? classes.errorText : classes.characterCount
            }
          >{`${notes.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
        </LayoutGridItem>
      </LayoutGrid>
    </React.Fragment>
  );
};

export default LogNotes;
