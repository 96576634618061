import { INotification } from 'app/models/Notification/Notification';
import { fetchLogByPageNumber } from './MessagingHelper';
import { ICoveredUserInboxes } from 'app/models/UserCoverage/UserCoverage';
import React from 'react';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';

export interface FindMessageByIdInput {
  id: string;
  globalData: INotification;
  candidateDetails: ICandidateDetails;
  hasActiveInbox: boolean;
  coveredInbox: ICoveredUserInboxes;
  smsData: any;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitResponse: any;
  dispatch: any;
  notificationDataActions: any;
  messageStack: any;
}

export async function findMessageById(input: FindMessageByIdInput) {
  input.setLoader(true);
  let pageNumber = 1;
  let found = false;

  const foundInPage1Data = input.globalData.sms?.chatHistory?.chats?.results?.find(message => message?.id === input.id);

  const maximumLoops = 100;
  if (!foundInPage1Data) {
    let newMessages = input.globalData.sms.chatHistory;
    while (!found && pageNumber < maximumLoops) {

      // newGlobalData is necessary so we can continue to load the next page throughout this loop and append each time
      const newGlobalData = {
        ...input.globalData,
        sms: {
          ...input.globalData.sms,
          chatHistory: {...newMessages}
        }
      };
      newMessages = await fetchLogByPageNumber(
        false,
        pageNumber,
        newGlobalData,
        input.candidateDetails,
        input.hasActiveInbox,
        input.coveredInbox,
        input.smsData,
        input.setLoader,
        input.handleSubmitResponse,
        input.dispatch,
        input.notificationDataActions,
        input.messageStack,
      );
      if (newMessages.chats.results.length === 0) {
        break;
      }

      const message = newMessages?.chats.results.find(message => message?.id === input.id);

      if (message) {
        found = true;
      } else {
        pageNumber += 1;
      }
    }
  }
}
