import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, FormControl, Button, Grid, Divider, IconButton, TextField, Checkbox } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomSelect } from './CustomSelect';
import { CustomCalendar } from './CustomCalendar';
import { CustomDateRangePicker } from './CustomDateRangePicker';
import { TypeAhead } from '../../components/Common/TypeAheads/TypeAhead';
import { useTranslation } from 'react-i18next';
import { FilterCompTypes } from './utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ResetIcon from 'app/assets/images/IconImages/ResetButton.svg';
import FunnelIcon from 'app/assets/images/IconImages/FunnelButton.svg';
import { CustomNumberRange } from './CustomNumberRange';
import { AsyncTypeAhead } from './AsyncTypeAhead';
import { PaperDropdown } from './PaperDropDown';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { CustomTreeView } from 'app/components/Common/TreeView/CustomTreeView';
import { TypeAheadDropdown } from './TypeAheadDropdown/TypeAheadDropdown';
import { DateRangePicker } from '../DateRangeNew';
import { NestedFilters } from './NestedFilters/NestedFilters';
import { WhiteTooltip } from '@AMIEWEB/Common';
import { CandidateAggregateSearch } from './CandidateSearch';
import { CandidateSkillsetFilter } from './CandidateSkillset';
import { FacilityAggregateSearch } from './FacilitySearch/Index';
import { UnitAggregateSearch } from '@AMIEWEB/Facility/UnitSearchFilter/UnitIndex';

const useStyles = makeStyles()({
  container: {
    flexWrap: 'nowrap',
  },
  title: {
    color: '#333333',
    fontSize: 16,
  },
  filterName: {
    color: '#707070',
    paddingBottom: 4,
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '1.8em',
  },
  button: {
    padding: '8px 40px',
    fontSize: 12,
  },
  submitButton: {
    backgroundColor: '#006FB9',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#006FCF',
    },
  },
  resetButton: {
    backgroundColor: '#8E9195',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8E9195',
    },
  },
  divider: {
    padding: '0 16px 0 0 !important',
  },
  cancelIcon: {
    color: '#D32F2F',
  },
  disableIcon: {
    color: '#C0C0C0',
  },
  muiIconButton: {
    paddingTop: '20px !important',
  },
  resetButtonVersion2: {
    padding: 0,
  },
  textFieldNumber: {
    fontSize: 12,
    width: '100%',
    '& input::placeholder': {
      fontSize: 12,
    },
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      display: 'none',
    },
  },
});
export const CustomFilter = ({
  analytics,
  applyOnClickAway = false,
  ...props
}: {
  analytics?: { caller: string; onApply?: boolean; properties?: { [key: string]: any } };
  [key: string]: any;
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control, errors } = useFormContext();
  const { justifyContent = 'space-around', enableTrailDivider = false, filterState } = props;

  const hiddenSubmitButton = React.useRef<HTMLButtonElement>();

  //App-Insights
  const trackOperation = filterName => {
    if (!analytics) return;
    if (analytics.onApply) {
      trackEvent({
        type: 'event',
        name: analytics.caller,
        properties: { filterName: filterName, ...(analytics?.properties || {}) },
      });
    }
  };

  return (
    <Grid container spacing={2} direction="column" classes={{ container: classes.container }}>
      {props.version0 && (
        <Grid item className={classes.title}>
          {t('search.filter.customFilterTitle')}
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={2} direction="row" justifyContent={justifyContent}>
          {props.fields?.map(
            (item, index) =>
              !item.hideFilter && (
                <Grid item key={index.toString()}>
                  {!props.version2 && (
                    <Typography variant="body2" className={classes.filterName}>
                      {item.filterName}
                    </Typography>
                  )}
                  {item.type === FilterCompTypes.SELECT && (
                    <FormControl>
                      <Controller
                        control={control}
                        name={item.name}
                        render={({ ref, onChange, ...rest }) => (
                          <CustomSelect
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            onChange={newValue => {
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                            }}
                            errorObject={errors[item.name]}
                            apply={() => {
                              trackOperation(item.filterName);
                              hiddenSubmitButton.current?.click();
                            }}
                            {...rest}
                            {...item}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                  {item.type === FilterCompTypes.CALENDAR && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <CustomCalendar
                          onChange={newValue => {
                            onChange(newValue);
                            trackOperation(item.filterName);
                            props.onChangeValues?.(item.name, newValue);
                          }}
                          errorObject={errors[item.name]}
                          {...rest}
                          {...item}
                        />
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.DATERANGE && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <CustomDateRangePicker
                          onChange={newValue => {
                            trackOperation(item.filterName);
                            onChange(newValue);
                            props.onChangeValues?.(item.name, newValue);
                            /** Temporary implementation to tackle lack of APPLY btn in picker */
                            if (item.version3) hiddenSubmitButton.current?.click();
                          }}
                          errorObject={errors[item.name]}
                          {...rest}
                          {...item}
                        />
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.DATERANGEV2 && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <DateRangePicker
                          onApply={value => {
                            if (!value.startDate && !value.endDate) {
                              onChange(null);
                              props.onChangeValues?.(item.name, null);
                            } else {
                              onChange(value);
                              props.onChangeValues?.(item.name, value);
                            }
                            hiddenSubmitButton.current?.click();
                          }}
                          applyOnClickAway={item?.applyOnClickAway}
                          trailingIconShow
                          initialDateRange={rest.value}
                          {...item}
                        />
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.TYPEAHEAD && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <div style={{ minWidth: 175 }}>
                          <TypeAhead
                            variant={'outlined'}
                            size={'small'}
                            onChange={newValue => {
                              trackOperation(item.filterName);
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                            }}
                            errorObject={errors[item.name]}
                            enableCustomScroll
                            removeCloseIcon
                            {...rest}
                            {...item}
                          />
                        </div>
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.ASYNCTYPEAHEAD && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <div style={{ minWidth: 175 }}>
                          <AsyncTypeAhead
                            onChange={newValue => {
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                              /** Temporary implementation to tackle lack of APPLY btn in picker */
                              if (item.version3) hiddenSubmitButton.current?.click();
                            }}
                            apply={() => {
                              trackOperation(item.filterName);
                              hiddenSubmitButton.current?.click();
                            }}
                            errorObject={errors[item.name]}
                            enableCustomScroll
                            removeCloseIcon
                            {...rest}
                            {...item}
                          />
                        </div>
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.TYPEAHEADDROPDOWN && (
                    <WhiteTooltip title={item.tooltipMessage ?? ''} arrow>
                      <FormControl>
                        <Controller
                          control={control}
                          name={item.name}
                          render={({ ref, onChange, ...rest }) => (
                            <TypeAheadDropdown
                              onChange={newValue => {
                                onChange(newValue);
                                props.onChangeValues?.(item.name, newValue);
                              }}
                              applyOnClickAway={item?.applyOnClickAway}
                              errorObject={errors[item.name]}
                              apply={newValue => {
                                props.onApply?.(item.name, newValue);
                                hiddenSubmitButton.current?.click();
                              }}
                              {...rest}
                              {...item}
                            />
                          )}
                        />
                      </FormControl>
                    </WhiteTooltip>
                  )}
                  {item.type === FilterCompTypes.CUSTOMDROPDOWN && (
                    <FormControl>
                      <Controller
                        control={control}
                        name={item.name}
                        render={({ ref, onChange, ...rest }) => (
                          <TypeAheadDropdown
                            onChange={newValue => {
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                            }}
                            errorObject={errors[item.name]}
                            apply={() => {
                              props.onApply?.();
                              if (!props.disableFormSubmit) hiddenSubmitButton.current?.click();
                            }}
                            value={filterState}
                            hiddenInput={props.hiddenInput}
                            {...item}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                  {item.type === FilterCompTypes.NUMBERRANGE && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <CustomNumberRange
                          onChange={newValue => {
                            onChange(newValue);
                          }}
                          apply={newValue => {
                            trackOperation(item.filterName);
                            props.onChangeValues?.(item.name, newValue);
                            hiddenSubmitButton.current?.click();
                          }}
                          errorObject={errors[item.name]}
                          {...rest}
                          {...item}
                        />
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.TREE && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <PaperDropdown
                          onChange={newValue => {
                            onChange(newValue);
                            props.onChangevalues?.(item.name, newValue);
                          }}
                          onApplyEvent={() => {
                            trackOperation(item.filterName);
                            hiddenSubmitButton.current?.click();
                          }}
                          {...rest}
                          {...item}
                          applyOnEnter={item.applyOnEnter}
                          applyOnClickAway={item.applyOnClickAway}
                          Component={CustomTreeView}
                        ></PaperDropdown>
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.TEXTFIELDNUMBER && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, value, ...rest }) => (
                        <div style={{ minWidth: 160 }}>
                          <TextField
                            variant={'outlined'}
                            size={'small'}
                            type={'number'}
                            className={classes.textFieldNumber}
                            onChange={newValue => {
                              const val = newValue.target.value.replace(/[^0-9]/g, '');
                              if (newValue.target.value === '' || val !== '') {
                                trackOperation(item.filterName);
                                newValue.target.value = val;
                                onChange(newValue);
                                props.onChangeValues?.(item.name, newValue);
                              }
                            }}
                            onBlurCapture={() => {
                              if (item?.applyOnClickAway) {
                                hiddenSubmitButton.current?.click();
                              }
                            }}
                            errorObject={errors[item.name]}
                            enableCustomScroll
                            removeCloseIcon
                            value={value ? value : ''}
                            {...rest}
                            {...item}
                          />
                        </div>
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.NESTEDFILTER && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, value }) => (
                        <NestedFilters
                          item={item}
                          value={value}
                          applyOnClickAway={item?.applyOnClickAway}
                          applyOnEnter={item?.applyOnEnter}
                          onApply={values => {
                            props.onApply?.(item.name, values);
                            hiddenSubmitButton.current?.click();
                          }}
                        />
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.CUSTOMCHECKBOX && item.hidden && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, value }) => (
                        <Checkbox edge="start" tabIndex={-1} checked={value} disableRipple hidden={true} />
                      )}
                    />
                  )}
                  {item.type === FilterCompTypes.CANDIDATESEARCH && (
                    <WhiteTooltip title={item.tooltipMessage ?? ''} arrow>
                      <Controller
                        control={control}
                        name={item.name}
                        render={({ ref, onChange, ...rest }) => (
                          <CandidateAggregateSearch
                            onChange={newValue => {
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                            }}
                            applyOnClickAway={item?.applyOnClickAway}
                            errorObject={errors[item.name]}
                            apply={newValue => {
                              onChange(newValue);
                              props.onApply?.(item.name, newValue);
                              hiddenSubmitButton.current?.click();
                            }}
                            {...rest}
                            {...item}
                          />
                        )}
                      />
                    </WhiteTooltip>
                  )}
                  {item.type === FilterCompTypes.FACILITYSEARCH && (
                    <WhiteTooltip title={item.tooltipMessage ?? ''} arrow>
                      <Controller
                        control={control}
                        name={item.name}
                        render={({ ref, onChange, ...rest }) => (
                          <FacilityAggregateSearch
                            onChange={newValue => {
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                            }}
                            applyOnClickAway={item?.applyOnClickAway}
                            errorObject={errors[item.name]}
                            apply={newValue => {
                              onChange(newValue);
                              props.onApply?.(item.name, newValue);
                              hiddenSubmitButton.current?.click();
                            }}
                            {...rest}
                            {...item}
                          />
                        )}
                      />
                    </WhiteTooltip>
                  )}
                  {item.type === FilterCompTypes.UNITFILTERSEARCH && (
                    <WhiteTooltip title={item.tooltipMessage ?? ''} arrow>
                      <Controller
                        control={control}
                        name={item.name}
                        render={({ ref, onChange, ...rest }) => (
                          <UnitAggregateSearch
                            onChange={newValue => {
                              onChange(newValue);
                              props.onChangeValues?.(item.name, newValue);
                            }}
                            applyOnClickAway={item?.applyOnClickAway}
                            errorObject={errors[item.name]}
                            apply={newValue => {
                              onChange(newValue);
                              props.onApply?.(item.name, newValue);
                              hiddenSubmitButton.current?.click();
                            }}
                            optionLookups={item.options}
                            {...rest}
                            {...item}
                          />
                        )}
                      />
                    </WhiteTooltip>
                  )}
                  {item.type === FilterCompTypes.CANDIDATESKILLSET && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, onChange, ...rest }) => (
                        <CandidateSkillsetFilter
                          onChange={newValue => {
                            onChange(newValue);
                            props.onChangevalues?.(item.name, newValue);
                          }}
                          onApplyEvent={() => {
                            trackOperation(item.filterName);
                            hiddenSubmitButton.current?.click();
                          }}
                          {...rest}
                          {...item}
                          Component={CustomTreeView}
                        ></CandidateSkillsetFilter>
                      )}
                    />
                  )}
                  {/* {item.type === FilterCompTypes.COMPACTSTATES && (
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ ref, value, onChange, ...rest }) => (
                        <CompactStates item={item}
                          value={value}
                          onChange={newValue => {
                            onChange(newValue);
                            props.onChangeValues?.(item.name, newValue);
                          }}
                          apply={(values) => {
                            trackOperation(item.filterName);
                            hiddenSubmitButton.current?.click();

                          }}
                          {...rest}
                          {...item}
                        />
                      )}
                    />
                    )
                  } */}
                </Grid>
              ),
          )}
          {props.version0 ? (
            <>
              <Grid item classes={{ root: classes.buttonGrid }}>
                <Button variant="contained" className={`${classes.submitButton} ${classes.button}`} type="submit">
                  {t('search.filter.apply')}
                </Button>
              </Grid>
              <Grid item classes={{ root: classes.buttonGrid }}>
                <Button
                  variant="text"
                  className={`${classes.resetButton} ${classes.button}`}
                  type="button"
                  onClick={props.onResetForm}
                >
                  {t('search.filter.Reset')}
                </Button>
              </Grid>
            </>
          ) : props.version1 ? (
            !props.enableClear ? (
              <Grid item classes={{ root: classes.muiIconButton }}>
                <IconButton color="secondary" type="submit" disabled={!props.enableApply} size="medium">
                  <CheckCircleIcon
                    color={props.enableApply ? 'primary' : 'disabled'}
                    classes={{ colorDisabled: classes.disableIcon }}
                  />
                </IconButton>
              </Grid>
            ) : (
              <Grid item classes={{ root: classes.muiIconButton }}>
                <IconButton color="secondary" type="button" onClick={props.onResetForm} size="medium">
                  <CancelIcon classes={{ root: classes.cancelIcon }} />
                </IconButton>
              </Grid>
            )
          ) : (
            <>
              {((!props.disableFormSubmit && !props.resetBtn) || props.enableReset) && !props?.isLinkTaskMod && (
                <Grid item>
                  <IconButton
                    color="secondary"
                    type="button"
                    disableTouchRipple
                    disableFocusRipple
                    classes={{ root: classes.resetButtonVersion2 }}
                    onClick={props.onResetForm}
                    size="medium"
                  >
                    <img src={ResetIcon} alt="Reset" style={{ opacity: 0.54 }} />
                  </IconButton>
                </Grid>
              )}
              {props.enableAdvancedFilterBtn && (
                <Grid item>
                  <IconButton
                    color="primary"
                    type="button"
                    disableTouchRipple
                    disableFocusRipple
                    classes={{ root: classes.resetButtonVersion2 }}
                    onClick={props.onResetForm}
                    disabled
                    size="small"
                  >
                    <img src={FunnelIcon} alt="Funnel" />
                  </IconButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {enableTrailDivider && (
        <Grid item className={classes.divider}>
          <Divider />
        </Grid>
      )}
      {!props.disableFormSubmit && <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />}
    </Grid>
  );
};
