import { Box, CircularProgress } from 'amn-ui-core';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { TwilioExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import {
  ConsentType,
  IInboxReadStatusCommand,
  InitiationPoints,
  ISmsPayload,
} from 'app/models/Notification/Notification';
import { publishSms } from 'app/services/NotificationServices/NotificationService';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { Concatenate } from 'utils/string/string';
import { CloseConfirmation } from '../../Common/CloseDialog';
import { ChannelType, IDirection } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  attachmentDetails,
  conversationNotes,
  newConversationNotes,
  notificationSelection,
} from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { Chat } from './Chats';
import DividerWithText from './Helpers/DividerWithText';
import { groupMessages, smsPayloadToFormData } from './Helpers/Helper';
import { UploadFileBackdropSms } from '../../Common/UploadBackdrop';
import messageStack from '../../MessageInbox/MessageStack';
import { useEnableOneTrustConsents } from 'oidc/CommunicationEnabler';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { selectAnyDrawerOpen } from '../../../../ApplicationRoot/Global.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { CustomBackdrop } from '@AMIEWEB/Common';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { logOutboundCoveredSMS } from '../BulkSms/MessageHelpers';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { useDecision } from '@optimizely/react-sdk';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { initialsmsCandidatesConsents } from 'store/redux-store/notification/initial-state';
import { useMessagingStyles } from './Messaging.styles';

import { useSmsDropzone, useSmsFeatureFlags } from './useSmsDropzone';
import { SmsInputField } from './SmsInputField';
import { SmsHeaderNotes } from './SmsHeaderNotes';
import { SmsAppBar } from './SmsAppBar';
import {
  fetchLogByPageNumber,
  GetActiveSender,
  GetFileFromUrl,
  GetOldestChatId,
  PrepareSmsPayload,
  ProcessAssociatedRecords,
} from './Helpers/MessagingHelper';
import useEffectOnce from 'utils/customHooks/useEffectOnce';
import { findMessageById } from './Helpers/FindMessage';

export const Sms = () => {
  const dispatch = useDispatch();
  const { enableBrandSelection, handleSubmitResponse } = useContext(NotificationContext);

  const [smsCoverageFlag] = useDecision(ff_notifications_coverage_sms);
  const enableOneTrustConsents = useEnableOneTrustConsents();
  const [loader, setLoader] = useState(false);
  const { promiseInProgress } = usePromiseTracker({ area: 'notification-saga', delay: 0 });

  const scrollRef = useRef<any>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const storedChatId = useRef('');

  const globalData = useSelector(notificationSelection);
  const conversationNotesData = useSelector(conversationNotes);
  const newNotes = useSelector(newConversationNotes);
  const user = useSelector(selectUser);
  const attachment = useSelector(attachmentDetails);
  const coveredInbox = useSelector(selectCoveredInbox);
  const { drawerData } = useSelector(selectSearchResults);
  const isAnyDrawerOpen = useSelector(selectAnyDrawerOpen);

  const manualEntry = globalData?.sms?.manualEntry;
  const unknownNum = !globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
  const warningMessage = globalData.snackbarData?.changeWarning;
  const unreadMessageId = globalData?.sms?.chatHistory!.chats?.results?.findLast(
    chat => chat.message?.readStatus?.isRead === false,
  )?.id;
  const smsData = globalData?.sms?.data;
  const { hasActiveInbox, hasActiveSharedInbox } = useSmsFeatureFlags(coveredInbox, user);
  const manuallyEnteredNumber = globalData?.sms?.manuallyEnteredNumber;
  const candidateDetails = globalData.sms.chatHistory!.candidateDetails;
  const { classes } = useMessagingStyles({ unknownNum, warningMessage: !!warningMessage, enableBrandSelection });

  const chats = useMemo(() => {
    return groupMessages(globalData?.sms?.chatHistory?.chats?.results || []);
  }, [globalData?.sms?.chatHistory?.chats?.results]);

  const [selectedKofaxLinks, setSelectedKofaxLinks] = useState<[string, string, string][]>([]);

  const initialNotesOpen = useMemo(() => {
    return newNotes !== undefined && newNotes.length > 0;
  }, [globalData?.sms?.minimized, newNotes]);
  const [isNotesOpen, setNotesOpen] = useState<boolean>(initialNotesOpen);

  const initialNotes = useMemo(() => {
    return newNotes === undefined || newNotes?.length === 0 ? conversationNotesData : newNotes;
  }, [newNotes, conversationNotesData, globalData?.sms?.open]);

  const [attachments, setAttachments] = useState<File>();

  const [activeScrollPosition, setActiveScrollPosition] = useState<boolean>(false);
  const [chatResults, setChatResults] = React.useState(globalData.sms.chatHistory.chats?.results);
  const [phoneNumber, setPhoneNumber] = useState<string>(manuallyEnteredNumber || '');
  const [outboundMessage, setOutboundMessage] = React.useState<any>({});

  const isReadOnly = useMemo(() => {
    return globalData.sms.readonly ?? false;
  }, [globalData.sms.readonly]);

  const drawerOpened = drawerData.open || isAnyDrawerOpen;

  //---Drag & Drop variables--
  const { getRootProps, getInputProps, isDragActive } = useSmsDropzone(attachments, setAttachments, setOutboundMessage);

  useEffect(() => {
    const candidateIds = globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
    const brandId = globalData?.sms?.data?.tos?.find(Boolean)?.brandId;
    const inititationPoint = globalData?.sms?.initiationPoint;
    if (
      candidateIds !== null &&
      candidateIds !== undefined &&
      inititationPoint !== InitiationPoints.communicationHistoryTab
    ) {
      enableOneTrustConsents &&
        dispatch(
          notificationDataActions.getCandidatesConsentsAction({
            candidateIds: [candidateIds],
            brandId: brandId,
            consentType: ConsentType.smsModal,
          }),
        );
    } else {
      dispatch(notificationDataActions.setSmsConsents(initialsmsCandidatesConsents));
    }
  }, [candidateDetails?.travelerId]);

  useEffect(() => {
    if (scrollRef.current && !activeScrollPosition) {
      scrollToMessage();
    }
  }, [scrollRef.current]);

  useEffect(() => {
    if (messagesEndRef && smsData?.scrollToMessage === undefined) {
      scrollToBottom();
    }
  }, [promiseInProgress]);

  const getFile = async () => {
    if (attachment) {
      const attachmentTemp = await GetFileFromUrl(attachment.url, attachment.name, attachment.type);
      setAttachments(attachmentTemp);
      setOutboundMessage(prevState => ({
        ...prevState,
        attachment: attachmentTemp,
        message: { attachmentUrls: [attachment.name] },
      }));
    }
  };

  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);
    }
  };
  const scrollToMessage = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setOutboundMessage({
      ...smsData,
      id: uuid(),
      expand: false,
      inProgress: true,
      error: false,
      processed: false,
      direction: IDirection.Outbound,
      message: {
        attachmentUrls: [],
      },
    });
    if (newNotes === undefined || newNotes.length === 0) {
      setNotesOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsData]);

  useEffect(() => {
    setSelectedKofaxLinks([]);
  }, [candidateDetails?.travelerId]);

  useEffectOnce(() => {
    if (smsData?.scrollToMessage) {
      findMessageById({
        id: smsData?.scrollToMessage,
        globalData,
        candidateDetails,
        hasActiveInbox,
        coveredInbox,
        smsData,
        setLoader,
        handleSubmitResponse,
        dispatch,
        notificationDataActions,
        messageStack,
      });
    }
  });

  useEffect(() => {
    const updatedInboxMessage = globalData.inbox
      ? globalData.inbox?.map(x =>
          x.travelerId === globalData.sms.data.tos?.find(Boolean).contactId ? { ...x, unreadLogs: 0 } : x,
        )
      : [];
    dispatch(notificationDataActions.updateInbox(updatedInboxMessage));

    if (globalData.sms.chatHistory?.chats?.notifySmsUponAttachmentSendViaEmail) {
      setSelectedKofaxLinks([]);
      dispatch(notificationDataActions.setFalseSmsUponAttachmentSendViaEmail());
    }
  }, [dispatch, globalData.sms.chatHistory]);

  const resendMessage = async id => {
    //Api is not yet ready
    if (!messageStack.messageStack) return;
    const messageIndex = chatResults.findIndex(chat => chat.id === id);
    const message: ISmsPayload = _.cloneDeep(chatResults[messageIndex]);
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() + 5);
    message.publishedOn = dt.toISOString();
    message.inProgress = true;
    message.error = false;
    message.processed = false;
    message.id = uuid();
    message.sender ??= message.message.sender;
    message.tos ??= message.message.tos;
    message.body ??= message.message.body;

    let chatResultsCopy = cloneDeep(chatResults);
    chatResultsCopy[messageIndex] = _.cloneDeep(message);
    if (
      !message?.userRole
        .toLowerCase()
        .split(/[,.]/)
        .some(role => role.trim() === 'recruitment')
    ) {
      message.associatedRecords = message.associatedRecords.filter(record => record.name !== 'brandId');
      delete message.brand;
      delete message.brandId;
    }
    if (message?.associatedRecords) {
      message.associatedRecords = ProcessAssociatedRecords(message.associatedRecords);
    }
    const messageForm = smsPayloadToFormData(message, attachments);
    await publishSms(messageForm);
    dispatch(candidateDetailActions.updateLastContactedOn(moment(new Date()).toISOString()));
  };

  useEffect(() => {
    let heightMultiplier = selectedKofaxLinks.length < 1 ? 120 : 85;
    if (!globalData?.sms?.data?.tos?.find(Boolean)?.contactId) {
      heightMultiplier = 73;
    } else if (enableBrandSelection) {
      heightMultiplier = 95;
    }
    setTimeout(() => {
      const header = document.getElementById('draggableArea')!;
      const content = document.getElementById('scrollArea')!;
      if (content) {
        content.style.height = 525 - header.childElementCount * heightMultiplier + 'px';
      }
    }, 0);
  }, [globalData.sms, enableBrandSelection, selectedKofaxLinks, globalData.snackbarData, globalData, isNotesOpen]);

  React.useEffect(() => {
    setChatResults(globalData.sms.chatHistory?.chats?.results);
  }, [globalData?.sms?.chatHistory?.chats]);

  const handleSend = async () => {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() + 5);
    outboundMessage.publishedOn = dt.toISOString();
    const outboundForPublish = _.cloneDeep(outboundMessage);
    outboundForPublish.brand = globalData?.sms?.isBrandSelectionSkipped ? null : outboundForPublish.brand;
    //Clear the textfield
    const clearedOutboundField = _.cloneDeep({
      ...outboundMessage,
      attachment: null,
      body: '',
      expand: false,
      id: Date.now().toString(36) + Math.random().toString(36).substring(2),
      message: {
        attachmentUrls: [],
      },
    });
    clearedOutboundField.brand = globalData?.sms?.isBrandSelectionSkipped ? null : clearedOutboundField.brand;
    setOutboundMessage(clearedOutboundField);

    //Clear the value in the redux store
    const messageData = {
      ..._.cloneDeep(outboundMessage),
      message: {
        attachmentUrls: attachments ? [attachments.name] : [],
        attachments: attachments
          ? [
              {
                contentUrl: attachments.name,
                dateDeliveredToKofax: null,
                thumbnailUrl: null,
              },
            ]
          : null,
      },
    };
    const result = !isNullOrUndefined(chatResults) ? [...chatResults, messageData] : [messageData];
    setChatResults(result);
    const messageStackCopy = cloneDeep(messageStack);
    messageStack.Reset();
    messageStack.messageStack = {
      ...messageStackCopy.messageStack,
      results: result,
    };
    dispatch(
      notificationDataActions.setSmsData({
        ...globalData.sms,
        attachments: undefined,
        data: { ...globalData.sms.data, body: '', attachment: attachments, scrollToMessage: undefined },
      }),
    );

    dispatch(
      notificationDataActions.updateChatHistory({
        ...globalData.sms.chatHistory,
        chats: _.cloneDeep({ ...globalData.sms.chatHistory?.chats, results: result }),
      }),
    );
    const tos = [
      {
        phoneNumber: TwilioExtensionCode.concat(unformatPhoneNumber(phoneNumber)),
        contactType: 'candidate',
      },
    ];
    const { ...publishSmsPayload }: ISmsPayload = _.cloneDeep(outboundMessage);

    const messageForm = PrepareSmsPayload(
      publishSmsPayload,
      globalData,
      enableBrandSelection,
      hasActiveInbox,
      hasActiveSharedInbox,
      user,
      coveredInbox,
      manualEntry,
      attachments,
      tos,
    );
    setAttachments(undefined);
    const response = await publishSms(messageForm);
    if (response.data.published && isNullOrUndefined(chatResults)) {
      dispatch(
        notificationDataActions.setNotificationData({
          ...globalData,
          sms: {
            ...globalData.sms,
            data: { ...globalData.sms.data, tos: tos, body: '' },
            manualEntry: false,
            chatHistory: {
              ...globalData.sms.chatHistory,
              chats: _.cloneDeep({ ...globalData.sms.chatHistory?.chats, results: result }),
            },
            attachments: undefined,
          },
        }),
      );
    }
    if (response.data.attachmentUrls && !!response.data.attachmentUrls.length) {
      const publishedIndex = messageStack.messageStack?.results.findIndex(x => x.id === response.data.id)!;
      if (!!messageStack.messageStack && !!messageStack.messageStack?.results) {
        const attachmentsUrls = response.data.attachmentUrls as string[];
        messageStack.messageStack.results[publishedIndex].message.attachmentUrls = _.cloneDeep(attachmentsUrls);
        let chatDataResults: any = cloneDeep(messageStack.messageStack.results);
        chatDataResults[publishedIndex].message.attachments = {
          contentUrl: response.data.attachmentUrls,
          dateDeliveredToKofax: null,
          thumbnailUrl: null,
        };
        setChatResults(chatDataResults);
        const messageStackCopy = cloneDeep(messageStack);
        messageStack.Reset();
        messageStack.messageStack = {
          ...messageStackCopy.messageStack,
          results: chatDataResults,
        };
        dispatch(
          notificationDataActions.updateChatHistory({
            ...globalData.sms.chatHistory,
            chats: { ...globalData.sms.chatHistory?.chats, results: result },
          }),
        );
      }
    }
    handleInboxUpdate(outboundForPublish, response.data.attachmentUrls);
    dispatch(candidateDetailActions.updateLastContactedOn(moment(new Date()).toISOString()));
    hasActiveInbox &&
      !hasActiveSharedInbox &&
      logOutboundCoveredSMS(
        smsCoverageFlag.enabled,
        coveredInbox?.activeInbox.coveredUserId,
        String(user.userInfo?.employeeId),
        outboundMessage.publishedOn,
      );
    scrollToBottom();
  };

  const handleScroll = async (e: any, flag = false) => {
    const element = e?.target;
    if ((element?.scrollTop === 0 || flag) && globalData?.sms?.chatHistory?.chats?.nextPageExists) {
      setCurrentChatId();
      setLoader(true);
      await fetchLogByPageNumber(
        false,
        globalData.sms.chatHistory!.chats!.pageNumber,
        globalData,
        candidateDetails,
        hasActiveInbox,
        coveredInbox,
        smsData,
        setLoader,
        handleSubmitResponse,
        dispatch,
        notificationDataActions,
        messageStack,
      ).then(() => {
        setActiveScrollPosition(true);
      });
    }
  };

  useEffect(() => {
    if (activeScrollPosition) {
      setScrollPosition();
    }
    return () => {
      setActiveScrollPosition(false);
    };
  }, [activeScrollPosition]);

  const setCurrentChatId = () => {
    storedChatId.current = GetOldestChatId(chatResults);
  };

  const setScrollPosition = () => {
    setTimeout(() => {
      const msg = document.getElementById(storedChatId.current);
      msg?.scrollIntoView();
    }, 250);
  };

  const updateReadStatus = () => {
    const activeInboxSender = GetActiveSender(coveredInbox);
    const readStatusPayload: IInboxReadStatusCommand = {
      sender: {
        phoneNumber: hasActiveInbox ? activeInboxSender?.phoneNumber : globalData?.sms?.data?.sender?.phoneNumber,
      },
      contactId: globalData.sms.data.tos?.find(Boolean)?.contactId?.toString() || '',
      isRead: true,
      channel: ChannelType.sms,
      readOn: new Date(),
      phoneNumber: globalData.sms.data.tos?.find(Boolean)?.phoneNumber || '',
    };
    dispatch(notificationDataActions.updateMessageReadStatus(readStatusPayload));
  };

  const handleInboxUpdate = (message, attachmentUrls: string[]) => {
    let updatedInboxResult = _.cloneDeep([...globalData.inbox]);
    var existingMessage = updatedInboxResult
      .filter(item =>
        item.travelerId !== ''
          ? item.travelerId === message.tos?.find(Boolean).contactId
          : item.name === message.tos?.find(Boolean).phoneNumber,
      )
      .find(Boolean);
    const associatedRecords = [
      {
        name: 'travelerId',
        value: candidateDetails?.travelerId,
      },
      {
        name: 'recruiterId',
        value: candidateDetails?.recruiter?.recruiterId,
      },
      {
        name: 'recruiter',
        value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
      },
      {
        name: 'brandId',
        value: candidateDetails?.brandId,
      },
    ];
    if (existingMessage) {
      //Removing the value from existing inbox records
      updatedInboxResult = updatedInboxResult.filter(item => item !== existingMessage);
      //Updating the existing record value in inbox
      existingMessage.log.id = message.id;
      existingMessage.log.publishedOn = new Date();
      existingMessage.log.message.body = message?.body;
      existingMessage.log.message.attachmentUrls = attachmentUrls;
      existingMessage.log.message.direction = IDirection.Outbound;
      existingMessage.log.associatedRecords = associatedRecords;
      updatedInboxResult.unshift(existingMessage);
    } else {
      const logMessage = {
        sender: message?.sender,
        tos: message?.tos,
        body: message?.body,
        direction: IDirection.Outbound,
      };
      const newLog = {
        name: message.tos?.find(Boolean).name,
        travelerId: message.tos?.find(Boolean).contactId,
        log: { associatedRecords: associatedRecords, message: logMessage, publishedOn: new Date() },
        unreadLogs: 0,
        publishedOn: new Date(),
      };
      updatedInboxResult.unshift(newLog);
    }
    dispatch(notificationDataActions.updateInbox(updatedInboxResult));
  };

  const handleConfirmationModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        sms: {
          ...globalData.sms,
          chatHistory: {},
          data: null,
          open: false,
          minimized: false,
          manualEntry: false,
          manuallyEnteredNumber: null,
        },
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
    if (!manualEntry) updateReadStatus();
  };

  return (
    <React.Fragment>
      {promiseInProgress ? (
        <CustomBackdrop open={promiseInProgress} />
      ) : (
        <Box className={`${classes.paper} ${drawerOpened ? classes.paperRightWithDrawerOpened : classes.paperRight}`}>
          {isDragActive && <UploadFileBackdropSms open getRootProps={getRootProps} />}
          <SmsAppBar
            chatResults={chatResults}
            isNotesOpen={isNotesOpen}
            selectedKofaxLinks={selectedKofaxLinks}
            setNotesOpen={setNotesOpen}
            scrollToBottom={scrollToBottom}
            attachments={attachments}
            outboundMessage={outboundMessage}
            updateReadStatus={updateReadStatus}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setLoader={setLoader}
          />
          {isNotesOpen && (
            <SmsHeaderNotes initialNotes={initialNotes} setNotesOpen={setNotesOpen} scrollToBottom={scrollToBottom} />
          )}
          {!outboundMessage.expand && !isNotesOpen && (
            <div
              {...getRootProps()}
              className={`${classes.scroll} scroll-class`}
              onScroll={handleScroll}
              id="scrollArea"
              style={{
                height: isReadOnly ? '480px' : '400px',
              }}
            >
              {loader && <CircularProgress className={classes.MuiCircularProgressRoot} />}

              {chats.map(function (messages, i) {
                return (
                  <React.Fragment>
                    <DividerWithText>{messages.period}</DividerWithText>
                    <div>
                      {messages.chats.map((chat, index) => {
                        return (
                          <>
                            {unreadMessageId === chat.id ? <DividerWithText>{`Last Read`}</DividerWithText> : undefined}
                            {smsData?.scrollToMessage && smsData?.scrollToMessage === chat.id ? (
                              <div ref={scrollRef} key={chat?.id} />
                            ) : undefined}
                            <Chat
                              resetSelected={!globalData.sms.chatHistory?.chats?.notifySmsUponAttachmentSendViaEmail}
                              attachments={chat?.message?.attachments}
                              chatId={chat.id}
                              message={
                                chat?.inProgress || chat?.error || chat.processed ? chat?.body : chat?.message?.body
                              }
                              direction={chat?.message?.direction}
                              time={chat?.publishedOn}
                              inProgress={chat?.inProgress}
                              error={chat?.error || chat?.deliveryStatus === 'Error'}
                              resendMessage={resendMessage}
                              removeKofaxLink={(fileName: string) => {
                                const copiedLinks: [string, string, string][] = [];
                                for (const link of selectedKofaxLinks) {
                                  if (link[0] === fileName) continue;
                                  copiedLinks.push([link[0], link[1], link[2]]);
                                }
                                setSelectedKofaxLinks(copiedLinks);
                              }}
                              addKofaxLink={(fileName: string, url: string, signedUrl: string) => {
                                const copiedLinks: [string, string, string][] = [];
                                for (const link of selectedKofaxLinks) {
                                  copiedLinks.push([link[0], link[1], link[2]]);
                                }
                                copiedLinks.push([fileName, url, signedUrl]);
                                setSelectedKofaxLinks(copiedLinks);
                              }}
                              selectedKofaxCount={selectedKofaxLinks.length}
                              hasResponse={!!chat?.response}
                              errorText={chat?.response?.errors?.[0]}
                              allowResend={chat.response?.canRetry}
                            />
                          </>
                        );
                      })}
                    </div>
                    <div ref={smsData?.scrollToMessage === undefined ? messagesEndRef : null} />
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {globalData?.snackbarData?.channel === ChannelType.sms &&
            !globalData.snackbarData?.changeWarning &&
            !isNotesOpen && (
              <Box className={classes.snackBar}>
                <CloseConfirmation
                  yesAction={handleConfirmationModalClose}
                  noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                />
              </Box>
            )}

          {!isNotesOpen && !isReadOnly && (
            <SmsInputField
              attachments={attachments}
              setAttachments={setAttachments}
              getInputProps={getInputProps}
              outboundMessage={outboundMessage}
              phoneNumber={phoneNumber}
              setOutboundMessage={setOutboundMessage}
              handleSend={handleSend}
              scrollToBottom={scrollToBottom}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
};
