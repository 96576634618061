import { Theme } from "amn-ui-core";
import { makeStyles } from 'tss-react/mui';


export const useEmailValidatorStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    typography: {
        color: theme.palette.framework.system.darkRed,
    },
    chipsContainer: {
        color: theme.palette.framework.system.white,
    },
    textFieldResize: {
        height: '40px',
        backgroundColor: '#FBFBFB',
    },
    textFieldNew: {
        backgroundColor: '#FBFBFB',
        borderBottom: `1px solid ${theme.palette.framework.system.lightGrey}`,
        borderBottomStyle: 'solid',
        '& .MuiInputBase-root': {
            backgroundColor: '#FBFBFB',
            paddingBottom: '0px',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#FBFBFB',
        },
        '& .MuiInputBase-root:hover': {
            backgroundColor: '#FBFBFB',
            paddingBottom: '0px',
            borderColor: theme.palette.framework.system.lightGrey,
        },
        '& .MuiOutlinedInput-root:hover': {
            backgroundColor: '#FBFBFB',
        },
        '& .Mui-disabled:before': {
            borderBottom: '1px solid #9A9A9A !important',
        },
        '& .MuiAutocomplete-inputRoot': {
            background: 'none !important',
        },
        '& .MuiAutocomplete-inputRoot.Mui-disabled:hover:before': {
            borderBottom: '1px solid #9A9A9A !important',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontWeight: 450,
        },
    },

    chip: {
        background: theme.palette.framework.system.white,
        color: theme.palette.framework.system.charcoal,
        margin: '4px 4px ',
    },
    consentWithdrawnIcon: {
        height: '5px',
        width: '5px',
        textAlign: 'center',
        marginTop: '5px',
    },
    chipError: {
        background: '#ffe8e9',
        color: theme.palette.framework.system.charcoal,
        margin: '0px 4px ',
        border: '1px solid #f07c7d',
        '& .MuiChip-avatar': {
            height: '27px',
            marginRight: '-10px',
            marginLeft: '5px',
        },
        '& .MuiChip-label': {
            color: theme.palette.system.darkRed,
            fontWeight: 450,
        },
    },
    showMore: { color: theme.palette.framework.system.lightNavyBlue },
}));