import { Box, Grid, Tooltip } from 'amn-ui-core';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import AddNotesDisabled from 'app/assets/images/Notification/add-notes-disabled.svg';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { ChannelType, ContactType } from '../../Constants';
import {
  notificationSelection,
  selectSmsCandidateConsents,
} from 'store/redux-store/notification/notification.selector';
import { canShowSidePanel, groupMessages } from './Helpers/Helper';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import {
  callUnknownNumber,
  getDefaultSender,
  setNotificationData,
} from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { selectUser } from 'oidc/user.selectors';
import { FetchConversationNotes } from 'app/services/NotificationServices/NotificationService';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { useMessagingStyles } from './Messaging.styles';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';
import { IEmployee } from 'app/models/Notification/Notification';
import { useSmsFeatureFlags } from './useSmsDropzone';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import CallDisabledIcon from 'app/assets/images/Notification/Inbox/CallDisabledIcon.svg';
import DisableMailIcon from 'app/assets/images/Notification/Inbox/DisableMailIcon.svg';
import AddNotes from '@mui/icons-material/NoteAddOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { NotificationContext } from '@AMIEWEB/Notification/NotificationWrapper';
import { GetActiveSender } from './Helpers/MessagingHelper';

interface ISmsHeaderProps {
  isNotesOpen: boolean;
  setNotesOpen: (value: boolean) => void;
}

export const SmsHeader = ({ isNotesOpen, setNotesOpen }: ISmsHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { displayCandidateSidePanel } = useContext(NotificationContext);

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  const { classes } = useMessagingStyles({ unknownNum: false, warningMessage: false, enableBrandSelection: false });

  const user = useSelector(selectUser);
  const globalData = useSelector(notificationSelection);
  const coveredInbox = useSelector(selectCoveredInbox);
  const consents = useSelector(selectSmsCandidateConsents);

  const { hasActiveInbox } = useSmsFeatureFlags(coveredInbox, user);

  const smsData = globalData?.sms?.data;
  const unknownNum = !globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
  const manualEntry = globalData?.sms?.manualEntry;
  const candidateDetails = globalData.sms.chatHistory!.candidateDetails;
  const chats = groupMessages(globalData?.sms?.chatHistory?.chats?.results || []);

  const activeCoveredSender: IEmployee = GetActiveSender(coveredInbox);
  const SENDER: IEmployee = getDefaultSender(user);

  const handleMouseDown = event => {
    event.preventDefault();
  };

  const handleAddNotesOnClick = async () => {
    const request = {
      brand: smsData?.brand,
      brandId: !!smsData?.tos[0].brandId ? smsData?.tos[0].brandId?.toString() : null,
      sender: hasActiveInbox ? activeCoveredSender : SENDER,
      tos: smsData?.tos[0],
      publishedOn: new Date(),
    };
    await FetchConversationNotes(request)
      .then(response => dispatch(notificationDataActions.updateNotes(response)))
      .catch(err => console.log(err));
    setNotesOpen(true);
  };

  const skills = () => {
    const verifiedSkills: [string, string][] = [];

    candidateDetails?.verifiedSkills.forEach(skill => {
      if (verifiedSkills.findIndex(x => x[0] === skill.disciplineAbbr && x[1] === skill.specialtyAbbr) === -1) {
        verifiedSkills.push([skill.disciplineAbbr, skill.specialtyAbbr]);
      }
    });
    return verifiedSkills.map(x => `${x[0]}, ${x[1]}`).join(' | ');
  };

  const handleNotificationData = async (
    channel: ChannelType,
    contactType: ContactType | null,
    manualLog: boolean = false,
  ) => {
    if (smsData?.tos?.find(Boolean)?.contactId) {
      setNotificationData(
        user,
        user.userInfo?.phoneNumber,
        candidateDetails,
        dispatch,
        globalData,
        channel,
        contactType,
        manualLog,
        skills,
        coveredInbox,
      );
    } else {
      callUnknownNumber(user, smsData, dispatch, globalData, channel);
    }
  };

  const headerName = globalData?.sms?.data?.tos?.find(Boolean)?.name
    ? CapitalizeEveryFirstLetter(
        (globalData?.sms?.data?.tos?.find(Boolean)?.name.length > 15
          ? globalData?.sms?.data?.tos?.find(Boolean)?.name.substring(0, 15) + '...'
          : globalData?.sms?.data?.tos?.find(Boolean)?.name
        ).toLowerCase(),
      )
    : formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber);

  const todayMessages = chats.filter(chat => chat.period === 'Today');

  return !isNotesOpen ? (
    <Tooltip
      classes={{ tooltip: classes.headerTooltip, arrow: classes.headerTooltipArrow }}
      arrow
      title={
        <>
          {canShowSidePanel(globalData?.sms?.chatHistory?.chats?.results, globalData?.sms?.data?.tos) && (
            <AccountCircleOutlinedIcon
              fontSize="small"
              className={classes.addNotesIcon}
              onMouseDown={handleMouseDown}
              onClick={() => {
                dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
                dispatch(globalActions.resetAllRecentHistoryDrawers());
                displayCandidateSidePanel();
                dispatch(globalActions.setSmsIconDrawer({ open: true }));
                dispatch(globalActions.setCandidateProfileDrawer({ open: false }));
              }}
            />
          )}
          {!consents?.hasCallConsents ? (
            <img src={CallDisabledIcon} alt="Phone disabled" className={classes.phoneDisabledIcon} />
          ) : (
            <>
              {!unknownNum ? (
                enableVoiceCommunication ? (
                  candidateDetails?.primaryPhoneNumber ? (
                    globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                      <PhoneOutlinedIcon
                        className={classes.addNotesIcon}
                        onMouseDown={handleMouseDown}
                        onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary)}
                      />
                    )
                  ) : candidateDetails?.secondary ? (
                    globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                      <PhoneOutlinedIcon
                        className={classes.addNotesIcon}
                        onMouseDown={handleMouseDown}
                        onClick={() => handleNotificationData(ChannelType.voice, ContactType.secondary)}
                      />
                    )
                  ) : (
                    <PhoneOutlinedIcon
                      className={classes.addNotesIcon}
                      onMouseDown={handleMouseDown}
                      onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary)}
                    />
                  )
                ) : (
                  <img src={PhoneDisabled} alt="Phone disabled" />
                )
              ) : (
                <></>
              )}
            </>
          )}

          {!consents?.hasEmailConsents ? (
            <img src={DisableMailIcon} alt="mail disabled" className={classes.emailDisabledIcon} />
          ) : (
            <>
              {' '}
              {enableEmailCommunication ? (
                candidateDetails?.primaryEmail ? (
                  globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                    <MailOutlinedIcon
                      className={classes.addNotesIcon}
                      onMouseDown={handleMouseDown}
                      onClick={() => handleNotificationData(ChannelType.email, ContactType.primary)}
                    />
                  )
                ) : candidateDetails?.secondaryEmail ? (
                  globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                    <MailOutlinedIcon
                      className={classes.addNotesIcon}
                      onMouseDown={handleMouseDown}
                      onClick={() => handleNotificationData(ChannelType.email, ContactType.secondary)}
                    />
                  )
                ) : (
                  <img src={EmailDisabled} alt="Email disabled" />
                )
              ) : (
                <img src={EmailDisabled} alt="Email disabled" />
              )}
            </>
          )}

          {todayMessages.length === 0 ? (
            <img src={AddNotesDisabled} style={{ marginLeft: '4px', marginRight: '4px' }} />
          ) : (
            <AddNotes
              fontSize="small"
              className={classes.addNotesIcon}
              onMouseDown={handleMouseDown}
              onClick={() => handleAddNotesOnClick()}
            />
          )}
        </>
      }
    >
      <Grid className={classes.candidateHeader}>
        <Box className={classes.header} style={{ fontSize: '19px' }}>
          {globalData?.sms?.data?.tos?.find(Boolean)?.contactId
            ? `${headerName} (${globalData?.sms?.data?.tos?.find(Boolean)?.contactId})`
            : !manualEntry
            ? formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber)
            : t('notification.sms.newNumber')}
        </Box>
        <Box className={classes.otConsentheader}>
          {!consents?.hasTextConcents && t('notification.sms.smsConsentWithdrawn')}
        </Box>
      </Grid>
    </Tooltip>
  ) : (
    <Box className={classes.header}>
      {globalData?.sms?.data?.tos?.find(Boolean)?.contactId
        ? `${headerName} (${globalData?.sms?.data?.tos?.find(Boolean)?.contactId})`
        : formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber)}
    </Box>
  );
};
