import { FilterCompTypes } from "app/ComponentLibrary/Filter/utils";
import { addDays, addMonths, endOfMonth, startOfMonth } from "date-fns";
import i18next from "i18next";

export enum FacilityCommunicationHistoryFiltersrName {
    communicationType = 'communicationType',
    initiatedBy = 'initiatedBy',
    date = 'date',
    contactName = 'contactName',
    units = 'units',
    contactStatus = 'contactStatus',
    userRole = 'userRole',
}

export const contactStatus = [
    { id: 0, value: 1, name: 'Active', label: 'Active' },
    { id: 1, value: 2, name: 'Inactive', label: 'Inactive' },
];

export class CommunicationHistoryFilters {
    constructor(public filterSpecs: any, public gridStateFilters: any) { }
    getCommunicationHistoryFilters = () => {
        const inputDate = new Date();
        return [
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.communicationType'),
                name: FacilityCommunicationHistoryFiltersrName.communicationType,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                options: this.filterSpecs.communicationType,
                isMultiSelect: true,
                isSelectAll: true,
            },
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.initiatedBy'),
                name: FacilityCommunicationHistoryFiltersrName.initiatedBy,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                isMultiSelect: true,
                options: this.filterSpecs?.initiatedByOpts,
                isSelectAll: true,
            },
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.date'),
                name: FacilityCommunicationHistoryFiltersrName.date,
                type: FilterCompTypes.DATERANGEV2,
                secondaryLabel: i18next.t('facility.facilityCommunicationHistory.date'),
                variant: 'outlined',
                useMaxWidth: true,
                maxWidth: 210,
                clickAway: true,
                disablePortal: true,
                applyOnClickAway: true,
                definedRanges: [
                    {
                        label: 'Today',
                        startDate: inputDate,
                        endDate: inputDate,
                        useLabelAsValue: false,
                    },
                    {
                        label: 'Yesterday',
                        startDate: addDays(inputDate, -1),
                        endDate: addDays(inputDate, -1),
                        useLabelAsValue: false,
                    },
                    {
                        label: 'Last 7 Days',
                        startDate: addDays(inputDate, -6),
                        endDate: inputDate,
                        useLabelAsValue: false,
                    },
                    {
                        label: 'Last 30 Month',
                        startDate: startOfMonth(addMonths(inputDate, -1)),
                        endDate: endOfMonth(addMonths(inputDate, -1)),
                        useLabelAsValue: false,
                    },
                    {
                        label: 'This Month',
                        startDate: startOfMonth(inputDate),
                        endDate: endOfMonth(inputDate),
                        useLabelAsValue: false,
                    },
                    {
                        label: 'Last Month',
                        startDate: startOfMonth(addMonths(inputDate, -1)),
                        endDate: endOfMonth(addMonths(inputDate, -1)),
                        useLabelAsValue: false,
                    }
                ],
            },
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.contactName'),
                name: FacilityCommunicationHistoryFiltersrName.contactName,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                isMultiSelect: true,
                options: this.filterSpecs.contactNameOpts,
                isSelectAll: true,
            },
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.units'),
                name: FacilityCommunicationHistoryFiltersrName.units,
                type: FilterCompTypes.UNITFILTERSEARCH,
                applyOnClickAway: true,
                options: this.filterSpecs.units,
                isMultiSelect: true,
                isSelectAll: true,
            },
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.contactStatus'),
                name: FacilityCommunicationHistoryFiltersrName.contactStatus,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                options: contactStatus,
            },
            {
                filterName: i18next.t('facility.facilityCommunicationHistory.userRole'),
                name: FacilityCommunicationHistoryFiltersrName.userRole,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                isMultiSelect: true,
                options: this.filterSpecs.userRole,
                isSelectAll: true,
            },
        ];
    };
}