import { useTheme } from 'amn-ui-core';
import { NotificationSnackbarProps } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useInjectReducer } from 'redux-injectors';
import { ChannelType, PAGE_SIZE, SendType } from './Constants';
import { Email } from './MultiChannel/Email/Email';
import { BrandSelection } from './MultiChannel/Sms/CredentialingAnalyst/BrandSelectionModal';
import { Sms } from './MultiChannel/Sms/Messaging';
import { ManualCallLog } from './MultiChannel/Voice/ManualCallLog';
import { Voice } from './MultiChannel/Voice/Voice';
import {
  notificationDataActions,
  notificationDataKey,
  notificationDataReducer,
} from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { BulkSms } from './MultiChannel/BulkSms/bulkSms';
import { SmsType } from 'app/enums/Sms';
import { DrawerFitted } from '@AMIEWEB/Common';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { CandidateDrawerPreviewer } from '@AMIEWEB/GlobalSearch/Candidate/CandidateDrawerPreviewer';
import { selectSmsIconDrawer } from 'app/ApplicationRoot/Global.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { GetUserLogs, handleSkipBrandSelection } from 'app/services/NotificationServices/NotificationService';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import moment from 'moment';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';

export const NotificationContext = createContext<any | null>(null);

const CLOSE_MESSAGE = `Are you sure you want to cancel?`;
export const dataValidator = data => {
  let error = false;
  if (data.channel === ChannelType.email || data.channelType === ChannelType.sms) {
    data.brand === null ||
    data.brand === '' ||
    data.brand === undefined ||
    data.useType === null ||
    data.useType === '' ||
    data.useType === undefined ||
    data.sender?.senderId === ''
      ? (error = true)
      : (error = false);
  }
  if (data.channel === ChannelType.voice) {
    data?.tos === null ||
    data?.tos === undefined ||
    (data?.tos === null && data?.tos?.count() !== 1) ||
    data.brand === null ||
    data.brand === '' ||
    data.brand === undefined ||
    data.useType === null ||
    data.useType === '' ||
    data.useType === undefined ||
    data.sender?.userId === '' ||
    data.purpose === ''
      ? (error = true)
      : (error = false);
  }
  return error;
};
export default function NotificationWrapper() {
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);
  const notificationData = useSelector(notificationSelection);
  const user = useSelector(selectUser);
  const [validationError, setValidationError] = useState<boolean>(dataValidator(notificationData));
  const [showBrandSelection, setShowBrandSelection] = useState(false);
  const globalData = useSelector(notificationSelection);
  const smsIconDrawer = useSelector(selectSmsIconDrawer);
  const { activeInbox } = useSelector(selectCoveredInbox);
  const coveredInbox = useSelector(selectCoveredInbox);
  const showCandidateSidePanel = smsIconDrawer?.open;
  const [attachments, setAttachments] = useState<File[]>([]);
  const [urlAttachments, setUrlAttachments] = useState<File[]>([]);
  const [senderMenuValue, setSenderMenuValue] = useState<any>(null);
  const hasChecked = React.useRef<any>(false);
  const open = notificationData.sms.open;
  const minimize = notificationData.sms.minimized;
  const theme = useTheme();
  const messages = globalData?.sms?.chatHistory?.chats?.results;

  const hasActiveSharedInbox =
    sharedDeskFlag?.enabled &&
    !!coveredInbox?.activeInbox &&
    coveredInbox?.activeInbox?.coveredUserId == user.userInfo?.sharedProfile?.employeeId;

  useInjectReducer({ key: notificationDataKey, reducer: notificationDataReducer });
  const dispatch = useDispatch();
  useEffect(() => {
    if (dataValidator(notificationData)) {
      handleSnackbarData({
        showCloseAction: false,
        showResponse: true,
        severity: 'error',
        message: 'Missing or invalid data.Please check the data sent to notification service',
      });
      setValidationError(true);
    }

    minimizedToasterCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);

  const checkConversationWithoutBrandId = (results): boolean => {
    if (results && results?.length > 0) {
      for (const result of results) {
        if (result.associatedRecords) {
          const hasBrandId = result.associatedRecords.some(record => record.name.toLowerCase() === 'brandid');
          if (!hasBrandId) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }
  };

  const isSameDayConversation = (checkDate: string, currentDate: string) => {
    return (
      moment(checkDate) > moment(currentDate).startOf('day') && moment(checkDate) < moment(currentDate).endOf('day')
    );
  };

  useEffect(() => {
    const chatResults = notificationData?.sms?.chatHistory?.chats?.results;
    const hasConversationWithoutBrandId = checkConversationWithoutBrandId(chatResults);
    async function checkBrandSelection() {
      const logsPayload = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        contactId: notificationData.sms?.data?.tos?.[0]?.contactId,
        channel: ['Conversation'],
        brand: notificationData.sms?.data?.tos?.[0]?.brand,
        brandId: notificationData.sms?.data?.tos?.[0]?.brandId?.toString(),
        sendType: SendType.one_to_one,
        initiatedBy: activeInbox?.phoneNumber,
      };
      if (!notificationData.sms.open && !notificationData.sms.minimized) {
        setShowBrandSelection(false);
      } else {
        const conversationLogs = await GetUserLogs(logsPayload);
        const sortedLogs = conversationLogs?.results?.sort((a, b) => {
          const dateA = new Date(a.publishedOn);
          const dateB = new Date(b.publishedOn);
          return dateB - dateA;
        });

        let isBrandSelectionSkipped;
        if (hasActiveSharedInbox) {
          const recordsInRange = sortedLogs?.filter(log =>
            isSameDayConversation(log.publishedOn, sortedLogs?.[0]?.publishedOn),
          );
          const skipRecord = recordsInRange?.some(record =>
            record?.associatedRecords?.some(item => item?.name === 'skipBrandSelection'),
          );
          isBrandSelectionSkipped = skipRecord;
        } else {
          const record = sortedLogs?.[0]?.associatedRecords?.some(item => item?.name === 'skipBrandSelection');
          isBrandSelectionSkipped = record;
        }
        dispatch(notificationDataActions.setIsBrandSelectionSkipped(isBrandSelectionSkipped));
        setShowBrandSelection(
          user &&
            Authorized(
              [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
              user.userInfo,
            ) &&
            notificationData.sms.chatHistory?.chats?.results?.length > 0 &&
            notificationData.sms.chatHistory.chats?.results?.find(Boolean)?.message?.tos?.find(Boolean)?.contactId &&
            hasConversationWithoutBrandId &&
            !notificationData.sms.readonly &&
            !isBrandSelectionSkipped,
        );
        handleChecked();
        dispatch(
          notificationDataActions.setEnableBrandSelection(
              notificationData.sms.chatHistory?.chats?.results?.find(Boolean)?.message?.tos?.find(Boolean)?.contactId &&
              hasConversationWithoutBrandId,
          ),
        );
      }
    }
    checkBrandSelection();
  }, [user, open, minimize]);

  const minimizedToasterCheck = () => {
    if (notificationData.email.open) {
      toast.dismiss('email');
    }
    if (notificationData.voice.open) {
      toast.dismiss('voice');
    }
    if (notificationData.log.open) {
      toast.dismiss('manualVoiceCall');
    }
    if (notificationData.sms.open) {
      toast.dismiss('sms');
    }
  };

  const onClose = (channel: ChannelType, manualLog: Boolean = false, message: String = CLOSE_MESSAGE) => {
    dispatch(
      notificationDataActions.setSnackBarData({
        manual: manualLog,
        channel: channel,
        showCloseAction: true,
        showResponse: false,
        message: message,
        severity: 'none',
      }),
    );
    if (channel === ChannelType.sms) {
      handleChecked();
    }
  };

  const handleSnackbarData = (response: NotificationSnackbarProps) => {
    response?.showResponse &&
      dispatch(
        globalActions.setSnackBar({
          severity: response.severity,
          message: response.message,
          actions: response.showCloseAction
            ? [
                {
                  closeAction: true,
                  closeSnackbar: true,
                  action: onNotificationSnackbarClose,
                  text: null,
                },
              ]
            : [],
        }),
      );
  };

  const onNotificationSnackbarClose = () => {
    if (notificationData.log.channel === ChannelType.email) {
      dispatch(globalActions.setActiveStep(1));
    }
  };

  const handleSubmitResponse = (showResponse, severity, message) => {
    handleSnackbarData({ showCloseAction: false, showResponse: showResponse, severity: severity, message: message });
  };

  const _onBrandSelection = () => {
    setShowBrandSelection(false);
  };

  const onSkip = async () => {
    const logs = notificationData.sms?.chatHistory?.chats?.results || [];
    const contactId = logs?.[logs.length - 1]?.message?.tos?.[0]?.contactId;
    const logsPayload = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      contactId: contactId,
      channel: ['Conversation'],
      sendType: SendType.one_to_one,
    };
    const conversationLogs = await GetUserLogs(logsPayload);
    const sortedLogs = conversationLogs?.results?.sort((a, b) => {
      const dateA = new Date(a.publishedOn);
      const dateB = new Date(b.publishedOn);
      return dateB - dateA;
    });

    sortedLogs?.length &&
      sortedLogs?.[0]?.id &&
      (await handleSkipBrandSelection(sortedLogs?.[0]?.id, contactId, true).then(() => {
        setShowBrandSelection(false);
        dispatch(
          notificationDataActions.setSmsData({
            ...globalData.sms,
            isBrandSelectionSkipped: true,
          }),
        );
      }));
  };

  const handleChecked = () => {
    hasChecked.current = false;
  };

  const displayCandidateSidePanel = () => {
    dispatch(globalActions.setDetailsPageDrawer({ open: false }));
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(globalActions.setSmsIconDrawer({ open: true }));
  };

  const enableBrandSelection = useMemo(() => {
    return (
      notificationData?.sms?.enableBrandSelection &&
      user &&
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
        user.userInfo,
      )
    );
  }, [notificationData?.sms?.enableBrandSelection, user?.userInfo]);

  const handleBrandSelection = () => {
    setShowBrandSelection(true);
  };

  const checkBrandAvailability = () => {
    if (showBrandSelection) {
      return (
        <>
          <BrandSelection
            openModal={showBrandSelection}
            travelerId={notificationData.sms.data?.tos?.find(Boolean)?.contactId}
            handleBrandSelection={_onBrandSelection}
            handleSkip={onSkip}
            onClose={handleChecked}
          />
          <Sms />
        </>
      );
    } else {
      return <Sms />;
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        data: notificationData?.email?.data,
        handleSubmitResponse,
        onClose,
        enableBrandSelection,
        handleBrandSelection,
        displayCandidateSidePanel,
        handleSmsClose: handleChecked,
      }}
    >
      {!validationError && notificationData.email?.open && (
        <Email
          attachments={attachments}
          setAttachments={setAttachments}
          urlAttachments={urlAttachments}
          setUrlAttachments={setUrlAttachments}
          senderMenuValue={senderMenuValue}
          setSenderMenuValue={setSenderMenuValue}
        />
      )}
      {!validationError && notificationData.voice?.open && <Voice />}
      {!validationError && notificationData.log?.open && <ManualCallLog />}
      {!validationError &&
        notificationData.sms?.open &&
        notificationData.sms.sendType === SmsType.one_to_one &&
        checkBrandAvailability()}
      {!validationError && notificationData.sms?.open && notificationData.sms.sendType === SmsType.bulk && <BulkSms />}
      {showCandidateSidePanel && (
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalActions.setSmsIconDrawer({ open: false }));
          }}
          width={400}
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={showCandidateSidePanel || false}
        >
          {
            <CandidateDrawerPreviewer
              isSearchGrid={false}
              isWorkDesk={false}
              hideNavigationArrows={true}
              travelerId={globalData.sms.data?.tos?.find(Boolean)?.contactId}
              brandId={
                (messages &&
                  messages.some(item => item) &&
                  messages.find(Boolean)!.associatedRecords &&
                  messages.find(Boolean)!.associatedRecords?.find(record => record.name.toLowerCase() === 'brandid')
                    ?.value) ||
                user.userInfo?.companyId
              }
              closeDrawer={() => {
                dispatch(globalActions.setSmsIconDrawer({ open: false }));
              }}
            />
          }
        </DrawerFitted>
      )}
    </NotificationContext.Provider>
  );
}
