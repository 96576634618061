import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import PDFIcon from 'app/assets/images/IconImages/PDFIcon.svg';
import { XGridIconCell } from 'app/components/Common/XGrid/cells/XGridIconCell';
import { PassportXGridOptionsCell } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PassportCredentialTab/PassportXGridOptionsCell';
import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useDispatch } from 'react-redux';
import { candidateDocumentActions } from './store/candidateDocuments.redux';
import KebabMenuItem from '../Common/KebabMenuItem';
import { ICandidatePassportDocuments } from './store/candidateDocuments.state';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { IUser } from 'oidc/user.redux';
import { Link } from 'amn-ui-core';
import { openInNewTab } from 'app/helpers/openInNewTab';

const checkCellViewPermission = (userInfo: IUser) => {
  const isAuthorized = Authorized(
    [
      userRoles.accountManagement_Leadership,
      userRoles.accountManagement_TeamMember,
      userRoles.recruitment_TeamMember,
      userRoles.recruitment_Leadership,
      userRoles.credentialing_Leadership,
      userRoles.credentialing_TeamMember,
      userRoles.clinical_Director,
      userRoles.clinical_Manager,
      userRoles.clinical_QualificationsSpecialist,
      userRoles.clinical_ReviewAnalyst,
    ],
    userInfo.userInfo,
  );
  return isAuthorized;
};

const setupContent = (isViewable: boolean, params) => {
  return (
    <Link
      style={{ paddingLeft: '10px', cursor: isViewable ? 'pointer' : 'not-allowed' }}
      onClick={event => (isViewable ? openInNewTab(params?.row?.url) : event.stopPropagation())}
      variant="body2"
    >
      {params?.row?.formTitle}
    </Link>
  );
};

export const getDocumentColumns = (t, userInfo: IUser) => [
  {
    ...XGridDefaultColumn,
    field: 'docType',
    headerName: 'Document Type',
    sortable: false,
    renderCell: XGridIconCell({
      icon: PDFIcon,
      align: 'left',
      hasTitle: true,
      getTitle: params => setupContent(checkCellViewPermission(userInfo), params),
    }),
  },
  {
    ...XGridDefaultColumn,
    field: 'submitDate',
    headerName: 'Date Added',
    renderCell: XGridOverflowCell()(),
  },
  {
    ...XGridDefaultColumn,
    field: 'fileStatus',
    headerName: 'File Status',
  },
  {
    ...getColumnDefinition(t, 'actions'),
    flex: 0,
    width: 50,
    sortable: false,
    align: 'center',
    disableColumnMenu: false,
    renderCell: PassportXGridOptionsCell({ // TODO: MUI 5 - @Kevin George, icon is missing from this, but required in the renderer. Please review this since an empty string was added
      align: 'center',
      icon: '',
      hoverTemplate: (params: GridCellParams | undefined) => <MenuItems rowMetaData={params} userInfo={userInfo} />,
    }),
  },
];

const getColumnDefinition = (t: Function, key: string) => {
  return {
    ...XGridDefaultColumn,
    field: key,
    headerName: t(`placement.profile.tabs.passportCredentialXGrid.columns.${key}`),
  };
};

const MenuItems = (params: { rowMetaData: GridCellParams | undefined; userInfo?: IUser }) => {
  const selectedRow = params?.rowMetaData?.row as ICandidatePassportDocuments;
  const dispatch = useDispatch();
  const handleDownload = (url: string) =>
    dispatch(candidateDocumentActions.initiatePDFFileDownload({ url: url, formTitle: selectedRow?.formTitle }));
  return (
    <KebabMenuItem
      id="download-pdf-document-menu"
      message="placement.profile.submitPacket.dialog.buttons.download"
      handleClick={() => handleDownload(selectedRow?.url)}
      handleDisableValidation={() => !checkCellViewPermission(params?.userInfo)}
    />
  );
};
