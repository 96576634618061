/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDecision } from '@optimizely/react-sdk';
import { Checkbox, CircularProgress, Grid, IconButton, TextField } from 'amn-ui-core';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { TaskFormBanner } from 'app/components/Common/Banner/TasksFormBanner';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { FormatDate } from 'app/components/Notification/Tasks/Common/FormatData';
import { ff_createexteraltask } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import {
  CC_CALL_TRAVELER_ID,
  ICoverageActionDetails,
  IExternalCC,
  IExternalTask,
  ITask,
  ITaskCategorizedList,
  IUser,
  QS_COORDINATOR,
  TaskCategory,
  TaskCoverageActionType,
  TaskCoverageDescription,
  TaskDescription,
  TaskEntity,
  TaskPriority,
  TaskPriorityList,
  ActionType,
} from 'app/models/Tasks/Tasks';
import { addDays, addHours, addYears, format, isValid, isWithinInterval, parse, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { selectUser } from 'oidc/user.selectors';
import { useReadOnly } from 'oidc/userRoles';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { makeStyles } from 'tss-react/mui';
import { DateRangePicker } from '../../../../app/ComponentLibrary/DateRangeNew';
import { ExternalAssignedToField } from '../CustomComponents/ExternalAssignedToField';
import { TaskAttachment } from '../CustomComponents/TaskAttachments/TaskAttachments';
import { taskDetailsActions } from '../store/Tasks.redux';
import {
  selectExistingTaskAttachments,
  selectExternalTaskCategoryList,
  selectExternalTaskId,
  selectTaskId,
  selectedCategoryList,
} from '../store/Tasks.selectors';
import { AssignedToValue } from './AssignedToEmployeeV2';
import { CCEmployees } from './CCEmployees';
import { convertUserData, fetchplacementEmpData, formatTaskCategoryList } from './FormatData';
import { TaskCategoryInputs } from './TaskCategoryInputs';
import { SelectWithAvatar } from './SelectWithAvatar';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';
import { SelectWithIcon } from '@AMIEWEB/Tasks/CreateTask/SelectWithIcon';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getSharedDeskAssociatedUser } from '../EditTask/helper';

export const createTaskModalStyles = makeStyles<{ createExtTaskFF: any; isExpanded: boolean }>()((theme, props) => ({
  // Modal styles
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: props?.isExpanded ? 'none' : '444px',
      overflow: 'visible',
    },
  },
  dialogContent: {
    paddingTop: props?.createExtTaskFF?.enabled ? '12px !important' : '30px !important',
    padding: '0px 18px 18px 24px',
    width: '100%',
  },
  errorText: {
    '& .MuiFilledInput-underline:before': {
      left: '0',
      right: '0',
      bottom: '0',
      position: 'absolute',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid #FF0000',
      pointerEvents: 'none',
    },
    width: '100%',
  },
  firstGridItem: {
    marginTop: '20px',
  },
  // Modal Body Styles
  inputs: {
    width: '100%',
    overflow: 'auto',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: '#006FB9',
      },
    },
  },
  dueDateContainer: {
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 22px) scale(1)',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '14px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 10px) scale(0.75)',
    },
  },
  dueDateInput: {
    '& .MuiFilledInput-input': {
      height: '1.3736em',
      padding: '27px 12px 10px',
      textAlign: 'left',
      font: 'normal normal normal 12px/19px Roboto',
      letterSpacing: '0px',
      opacity: '1',
    },
  },
  dueDateField: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '4px',
  },
  fieldLabel: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
  },
  ccBtn: {
    padding: '0',
    width: '36px',
    height: '36px',
    minWidth: '0',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: `1px solid ${theme.palette.system.fadedGrey}`,
    borderRadius: '5px',
    opacity: '1',
    boxShadow: 'none',
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0',
    color: theme.palette.framework.system.charcoal,
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
  },
  banner: {
    paddingBottom: '1px !important',
  },
  validationLoader: {
    position: 'absolute',
    zIndex: 999,
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  paper: {
    '& .MuiAutocomplete-listbox > li': {
      borderTop: '1px solid #c7c7c7',
    },
    '& .MuiAutocomplete-listbox > li:first-child': {
      borderTop: 'none',
      marginBottom: '0',
    },
  },
  fileTypeText: {
    textTransform: 'none',
  },
  snackBar: {
    position: 'absolute',
    zIndex: 2,
  },
  fullWidth: {
    width: '100%',
    justifyContent: 'unset',
  },
  flexContainer: {
    width: '16rem',
    display: 'flex',
    alignItems: 'flex-start',
    height: '24px',
  },
  checkBox: {
    '& .MuiCheckbox-root': {
      padding: '0px',
    },
    padding: '0px',
    height: '24px',
  },
  CheckBoxLabel: {
    margin: '3px 0px',
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
    paddingLeft: '9px',
  },
}));

export const CreateTask = ({
  open,
  handleClose,
  getAllIds,
  recruiterDetails,
  container,
}: {
  open: boolean;
  handleClose: () => void;
  getAllIds: any;
  recruiterDetails?: any;
  container: number;
}) => {
  const { t } = useTranslation();
  const [creatExteralTaskFeatureFlag] = useDecision(ff_createexteraltask);
  const dispatch = useDispatch();
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const createdId = useSelector(selectTaskId);
  const externalCreatedId = useSelector(selectExternalTaskId);
  const userInfo = useSelector(selectUser);
  const list = useSelector(selectedCategoryList);
  const externalTaskList = useSelector(selectExternalTaskCategoryList);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const activeCoverage = useSelector(selectActiveCoverage);
  const attachmentAttributes = useSelector(selectExistingTaskAttachments);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'new-task-submit', delay: 0 });
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [placementEmployees, setPlacementEmployees] = useState<ITypeAheadOption[]>([]);
  const [assignedToValue, setAssignedValue] = useState<ITypeAheadOption[]>([]);
  const [externalAssignedToValue, setExternalAssignedValue] = useState<ITypeAheadOption | undefined>();
  const [ccEmpValues, setCCEmpvalues] = useState<ITypeAheadOption[]>([]);
  const [showField, setShowField] = useState<boolean>(false);
  const [showccField, setShowccField] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [optionsList, setOptionsList] = useState(formatTaskCategoryList(list));
  const filteredList =
    externalTaskList?.filter(
      item => item.categoryId !== TaskCategory.SUBMISSIONREQS && item.categoryId !== TaskCategory.BOOKINGREQS,
    ) || [];
  const [externalTaskOptionsList, setExternalTaskOptionsList] = useState(formatTaskCategoryList(filteredList));
  const [showBanner, setShowBanner] = useState(false);
  const [serverErrorBanner, setServerErrorBanner] = useState(false);
  const [taskCategoryValue, setTaskCategoryValue] = useState<ITaskCategorizedList>();
  const [attachments, setAttachments] = useState<any>([]);
  const [externalTask, setExternalTask] = useState<boolean>(false);
  const [dueDateValue, setDueDateValue] = useState<any>('');
  const [descValue, setDescValue] = useState<any>('');
  const [coverageActionDetails, setCoverageActionDetails] = useState<any>(null);
  const [selectedCoverageOption, setSelectedCoverageOption] = useState<any>(null);
  const [selectedPriority, setSelectedPriority] = useState<string>(TaskPriority.Normal.toString());
  const date = new Date();
  const { classes } = createTaskModalStyles({ createExtTaskFF: creatExteralTaskFeatureFlag, isExpanded: expanded });
  const { readOnly } = useReadOnly();
  const createTaskVar = {
    task: 'task',
    taskCategory: 'taskCategory',
    cc: 'cc',
    dueDate: 'dueDate',
    assignedTo: 'assignedTo',
    description: 'description',
    onBehalfOf: 'onBehalfOf',
    taskPriorityId: 'taskPriorityId',
  };

  useEffect(() => {
    if (foundPlacement?.header) {
      fetchplacementEmpData(foundPlacement, setPlacementEmployees);
      dispatch(taskDetailsActions.setPlacementBrandId(foundPlacement?.header?.brandId));
    }
    if (candidateDetails) {
      dispatch(taskDetailsActions.setPlacementBrandId(candidateDetails?.brandId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPlacement?.header?.placementId, candidateDetails]);

  useEffect(() => {
    dispatch(taskDetailsActions.setTaskAttachments(attachments));
  }, [attachments]);

  const getChildComponentsValue = () => {
    const ccValues = ccEmpValues?.map((a: ITypeAheadOption) => a.object).map(d => d.empId);
    const assignedTo = assignedToValue?.map((a: ITypeAheadOption) => a.object)?.map(d => d.empId);
    const externalAssignedTo = externalAssignedToValue?.object?.empId;
    if (externalTask) {
      setValue(createTaskVar.assignedTo, externalAssignedTo);
    } else {
      setValue(createTaskVar.assignedTo, assignedTo);
    }
    setValue(createTaskVar.cc, ccValues);
    setValue(createTaskVar.dueDate, dueDateValue);
    if (assignedTo?.length > 0 || externalAssignedTo) {
      clearErrors(createTaskVar.assignedTo);
    }
  };


  useEffect(() => {
    getChildComponentsValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccEmpValues, assignedToValue, externalAssignedToValue, dueDateValue]);

  useEffect(() => {
    if (externalTask) {
      setExternalTaskOptionsList(formatTaskCategoryList(filteredList));
    } else {
      if (
        list &&
        ((currentPlacementStatus && currentPlacementStatus?.activityStatusId > 2) ||
          (container && container !== TaskEntity.PLACEMENTS))
      ) {
        const filteredListOthers =
          list?.filter(
            item => item.categoryId === TaskCategory.OTHERS || item.categoryId === TaskCategory.CREDENTIALING,
          ) || [];
        setOptionsList(formatTaskCategoryList(filteredListOthers));
      } else if (list) {
        const filteredListExt =
          list?.filter(
            item => item.categoryId !== TaskCategory.SUBMISSIONREQS && item.categoryId !== TaskCategory.BOOKINGREQS,
          ) || [];
        setOptionsList(formatTaskCategoryList(filteredListExt));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const onCloseCall = () => {
    setCancelModalOpen(false);
    setShowField(false);
    setShowccField(false);
    setShowBanner(false);
    setServerErrorBanner(false);
    handleClose();
    setCCEmpvalues([]);
    setAssignedValue([]);
    setExternalAssignedValue(undefined);
    dispatch(taskDetailsActions.setExternalTaskDataId(undefined));
    dispatch(taskDetailsActions.setTaskDataId(undefined));
  };

  const onCloseModal = (e, reason) => {
    if (reason === 'backdropClick') return;
    if (isDirty || showField) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
  };

  const checkDateValidation = value => {
    if (value.length === 0) {
      return t('required');
    }

    if (value.includes('_') || value.includes(' ')) {
      return t('invalidFormat');
    }

    const isoDate = parseISO(value);
    const isDateValid = isValid(isoDate);

    if (isDateValid) {
      const isWithinAllowedInterval = isWithinInterval(isoDate, { start: addDays(date, -1), end: addYears(date, 10) });

      if (isWithinAllowedInterval) {
        clearErrors(createTaskVar.dueDate);
        const formattedDate = format(isoDate, 'MM/dd/yyyy');
        setDueDateValue(formattedDate);
        return null;
      } else {
        return t('invalidDate');
      }
    } else {
      const customDateFormat = parse(value, 'MM/dd/yyyy', new Date(), { locale: enGB });
      const isCustomDateValid = isValid(customDateFormat);
      if (
        isCustomDateValid &&
        isWithinInterval(customDateFormat, { start: addDays(date, -1), end: addYears(date, 10) })
      ) {
        clearErrors(createTaskVar.dueDate);
        const formattedDate = customDateFormat.setHours(12);
        const dueDate = format(formattedDate, 'MM/dd/yyyy');
        setDueDateValue(dueDate);
        return null;
      } else {
        return t('invalidDate');
      }
    }
  };

  const changeExternalTask = event => {
    setExternalTask(event.target.checked);
    setDueDateValue(undefined);
    setDescValue('');
    setAttachments([]);
    const parsedOption = {
      empId: foundPlacement?.header?.candidate?.id,
      label: foundPlacement?.header?.candidate?.firstName + ' ' + foundPlacement?.header?.candidate?.lastName,
      role: '',
    };
    const option: ITypeAheadOption = {
      object: parsedOption,
      label: '',
    };
    const ccUser: IUser[] = [
      {
        id: recruiterDetails?.recruiterId,
        firstName: recruiterDetails?.first,
        lastName: recruiterDetails?.last,
      },
    ];
    const convertedCC = convertUserData(ccUser);
    if (event.target.checked) {
      setShowField(false);
      setTaskCategoryValue(undefined);
      setExternalAssignedValue(option);
      setShowccField(true);
      setCCEmpvalues(convertedCC);
      const ccValues = convertedCC?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
      setTimeout(() => {
        setValue(createTaskVar.cc, ccValues);
      }, 0);
    } else {
      setCCEmpvalues([]);
      setAssignedValue([]);
    }
    setShowBanner(false);
    clearErrors(createTaskVar.task);
    clearErrors(createTaskVar.taskCategory);
    clearErrors(createTaskVar.dueDate);
    clearErrors(createTaskVar.assignedTo);
  };

  const submitTask = formData => {
    const today = new Date();
    if (externalTask) {
      const ccValue: IExternalCC[] = [];
      ccEmpValues?.map(emp => {
        const name: any[] = emp?.object?.label?.split(' ');
        const cc: IExternalCC = {
          ccFirstName: name[0],
          ccLastName: name[1],
          ccId: emp?.object?.empId,
        };
        ccValue.push(cc);
      });
      const newTask = _cloneDeep(formData) as IExternalTask;
      newTask.sentByFirstName = userInfo?.userInfo?.firstName;
      newTask.sentByLastName = userInfo?.userInfo?.lastName;
      newTask.sentById = userInfo?.userInfo?.employeeId || 14583;
      newTask.assignedToCandidateFirstName = foundPlacement?.header?.candidate?.firstName;
      newTask.assignedToCandidateLastName = foundPlacement?.header?.candidate?.lastName;
      newTask.candidateId = foundPlacement?.header?.candidate?.id ? foundPlacement?.header?.candidate?.id : 0;
      newTask.companyId =
        container === TaskEntity.CANDIDATES
          ? candidateDetails?.brandId
          : container === TaskEntity.PLACEMENTS
          ? foundPlacement.header.brandId
          : 0;
      newTask.facilityId =
        container === TaskEntity.PLACEMENTS
          ? foundPlacement.header.facility.id
          : getAllIds?.facilityId
          ? getAllIds?.facilityId
          : 0;
      newTask.orderId =
        container === TaskEntity.PLACEMENTS
          ? foundPlacement.header.orderId
          : getAllIds?.orderId
          ? getAllIds?.orderId
          : 0;
      newTask.placementId =
        container === TaskEntity.PLACEMENTS ? foundPlacement.header.placementId : getAllIds.placementId;
      newTask.sentDate = moment.tz(today, 'America/Los_Angeles').format();
      newTask.taskCategoryId = taskCategoryValue ? taskCategoryValue?.categoryId : TaskCategory.OTHERS;
      newTask.taskNameId = taskCategoryValue ? taskCategoryValue?.taskId : 0;
      newTask.cc = ccValue;
      newTask.dueDate = moment(dueDateValue).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      newTask.isAMIEWebRequest = true;
      dispatch(taskDetailsActions.saveExternalTaskAction(newTask));
    } else {
      const newTask = _cloneDeep(formData) as ITask;
      newTask.sentBy = coverageActionDetails
        ? coverageActionDetails?.coveredForEmployeeId
        : !isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
        ? parseInt(userInfo?.userInfo?.sharedProfile?.employeeId)
        : userInfo?.userInfo?.employeeId || 10;
      newTask.sentDate = moment.tz(today, 'America/Los_Angeles').format();
      newTask.statusId = 1;
      newTask.statusChangeDate = moment.tz(today, 'America/Los_Angeles').format();
      newTask.facilityId =
        container === TaskEntity.PLACEMENTS ? foundPlacement.header.facility.id : getAllIds.facilityId;
      newTask.candidateId =
        container === TaskEntity.PLACEMENTS ? foundPlacement.header.candidate.id : getAllIds.candidateId;
      newTask.placementId =
        container === TaskEntity.PLACEMENTS ? foundPlacement.header.placementId : getAllIds.placementId; // use 7055283 for now
      newTask.orderId = container === TaskEntity.PLACEMENTS ? foundPlacement.header.orderId : getAllIds.orderId;
      newTask.unitId = container === TaskEntity.ORDERS || container === TaskEntity.PLACEMENTS ? getAllIds.unitId : 0; // unit id wil be fetched at DB level
      newTask.taskCategory = taskCategoryValue ? taskCategoryValue?.categoryId : TaskCategory.OTHERS;
      newTask.taskCategoryText = taskCategoryValue ? taskCategoryValue?.groupBy : '';
      newTask.taskNameId = taskCategoryValue ? taskCategoryValue?.taskId : 0;
      newTask.dueDate = moment(dueDateValue).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      newTask.assignedTo = assignedToValue?.map(emp => emp?.object);
      newTask.cc = ccEmpValues?.map(cc => cc?.object);
      newTask.companyId =
        container === TaskEntity.CANDIDATES
          ? candidateDetails?.brandId
          : container === TaskEntity.PLACEMENTS
          ? foundPlacement.header?.brandId
          : 0;
      newTask.taskCoverageAction = coverageActionDetails;
      newTask.taskPriorityId = parseInt(selectedPriority);
      newTask.sharedDeskAssociatedUser = isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
        ? undefined
        : getSharedDeskAssociatedUser(userInfo?.userInfo, ActionType.CreateTask, TaskDescription.CreateTask);
      dispatch(taskDetailsActions.saveTaskAction(newTask));
    }
  };

  const setDefaultDate = () => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    const today = FormatDate(date);
    if (today) {
      setDueDateValue(today);
      clearErrors(createTaskVar.dueDate);
    } else {
      setError(createTaskVar.dueDate, { type: 'required' });
    }
  };

  const handleCoverageSelection = (data: ICoverageDetails) => {
    setSelectedCoverageOption(data.id);
    if (data.id.toString() !== userInfo?.userInfo.employeeId.toString()) {
      setCoverageActionDetails({
        coveredByEmployeeId: data?.coveringEmployeeId,
        coveredByEmployeeFirstName: data?.coveringEmployeeFirstName,
        coveredByEmployeeLastName: data?.coveringEmployeeLastName,
        coveredForEmployeeId: data?.coveredEmployeeId,
        coveredForEmployeeFirstName: data?.coveredEmployeeFirstName,
        coveredForEmployeeLastName: data?.coveredEmployeeLastName,
        actionById: userInfo?.userInfo?.employeeId,
        actionDate: moment.tz(new Date(), 'America/Los_Angeles').format(),
        actionType: TaskCoverageActionType.CREATE_TASK,
        actionTypeDescription: TaskCoverageDescription.CREATE_TASK,
      } as ICoverageActionDetails);
    } else {
      setCoverageActionDetails(null);
    }
  };

  useEffect(() => {
    setDefaultDate();
    setCoverageActionDetails(null);
    setSelectedCoverageOption(null);
  }, [recruiterDetails, externalTask]);

  useEffect(() => {
    if (createdId && Number(createdId) > 0) {
      setServerErrorBanner(false);
      onCloseCall();
    } else if (createdId && Number(createdId) < 0) {
      setServerErrorBanner(true);
    }
    if (externalCreatedId) {
      onCloseCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdId, externalCreatedId]);

  useEffect(() => {
    if (taskCategoryValue && taskCategoryValue?.id === CC_CALL_TRAVELER_ID) {
      setPlacementEmployees(_placementEmployees => {
        _placementEmployees.forEach((item, i) => {
          if (item?.object?.role === QS_COORDINATOR) {
            _placementEmployees.splice(i, 1);
            _placementEmployees.unshift(item);
          }
        });
        return _placementEmployees;
      });
    } else {
      setPlacementEmployees(_placementEmployees =>
        _placementEmployees.sort((a, b) => (a?.object?.label < b?.object?.label ? -1 : 1)),
      );
    }
  }, [taskCategoryValue]);

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={open}
        fullWidth
        className={classes.modalContainer}
        onClose={onCloseModal}
        variant="blue"
        dialogTitleProps={{
          text: externalTask
            ? t('notification.createTask.externalTaskModalName')
            : t('notification.createTask.modalTitle'),
          closeButton: true,
          expandable: true,
          onExpandChange: expanded => {
            setExpanded(expanded);
          },
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent },
        }}
        dialogActions={[
          {
            text: t('notification.createTask.cancelBtn'),
            variant: 'contained',
            color: 'tertiary',
            onClick: e => onCloseModal(e, 'cancel'),
          },
          {
            text: t('notification.createTask.submitBtn'),
            variant: 'contained',
            disabled: !isDirty || isCallingAPI || readOnly,
            tooltipProps: readOnly
              ? {
                  tooltipContent: t('global.readOnlyTooltip'),
                }
              : undefined,
            onClick: async e => {
              if (!readOnly) {
                setShowBanner(false);
                if (showField) {
                  await trigger(createTaskVar.task);
                }
                const validTask = await trigger();
                const assignToCheck = getValues(createTaskVar.assignedTo);
                if (!externalTask && assignToCheck?.length <= 0) {
                  setError(createTaskVar.assignedTo, {
                    type: 'required',
                  });
                  setShowBanner(true);
                } else {
                  if (validTask) {
                    handleSubmit(submitTask)().catch(err => {
                      setServerErrorBanner(true);
                    });
                  } else {
                    setShowBanner(true);
                  }
                }
              }
            },
          },
        ]}
      >
        <Grid container style={{ maxWidth: '444px' }} spacing={4} data-testid="create-task-modal-container">
          {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
          {showBanner && isDirty && !externalTask && (
            <Grid item xs={12} className={classes.banner}>
              <TaskFormBanner message={t('notification.createTask.validationBannerMessage')} />
            </Grid>
          )}
          {showBanner && externalTask && (
            <Grid item xs={12} className={classes.banner}>
              <TaskFormBanner message={t('notification.createTask.validationBannerMessage')} />
            </Grid>
          )}
          {serverErrorBanner && (
            <Grid item xs={12} className={classes.banner}>
              <TaskFormBanner message={t('notification.createTask.APICallEror')} />
            </Grid>
          )}
          <Grid container spacing={4} item xs={12} sx={{ marginTop: '12px' }} className={classes.banner}>
            {!externalTask && activeCoverage?.length > 0 && (
              <Grid item xs={6}>
                <SelectWithAvatar
                  selectedCoverageOption={selectedCoverageOption}
                  handleCoverageSelection={handleCoverageSelection}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <SelectWithIcon
                name={createTaskVar.taskPriorityId}
                label={t('notification.createTask.priority')}
                options={TaskPriorityList}
                value={selectedPriority}
                handleChange={item => {
                  setSelectedPriority(item.value);
                }}
                disabled={isCallingAPI}
              />
            </Grid>
          </Grid>
          {container === TaskEntity.PLACEMENTS && creatExteralTaskFeatureFlag.enabled && (
            <Grid item xs={12}>
              <div className={classes.flexContainer}>
                <div className={classes.checkBox}>
                  <Checkbox color="primary" onChange={changeExternalTask} />
                </div>
                <div className={classes.CheckBoxLabel}>{t('notification.createTask.checkBoxLabel')}</div>
              </div>
            </Grid>
          )}
          <TaskCategoryInputs
            externalTask={externalTask}
            createTaskVar={createTaskVar}
            formMethods={formMethods}
            options={externalTask ? externalTaskOptionsList : optionsList}
            isCallingAPI={isCallingAPI}
            classes={classes}
            setShowField={setShowField}
            showField={showField}
            setTaskCategoryValue={setTaskCategoryValue}
            container={container}
          />
          <Grid item xs={12} data-testid="task-details-input">
            <Controller
              control={control}
              name={'description'}
              rules={{
                maxLength: { value: 4000, message: t('notification.createTask.maxLimitLabel') },
              }}
              render={({ ref, onChange, ...rest }) => (
                <TextField
                  multiline
                  variant="filled"
                  className={classes.inputs}
                  color="primary"
                  value={descValue}
                  maxRows={5}
                  InputLabelProps={{
                    className: classes.fieldLabel,
                  }}
                  error={
                    externalTask && errors?.description
                      ? true
                      : false || (isDirty && errors?.description)
                      ? true
                      : false
                  }
                  disabled={isCallingAPI}
                  id="description"
                  label={t('notification.createTask.detailsLabel')}
                  onChange={e => {
                    if (e.target.value?.length > 4000) {
                      setError(createTaskVar.description, {
                        type: 'maxLength',
                        message: t('notification.createTask.maxLimitLabel'),
                      });
                    } else if (e.target.value?.length <= 4000) {
                      clearErrors(createTaskVar.description);
                    }
                    onChange(e);
                    setDescValue(e.target.value);
                  }}
                  helperText={
                    (externalTask && errors?.description && errors?.description?.message) ||
                    (isDirty && errors?.description && errors?.description?.message)
                  }
                />
              )}
            />
          </Grid>

          {externalTask && (
            <Grid item xs={7} data-testid="task-assigned-to-input">
              <ExternalAssignedToField value={externalAssignedToValue} />
            </Grid>
          )}
          <Grid item xs={5} data-testid="task-due-date-input" className={classes.dueDateContainer}>
            <Controller
              control={control}
              name={createTaskVar.dueDate}
              rules={{ validate: checkDateValidation }}
              render={({ ref, onChange, value, ...rest }) => (
                <DateRangePicker
                  variant="filled"
                  placeholder={t('notification.createTask.dueDateLabel')}
                  className={classes.dueDateInput}
                  useMaxWidth={true}
                  single={true}
                  value={dueDateValue}
                  initialDateRange={{
                    startDate: dueDateValue ? new Date(dueDateValue) : undefined,
                    endDate: dueDateValue ? new Date(dueDateValue) : undefined,
                    useLabelAsValue: true,
                  }}
                  labelClass={dueDateValue ? classes.fieldLabel : classes.dueDateField}
                  minDate={addDays(date, -1)}
                  maxWidth={150}
                  trailingIconShow={true}
                  isDisabled={isCallingAPI}
                  disablePortal={true}
                  actionBar={false}
                  clickAway={true}
                  keepClickAwayVal={true}
                  onInlineEdit={() => {
                    clearErrors(createTaskVar.dueDate);
                  }}
                  onBlurText={dateString => {
                    let dateValue: any = null;
                    if (dateString && dateString?.length > 0 && !dateString?.includes('_')) {
                      const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
                      const midDayValue = addHours(parsedDate, 18);
                      dateValue = FormatDate(midDayValue);
                    } else {
                      dateValue = dateString;
                    }
                    setDueDateValue(dateValue);
                  }}
                  fallbackPlacements={['bottom-start']}
                  onChange={e => {
                    const dueDate = e?.endDate ? FormatDate(e?.endDate?.setHours(18)) : '';
                    onChange(dueDate);
                    setDueDateValue(dueDate);
                  }}
                  error={externalTask && errors?.dueDate ? true : false || (isDirty && errors?.dueDate) ? true : false}
                  helperText={
                    externalTask && errors?.dueDate
                      ? errors?.dueDate?.message
                      : '' || (isDirty && errors?.dueDate)
                      ? errors?.dueDate?.message
                      : ''
                  }
                  definedRanges={[
                    {
                      label: t('notification.createTask.dueDateLabels.today'),
                      startDate: date,
                      endDate: date,
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.tomorrow'),
                      startDate: addDays(date, 1),
                      endDate: addDays(date, 1),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.2Days'),
                      startDate: addDays(date, 2),
                      endDate: addDays(date, 2),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.7Days'),
                      startDate: addDays(date, 7),
                      endDate: addDays(date, 7),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.30Days'),
                      startDate: addDays(date, 30),
                      endDate: addDays(date, 30),
                      useLabelAsValue: false,
                    },
                  ]}
                />
              )}
            />
          </Grid>
          {!externalTask && (
            <Grid item xs={12} data-testid="task-assigned-to-input">
              <Controller
                control={control}
                name={createTaskVar.assignedTo}
                rules={{ required: true }}
                render={({ ref, onChange, value, ...rest }) => (
                  <AssignedToValue
                    formMethods={formMethods}
                    taskVar={createTaskVar}
                    placementEmployees={placementEmployees}
                    assignedToValue={assignedToValue}
                    setAssignedValue={data => {
                      setAssignedValue(data);
                      onChange(data);
                    }}
                    ccValues={ccEmpValues}
                    isDisabled={isCallingAPI}
                    error={isDirty && errors?.assignedTo ? true : false}
                    isTouched={isDirty}
                    helperText={isDirty && errors?.assignedTo && t('required')}
                    container={container}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {!showccField && (
              <IconButton
                color="primary"
                data-testid="task-cc-btn"
                className={classes.ccBtn}
                disabled={isCallingAPI}
                centerRipple={false}
                onClick={() => {
                  setShowccField(true);
                }}
              >
                {t('notification.createTask.ccBtn')}
              </IconButton>
            )}
            {showccField && !externalTask && (
              <Controller
                control={control}
                name={'cc'}
                render={({ ref, onChange, value, ...rest }) => (
                  <CCEmployees
                    placementEmployees={placementEmployees}
                    ccEmpValues={ccEmpValues}
                    setCCEmpvalues={data => {
                      setCCEmpvalues(data);
                      onChange(data);
                    }}
                    assignedToValue={assignedToValue}
                    isDisabled={isCallingAPI}
                    formMethods={formMethods}
                    container={container}
                  />
                )}
              />
            )}
            {showccField && externalTask && (
              <Controller
                control={control}
                name={'cc'}
                render={({ ref, onChange, value, ...rest }) => (
                  <CCEmployees
                    placementEmployees={placementEmployees}
                    ccEmpValues={ccEmpValues}
                    setCCEmpvalues={setCCEmpvalues}
                    assignedToValue={externalAssignedToValue}
                    isDisabled={isCallingAPI}
                    formMethods={formMethods}
                    container={container}
                  />
                )}
              />
            )}
          </Grid>
          {!externalTask && (
            <TaskAttachment
              setAttachments={setAttachments}
              attachments={attachments}
              existingAttachmentAttributes={attachmentAttributes}
            />
          )}
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
