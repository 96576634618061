import { GetMomentOfDate } from 'utils/dates/moment';
import { missingField } from 'app/constants';
import { Concatenate } from 'utils/string/string';
import Call from 'app/assets/images/WorkDesk/Call.png';
import email from 'app/assets/images/WorkDesk/email.png';
import { getAge } from 'app/components/Order/OrderDetails/helper';
import { IMapAddress } from './candidateOrderMatch.redux';

const formatAddress = address => {
  if (!address) return '';
  return address.replace(/ /g, '%20').replace(/#/g, '%23').replace(/\./g, '');
};

const formatValue = value => (value ? `%20${value.replace(/\./g, '')}` : '');

const formatGoogleMapLink = (candidate: IMapAddress, facility: IMapAddress) => {
  const originAddress = formatAddress(candidate.address);
  const destinationAddress = formatAddress(facility.address);
  const domainUrl = globalThis?.app?.env?.REACT_APP_GOOGLE_DIRECTIONS_URL;
  const origin = Concatenate(
    [originAddress, formatValue(candidate.city), formatValue(candidate.state), formatValue(candidate.zip)],
    '%2C',
  );
  const destination = Concatenate(
    [destinationAddress, formatValue(facility.city), formatValue(facility.state), formatValue(facility.zip)],
    '%2C',
  );
  return `${domainUrl}&origin=${origin}&destination=${destination}`;
};

export const orderMatchFlattenData = (items,candidateAddress) =>
  items.map((item, index) => {
    const dateEnteredMoment = GetMomentOfDate(item.dateEntered);
    const dateVerifiedMoment = GetMomentOfDate(item.dateVerified);

    const startDateMoment = GetMomentOfDate(item.startdate);
    const endDateMoment = GetMomentOfDate(item.endDate);

    const set = new Set();

    type Skillset = {
      disciplineAbbr?: string;
      specialtyAbbr?: string;
    };

    item?.skillsets?.forEach((skillset: Skillset) => {
      const tempArray = [];
      skillset.disciplineAbbr && tempArray.push(skillset.disciplineAbbr);
      skillset.specialtyAbbr && tempArray.push(skillset.specialtyAbbr);
      set.add(tempArray.join('-'));
    });

    const skillSets = set.size > 0 ? Array.from(set).join(', ') : missingField;
    set.clear();

    const shiftCont =
      item.shifts && item.shifts?.length
        ? item.shifts
            ?.map(item => {
              return item.description;
            })
            .join(', ')
        : missingField;

    const orderPriorities =
      item.orderPriorities && item.orderPriorities?.length
        ? item.orderPriorities
            ?.map(item => {
              return item.description;
            })
            .join(', ')
        : missingField;
    const accountManagerAttributes = [
      { key: Call, value: item.accountManager?.phoneNumber },
      { key: email, value: item.accountManager?.email },
    ];

    const facilityCoordinates =
      item.geoPoint?.coordinates
        ? {
            latitude: item.geoPoint?.coordinates[1],
            longitude: item.geoPoint?.coordinates[0],
          }
        : missingField;
    const data = {
      id: `${item.orderId}-${index}`,
      indexCounter: index,
      orderId: item.orderId,
      previewDrawerViewerId: item.orderId,
      previewDrawerViewerType: 1,
      vmsReqNo: item.vmsReqNo || missingField,
      positionsAvailable: item.positionsAvailable || missingField,
      maxFilesSent: item.maxFilesSent || missingField,
      filesPending: item.filesPending || missingField,
      filesOut: item.filesOut || missingField,
      mspClient: item.mspClient || missingField,
      affiliate: item.affiliate || missingField,
      facility: item.facility || missingField,
      facilityId: item.facilityId || 0,
      unit: item.unit || missingField,
      skillsets: skillSets,
      shifts: shiftCont.length ? shiftCont : missingField,
      paysGww: item.paysGww == null ? missingField : item.paysGww ? 'Yes' : 'No',
      guaranteedHours: item.guaranteedHours ? item.guaranteedHours : missingField,
      expectedHours: item.expectedHours ? item.expectedHours : missingField,
      reason: item.reason || missingField,
      orderBillRate: item.orderBillRate ? item.orderBillRate : missingField,
      startdate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : missingField,
      endDate: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      orderPriority: orderPriorities.length ? orderPriorities : missingField,
      weeklyGrossPay: item.weeklyGrossPay || missingField,
      accountManager: Concatenate([item.accountManager?.firstName, item.accountManager?.lastName], ' ') || missingField,
      accountManagerAttributes,
      city: item.city || missingField,
      stateName: item.stateName ? item.stateName : missingField,
      zipCode: item.zipCode || missingField,
      division: item.division || missingField,
      orderType: item.orderType || missingField,
      businessRelationship: item.businessRelationship || missingField,
      facilityStatus: item.facilityStatus || missingField,
      dateEntered: dateEnteredMoment ? getAge(item.dateEntered) : missingField,
      dateVerified: dateVerifiedMoment ? getAge(item.dateVerified) : missingField,
      covidVaccinationRequired: item.covidVaccinationRequired || missingField,
      staffingVendor: item.staffingVendor || missingField,
      techVendor: item.technologyVendor || missingField,
      region: item.region || missingField,
      facilityCoordinates,
      drivingDistanceMapLink:
        candidateAddress?.addressTypeId &&
        candidateAddress?.address1 &&
        candidateAddress?.city &&
        candidateAddress?.zip &&
        item?.facilityAddress1 &&
        item?.state &&
        item?.city &&
        item?.zipCode
          ? formatGoogleMapLink(
              {
                address: candidateAddress?.address1,
                state: candidateAddress?.stateProvinceId,
                city: candidateAddress?.city,
                zip: candidateAddress?.zip,
              },
              {
                address: item.facilityAddress1,
                state: item.state,
                city: item.city,
                zip: item.zipCode,
              },
            )
          : null,
    };
    return data;
  });
