import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment-timezone';
import { ICueNoteResponse } from 'app/models/Tasks/Tasks';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { taskDetailsActions } from '../store/Tasks.redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  root: {
    border: ' 1px solid #ECECEC',
    borderColor: '#c5c4c4',
    flexWrap: 'nowrap',
    background: '#B3E0F4 0% 0% no-repeat padding-box',
    width: '100%',
    paddingTop: '10px',
    float: 'right',
    marginBottom: '0px !important',
    boxShadow: 'none',
  },
  cardContent: {
    padding: '0',
    paddingTop: '2px',
    paddingBottom: '0px !important',
    width: '15rem',
    display: 'contents',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  sentByName: {
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Roboto',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#555555',
    opacity: 1,
    fontWeight: 'bold',
    marginLeft: '10px',
    display: 'inline',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  timestamp: {
    textAlign: 'left',
    font: 'normal normal 400 10px/20px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
    marginLeft: '5px',
    marginRight: '25px',
    whiteSpace: 'nowrap',
  },
  cueContent: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#333333',
    textAlign: 'left',
    margin: '5px 10px 5px 10px',
  },
  replyCard: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    border: '0px',
    borderRadius: '10px 10px 10px 0px',
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '3px',
    marginBottom: '10px !important',
  },
  closeBtn: {
    padding: '0px !important',
    right: '0',
    position: 'absolute',
    opacity: '1',
    marginRight: '14px',
  },
  closeIcon: {
    width: '1rem',
    height: '1rem',
  },
  msgHeader: {
    justifyContent: 'space-between',
    display: 'inline-flex',
    width: 'inherit',
  },
});

export const ReplyCard = ({
  cueNote,
  closeButton,
  bgColor,
  onClose = () => {},
}: {
  cueNote: ICueNoteResponse | undefined;
  closeButton: boolean;
  bgColor: string;
  onClose?: () => void;
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [sentDate, setSentDate] = useState('');
  const ref = React.useRef<any>(null);
  const { t } = useTranslation();

  const handleRemove = event => {
    cueNote = undefined;
    dispatch(taskDetailsActions.setCueNoteReplyParent(undefined));
    onClose();
  };

  const displaySentDate = cueNote => {
    const formattedDate = moment.tz(cueNote?.sentDate, 'America/Los_Angeles');
    if (formattedDate.diff(moment(), 'days') <= -2) {
      setSentDate(moment(cueNote?.sentDate).format('L hh:mm A'));
    } else {
      setSentDate(moment(cueNote?.sentDate).format('hh:mm A'));
    }
  };

  useEffect(() => {
    displaySentDate(cueNote);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cueNote]);

  const getReplyName = ()=>{
    let reply='';
    if(cueNote?.coverageDetail)
      reply+=`${cueNote?.coverageDetail?.coveredByEmployeeFirstName} ${cueNote?.coverageDetail?.coveredByEmployeeLastName} ${t('notification.taskSidePanel.cueNotes.obo')} `; 
   reply+= `${cueNote?.sentByFirstName} ${cueNote?.sentByLastName}`;
   return reply;
  }

  return (
    <Grid data-testid="reply-card-container">
      <Card
        className={`${classes.root} ${classes.replyCard}`}
        data-testid="reply-card"
        style={{ marginBottom: '12px', cursor: 'default', backgroundColor: bgColor }}
      >
        <CardContent className={classes.cardContent}>
          <Grid container xs={12}>
            <div className={classes.msgHeader}>
              <div className={classes.sentByName}>{getReplyName()}</div>
              <div className={classes.timestamp}>{sentDate}</div>
            </div>
            {closeButton && (
              <IconButton color="primary" className={classes.closeBtn} onClick={handleRemove} size="small">
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            )}
          </Grid>
          <Grid container xs={12}>
            {cueNote?.text !== '' && (
              <>
                <Typography
                  id="typography"
                  variant="body1"
                  className={classes.cueContent}
                  style={{
                    lineHeight: '20px',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                  ref={ref}
                >
                  <span id="cueText">{cueNote?.text}</span>
                </Typography>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
