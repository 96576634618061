import { getMultiSelectOptions } from '@AMIEWEB/GlobalSearch/helper';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { orderFlattenData } from 'app/components/GlobalSearch/Order/SearchHelper';
import { ExceptionType } from 'app/enums/Common';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { getAMIECategorySearch, getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { trackPromise } from 'react-promise-tracker';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import { globalSearchActions, totalCountErrorThreshold } from 'store/redux-store/global-search/slice';
import { OrderMatchesPayload } from '../OrderMatchesGrid/OrderMatches';
import { getCandidateOrderMatchActions } from './candidateOrderMatch.redux';
import { orderMatchFlattenData } from './orderMatchHelper';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import moment from 'moment';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'candidate-order-match-call');
const TrackWrapperLookup = (fn, ...args) => trackPromise(fn(...args), 'candidate-order-match-Lookups');

export function* getCandidateOrderMatch(action: PayloadAction<OrderMatchesPayload>) {
  try {
    const requestBody = {
      searchType: SearchType.order,
      keyword: action.payload.keyword,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      sortedColumn: action.payload.sortedColumn,
      skillSets: action.payload.skillSets,
      orderSkillsetFilter: action.payload.orderSkillsetFilter,
      locations: action.payload.locations,
      placementLength: action.payload.placementLength,
      candidateAvailableStartDate: action.payload.candidateAvailableStartDate,
      education: action.payload.education,
      specialtyIds: action.payload.specialtyIds,
      disciplineIds: action.payload.disciplineIds,
      orderBillRate: action.payload.orderBillRate,
      billRate: action.payload.billrate,
      states: action.payload.states,
      city: action.payload.city,
      zipCode: action.payload.zipCode,
      radius: action.payload.radius,
      divisionIds: action.payload.divisionIds,
      businessRelationshipIds: action.payload.businessRelationshipIds,
      orderTypeIds: action.payload.orderTypeIds,
      facilityStatusIds: action.payload.facilityStatusIds,
      subSpecialtyIds: action.payload.subSpecialtyIds,
      facilityIds: action.payload.facilityIds,
      mspClientIds: action.payload.mspClientIds,
      affiliateIds: action.payload.affiliateIds,
      dateEnteredRange: action.payload.dateEnteredRange,
      endDateRange: action.payload.endDateRange,
      paysGww: action.payload.paysGww,
      guaranteedHours: action.payload.guaranteedHours,
      maxFilesSentId: action.payload.maxFilesSentId,
      positions: action.payload.positions,
      orderStartDateRange: action.payload.orderStartDateRange,
      expectedHours: action.payload.expectedHours,
      reasonids: action.payload.reasonids,
      amUserIds: action.payload.amUserIds,
      orderPriorityIds: action.payload.orderPriorityIds,
      shiftIds: action.payload.shiftIds,
      weeklyGrossPay: action.payload.weeklyGrossPay,
      vaccinatedIds: action.payload.vaccinatedIds,
      orderIds: action.payload.orderIds,
      multiSortedColumns: action.payload.multiSortedColumns,
      regionIds: action.payload.region,
    };

    const response = yield call(TrackWrapper, getAMIECategorySearch, requestBody);

    if (response?.paging && response?.paging.total > totalCountErrorThreshold) {
      yield put(
        globalActions.setBanner({
          message: action.payload.translation('search.globalSearch.exceedLimitBanner'),
          severity: 'warning',
          justify: 'center',
        }),
      );
    } else {
      yield put(globalActions.closeBanner());
    }

    if (response) {
      const candidateService: CandidateService = yield getContext('candidateService');
      const addressResponse = yield call(
        TrackWrapper,
        candidateService.getCandidateAddress,
        action.payload?.candidateId,
        action.payload?.candidateBrandId,
      );
      const currentDate = moment();
      const addresses = addressResponse?.data?.filter(
        address =>
          (address?.addressTypeId === 1 || address?.addressTypeId === 4) &&
          (!address?.dateMoveOut ||
            (moment(address?.dateMoveOut, 'YYYY-MM-DD').isValid() &&
              moment(address?.dateMoveOut).isAfter(currentDate))),
      );

      let candidateAddress = addresses
        ?.filter(address => address?.addressTypeId === 1)
        ?.reduce(
          (latest, address) =>
            !latest || moment(address?.lastUpdate).isAfter(moment(latest?.lastUpdate)) ? address : latest,
          null,
        );

      if (!candidateAddress) {
        candidateAddress = addresses
          ?.filter(address => address?.addressTypeId === 4)
          ?.reduce(
            (latest, address) =>
              !latest || moment(address?.lastUpdate).isAfter(moment(latest?.lastUpdate)) ? address : latest,
            null,
          );
      }
      yield put(
        getCandidateOrderMatchActions.setData({
          items: orderMatchFlattenData(response.items, candidateAddress),
          count: response.paging?.total,
        }),
      );
      yield put(
        globalSearchActions.setSearchResults({
          items: orderFlattenData(response.items),
          count: response.paging?.total,
          miscellaneous: {},
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCandidateOrderMatch',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/store/candidateOrderMatch.saga.ts',
      },
    });
  }
}

export function* getCandidateLookups() {
  try {
    const [shiftLookups, statesLookups] = yield all([
      call(TrackWrapperLookup, getSearchFilterOptions, 'shiftlist'),
      call(TrackWrapperLookup, getSearchFilterOptions, 'states'),
    ]);

    const states = getMultiSelectOptions(statesLookups || []);
    const shiftOpts = getMultiSelectOptions(shiftLookups || []);
    const result = {
      shiftOpts: shiftOpts,
      statesOpts: states,
    };
    yield put(getCandidateOrderMatchActions.setCandidateLooUps(result));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCandidateOrderMatchLookup',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/store/candidateOrderMatch.saga.ts',
      },
    });
  }
}

export function* candidateOrderMatchSaga() {
  yield takeLatest(getCandidateOrderMatchActions.getCandidateOrderMatchAction.type, getCandidateOrderMatch);
  yield takeLatest(getCandidateOrderMatchActions.getLookUps.type, getCandidateLookups);
}
