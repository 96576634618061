import { SkillSetCollections, TravelExperienceSections } from 'app/enums/Common';
import { ITransformedIssuedBy } from 'app/models/Unit/CreateUnit';
import { ICollection } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { isNil } from 'lodash';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { RequirementType } from 'app/enums/Facility';
import {
  VirtualInterviewRatingsId,
  VoiceAdvantage,
  VAAutoOffer,
  MissingPrioritiesEnum,
} from '@AMIEWEB/NewOrder/constants';
import { virtualRatingList, virtualRatingListWithVoiceAdvantage } from 'app/constants';

export const defaultValuesForCreate = (facilityDetail: FacilityResponse | null) => {
  return {
    skillSetGroup: getDefaultSkillSetForCreate(),
    name: '',
    unitId: 0,
    costCenter: '',
    floor: '',
    unitSize: null,
    fyreIntegration: false,
    ssn: false,
    dob: false,
    firstDayInstructions: '',
    externalJobDescription: '',
    internalNotes: '',
    orderPriorities: [],
    virtualRating: [],

    submissionRequirement: false,
    reference: {
      numberOfReferenceRequired: facilityDetail?.numberOfReferenceRequired,
      isRequired: facilityDetail?.submissionRequiredReference,
      referenceFromSupervisor: facilityDetail?.supervisorAndAboveReference,
      withinNumberOfMonths: facilityDetail?.withinLastMonthsReferenceChanged,
      numberOfReferenceToRFO: facilityDetail?.numberOfReferenceToRFO,
      entireWorkHistory: facilityDetail?.entireWorkHistory,
    },
    nurse: null,
    patient: null,
    notifyCandidate: false,
    patientsSeen: null,
    numberOfVisits: null,
    productivityPercentage: null,
    bestBets: {
      Description: facilityDetail?.bestBets,
      ID: facilityDetail?.bestBetsId,
    },
    unitContacts: [],
    workHistoryGap: {
      withinTheLastDays: null,
      withinTheLastYears: null,
      entireWorkHistory: false,
    },
    preferredQualifications: '',
    additionalRequiredQualifications: '',
  };
};

const handleVoiceAdvantage = virtualRating => {
  if (virtualRating && virtualRating.length > 0) {
    const ratingArray = [...virtualRating];
    const voiceAdvantageIndex = virtualRating.findIndex(x => x?.object?.name === VoiceAdvantage);
    if (voiceAdvantageIndex === -1) {
      const isAtoIDataPresent = virtualRating.some(item => virtualRatingList?.includes(item?.object?.name));
      if (isAtoIDataPresent) {
        ratingArray.push({
          object: { value: '45', name: VoiceAdvantage },
          label: VoiceAdvantage,
        });
      }
    }
    return ratingArray;
  }
  return [];
};

export const defaultValuesForEdit = (
  editUnit,
  selectedUnitDetail,
  getBestBets,
  data,
  issuedByData: ITransformedIssuedBy[],
  licenseData,
  states,
  facilityData,
) => {
  const updatedVirtualRating = handleVoiceAdvantage(editUnit?.virtualRating);
  return {
    skillSetGroup: data.groups
      ? getDefaultSkillSetForEdit(data, issuedByData, licenseData, states, facilityData)
      : getDefaultSkillSetForCreate(),
    name: editUnit?.name,
    unitId: editUnit?.unitId,
    costCenter: editUnit?.costCenter,
    floor: editUnit?.floor,
    unitSize: editUnit?.unitSize,
    fyreIntegration: editUnit?.fyreIntegration,
    ssn: editUnit?.ssn,
    dob: editUnit?.dob,
    firstDayInstructions: editUnit?.firstDayInstructions,
    externalJobDescription: editUnit?.externalJobDescription,
    internalNotes: editUnit?.internalNotes,
    virtualRating: !!updatedVirtualRating ? updatedVirtualRating : [],
    orderPriorities: !!updatedVirtualRating
      ? manageMissingValue(editUnit?.orderPriorities ?? [], updatedVirtualRating)
      : !!editUnit?.orderPriorities
      ? editUnit?.orderPriorities
      : [],
    submissionRequirement: editUnit?.submissionRequirement ? editUnit?.submissionRequirement : false,
    reference: {
      numberOfReferenceRequired:
        editUnit?.reference.isRequired && editUnit?.reference.numberOfReferenceRequired === 0
          ? 1
          : editUnit?.reference.numberOfReferenceRequired,
      isRequired: editUnit?.reference.isRequired,
      referenceFromSupervisor: editUnit?.reference.referenceFromSupervisor,
      withinNumberOfMonths: editUnit?.reference.withinNumberOfMonths,
      numberOfReferenceToRFO: editUnit?.reference?.numberOfReferenceToRFO,
      entireWorkHistory: editUnit?.reference?.entireWorkHistory,
    },
    nurse: editUnit?.nurse,
    patient: editUnit?.patient,
    notifyCandidate: selectedUnitDetail?.notifyCandidate,
    patientsSeen: editUnit?.patientsSeen,
    numberOfVisits: editUnit?.numberOfVisits,
    productivityPercentage: selectedUnitDetail?.productivityPercentage,
    bestBets: {
      Description: getBestBets(editUnit?.bestBetsId) ?? editUnit?.bestBets,
      ID: editUnit?.bestBetsId,
    },
    unitContacts: editUnit?.unitContacts ? editUnit?.unitContacts : [],
    workHistoryGap: {
      withinTheLastDays: editUnit?.workHistoryGap?.withinTheLastDays,
      withinTheLastYears: editUnit?.workHistoryGap?.withinTheLastYears,
      entireWorkHistory: editUnit?.workHistoryGap?.entireWorkHistory,
    },
    preferredQualifications: editUnit?.preferredQualifications,
    additionalRequiredQualifications: selectedUnitDetail?.additionalRequiredQualifications
      ? selectedUnitDetail?.additionalRequiredQualifications
      : '',
  };
};

const getDefaultSkillSetForCreate = () => {
  return {
    operation: null,
    groups: [
      {
        id: 0,
        operation: null,
        skillsets: [
          {
            id: 0,
            isPrimary: true,
            skillSet: {
              discipline: null,
              specialty: null,
              subSpecialty: null,
              yearsOfExperience: null,
              monthsOfExperience: null,
              isRequired: false,
              isNewGrad: false,
            },
            travelExperience: {
              experienceType: null,
              isRequired: false,
              numberOfAssignments: null,
              yearsOfExperience: null,
              monthsOfExperience: null,
              withinTwoYears: false,
              isAMNTravel: false,
            },
            collections: [
              {
                id: 0,
                type: SkillSetCollections.Licenses,
                operation: null,
                requirements: [],
              },
              {
                id: 1,
                type: SkillSetCollections.Certifications,
                operation: null,
                requirements: [
                  {
                    id: 0,
                    documentAttachmentRequired: false,
                    isRequired: false,
                    certificates: null,
                    subCertificate: null,
                    issuedBy: null,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
};

const getDefaultSkillSetForEdit = (data, issuedByData: ITransformedIssuedBy[], licenseData, states, facilityData) => {
  const formattedValue = {
    operation: data.operation,
    groups: data.groups.map(group => {
      return {
        id: group.id,
        operation: group.operation,
        skillsets: group.skillsets.map(skill => {
          return {
            collections: getCollections(skill.collections, issuedByData, licenseData, states, facilityData),
            id: skill.id,
            isPrimary: skill.isPrimary,
            skillSet: {
              discipline: skill.skillSet.discipline
                ? {
                    label: skill.skillSet.discipline,
                    object: {
                      Abbreviation: skill.skillSet.disciplineAbbr,
                      Description: skill.skillSet.discipline,
                      ID: skill.skillSet.disciplineId,
                    },
                  }
                : null,
              specialty: skill.skillSet.specialty
                ? {
                    label: skill.skillSet.specialty,
                    object: {
                      DisciplineID: skill.skillSet.disciplineId,
                      Description: skill.skillSet.specialtyAbbr,
                      SpecialtyID: skill.skillSet.specialtyId,
                    },
                  }
                : null,
              subSpecialty: skill.skillSet.subSpecialty
                ? {
                    label: skill.skillSet.subSpecialty,
                    object: {
                      Description: skill.skillSet.subSpecialty,
                      SpecialtyID: skill.skillSet.specialtyId,
                      SubSpecialtyID: skill.skillSet.subSpecialtyId,
                    },
                  }
                : null,
              yearsOfExperience: skill.skillSet.yearsOfExperience,
              monthsOfExperience: skill.skillSet.monthsOfExperience,
              isRequired: skill.skillSet.disciplineId && skill.skillSet.specialtyId ? true : false,
              isPrimary: skill.isPrimary,
              isNewGrad: skill.skillSet.isNewGrad,
            },
            travelExperience: {
              experienceType: skill.travelExperience?.experienceTypeText
                ? {
                    groupBy: !isNil(skill.travelExperience?.numberOfAssignments)
                      ? TravelExperienceSections.NoOfAssignments
                      : TravelExperienceSections.YearsOfExperience,
                    label: skill.travelExperience.experienceTypeText,
                    object: {
                      title: skill.travelExperience.experienceTypeText,
                      optionId: skill.travelExperience.experienceTypeId,
                      id: `${!isNil(skill.travelExperience?.numberOfAssignments) ? 1 : 2}-${
                        skill.travelExperience.experienceTypeId
                      }`,
                    },
                  }
                : null,
              isRequired:
                skill.travelExperience?.numberOfAssignments >= 1 ||
                skill.travelExperience?.yearsOfExperience >= 1 ||
                skill.travelExperience?.monthsOfExperience >= 1
                  ? true
                  : false,
              numberOfAssignments: skill.travelExperience?.numberOfAssignments,
              yearsOfExperience: skill.travelExperience?.yearsOfExperience,
              monthsOfExperience: skill.travelExperience?.monthsOfExperience,
              withinTwoYears: skill.travelExperience?.withinTwoYears,
              isAMNTravel: skill.travelExperience?.isAMNTravel,
            },
          };
        }),
      };
    }),
  };

  return formattedValue;
};

const getCollections = (
  collectionData: ICollection[],
  issuedByData: ITransformedIssuedBy[],
  licenseData,
  states,
  facilityData,
) => {
  const licenses = collectionData?.find(collection => collection?.type === SkillSetCollections.Licenses);
  const certifications = collectionData?.find(collection => collection?.type === SkillSetCollections.Certifications);
  const formattedLicense = {
    id: licenses?.id,
    operation: licenses?.operation,
    requirements: excludeInActiveSavedLicensesAndUpdateCompactToSingleStateForNonCompactLicense(
      licenses?.requirements,
      licenseData,
      states,
      facilityData,
    ),
    type: licenses?.type,
  };

  const formattedCertification = {
    id: certifications?.id,
    operation: certifications?.operation,
    requirements: certifications?.requirements?.map((requirement, index) => {
      return {
        id: index,
        certificates: {
          label: requirement?.requirementDescription
            ? `${requirement?.requirementDescription}`
            : `${requirement?.requirementAbbreviation}`,
          object: {
            abbreviation: requirement?.requirementAbbreviation,
            category: SkillSetCollections.Certifications,
            certificationName: requirement?.requirementDescription
              ? `${requirement?.requirementDescription}`
              : `${requirement?.requirementAbbreviation}`,
            credentialName: requirement?.requirementDescription,
            id: requirement?.id,
            requirementTypeID: requirement?.requirementTypeId,
          },
        },
        issuedBy: getIssuedBy(requirement?.requirementTypeId, requirement?.issuedById, issuedByData),
        subCertificate: requirement?.subCertificate?.subCertificationId
          ? [
              {
                subCertificate: {
                  name: requirement?.subCertificate?.subCertificateName,
                  value: requirement?.subCertificate?.subCertificateAbbreviation,
                  id: requirement?.subCertificate?.subCertificationId,
                },
              },
            ]
          : [],
        documentAttachmentRequired: requirement?.documentAttachmentRequired,
        isRequired:
          requirement?.requirementTypeId === RequirementType.cert
            ? requirement?.subCertificate?.subCertificationId
              ? true
              : false
            : requirement?.requirementTypeId
            ? true
            : false,
      };
    }),
    type: certifications?.type,
  };
  return [licenses ? formattedLicense : {}, certifications ? formattedCertification : {}];
};

export const getDefaultSkillSet = id => {
  return {
    id: id,
    skillSet: {
      discipline: null,
      specialty: null,
      subSpecialty: null,
      yearsOfExperience: 0,
      monthsOfExperience: 0,
      isRequired: false,
      isNewGrad: false,
    },
    collections: [
      {
        id: 0,
        type: SkillSetCollections.Licenses,
        operation: null,
        requirements: [],
      },
      {
        id: 1,
        type: SkillSetCollections.Certifications,
        operation: null,
        requirements: [
          {
            documentAttachmentRequired: false,
            isRequired: false,
            certificates: null,
            subCertificate: null,
          },
        ],
      },
    ],
  };
};

export const getCombinedCertSubCertData = (requirements: any) => {
  if (!requirements?.length) return [];
  return requirements?.reduce((acc, req) => {
    const currentItem = acc[acc?.length - 1];
    if (
      currentItem &&
      req?.requirementTypeId === currentItem?.requirementTypeId &&
      req?.subCertificate?.subCertificationId
    ) {
      if (!Array.isArray(currentItem?.subCertificate)) {
        currentItem.subCertificate = [currentItem?.subCertificate];
      }
      currentItem.subCertificate.push({
        ...req?.subCertificate,
        isRequired: req?.isRequired,
        documentAttachmentRequired: req?.documentAttachmentRequired,
      });
    } else {
      acc.push({
        ...req,
        subCertificate: req.subCertificate?.subCertificationId
          ? [
              {
                ...req?.subCertificate,
                isRequired: req?.isRequired,
                documentAttachmentRequired: req?.documentAttachmentRequired,
              },
            ]
          : null,
      });
    }
    return acc;
  }, []);
};

export const getIssuedBy = (requirementTypeId: number, issuedById: number, issuedByData: ITransformedIssuedBy[]) => {
  const savedIssuedBy = issuedByData.find(
    data => data.object.certificationRequirementTypeId === requirementTypeId && data.object.id === issuedById,
  );
  return savedIssuedBy !== undefined ? savedIssuedBy : null;
};

const manageMissingValue = (existingArray, requiredArray) => {
  const orderPriorities = [...requiredArray];
  existingArray.forEach(item2 => {
    const isPresent = requiredArray.some(item1 => item1?.object?.name === item2?.object?.name);
    if (!isPresent && !virtualRatingListWithVoiceAdvantage?.includes(item2?.object?.name)) {
      orderPriorities.push(item2);
    }
  });
  const updatedOrderPriority = orderPriorities?.map(item => {
    // VoiceAdvantage and VAAutoOffer contain spaces, so they don't match enum properties.
    const keyToSearch =
      item?.object?.name === VoiceAdvantage
        ? MissingPrioritiesEnum.VoiceAdvantage45
        : item?.object?.name === VAAutoOffer
        ? MissingPrioritiesEnum.VAAutoOffer46
        : item?.object?.name;

    if (item?.object?.value === 0 && VirtualInterviewRatingsId?.hasOwnProperty(keyToSearch)) {
      const id = Number(VirtualInterviewRatingsId[keyToSearch]);
      return {
        ...item,
        label: id + '. ' + item?.object?.name,
        object: {
          value: id,
          name: item?.object?.name,
        },
      };
    }
    return item;
  });

  return updatedOrderPriority;
};

const excludeInActiveSavedLicensesAndUpdateCompactToSingleStateForNonCompactLicense = (
  requirements,
  licenseData,
  states,
  facilityData,
) => {
  const result = [];
  const defaultState = states?.find(
    state =>
      state?.value === facilityData?.facilityResponse?.location?.state ||
      state?.value === facilityData?.response?.location?.state,
  );
  if (requirements?.length) {
    for (const requirement of requirements) {
      for (const each of licenseData) {
        if (each.requirementTypeID === requirement.requirementTypeId) {
          result.push({
            ...requirement,
            isMultiState: each?.compact ? requirement.isMultiState : each?.compact,
            documentAttachmentRequired: each?.compact
              ? requirement.documentAttachmentRequired
              : requirement.requirementState.state
              ? requirement.documentAttachmentRequired
              : false,
            requirementState: each?.compact
              ? requirement.requirementState
              : requirement.requirementState.state
              ? requirement.requirementState
              : {
                  stateAbbreviation: defaultState?.value,
                  state: defaultState?.name,
                },
          });
          break;
        }
      }
    }
  }
  return result;
};
