import React, { createContext, useState, useEffect, ReactNode, FC } from 'react';

// Define the shape of your context's value
interface VisibilityContextType {
  isVisible: boolean;
}

const VisibilityContext = createContext<VisibilityContextType | undefined>(undefined);

interface VisibilityProviderProps {
  children: ReactNode;
}

export const VisibilityProvider: FC<VisibilityProviderProps> = ({ children }) => {
  const [isVisible, setVisibility] = useState<boolean>(!document.hidden);

  useEffect(() => {
    let timeout = null;

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (timeout) {
          clearTimeout(timeout); // Clear any existing timeout
        }
        // Tab becomes active: Start 3000ms delay
        timeout = setTimeout(() => {
          setVisibility(true); // Update visibility after 3000ms
        }, 3000);
      } else {
        // Tab becomes inactive: Update immediately
        if (timeout) {
          clearTimeout(timeout); // Clear any pending activation timeout
        }
        setVisibility(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (timeout) {
        clearTimeout(timeout); // Clear timeout on unmount
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      setVisibility(false);
    };
  }, []);
  return <VisibilityContext.Provider value={{ isVisible: isVisible }}>{children}</VisibilityContext.Provider>;
};

export default VisibilityContext;
