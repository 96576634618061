import React, { useState } from 'react';
import {
  TextField,
  Checkbox,
  Paper,
  Popper,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Button,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/global-styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TypeAheadListBox from 'app/ComponentLibrary/Filter/TypeAheadDropdown/TypeAheadListBox';

const useStyles = makeStyles<{
  showListBox: boolean;
}>()((theme, { showListBox }) => ({
  checked: {
    color: `${theme.palette.framework.system.skyBlue} !important`,
  },
  icon: {
    '&:hover': {
      fill: theme.palette.framework.system.skyBlue,
    },
  },

  textField: {
    fontSize: 12,
    color: '#52429A',
    '&::placeholder': {
      opacity: 1,
      fontSize: 12,
      color: '#C0C0C0',
    },
  },
  popperDisablePortal: {
    position: 'relative',
    width: '-webkit-fill-available !important',
  },
  fullWidth: {
    padding: '0px 20px 6px',
  },
  paper: {
    margin: 0,
  },
  option: {
    paddingLeft: 10,
  },
  listBox: {
    /** Ensure utmost five options are within dropdown
     * and list does not toggle when anchor is at the screen center
     */
    maxHeight: showListBox ? 360 : 250,
  },
  customTextField: {
    display: 'none',
  },
  submitButton: {
    margin: '-6.5px',
    marginTop: '4px',
    '& .button-actualText': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  listBoxContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.2fr',
    margin: '14px 8px 24px 20px',
    width: '460px',
  },
  colorTag: {
    borderRight: `1px solid ${theme.palette.framework.system.lightGrey}`,
    padding: `0px !important`,
    marginTop: 8,
  }
}));

export interface ISelection {
  id: number;
  name: string;
  value: any;
  labelPrefix?: string;
  labelPostfix?: string;
}
const smallTextFieldTheme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '37.625px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: '37.625px' },
      },
    },
  },
});
const getObjectValue = obj => (obj ? (typeof obj.value === 'object' ? obj.id : obj.value) : obj);

export const CustomTypeAhead = props => {
  const { t } = useTranslation();
  const {
    label,
    options = [],
    isError,
    isDisabled,
    helperText,
    customClasses,
    noOptionsText,
    variant,
    size,
    removeCloseIcon,
    isMultiSelect,
    filterName,
    searchOnCharacterCount = 3,
    selectAllValue = null,
    isLoading = false,
    nonNullable = false,
    hiddenInput,
    getOptionDisabled,
    forceCheckboxOptIcon = false,
    unreadInboxCheck = false,
    isSelectAll = false,
    showListBox = false,
    customList,
  } = props;
  const { classes, cx } = useStyles({ showListBox });

  const [textFieldValue, setTextFieldValue] = useState<any>('');

  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput' || reason === 'blur') {
      return;
    }
    props.handleClose();
  };
  const handleSelectAll = () => {
    if (isMultiSelect) props.onChange(options || []);
  };

  const onInputChange = (event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    setTextFieldValue(prevValue => (reason === 'input' ? newInputValue : prevValue));
    if (reason === 'input' && props.fetchOptions && (!newInputValue || searchOnCharacterCount <= newInputValue.length))
      props.onInputChange(newInputValue);
  };

  /** choice is returned as an array for both multiselect & nullable single-select
   *  but is an object for non-nullable single-select
   */
  const handleChange = (
    event,
    choice: ISelection[] | ISelection | null,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails,
  ) => {
    /** Override option for handleChange */
    if (props.handleChangeCallback) {
      props.handleChangeCallback(choice, reason, details, props.onChange);
      return;
    }
    /** Multi-select behavior */
    if (isMultiSelect && Array.isArray(choice)) {
      /** check - clicks on selectAllValue option */
      if (selectAllValue && details?.option?.value === selectAllValue) {
        props.onChange(choice.some(item => item?.value === selectAllValue) ? options : []);
        return;
      } /** choose  selectAllValue if all values have been selected*/ else if (
        selectAllValue &&
        choice.length === options.length - 1 &&
        !choice.some(item => item?.value === selectAllValue)
      ) {
        props.onChange(options);
        return;
      }
      /** splice the selectAllValue if click is on a differnt option and selectAllValue is selected */
      choice.some(item => item?.value === selectAllValue) && choice.splice(0, 1);
      props.onChange(choice);
    } /** SIngle-select behavior */ else {
      /** Choice would be an non-array object when it is nonNullable */
      props.onChange(!Array.isArray(choice) ? choice : Array.isArray(choice) && choice.length ? choice.pop() : null);
    }
  };

  const listBoxRef = React.useRef(
    React.memo(props => <TypeAheadListBox {...props} customList={customList} classes={classes} />),
  );

  return (
    <Autocomplete
      open
      multiple={!nonNullable}
      clearOnBlur
      fullWidth
      onClose={handleClose}
      filterOptions={props.filterOptions ? props.filterOptions : props.fetchOptions && (opt => opt)}
      noOptionsText={noOptionsText || undefined}
      value={
        (isMultiSelect || nonNullable) && props.value !== null ? props.value || [] : props.value ? [props.value] : []
      }
      options={options}
      disabled={isDisabled}
      loading={isLoading}
      disableCloseOnSelect
      disablePortal
      isOptionEqualToValue={(option, value) => getObjectValue(option) === getObjectValue(value)}
      getOptionLabel={(option: ISelection) => option.name}
      getOptionDisabled={getOptionDisabled}
      popupIcon={props.popupIcon ? props.popupIcon : null}
      forcePopupIcon={true}
      renderTags={() => null}
      classes={
        {
          listbox: classes.listBox,
          popperDisablePortal: classes.popperDisablePortal,
          paper: classes.paper,
          fullWidth: classes.fullWidth,
          option: classes.option,
          ...customClasses,
        } || undefined
      }
      clearIcon={removeCloseIcon ? null : <CloseIcon fontSize="small" />}
      onInputChange={onInputChange}
      inputValue={textFieldValue}
      ListboxComponent={showListBox ? listBoxRef?.current : undefined}
      renderInput={params => (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={smallTextFieldTheme}>
            <TextField
              error={isError}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              helperText={helperText}
              label={label}
              placeholder={t('appHeader.searchPlaceHolder')}
              {...params}
              variant={variant || 'filled'}
              className={cx({ [classes.customTextField]: hiddenInput })}
              size={size || undefined}
              InputProps={{
                ...params.InputProps,
                classes: { input: classes.textField },
                startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
                'aria-label': 'filter-type-ahead',
              }}
              onKeyDown={(event: any) => {
                if ((event.key === 'Backspace' && !textFieldValue) || event.key === 'Enter') {
                  event.stopPropagation();
                }
              }}
            />

            <div className={classes.submitButton}>
              {isMultiSelect && isSelectAll && (
                <Button
                  className="clear-all-button"
                  aria-label="apply-button"
                  variant="text"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  classes={classes.submitButton}
                  onClick={handleSelectAll}
                >
                  <span className="button-actualText">{t('search.filter.candidateSearch.selectAll')}</span>
                </Button>
              )}
            </div>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
      onChange={handleChange}
      renderOption={(p, option, { selected }) => (
        <li id={option?.value} {...p} key={!Array.isArray(option?.value) ? option?.value : option?.id}>
          <Checkbox
            size="small"
            classes={{ checked: classes.checked }}
            icon={
              isMultiSelect || forceCheckboxOptIcon ? (
                <CheckBoxOutlineBlankIcon classes={{ root: classes.icon }} />
              ) : (
                <RadioButtonUncheckedIcon />
              )
            }
            checkedIcon={
              isMultiSelect || forceCheckboxOptIcon ? undefined : (
                <RadioButtonCheckedIcon classes={{ root: classes.icon }} />
              )
            }
            style={{ marginRight: unreadInboxCheck ? 0 : 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
      aria-label={`custom-typeAhead-${filterName}`}
    />
  );
};

const PaperComponent = props => (
  <Paper {...props} elevation={0}>
    {props.children}
  </Paper>
);

const PopperComponent = props => (
  <Popper
    {...props}
    sx={{ width: 'fit-content', position: 'relative !important', transform: 'unset !important' }}
    placement="bottom-start"
  />
);
