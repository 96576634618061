import moment from 'moment';
import { instanceOfISelection, ISelection } from './CustomSelect';
import { instanceOfAggregateUnit } from '@AMIEWEB/Facility/UnitSearchFilter/UnitIndex';

export const getDateRangeLabel = ({ startDate, endDate }) =>
  `${moment(
    Object.prototype.toString.call(startDate) === '[object Date]' ? startDate : startDate?.substring(0, 10),
  ).format('MM/DD/YYYY')} ${!!endDate ? '- ' + moment(
    Object.prototype.toString.call(endDate) === '[object Date]' ? endDate : endDate?.substring(0, 10),
  ).format('MM/DD/YYYY') : ''}`;

// Creates options suitable for customSelect Component
export const getMultiSelectOptions = (options, selfValued?) =>
  options.length
    ? [
      { id: 0, name: 'All', value: 'All' },
      ...options.map((opt, index) => ({
        id: index + 1,
        name: selfValued ? opt.name : opt,
        value: selfValued ? opt.id : opt,
      })),
    ]
    : [];
export const getUnitMultiSelectOptions = (options, selfValued?) =>
  options.length
    ? [
      ...options.map((opt, index) => ({
        id: opt.unitId,
        name: selfValued ? opt.name : opt,
        value: selfValued ? opt.unitId : opt,
      })),
    ]
    : [];

export const getSigleSelectOptions = (options, selfValued?) => [
  ...options.map((opt, index) => ({
    id: index + 1,
    name: selfValued ? opt.name : opt,
    value: selfValued ? opt.id : opt,
  })),
];

// generate class filter
export const isItemIncluded = (value, choice) => {
  return Array.isArray(choice)
    ? choice.length
      ? Array.isArray(value)
        ? instanceOfISelection(choice[0])
          ? choice.some(item => value.includes(item.value))
          : choice.some(item => value.includes(item))
        : instanceOfISelection(choice[0])
          ? choice.some(item => item.value === value)
          : choice.includes(value)
      : true
    : (instanceOfISelection(choice) ? choice.value : choice) === value;
};

export const notSent = 'Not Sent';

export const isDataIncluded = (value, choice) => {
  return Array.isArray(choice)
    ? choice.length
      ? Array.isArray(value)
        ? instanceOfISelection(choice[0])
          ? choice.some(item => value.includes(item.name))
          : choice.some(item => value.includes(item))
        : instanceOfISelection(choice[0])
          ? choice.some(item => item.name === value)
          : choice.includes(value)
      : true
    : (instanceOfISelection(choice) ? choice.name : choice) === value;
};

export const isDateIncluded = (value, choice) => {
  if (Array.isArray(choice)) {
    return choice?.some(item => item.id === value.cuedTo);
  }
};

export const isItemIncludedCommaSeparatedUnitValues = (value, unitId, choice) => {
  const choiceArray = Array.isArray(choice) ? choice : choice?.selections?.length > 0 ? choice?.selections || [] : choice;

  const unitSeatchText = parseInt(choiceArray?.unitId) > 0 || choiceArray?.unitName;
  if (unitSeatchText) {
    if (parseInt(choiceArray?.unitId) > 0) {
      return unitId?.some(id => id?.toString()?.startsWith(choiceArray?.unitId?.toString()));
    }

    if (choiceArray?.unitName) {
      return value?.toLowerCase().includes(choiceArray?.unitName?.toLowerCase());
    }
  }
  else if (!unitSeatchText) {
    return !choiceArray?.length
      ? true
      : instanceOfISelection(choice[0])
        ? choiceArray.some(item => value?.includes(item?.value))
        : instanceOfAggregateUnit(choice)
          ? choiceArray?.some(item => value?.includes(item?.unitId?.toString()) || value?.includes(item?.unitName))
          : choiceArray.includes(value);
  }
};

export const isItemIncludedCommaSeparatedValues = (value, choice) => {
  const choiceArray = Array.isArray(choice) ? choice : choice?.selections?.length > 0 ? choice?.selections || [] : choice;

  if (parseInt(choiceArray?.unitId) > 0 || choiceArray?.unitName) {
    return (parseInt(choiceArray?.unitId) > 0 && value.includes(choiceArray?.unitId)) || value.includes(choiceArray?.unitName);
  }
  return !choiceArray?.length
    ? true
    : instanceOfISelection(choice[0])
      ? choiceArray.some(item => value.includes(item?.value))
      : instanceOfAggregateUnit(choice)
        ? choiceArray?.some(item => value?.includes(item?.unitId.toString()) || value?.includes(item?.unitName))
        : choiceArray.includes(value);
};

export const isSubstringIncluded = (value, choice) =>
  choice.length
    ? instanceOfISelection(choice[0])
      ? choice.some(item => value === item.value)
      : choice.some(ch => ch === value)
    : true;

export const itemIsInRange = (value, choice) =>
  choice
    ? moment(value).isSameOrAfter(moment(choice.startDate)) && moment(value).isSameOrBefore(moment(choice.endDate))
    : true;

export const itemIsTillDate = (value, toDate) => (toDate ? moment(value).isSameOrBefore(moment(toDate)) : true);

export const getMultiSelectChips = values =>
  values.reduce((chips, value) => (value !== 'All' ? [...chips, { label: value, enableDelete: true }] : chips), []);
// generate class filter

// Function to remove chips specied by filter by chip provision if they are no longer applicable
export const getValidFilterSelectionByChip = (values, selections) =>
  (
    selections
      ? instanceOfISelection(selections[0])
        ? values.some(item => item.value === selections[0].value)
        : values.includes(selections[0])
      : false
  )
    ? selections
    : null;

// Logic to update filter options w.r.t service call - for react-hook-forms
export const filterChoiceWithNewOptions = (
  choices: any[],
  newOpts: ISelection[],
  fieldName: string,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
      }>
      | undefined,
  ) => void,
) => {
  const newChoices = choices.length
    ? instanceOfISelection(choices[0])
      ? choices.reduce(
        (resultArray, choice) =>
          newOpts.some(opt => opt.value === choice.value) ? [...resultArray, choice] : resultArray,
        [] as ISelection[],
      )
      : choices.reduce(
        (resultArray, choice) => (newOpts.some(opt => opt.value === choice) ? [...resultArray, choice] : resultArray),
        [] as any[],
      )
    : [];
  const allValueIndex = newChoices.findIndex(opt => opt.value === 'All');
  allValueIndex !== -1 && newChoices.length !== choices.length && newChoices.splice(allValueIndex, 1);
  setValue(fieldName, newChoices, { shouldDirty: true });
  return newChoices;
};
