import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import baseAxiosInstance from 'utils/BaseApi';
import { CacheType, cacheResponse, getCachedResponse } from 'utils/networkCache';
import { httpSuccess } from '../serviceHelpers';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const credentialingBase = globalThis?.app?.env?.REACT_APP_CREDENTIALING_SERVICE_BASE_URL;
const credentialingAttachmentBase = globalThis?.app?.env?.REACT_APP_CREDENTIALING_ATTACHMENT_SERVICE_BASE_URL;

export async function expiringDocumentsByTitle(
  body: object,
  pageNumber: number,
  pageSize: number,
  sortColumns: string[],
) {
  try {
    const columns = sortColumns?.join(',') ?? '';
    const url = `${credentialingBase}/expiringInDays?PageNumber=${pageNumber}&PageSize=${pageSize}&SortColumns=${columns}`;
    const response = await baseAxiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'expiringDocumentsByTitle',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    return 'error';
  }
}

export async function getExpiringDocumentsByVolume() {
  try {
    const url = `${credentialingBase}/expiringvolume`;
    const response = await baseAxiosInstance.get(url);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getExpiringDocumentsByVolume',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
}

export const getCandidateCredentials = async (id: string) => {
  try {
    const url = `${credentialingBase}/bycandidateid/${id}`;
    const response = await baseAxiosInstance.get(url);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateCredentials',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export const getLookups = async (category?: string) => {
  try {
    const url = `${credentialingBase}/lookups${category ? `?category=${category}` : ''}`;
    const response = await baseAxiosInstance.get(url);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getLookups',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

/** Moved definition copy to credentialing-service */
export const getCredentialLookups = async (category?: string, cacheControl: boolean = true) => {
  try {
    const url = `${credentialingBase}/lookups${category ? `/${category}` : ''}`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url, {
        returnDataOnly: true,
      });
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCredentialLookups',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export const getModels = async () => {
  try {
    const url = `${credentialingBase}/models`;
    const response = await baseAxiosInstance.get(url);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getModels',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export const editCredential = async (id: number | undefined, credential: any) => {
  try {
    const url = `${credentialingBase}/update/${id}`;
    const response = await baseAxiosInstance.post(url, credential);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'editCredential',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    //** handle different edit api errors on ui */
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const createCredential = async (id: number | undefined, credential: any) => {
  try {
    const url = `${credentialingBase}/create/${id}`;
    const response = await baseAxiosInstance.post(url, credential);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'createCredential',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    return error.response;
  }
};

export const deleteCredential = async (candidateId: number | undefined, requirementID: number) => {
  try {
    const url = `${credentialingBase}/delete/${candidateId}/${requirementID}`;
    const response = await baseAxiosInstance.delete(url);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteCredential',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    return error.response;
  }
};

export const getAttachments = async (
  candidateId: string | number | undefined,
  requirementID: string | number | undefined,
  includeDocumentData: boolean = true,
) => {
  try {
    const url = `${credentialingAttachmentBase}/${candidateId}/${requirementID}?includeDocumentData=${includeDocumentData}`;
    const response = await baseAxiosInstance.get(url);
    return response?.data.documents;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAttachments',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export const deleteAttachment = async (
  candidateId: string | number | undefined,
  travelerRequirementId: string | number | undefined,
  travelerRequirementImageId: string | number | undefined,
) => {
  try {
    const url = `${credentialingAttachmentBase}/${candidateId}/${travelerRequirementId}/attachment/${travelerRequirementImageId}`;
    const response = await baseAxiosInstance.delete(url);
    return response?.status;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteAttachment',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export const addAttachment = async (
  candidateId: string | number | undefined,
  travelerRequirementId: string | number | undefined,
  newAttachment: any,
) => {
  try {
    const url = `${credentialingAttachmentBase}/${candidateId}/${travelerRequirementId}`;
    const response = await baseAxiosInstance.post(url, newAttachment);
    let responseReturn = response?.status;
    //if api accept document or upload is sucess
    if (responseReturn === 200 && response?.data === 1) {
      responseReturn = 200;
    } else {
      responseReturn = 503;
    }
    return responseReturn;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'addAttachment',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export const getRequirementTypes = async (cacheControl: boolean = true) => {
  try {
    const url = `${credentialingBase}/requirementtypes`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url, {
        returnDataOnly: true,
      });
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getRequirementTypes',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
};

export async function getExceptionWaiveDetails(exceptionWaiveId: number) {
  try {
    const url = `${credentialingBase}/exceptionwaive/${exceptionWaiveId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getExceptionWaiveDetails',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
}

export async function postExceptionWaiveDetails(data: any) {
  try {
    const url = `${credentialingBase}/exceptionwaive/update`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postExceptionWaiveDetails',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
  }
}

export async function postExceptionWaiveAttachments(data: any) {
  try {
    const url = `${credentialingAttachmentBase}/exceptionwaive/attachment/save`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postExceptionWaiveAttachments',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    return error.response;
  }
}

export async function updateExceptionWaiveAttachmentTitle(data: any) {
  try {
    const url = `${credentialingAttachmentBase}/exceptionwaive/attachment/title`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateExceptionWaiveAttachmentTitle',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteExceptionWaiveAttachment(data: any) {
  try {
    const url = `${credentialingAttachmentBase}/exceptionwaive/attachment/delete`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteExceptionWaiveAttachment',
        area: 'src/app/services/CredentialingServices/CredentialingServices.ts',
      },
    });
    return error.response;
  }
}
