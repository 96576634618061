import React from 'react';
import { Box, Button, Checkbox, Divider, Grid, IconButton, Tab, Tabs, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { useComposeEmailStyles } from './ComposeEmail.styles';
import styledComponent from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { SendType, UseType } from '@AMIEWEB/Notification/Constants';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { InitiationPoints, TemplateType } from 'app/models/Notification/Notification';
import { EmailTemplateList } from './EmailTemplateList';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { usePromiseTracker } from 'react-promise-tracker';

const TabBox = styledComponent(Box)`
  margin: 5% 5% 0 0%;
`;

export const EmailSettings = props => {
  const { t } = useTranslation();
  const { classes } = useComposeEmailStyles();
  const dispatch = useDispatch();
  const globalData = useSelector(notificationSelection);
  const data = globalData?.email?.data!;
  const { promiseInProgress } = usePromiseTracker({ area: 'delete-template', delay: 0 });
  const {
    isExpanded,
    emailTemplates,
    setEmailTemplates,
    groupEmail,
    setGroupEmail,
    action,
    setAction,
    loader,
    setLoader,
    templateChangeTracker,
    handleEmailPropertyChange,
    handleAttachments,
    setTemplateChangeTracker,
    handleSelectedTemplate,
    handleMinimize,
    getTemplatesWithCategory,
  } = props;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    event.stopPropagation();
    setEmailTemplates(prevState => ({ ...prevState, templateTab: newValue }));
  };

  const handleSignatureOnClick = () => {
    dispatch(globalActions.setProfileOpen(true));
    dispatch(globalActions.setEmailSignatureExpanded(true));
    handleMinimize();
  };

  const handleTemplateDelete = templateDataToDelete => {
    setLoader({ ...loader, backdropLoader: true });
    const payload = {
      templateId: templateDataToDelete?.id,
      category: templateDataToDelete?.category,
      getTemplate: () => getTemplatesWithCategory(templateDataToDelete?.category),
    };
    dispatch(notificationDataActions.deletePersonalTemplate(payload));
    if (!promiseInProgress) {
      setLoader({ ...loader, backdropLoader: false });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGroupEmail = !groupEmail;

    setGroupEmail(newGroupEmail);

    if (!newGroupEmail) {
      dispatch(notificationDataActions.setEmailType(SendType.bulk));
    } else {
      dispatch(notificationDataActions.setEmailType(SendType.one_to_one));
    }
  };

  return (
    <Grid
      item
      xs={isExpanded ? 2 : 3}
      className={isExpanded ? classes.expandedChangeTemplateGrid : classes.changeTemplateGrid}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Grid xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item xs={10}>
            <Typography variant="subtitle2" sx={{ fontSize: '1.15rem' }}>
              {'Email Settings'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={classes.closeSelection}
              disableRipple
              onClick={() => setEmailTemplates(prevState => ({ ...prevState, showTemplate: false }))}
            >
              <CloseIcon color="action" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid xs={12}>
          <Typography variant="h6" sx={{ fontSize: '0.9rem', paddingTop: '10px', fontWeight: '500' }}>
            {'Send As'}
          </Typography>
        </Grid>
        <Grid xs={12} sx={{ paddingLeft: '0px', paddingTop: '3px', display: 'flex', flexDirection: 'row' }}>
          <Grid item>
            <Checkbox
              name="urgency"
              color="primary"
              checked={
                groupEmail &&
                ((data.useType === UseType.ClientContacts && !data.facilityOneToOne) ||
                  data.packetSubmission ||
                  data.confirmationLetter ||
                  data.useType === UseType.Bulk ||
                  data?.initiationPoint === InitiationPoints.marginToolInternalEmail ||
                  data?.initiationPoint === InitiationPoints.marginToolCandidateEmail) &&
                !data?.disableGroupEmailCheckbox
              }
              onChange={event => {
                handleCheckboxChange(event);
              }}
              sx={{ padding: '0px' }}
              disabled={
                !(
                  (data.useType === UseType.ClientContacts && !data.facilityOneToOne) ||
                  data.packetSubmission ||
                  data.confirmationLetter ||
                  data.useType === UseType.Bulk ||
                  data?.initiationPoint === InitiationPoints.marginToolInternalEmail ||
                  data?.initiationPoint === InitiationPoints.marginToolCandidateEmail
                ) || data?.disableGroupEmailCheckbox
              }
            />
          </Grid>
          <Grid item sx={{ paddingTop: '1px', paddingLeft: '5px' }}>
            <label
              htmlFor="urgency"
              sx={{
                fontSize: '0.9rem',
                fontFamily: 'sans-serif',
                color: !(
                  (data.useType === UseType.ClientContacts && !data.facilityOneToOne) ||
                  data.packetSubmission ||
                  data.confirmationLetter ||
                  data.useType === UseType.Bulk
                )
                  ? '#BDBDBD'
                  : '',
              }}
            >
              {' Group Email'}
            </label>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start" className={classes.container}>
        <Grid item xs={10} sx={{ paddingLeft: '5%' }}>
          <Typography variant="h6" sx={{ fontSize: '0.9rem', fontWeight: '500' }}>
            <span>{t('notification.templateSelector')}</span>
          </Typography>
        </Grid>
      </Grid>
      <Tabs
        className={classes.templateSelectionTabs}
        textColor="primary"
        value={emailTemplates.templateTab}
        onChange={(event, newValue: string) => handleTabChange(event, newValue)}
      >
        <Tab className={classes.tab} label={TemplateType.Public} value={TemplateType.Public} />
        <Tab className={classes.tab} label={TemplateType.Personal} value={TemplateType.Personal} />
      </Tabs>
      <Divider />
      <TabBox
        className={isExpanded ? classes.expandedScroll : classes.templateScroll}
        sx={{ height: !isExpanded && emailTemplates.selectedTemplate?.title ? '460px' : '' }}
      >
        <>
          <EmailTemplateList
            type={emailTemplates.templateTab === TemplateType.Public ? TemplateType.Public : TemplateType.Personal}
            action={action}
            loader={loader}
            emailTemplates={emailTemplates}
            setEmailTemplates={setEmailTemplates}
            setLoader={setLoader}
            templateChangeTracker={templateChangeTracker}
            handleEmailPropertyChange={handleEmailPropertyChange}
            handleAttachments={handleAttachments}
            setAction={setAction}
            setTemplateChangeTracker={setTemplateChangeTracker}
            handleTemplateDelete={handleTemplateDelete}
            handleSelectedTemplate={handleSelectedTemplate}
          />
        </>
      </TabBox>
      <Grid container sx={{ padding: '3% 1% 1% 1%', alignItems: 'flex-end' }}>
        <Button
          onClick={() => {
            handleSignatureOnClick();
          }}
          color="primary"
          sx={{ alignItems: 'flex-start', paddingLeft: '0px' }}
        >{`Email Signature`}</Button>
      </Grid>
    </Grid>
  );
};
