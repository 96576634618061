import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import React, { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { FacilityContactsActions } from '../Store/FacilityContacts.redux';
import { selectFilterChipSelected } from '../Store/FacilityContacts.selector';

export const FacilityContactsChips = props => {
  const dispatch = useDispatch();
  const filterChipSelected = useSelector(selectFilterChipSelected);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  // Filter
  const formMethods = useFormContext();
  const { getValues, setValue } = formMethods;

  const determineChipSelected = (newChip, selected) =>
    newChip.isSelected ? null : [instanceOfISelection(newChip) ? newChip : newChip.value];

  //EVENTS
  const handleChipDelete = (field, chip) => {
    const chosenValue = getValues(field)?.selections?.length > 0 ? getValues(field)?.selections : getValues(field);
    let newChipValues = {};
    if (chosenValue && chosenValue.length > 0 && Array.isArray(chosenValue)) {
      const newValues = [...chosenValue];
      if (field === 'name' || field === 'contactPreference') {
        newValues.splice(
          newValues.findIndex(opt => opt === chip.value),
          1,
        );
      } else if (field === 'units') {
        newValues.splice(
          newValues.findIndex(opt => opt.value === chip?.value?.value?.unitId),
          1,
        );
        newChipValues = {
          selections: newValues?.map(opt => ({
            id: opt?.id,
            unitId: opt?.unitId,
            value: opt?.value,
            unitName: opt?.unitName,
            name: opt?.name,
          })),
          unitId: null,
          unitName: null,
        };
      } else {
        newValues.splice(
          newValues.findIndex(opt => opt.value === chip.label),
          1,
        );
      }

      setValue(field, field === 'units' ? newChipValues : newValues);
    } else if (chosenValue && field === 'units') {
      setValue(field, {
        ...chosenValue,
        unitId: chip?.value?.value === chosenValue?.unitId ? null : chosenValue?.unitId,
        unitName: chip?.value?.value === chosenValue?.unitName ? null : chosenValue?.unitName,
      });
    } else {
      setValue(field, null);
    }
    const newChipSelection = {
      ...filterChipSelected,
      [field]: null,
    };
    dispatch(FacilityContactsActions.setFilterChipSelected(newChipSelection));
    props.onApplyFilter(getValues(), newChipSelection);
  };

  const handleChipClick = (key, chip) => {
    if (!['pipelineSelected'].includes(key)) {
      const newChipSelection = {
        ...filterChipSelected,
        [key]: determineChipSelected(chip, filterChipSelected[key]),
      };
      // dispatch(FacilityContactsActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  return (
    <>
      <ChipsContainer
        chips={props.chips}
        onDelete={(field, chip, index) => handleChipDelete(field, chip)}
        onClick={(field, chip, index) => handleChipClick(field, chip)}
      />
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
