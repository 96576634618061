import { Grid, IconButton, TextField, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SendIcon from 'app/assets/images/Notification/SMS/Images/Send.svg';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import _cloneDeep from 'lodash/cloneDeep';
import { selectUser } from 'oidc/user.selectors';
import { taskDetailsActions } from '../store/Tasks.redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  CuedTo,
  ICueListForPlacement,
  ICueNote,
  ICueNoteContent,
  ICueNoteParent,
  ICueRecipient,
  RepliedTo,
} from 'app/models/Tasks/Tasks';
import {
  selectCheckPanelChange,
  selectCueNoteId,
  selectCueNoteReplyParent,
  selectCueNotesList,
  selectCueRecipient,
} from '../store/Tasks.selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import CueRecipientList from './CueRecipientList';
import { LastRecipientTab } from './LastRecipientTab';
import {  useResetCueNoteData } from './useResetCueNoteData';
import { ReplyCard } from './ReplyCard';
import { withReadOnly } from 'oidc/withReadOnly';
import { useReadOnly } from 'oidc/userRoles';
import { selectDrawerData } from 'store/redux-store/global-search/selectors';
import { getCueNotesCountByPlacementId } from 'app/services/TaskServices/TaskServices';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
import { getEmployeeDetails, hasSharedProfile } from '../EditTask/helper';

const useStyles = makeStyles()({
  inputs: {
    scrollBarBgColor: '#f1f1f1',
    backgroundColor: '#FFFFFF',
    color: '#888888',
    borderRadius: '2px',
    width: '15rem',
    '& .MuiFormHelperText-root': {
      backgroundColor: '#F5F5F5 !important',
      textAlign: 'right !important',
      marginTop: '0 !important',
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#CC0000 !important',
      caretColor: '#CC0000 !important',
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderColor: '#CC0000 !important',
      display: 'block',
    },
    '& .MuiOutlinedInput-root': {
      padding: '8px 9px 8px 9px !important',
      caretColor: '#006FB9 !important',
      display: 'block',
    },
    '& .MuiOutlinedInput': {
      padding: '0px 3px !important',
      display: 'block',
    },
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: '#006FB9 !important',
      },
    },
  },
  form: {
    padding: '10px 12px',
    justifyContent: 'space-between',
    display: 'flex',
  },
  container: {
    backgroundColor: '#F5F5F5',
    paddingTop: '10px',
  },
  sendButton: {
    padding: '0px',
    borderRadius: '0px',
    bottom: '10px',
    position: 'inherit',
    display: 'flex',
    width: '24px',
  },
  sendIcon: {
    display: 'inherit',
    width: '100%',
  },
  disabled: {
    opacity: '50%',
  },
  btnCol: {
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingBottom: '26px',
  },
});

export default function CueInput({
  clearCueField,
  setChangePanel,
  setShowRecipient,
  setOffset,
  getCueNotes,
  cueCount,
  setCueCount,
  setLastCueNoteId,
  selectedFilters,
  startDate,
  endDate,
  isFilterApplied,
  cueNotesDrawer = false,
}: {
  clearCueField: boolean;
  setChangePanel: (e) => void;
  setShowRecipient: (e) => void;
  setOffset: (e) => void;
  getCueNotes?: (boolean) => void;
  cueCount?: number | null;
  setCueCount?: (e) => void;
  lastCueNoteId?: number | null;
  setLastCueNoteId?: (e) => void;
  selectedFilters?: any[];
  startDate?: any;
  endDate?: any;
  isFilterApplied?: boolean;
  cueNotesDrawer?: boolean;
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  const checkPanel = useSelector(selectCheckPanelChange);
  const userInfo = useSelector(selectUser);
  const [textValue, setTextValue] = useState<any>('');
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'new-cue-note-submit', delay: 0 });
  const { promiseInProgress: callingGetCueNotes } = usePromiseTracker({ area: 'get-cue-notes-list', delay: 0 });
  const { promiseInProgress: callingGetCueNotesAlert } = usePromiseTracker({ area: 'get-cue-notes-alert', delay: 0 });
  const createdId = useSelector(selectCueNoteId);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [showRecipientList, setShowRecipientList] = React.useState(false);
  const [isReplyNoteRemoved, setReplyNoteRemoved] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState<string | undefined>(undefined);
  const NoCue = t('notification.taskSidePanel.cueNotes.noCue');
  const [showRecipientTab, setRecipientTab] = useState(false);
  const cueNotesList = useSelector(selectCueNotesList);
  const recipientList = useSelector(selectCueRecipient);
  const [lastRecipient, setLastRecipient] = useState<any>();
  const [isReplyCue, setReplyCue] = useState<boolean>(false);
  const parentDataForReply = useSelector(selectCueNoteReplyParent);
  const drawerData = useSelector(selectDrawerData);
  const [selectedNewRecipient, setSelectedNewRecipient] = useState(null);
  const CHARACTER_LIMIT = 4000;
  const replyRecipient = {} as ICueRecipient;
  const ref = useRef<any>(null);
  const { readOnly } = useReadOnly();

  const employeeDetails = getEmployeeDetails(userInfo?.userInfo);

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;

  const submitCueNote = formData => {
    const newCueNote = _cloneDeep(formData) as ICueNote;
    const recipient: ICueRecipient = recipientList.find(rcpt =>
      rcpt?.coverageDetails?.some(cvg => cvg.coveringEmployeeId === parseInt(getEmployeeId(userInfo?.userInfo))),
    );
    newCueNote.senderId = recipient ? recipient.employeeId : parseInt(getEmployeeId(userInfo?.userInfo)) || 0;
    newCueNote.recipientId = selectedRecipient && selectedRecipient !== NoCue ? parseInt(selectedRecipient) : 0;
    newCueNote.placementId = drawerData?.placementId ?? foundPlacement?.header?.placementId;

    if (hasSharedProfile(userInfo)) {
      newCueNote.sharedDeskAssociatedUser = {
        employeeId: userInfo?.userInfo?.employeeId.toString(),
        firstName: userInfo?.userInfo?.firstName,
        lastName: userInfo?.userInfo?.lastName,
      };
    }
    if (parentDataForReply) {
      newCueNote.parentCueNote = _cloneDeep(parentDataForReply) as ICueNoteParent;
    } else newCueNote.parentCueNote = undefined;

    if (recipient) {
      newCueNote.coverageDetail = {
        coveredByEmployeeId: parseInt(employeeDetails?.employeeId),
        coveredByEmployeeFirstName: employeeDetails?.firstName,
        coveredByEmployeeLastName: employeeDetails?.lastName,
      };
    }
    if (newCueNote.text?.replace(/\s+/g, '')?.length === 0) newCueNote.text = '';
    dispatch(taskDetailsActions.saveCueNoteAction(newCueNote));
    setValue('text', '');
    if (!isReplyCue) {
      const recipientData = recipientList?.filter(a => a?.employeeId === newCueNote?.recipientId);
      const cueNote: ICueNoteContent = {
        senderId: newCueNote.senderId,
        placementId: newCueNote.placementId,
        text: newCueNote?.text,
        statusId: newCueNote?.statusId,
        recipient: {
          id: recipientData[0]?.employeeId,
          firstName: recipientData[0]?.firstName,
          lastName: recipientData[0]?.lastName,
        },
      };
      if (recipientData?.length > 0) {
        dispatch(taskDetailsActions.setCueNoteCreatedData(cueNote));
      }
    }
    setReplyCue(false);
    setCueCount?.(prev => prev + 1);
    setSelectedNewRecipient(null);
    setReplyNoteRemoved(false);
  };

  const checkCharLimit = async () => {
    let response;
    if (!cueNotesDrawer && foundPlacement?.header?.placementId > 0) {
      response = await getCueNotesCountByPlacementId(foundPlacement?.header?.placementId);
    }
    if (response?.data > cueCount) {
      setCueCount?.(response.data);
      getCueNotes?.(true);
      setLastCueNoteId?.(cueNotesList[cueNotesList.length - 1]?.taskId);
    } else {
      if (!!isFilterApplied) {
        const data = {
          placementId: foundPlacement?.header?.placementId,
          createdStartDate: startDate || null,
          createdEndDate: endDate || null,
          cuedTo:
            selectedFilters?.[0]?.name === CuedTo ? selectedFilters?.[0]?.children?.map(c => parseInt(c.value)) : null,
          repliedToId:
            selectedFilters?.[0]?.name === RepliedTo
              ? selectedFilters?.[0]?.children?.map(c => parseInt(c.value))
              : null,
        } as ICueListForPlacement;
        if (data?.placementId > 0) {
          dispatch(taskDetailsActions.setCueListforPlacementOptions(data));
        }
      }
      textValue?.length <= CHARACTER_LIMIT
        ? selectedRecipient === NoCue || textValue.replace(/\s+/g, '').length > 0
          ? handleSubmit(submitCueNote)().catch(err => {
              dispatch(
                globalActions.setSnackBar({
                  message: t('notification.taskSidePanel.cueNotes.cueNoteCreationFailure'),
                  severity: 'error',
                }),
              );
            })
          : dispatch(
              globalActions.setSnackBar({
                message: t('notification.taskSidePanel.cueNotes.cueNoteInputMissingText'),
                severity: 'error',
              }),
            )
        : dispatch(
            globalActions.setSnackBar({
              message: t('notification.taskSidePanel.cueNotes.cueNoteInputLimitExceeded'),
              severity: 'error',
            }),
          );
      setLastCueNoteId?.(null);
    }
  };

  const onCloseCall = () => {
    setTextValue('');
    setValue('text', '');
    setCharacterCount(0);
    clearErrors('text');
    setDisableSubmit(true);
    setShowRecipientList(false);
    if (selectedRecipient === NoCue) dispatch(taskDetailsActions.setCueNoteDataId(undefined));
    setSelectedRecipient('');
    dispatch(taskDetailsActions.setCueNoteReplyParent(undefined));
    setLastRecipient([]);
    setReplyNoteRemoved(false);
  };
  useResetCueNoteData();

  useEffect(() => {
    setShowRecipient(showRecipientList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRecipientList]);

  useEffect(() => {
    if (clearCueField) onCloseCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCueField]);

  const getDisableSubmit = () => {
    const withinCharacterLimit = textValue?.length <= CHARACTER_LIMIT;
    if (showRecipientList) {
      if (selectedNewRecipient === NoCue) {
        return false;
      }
      if (selectedNewRecipient && textValue?.length > 0 && withinCharacterLimit) {
        return false;
      }
      return true;
    } else {
      if (isReplyCue && textValue?.length > 0 && withinCharacterLimit) {
        return false;
      }
      if (isReplyNoteRemoved && selectedRecipient && textValue?.length > 0 && withinCharacterLimit) {
        return false;
      }
      if (selectedNewRecipient && selectedNewRecipient === NoCue) {
        return false;
      }
      return true;
    }
  };

  const onInputChange = e => {
    setTextValue(e.target.value);
    if (e.target.value?.length > CHARACTER_LIMIT) {
      setDisableSubmit(true);
      setError('text', {
        type: 'maxLength',
        message: `${e.target.value?.length} / ${CHARACTER_LIMIT}`,
      });
    } else if (e.target.value?.length <= CHARACTER_LIMIT) {
      setDisableSubmit(false);
      clearErrors('text');
    }
  };
  const onFocusInput = () => {
    setShowRecipientList(!parentDataForReply);
    setRecipientTab(false);
  };

  useEffect(() => {
    if (!isReplyCue) {
      if (selectedRecipient && selectedRecipient !== NoCue) {
        const lastRecipientDetail = recipientList?.filter(a => a?.employeeId === parseInt(selectedRecipient));
        if (lastRecipientDetail?.length > 0) {
          dispatch(taskDetailsActions.setLastRecipientDetails(lastRecipientDetail[0]));
        }
      } else {
        dispatch(taskDetailsActions.setLastRecipientDetails(undefined));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecipient, lastRecipient]);

  useEffect(() => {
    setCharacterCount(textValue?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  useEffect(() => {
    if (createdId && Number(createdId) >= 0) {
      onCloseCall();
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.taskSidePanel.cueNotes.cueNoteCreationSuccess'),
          severity: 'success',
        }),
      );
    } else if (createdId && Number(createdId) < 0) {
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.taskSidePanel.cueNotes.cueNoteCreationFailure'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdId]);

  useEffect(() => {
    const disabledStatus = getDisableSubmit();
    setDisableSubmit(disabledStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue, selectedRecipient, selectedNewRecipient, showRecipientList, isReplyCue]);

  useEffect(() => {
    if (textValue && textValue?.length > 0) {
      setChangePanel(false);
    } else {
      setChangePanel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPanel, textValue]);

  useEffect(() => {
    const prevRecipient =
      cueNotesList?.length > 0 ? recipientList?.filter(a => a?.employeeId === cueNotesList[0]?.lastRecipientId) : [];
    if (parentDataForReply) {
      const recipientFromList = recipientList?.filter(a => a?.employeeId === parentDataForReply?.sentById!);
      setShowRecipientList(false);
      setReplyCue(true);
      dispatch(taskDetailsActions.setLastRecipientDetails(undefined));
      if (recipientFromList.length) {
        setLastRecipient(recipientFromList[0]);
        setSelectedRecipient(recipientFromList[0]?.employeeId.toString());
      } else {
        replyRecipient.placementId = foundPlacement.header.placementId;
        replyRecipient.employeeId = parentDataForReply.sentById!;
        replyRecipient.userName = userInfo?.userInfo?.ntUserName!;
        replyRecipient.firstName = parentDataForReply.sentByFirstName!;
        replyRecipient.lastName = parentDataForReply.sentByLastName!;
        setLastRecipient(replyRecipient);
        setSelectedRecipient(t('notification.taskSidePanel.cueNotes.noCue'));
      }
      setRecipientTab(false);
    } else {
      setReplyCue(false);
      if (prevRecipient?.length > 0 && !selectedNewRecipient) {
        setLastRecipient(prevRecipient);
        setSelectedRecipient(prevRecipient[0]?.employeeId.toString());
        setRecipientTab(true);
        if (textValue && textValue?.length < CHARACTER_LIMIT) setDisableSubmit(false);
      } else if (!selectedNewRecipient) {
        setLastRecipient(undefined);
        setRecipientTab(false);
        setDisableSubmit(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cueNotesList, recipientList, parentDataForReply]);

  useEffect(() => {
    if (ref !== null) setOffset(ref.current.clientHeight - 16);
  });

  return (
    <Grid className={classes.container} id="inputContainer" ref={ref}>
      {showRecipientList && (
        <CueRecipientList
          setShowRecipientList={setShowRecipientList}
          setSelectedRecipient={setSelectedRecipient}
          setSelectedNewRecipient={setSelectedNewRecipient}
          setRecipientTab={setRecipientTab}
          input={textValue}
          setInput={setTextValue}
          setCharacterCount={setCharacterCount}
          lastRecipient={lastRecipient}
          clearErrors={clearErrors}
          selectedRecipient={selectedRecipient}
        />
      )}
      {showRecipientTab && !showRecipientList && !callingGetCueNotes && (
        <LastRecipientTab lastRecipient={lastRecipient[0]} />
      )}
      <FormProvider {...formMethods}>
        <Grid className={classes.form}>
          <Grid>
            <Controller
              control={control}
              name={'text'}
              rules={{
                maxLength: { value: CHARACTER_LIMIT, message: `${characterCount} / ${CHARACTER_LIMIT}` },
              }}
              render={({ ref, onChange, value, ...rest }) => (
                <div>
                  <TextField
                    multiline
                    maxRows={6}
                    variant="outlined"
                    placeholder={t('notification.taskSidePanel.cueNotes.label')}
                    className={classes.inputs}
                    value={textValue}
                    error={isDirty && errors?.text ? true : false}
                    disabled={isCallingAPI}
                    id="text"
                    onChange={e => {
                      onChange(e);
                      onInputChange(e);
                    }}
                    onFocus={onFocusInput}
                    onKeyDown={e => {
                      if (e.keyCode === 13 && e.shiftKey && !disableSubmit) {
                        e.preventDefault();
                        checkCharLimit();
                      } else if (e.keyCode === 13 && e.shiftKey) {
                        e.preventDefault();
                      }
                    }}
                    helperText={`${characterCount} / ${CHARACTER_LIMIT}`}
                    InputProps={{
                      startAdornment:
                        parentDataForReply && !callingGetCueNotes ? (
                          <ReplyCard
                            cueNote={parentDataForReply}
                            closeButton={!(drawerData?.previewDrawerViewerType === 1)}
                            bgColor={theme.palette.framework.system.whisper}
                            onClose={() => {
                              setShowRecipientList(true);
                              setReplyNoteRemoved(true);
                              setDisableSubmit(true);
                            }}
                          />
                        ) : (
                          ''
                        ),
                    }}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid className={classes.btnCol}>
            <IconButton
              color="primary"
              className={classes.sendButton}
              disableRipple
              disableFocusRipple
              disabled={disableSubmit || isCallingAPI || callingGetCueNotesAlert}
              style={{ backgroundColor: 'transparent' }}
              size="small"
            >
              {withReadOnly('span')({
                className: `${classes.sendIcon} ${disableSubmit && classes.disabled}`,
                onClick: () => {
                  if (!readOnly) {
                    checkCharLimit();
                  }
                },
                'aria-hidden': 'true',
                children: <img src={SendIcon} alt="Send" style={{ width: '100%' }} />,
              })}
            </IconButton>
          </Grid>
        </Grid>
      </FormProvider>
    </Grid>
  );
}
