import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { Button, Grid, Alert, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  orderCreationReducer,
  orderCreationReset,
  orderCreationSliceKey,
  setEditUnitEditMode,
} from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';
import { createOrderOcbrReducer, createOrderOcbrSliceKey } from 'store/redux-store/create-order-ocbr/slice';
import { createOrderOcbrSaga } from 'store/redux-store/create-order-ocbr/saga';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  selectEditUnit,
  selectError,
  selectFacilityDetails,
  selectFilterCertificationsDecision,
  selectHasNotNow,
  selectMandatoryFieldsStatus,
  selectMessage,
  selectStatus,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { CustomCircularProgress } from '@AMIEWEB/Order/OrderDetails/OrderDetailsDesign';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { isNumeric } from 'utils/string/string';
import { ff_order_ui_qualificationlist } from 'app/constants/FeatureFlags/Order/Keys';
import { useDecision } from '@optimizely/react-sdk';
import {
  FacilityContactSliceKey,
  FacilityContactsReducer,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { facilityClientContactsSaga } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.saga';
import UnitDetailWrapper from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailWrapper';
import UnitDetailHeader from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailHeader';
import {
  FacilityUnitActions,
  FacilityUnitKey,
  FacilityUnitReducer,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { facilityUnitsSaga } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga';
import {
  selectLicenseData,
  selectSkillsSetsForUnits,
  selectTransformedIssuedBy,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';

const useStyles = makeStyles()(theme => ({
  customAlert: {
    width: '100%',
    zIndex: 99,
    top: '40px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    marginBottom: '12px',
    '& .MuiAlert-icon': {
      fontSize: 24,
    },
    alignItems: 'center',
    height: '52px',
  },
  alertMessage: {
    padding: '13px 0',
  },
  alertIcon: {
    padding: '9px 0',
  },
  fullWidth: {
    width: '100%',
  },
  addJustButton: {
    marginBottom: '5px',
  },
  cancelButton: {
    backgroundColor: '#8E9195',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8E9195',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginBottom: 22,
  },
  button: {
    width: 'auto',
    fontSize: '12px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#ffffff',
    },
  },
  buttonWidth: {
    marginRight: theme.spacing(1),
  },
  standardizeButton: {
    fontSize: 12,
    fontWeight: 700,
    margin: '0 1rem',
    'margin-right': '0.2rem',
  },
}));

export const EditUnitWrapper = () => {
  const params = useParams<{ id: string; unitId: string }>();
  useDocumentTitle({ title: `Edit Unit - Unit Id ${params.unitId}` });

  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });

  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });

  useInjectSaga({ key: FacilityContactSliceKey, saga: facilityClientContactsSaga });
  useInjectReducer({ key: FacilityContactSliceKey, reducer: FacilityContactsReducer });

  useInjectSaga({ key: FacilityUnitKey, saga: facilityUnitsSaga });
  useInjectReducer({ key: FacilityUnitKey, reducer: FacilityUnitReducer });

  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });
  const { classes } = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const facilityDetails = useSelector(selectFacilityDetails);
  const appStatus = useSelector(selectStatus);
  const message = useSelector(selectMessage);
  const newUnitObj = useSelector(selectEditUnit);
  const isMandatoryFilled = useSelector(selectMandatoryFieldsStatus);
  const hasNotNow = useSelector(selectHasNotNow);
  const errorDetails = useSelector(selectError);
  const [openCancelConfirmation, setOpenCancelConfirmation] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [filterCertListFlag] = useDecision(ff_order_ui_qualificationlist);
  const selectDecision = useSelector(selectFilterCertificationsDecision);
  const issuedByData = useSelector(selectTransformedIssuedBy);
  const licenseData = useSelector(selectLicenseData);
  const skillSetGroup = useSelector(selectSkillsSetsForUnits);
  if (selectDecision === undefined || selectDecision === null) {
    dispatch(orderCreationActions.setCertificationsFlagDecision(filterCertListFlag.enabled));
  }

  const formMethods = useForm({ defaultValues: newUnitObj, shouldUnregister: false });
  const { getValues, reset } = formMethods;

  const handleBeforeUnload = event => {
    if (isDirty) {
      event.returnValue = '';
      event.preventDefault();
      setOpenCancelConfirmation(true);
    }
  };

  useEffect(() => {
    if (Number(params?.id)) {
      const facilityId = Number(params.id);
      const unitId = Number(params.unitId);
      const editMode = queryParams.get('mode') ?? 'standardized';
      const remberMode = isNumeric(queryParams.get('rember')) && Number(queryParams.get('rember')) > -1 ? true : false;
      dispatch(
        orderCreationActions.unitEditInitializationAction({
          id: facilityId,
          unitId,
          editMode,
          remberMode,
          isNewUnitPage: true,
        }),
      );
      if (!licenseData?.length) dispatch(FacilityUnitActions.getLicenses({ id: parseInt(params.id) }));
      dispatch(FacilityUnitActions.getCompactStatus());
      if (!issuedByData?.length) dispatch(FacilityUnitActions.getIssuedBy());
      if (!skillSetGroup?.length) dispatch(FacilityUnitActions.getSkillSetsForUnits({ unitId: unitId }));
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      dispatch(FacilityUnitActions.setInvalidUnitName(false));
    };
  }, [params?.id]);

  useMemo(() => {
    if (!!appStatus && appStatus === 'succeeded') {
      reset(newUnitObj);
    } else if (!!appStatus && appStatus === 'proceedToParent') {
      reset({});

      dispatch(orderCreationReset());
      history.push({
        pathname: `/facility/${params.id}/unit/${params.unitId}`,
        state: 'navigationCycle',
      });
    }
  }, [appStatus]);

  useMemo(() => {
    if (!!errorDetails && errorDetails.isError === true && !openErrorMessage) {
      setOpenErrorMessage(true);
    }
  }, [errorDetails]);
  /*Event Handlers*/

  const handleCancelConfirmClose = (isConfirm: boolean) => {
    if (isConfirm) {
      reset({});

      dispatch(orderCreationReset());
      setOpenErrorMessage(false);
      history.push({
        pathname: `/facility/${params.id}/unit/${params.unitId}`,
        state: 'navigationCycle',
      });
    }
  };

  const handleStandardizeClick = () => {
    dispatch(setEditUnitEditMode('standardize'));
  };

  /*End Event Handlers*/

  return appStatus !== 'loading' && !!facilityDetails.facilityId && errorDetails && !errorDetails.isError ? (
    <>
      {appStatus === 'newLoad' && <CustomCircularProgress sx={{ zIndex: 999 }} />}
      <DetailsPage
        title={`Edit Unit`}
        isCreateForm={false}
        paddingBottom="4rem"
        titleActions={
          hasNotNow ? (
            <Button
              variant="contained"
              color="primary"
              classes={{ containedPrimary: `${classes.standardizeButton} button` }}
              onClick={handleStandardizeClick}
            >
              {`Standardize Unit`}
            </Button>
          ) : null
        }
        head={<UnitDetailHeader />}
        infoBanner={
          isMandatoryFilled !== true &&
          message !== undefined &&
          message !== null &&
          message !== '' && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                className={classes.customAlert}
                classes={{
                  message: classes.alertMessage,
                  icon: classes.alertIcon,
                }}
              >
                {message}
              </Alert>
            </Grid>
          )
        }
      >
        <UnitDetailWrapper />
      </DetailsPage>
      {openCancelConfirmation && (
        <GenericDialog
          open={openCancelConfirmation}
          onClose={() => setOpenCancelConfirmation(false)}
          dialogTitleProps={{ text: t('cancelModal.undoConfirmation') }}
          children={<Typography> {t('cancelModal.saveConfirmation')} </Typography>}
          fullWidth
          maxWidth={'sm'}
          disableEscapeKeyDown
          dialogActions={[
            {
              text: t('undoModal.confirmation.cancel'),
              color: 'tertiary',
              onClick: () => {
                setOpenCancelConfirmation(false);
              },
            },
            {
              text: t('undoModal.confirmation.yes'),
              variant: 'contained',
              onClick: () => {
                handleCancelConfirmClose(true);
              },
            },
          ]}
        />
      )}
    </>
  ) : !!errorDetails && errorDetails.isError === true ? (
    <>
      <GenericDialog
        open={openErrorMessage && errorDetails.isError}
        onClose={() => handleCancelConfirmClose(false)}
        dialogTitleProps={{ text: errorDetails.error }}
        fullWidth
        maxWidth={'sm'}
        disableEscapeKeyDown
        dialogActions={[
          {
            text: 'Ok',
            variant: 'contained',
            onClick: () => {
              handleCancelConfirmClose(true);
            },
          },
        ]}
      />
    </>
  ) : (
    <CustomCircularProgress />
  );
};
