import React, { useEffect, useState } from 'react';
import AutoCompleteRenderChips from '@AMIEWEB/Order/OrderCreationForm/Common/AutoCompleteRenderChips';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { commonVIPriority } from '@AMIEWEB/Unit/helper';
import { disabledOrderPrioritiesList, virtualRatingList, virtualRatingListWithVoiceAdvantage } from 'app/constants';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { cloneDeep } from 'lodash';
import { getOPObjectbyLabel, updateArrayLabels } from './Helper';
import { UseAtoIValuePresent } from './hook';

const OrderPriorities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderPriorities = useSelector(selectOrderPriorities);
  const [orderDisabledPriorities, setOrderDisabledPriorities] = useState<string[]>([]);

  const {
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext();
  const watchValue = !!getValues('orderPriorities')
    ? cloneDeep(updateArrayLabels(getValues('orderPriorities'), orderPriorities))
    : [];
  const watchVirtualRatingValue = !!getValues('virtualRating') ? getValues('virtualRating') : [];

  const facility = useSelector(selectFacility);
  const facilityDetailsResponse = facility.facilityResponse;
  const isVoiceAdvantageDisabled = UseAtoIValuePresent(watchValue);
  const addToWatchValueIfNotPresent = label => {
    const object = getOPObjectbyLabel(label, orderPriorities);

    if (!watchValue.find(item => item?.object?.name === label) && object) {
      watchValue.unshift({
        object: object,
        label: object.value + '. ' + object.name,
      });
      setValue('orderPriorities', watchValue);
    }
  };

  const removeFromWatchValueIfPresent = label => {
    const index = watchValue.findIndex(item => item?.object?.name === label);
    if (index !== -1) {
      watchValue.splice(index, 1);
      setValue('orderPriorities', watchValue);
    }
  };

  facilityDetailsResponse?.isMagnetStatus
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.magnetStatus'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.magnetStatus'));

  facilityDetailsResponse?.isTraumaCenter
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.traumaCenter'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.traumaCenter'));

  facilityDetailsResponse?.isTeaching
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.teachingHospital'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.teachingHospital'));

  facilityDetailsResponse?.bedSize <= 199 && facilityDetailsResponse?.bedSize > 0
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.bedSize'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.bedSize'));

  useEffect(() => {
    dispatch(orderCreationActions.getOrderPriorityTypes());
  }, []);

  useEffect(() => {
    let options: string[] = [];
    const mergeList = isVoiceAdvantageDisabled
      ? [...disabledOrderPrioritiesList, commonVIPriority]
      : [...disabledOrderPrioritiesList];

    if (orderPriorities && orderPriorities.length > 0) {
      mergeList.forEach(label => {
        const option = orderPriorities.find(x => x.hasOwnProperty('object') && x.object.name === label);
        if (option) {
          options.push(option.label);
        }
      });
    }
    setOrderDisabledPriorities(options);
  }, [orderPriorities, isVoiceAdvantageDisabled]);

  const onOrderPrioritiesUpdate = data => {
    const orderPrioritiesSelectionData = [...data];
    const isAtoIDataPresent = data?.some(x => virtualRatingList?.includes(x?.object?.name));
    const isVoiceAdvantageDataPresent = data?.some(x => x?.object?.name === commonVIPriority);
    if (isAtoIDataPresent && !isVoiceAdvantageDataPresent) {
      setValue(
        'orderPriorities',
        [
          ...orderPrioritiesSelectionData,
          {
            object: { value: 45, name: commonVIPriority },
            label: '45. ' + commonVIPriority,
          },
        ],
        { shouldDirty: true },
      );
    } else if (isVoiceAdvantageDisabled && !isAtoIDataPresent) {
      setValue(
        'orderPriorities',
        orderPrioritiesSelectionData?.filter(item => item?.object?.name !== commonVIPriority),
        { shouldDirty: true },
      );
      if (orderPrioritiesSelectionData?.length === 1) {
        setValue('virtualRating', [], { shouldDirty: true });
        return;
      }
    } else {
      setValue('orderPriorities', orderPrioritiesSelectionData, { shouldDirty: true });
      if (orderPrioritiesSelectionData?.length === 0) {
        setValue('virtualRating', [], { shouldDirty: true });
      }
    }
  };

  useEffect(() => {
    if (isDirty) {
      const uniqueWatchValues = [...new Map(watchValue.map(item => [item.label, item])).values()];
      if (watchVirtualRatingValue?.length === 0) {
        setValue(
          'orderPriorities',
          uniqueWatchValues?.filter(item => !virtualRatingListWithVoiceAdvantage.includes(item?.object?.name)),
          { shouldDirty: true },
        );
        return;
      }
      const virtualRatingsFilteredData = orderPriorities.filter(item =>
        watchVirtualRatingValue?.map(op => op?.label).includes(item?.object?.name),
      );
      const virtualRatingsValue = new Set(virtualRatingsFilteredData.map(item => item?.object?.name));
      setValue('orderPriorities', [
        ...virtualRatingsFilteredData.filter(
          item => !uniqueWatchValues.some(first => first?.object?.name === item?.object?.name),
          { shouldDirty: true },
        ),

        ...uniqueWatchValues.filter(
          item =>
            virtualRatingsValue.has(item?.object?.name) ||
            !virtualRatingListWithVoiceAdvantage.includes(item?.object?.name),
        ),
      ]);
    }
  }, [watchVirtualRatingValue?.length]);

  return (
    <AutoCompleteRenderChips
      label={t('facility.units.unitDetails.additionalInformation.orderPriorities')}
      options={orderPriorities}
      id="order-priorities-dropdown"
      name="orderPriorities"
      disabledValue={orderDisabledPriorities}
      getOptionDisabled={option => !!orderDisabledPriorities?.includes(option.label)}
      disabledOptionsTooltipString={t(
        'facility.units.unitDetails.additionalInformation.orderPrioritiesDisabledOptionsTooltip',
      )}
      optionsWithHoverEffect={disabledOrderPrioritiesList}
      handleChange={data => onOrderPrioritiesUpdate(data)}
      handleDataChangeInParent={true}
    />
  );
};

export default OrderPriorities;
