// export const validDecimal = (value: string, restrictions: { numberLength: number; decimalLength: number }): boolean => {
//   const regex = new RegExp(
//     `^([0-9]{0,${restrictions.numberLength}}[.]{1}[0-9]{0,${restrictions.decimalLength}}|[0-9]{0,${restrictions.numberLength}})$`,
//   );
//   const validate = value.match(regex);
//   // Code: Uncomment to restrict overall length regardless of decimal place
//   // const length = value.replace(/[^0-9]/g, '').length <= restrictions.numberLength;
//   return validate !== null; // && length;
// };

export const validDecimal = (
  value: string,
  restrictions: { numberLength: number; decimalLength: number; belowOne?: boolean; maxValue?: number },
): boolean => {
  const { numberLength, decimalLength, belowOne, maxValue } = restrictions;

  const regex = new RegExp(`^([0-9]{0,${numberLength}}[.]{1}[0-9]{0,${decimalLength}}|[0-9]{0,${numberLength}})$`);
  const validate = value.match(regex);

  const belowOneRegex = new RegExp(`^0(\\.\\d{0,${decimalLength}})?$`);
  const numericValue = parseFloat(value);

  if (!validate) return false;

  if (belowOne && !belowOneRegex.test(value)) return false; // Check for below one condition
  if (maxValue !== undefined && (numericValue < 0 || numericValue > maxValue)) return false; // Check max value

  return true;
};

export const validInteger = (value: string, restrictions: { numberLength: number }): boolean => {
  const regex = new RegExp(`^([0-9]{0,${restrictions.numberLength}})$`);
  const validate = value.match(regex);
  // Code: Uncomment to restrict overall length regardless of decimal place
  // const length = value.replace(/[^0-9]/g, '').length <= restrictions.numberLength;
  return validate !== null; // && length;
};

export const formatToAmericanNotaion = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function kFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ];
  const regexExp = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item ? (num / item.value).toFixed(digits).replace(regexExp, '$1') + item.symbol : '0';
}

/**
 * Method to round off dollar values to 2 decimal points
 * @param value
 * @returns
 */
export const formatDollarValues = (value: number) => {
  return value?.toFixed(2);
};
