import { Grid, TextField } from 'amn-ui-core';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useSubStyles } from './useSubStyles';

export interface IUnitSubSearch {
  unitName: string;
  unitId: string;
}

export const UnitSubSearch: React.FC<{
  value: IUnitSubSearch;
  onChange: (state: IUnitSubSearch) => void;
}> = ({ value, onChange }) => {
  const { classes } = useSubStyles();
  const { t } = useTranslation();

  const handleChange = React.useCallback(
    (searchProps: { [key in keyof IUnitSubSearch]?: string }) => {
      const filterKey = Object.keys(searchProps);

      searchProps[filterKey[0]] = searchProps[filterKey[0]] ?? null;
      onChange({ ...value, ...searchProps });
    },
    [onChange, value],
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={1}
      classes={{
        root: classes.headerContainer,
      }}
    >
      <Grid item>
        <TextField
          value={value?.unitName ?? ''}
          variant="outlined"
          size="small"
          className={classes.textFieldFilterName}
          label={<SearchLabel label={t('search.filter.unitSearch.unitName')} />}
          inputProps={{ maxLength: 225 }}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ unitName: event.target?.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          value={value?.unitId ?? ''}
          variant="outlined"
          size="small"
          className={classes.textFieldFid}
          label={<SearchLabel label={t('search.filter.unitSearch.uid')} />}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => {
            if (/^\d*$/.test(event.target?.value)) {
              handleChange({ unitId: event.target?.value });
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

const SearchLabel = ({ label }) => (
  <>
    <SearchIcon style={{ opacity: 0.6 }} /> <span>{label}</span>
  </>
);
