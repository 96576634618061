import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { selectUnitSaveNameError } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { getPositiveIntegerValue } from '@AMIEWEB/NewOrder/CreateOrder/helper';
import UnitDetailCard from '@AMIEWEB/Order/OrderCreationForm/Common/UnitDetailCard';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Grid, Switch } from 'amn-ui-core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ nameHasError: boolean }>()((theme, { nameHasError }) => ({
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${nameHasError ? theme.palette.system.errorRed : theme.palette.system.primary} !important`,
    },
  },
  switchSelected: { paddingLeft: '15px' },
  switchUnselected: { paddingRight: '15px' },
  switchLabel: {
    fontSize: '14px',
  },
  switchLightBlue: {
    '& .MuiSwitch-switchBase': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: `${theme.palette.secondary.main}`,
    },
    '& .MuiSwitch-track': {
      backgroundColor: `${theme.palette.system.backgroundGrey}`,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${theme.palette.secondary.main}`,
    },
  },
}));

const SwitchLabel = ({ text }) => <span style={{ display: 'inline-block', minWidth: '106px' }}>{text}</span>;

const Information = () => {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const dispatch = useDispatch();
  const { classes } = useStyles({ nameHasError: Boolean(errors?.name) });
  const unitNameError = useSelector(selectUnitSaveNameError);

  return (
    <UnitDetailCard
      icon={<SummarizeOutlinedIcon />}
      title={t('facility.units.unitDetails.unitRequirements.information')}
    >
      <Grid container>
        <Grid xs={4} pr={2} pb={2} item>
          <Controller
            name={'name'}
            control={control}
            rules={{ required: true }}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                inputName={'name'}
                inputLabel={t('order.createUnit.unitDetails.unitName')}
                inputDefaultValue={rest.value}
                inputValue={rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value);
                  dispatch(FacilityUnitActions.setInvalidUnitName(false));
                }}
                id={'createUnitName'}
                fieldStyle={classes.fieldStyle}
                inputError={unitNameError || errors?.name}
                helperText={
                  unitNameError
                    ? t('facility.units.unitDetails.unitRequirements.unitNameAlreadyExists')
                    : errors?.name
                    ? t('facility.units.unitDetails.unitRequirements.required')
                    : ''
                }
              />
            )}
          />
        </Grid>
        <Grid xs={2.5} pr={2} pb={2} item>
          <Controller
            name={'unitSize'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                id={'createUnitSize'}
                inputDefaultValue={null}
                inputLabel={t('order.createUnit.bestBetsUnitSize.unitSize')}
                inputName={'unitSize'}
                inputValue={rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value === '' ? null : getPositiveIntegerValue(event.target.value));
                }}
                inputType={'number'}
                fieldStyle={classes.fieldStyle}
              />
            )}
          />
        </Grid>
        <Grid xs={2.5} pr={2} pb={2} item marginRight={6}>
          <Controller
            name={'costCenter'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                inputName={'costCenter'}
                inputLabel={t('order.createUnit.unitDetails.costCenter')}
                inputDefaultValue={rest.value}
                inputValue={rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value);
                }}
                id={'createUnitCostCenter'}
                fieldStyle={classes.fieldStyle}
              />
            )}
          />
        </Grid>
        <Grid item paddingRight={2}>
          <Grid>
            <Controller
              name={'fyreIntegration'}
              control={control}
              render={({ onChange, ...rest }) => (
                <Grid className={classes.switchLabel}>
                  <SwitchLabel text={t('facility.units.unitDetails.fyreIntegration')} />
                  <Switch
                    checked={rest.value}
                    className={`${classes.switchLightBlue} ${
                      rest.value ? classes.switchSelected : classes.switchUnselected
                    }`}
                    onClick={() => {
                      onChange(!rest.value);
                    }}
                  />
                  {rest.value
                    ? t('order.orderDetails.buttonConfirmation.Yes')
                    : t('order.orderDetails.buttonConfirmation.No')}
                </Grid>
              )}
            />
          </Grid>
          <Grid>
            <Controller
              name={'ssn'}
              control={control}
              render={({ onChange, ...rest }) => (
                <Grid className={classes.switchLabel}>
                  <SwitchLabel text={`${t('facility.units.unitDetails.ssn')} ${t('order.requiredMessage')}`} />
                  <Switch
                    checked={rest.value}
                    className={`${classes.switchLightBlue} ${
                      rest.value ? classes.switchSelected : classes.switchUnselected
                    }`}
                    onClick={() => {
                      onChange(!rest.value);
                    }}
                  />
                  {rest.value
                    ? t('order.orderDetails.buttonConfirmation.Yes')
                    : t('order.orderDetails.buttonConfirmation.No')}
                </Grid>
              )}
            />
          </Grid>
          <Grid>
            <Controller
              name={'dob'}
              control={control}
              render={({ onChange, ...rest }) => (
                <Grid className={classes.switchLabel}>
                  <SwitchLabel text={`${t('facility.units.unitDetails.dob')} ${t('order.requiredMessage')}`} />
                  <Switch
                    checked={rest.value}
                    className={`${classes.switchLightBlue} ${
                      rest.value ? classes.switchSelected : classes.switchUnselected
                    }`}
                    onClick={() => {
                      onChange(!rest.value);
                    }}
                  />
                  {rest.value
                    ? t('order.orderDetails.buttonConfirmation.Yes')
                    : t('order.orderDetails.buttonConfirmation.No')}
                </Grid>
              )}
            />
          </Grid>
        </Grid>
        <Grid xs={4} pr={2} pb={2} item>
          <Controller
            name={'floor'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                inputName={'information.floor'}
                inputLabel={t('order.createUnit.unitDetails.floor')}
                inputValue={rest.value}
                inputDefaultValue={rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value);
                }}
                id={'createUnitFloor'}
                fieldStyle={classes.fieldStyle}
              />
            )}
          />
        </Grid>
      </Grid>
    </UnitDetailCard>
  );
};

export default Information;
